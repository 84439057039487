import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import injectReducer from '../../utils/injectReducer';
import reducer, { setAnswer, startViewQuestion, clickLearnMore } from './reducer';

const key = 'questions';

const mapStateToProps = (state) => {
  const answers = state.getIn([key, 'answers']);
  const startTimes = state.getIn([key, 'startTimes']);
  const learnMoreClicks = state.getIn([key, 'learnMoreClicks']);
  return {
    answers: answers ? answers.toJS() : [],
    score: answers ? answers.filter((a) => a && a[1]).size : 0,
    startTimes,
    learnMoreClicks,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setAnswer,
  startViewQuestion,
  clickLearnMore,
}, dispatch);

export const withReducer = injectReducer({ key, reducer });

export const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withReducer, withConnect);
