import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql, withPrefix } from 'gatsby'

import Box from '../../components/Box';

const Layout = ({ children, title, og }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            url
            description
          }
        }
      }
    `}
    render={({ site: { siteMetadata } }) => {
      const t = title || siteMetadata.title;
      return (
        <div>
          <Helmet>
            <html lang="zh-Hant" />
            <title>{t}</title>
            <meta name="description" content={siteMetadata.description} />
            <link rel="apple-touch-icon" sizes="180x180" href={withPrefix('/apple-touch-icon.png')} />
            <link rel="icon" type="image/png" sizes="32x32" href={withPrefix('/favicon-32x32.png')} />
            <link rel="icon" type="image/png" sizes="16x16" href={withPrefix('/favicon-16x16.png')} />
            <link rel="mask-icon" color="#5bbad" href={withPrefix('/safari-pinned-tab.svg')} />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff" />
            <meta property="og:title" content={t} />
            <meta property="og:description" content={siteMetadata.description} />
            <meta property="og:image" content={`${siteMetadata.url}/${og || 'fb'}.png`} />
          </Helmet>
          <Box height="100vh">
            {children}
          </Box>
        </div>
      );
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node,
}

export default Layout
