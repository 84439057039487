import { fromJS } from 'immutable';

// action type 用來分辨現在的Action是哪個Action
export const SET_ANSWER = 'App/QuestionPage/SET_ANSWER';
export const START_VIEW_QUESTION = 'App/QuestionPage/START_VIEW_QUESTION';
export const CLICK_LEARN_MORE = 'App/QuestionPage/CLICK_LEARN_MORE';

export const setAnswer = (qId, optId, isCorrect) => ({
  type: SET_ANSWER,
  qId,
  optId,
  isCorrect,
});

export const startViewQuestion = (qId) => ({
  type: START_VIEW_QUESTION,
  qId,
});

export const clickLearnMore = (qId) => ({
  type: CLICK_LEARN_MORE,
  qId,
});

// action 就是 {
//   type: SET_ANSWER,
//   qId,
//   optId,
//   isCorrect,
// }

const initialState = fromJS({
  answers: [],
  startTimes: [],
  learnMoreClicks: [],
});

export default function QuestionReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ANSWER:
      return state.setIn(['answers', action.qId], [action.optId, action.isCorrect, new Date()]);
    case START_VIEW_QUESTION:
      return state.setIn(['startTimes', action.qId], new Date());
    case CLICK_LEARN_MORE:
      // 使用updateIn會先取出原本的值，如果有值，就不變更，沒值，就記錄現在時間
      // 如此一來就會只記錄第一次點learn-more的時間點
      return state.updateIn(['learnMoreClicks', action.qId], (val) => {
        if (val) return val;
        if (window.ga) {
          window.ga('send', 'event', '深入了解', `第${action.qId + 1}題`);
        }
        return new Date();
      });
    default:
      return state;
  }
}
