import styled, { keyframes } from 'styled-components'

import isIE from '../../components/utils/isIE'
import injectProps from '../../components/utils/injectProps'

const pulse = keyframes`
  0%, 100% {
    transform: scale(0.2)
  }

  50% {
    transform: scale(1)
  }
`

export default styled.circle`
animation: ${pulse} 3s infinite;
${isIE && 'opacity: 0.5;'}
${injectProps('animationDelay')}
${(props) => `transform-origin: ${props.cx}px ${props.cy}px`}
`;
