import React from 'react';

import Box from './Box';
import { mobileOrDesktop } from './ThemeProvider/theme';


const Title = ({ children, ...props}) => {
  return (
    <Box {...props} pt={mobileOrDesktop('0.5em', '1em')}>
      <Box fontSize={mobileOrDesktop('2em', '3.25em', '1.75em', '3em')} fontWeight="bold" color="yellow" position="relative" display="inline-block">
        {children}
        <Box position="absolute" top="0" left="0" color="black" transform="translateX(0.1em)">
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default Title;
