import React from 'react';
import PulseCircle from './PulseCircle'
import SVG from '../../components/SVG'

const Background = ({ handleOnClick }) => {
  return (
    <SVG viewBox="0 0 1920 1080" display="block">
      <g>
        <g opacity="0.5">
          <polygon points="-1.5 614.5 787.5 376.5 1223.5 376.5 1919.5 597.5 1919.5 1094.5 -1.5 1094.5 -1.5 614.5" fill="#ccc" stroke="#999" stroke-miterlimit="10" style={{ mixBlendMode: 'multiply' }}/>
          <path d="M184.05,555.07l151.06-45.42L410.65,487l75.65-22.28,302.61-89.16a1.41,1.41,0,0,1,.35-.05H1223a1.15,1.15,0,0,1,.37.06l334.33,105.87,167.16,52.93,41.79,13.23,41.72,13.45L1891.84,588l-.17,0,105.44.56-105.44.56a.41.41,0,0,1-.16,0h0l-83.72-26-41.86-13-41.79-13.23L1557,483.82,1222.65,378l.38.06H789.26l.36-.05L487,467.11l-75.65,22.3L335.6,511.33Z" fill="#999"/>
          <path d="M1725.9,533l-1.8-58.69-.22-7.33c-.06-2.45,0-4.89,0-7.34,0-4.89,0-9.78,0-14.67l.43-29.35.2-14.68c.07-4.89,0-9.78.07-14.67l.07-29.34.17-58.69.09-29.34c0-9.78-.07-19.56-.11-29.34-.12-19.57-.11-39.12-.38-58.69l-.33-29.35c-.07-9.78-.29-19.56-.2-29.34l.15-58.7a1.27,1.27,0,0,1,1-1.22l85.69-20.67,21.47-5c7.15-1.66,14.29-3.4,21.48-4.91l43.07-9.36h0a.53.53,0,0,1,.35,0l32,15.91-32.52-14.9.36,0L1854.19,34c-7.12,1.81-14.3,3.39-21.45,5l-21.47,5L1725.6,64.65l1-1.21-.15,58.69c-.09,9.78.13,19.56.2,29.34l.33,29.34c.27,19.56.26,39.13.38,58.7,0,9.78.13,19.56.11,29.35l-.09,29.34-.17,58.7-.07,29.35c0,4.89,0,9.78-.07,14.67l-.2,14.67-.4,29.34c-.06,4.89-.25,9.78-.37,14.67-.06,2.45-.17,4.89-.18,7.34v7.33Z" fill="#999"/>
          <path d="M1725,153.62c-8,0-16.06,0-24.08,0l-24.08-.07,1.13-1.1c.06,4,.18,8,.14,11.93l-.06,11.93-.07,23.86c0,15.91-.21,31.82-.35,47.72s-.42,31.81-.2,47.71l.33,47.71-1.18-1.24,48.5,2.61H1725L1804,340l19.73-1.65c6.59-.51,13.18-.83,19.77-1.26l39.56-2.34-39.42,4.15c-6.57.66-13.14,1.42-19.72,2l-19.75,1.59-79.11,4.68H1725l-48.5-2.6a1.26,1.26,0,0,1-1.18-1.24l-.33-47.73c-.22-15.91.07-31.81.2-47.72s.36-31.81.35-47.72l.07-23.86V164.37c0-4,.12-8,.21-11.92v0a1.07,1.07,0,0,1,1.1-1.05h0l24,1.1Q1713,153,1725,153.62Z" fill="#999"/>
          <path d="M1753.26,394.76l.36,4.69.29,4.69.58,9.38,0,18.76-.07,37.5-.46,37.51-.24,18.75c-.49,6.25-.89,12.5-1.48,18.74-.43-6.26-.67-12.51-1-18.76l.24-18.75.46-37.5.07-37.51,0-18.75.61-9.37.31-4.69Z" fill="#999"/>
          <path d="M1768.14,394.76c.39,5.55.63,11.09.87,16.64l-.4,16.6-.78,33.2.87,33.2.43,16.6c-.23,5.54-.46,11.09-.81,16.64-.65-5.53-1.16-11.06-1.69-16.59l-.43-16.61-.87-33.24.78-33.23.38-16.62C1767,405.82,1767.49,400.29,1768.14,394.76Z" fill="#999"/>
          <path d="M1725.36,381l4.91-.57,4.92-.51,9.83-1,19.72-.83,39.43-1.66,39.45-1.32,19.72-.65c6.59.2,13.17.3,19.76.59-6.55.72-13.12,1.27-19.67,1.91l-19.72.65-39.44,1.32-39.43,1.66-19.71.84-9.89-.17-4.94-.1Z" fill="#999"/>
          <path d="M1734.82,395.64l4.67-.57,4.67-.51,9.35-1,18.74-.81,37.49-1.64,37.52-.67,18.76-.33c6.27.31,12.53.52,18.79.92-6.25.62-12.49,1.05-18.73,1.58l-18.76.33-37.5.67-37.47,1.64-18.74.83-9.39-.19-4.7-.09Z" fill="#999"/>
          <path d="M1891.67,497.63q-3.72,1.41-7.47,2.74l-3.74,1.3a25.52,25.52,0,0,1-3.8,1.14l-15.32,4-30.65,7.93.94-1.21v29.74c0,1,0,2,0,3s-.12,2-.18,3c-.32,4-.67,7.93-1.09,11.9-.42-4-.77-7.93-1.09-11.9-.07-1-.2-2-.19-3s0-2,0-3V513.49a1.25,1.25,0,0,1,.94-1.21l30.64-7.93,15.32-4a27,27,0,0,1,3.87-.84l3.92-.68C1886.43,498.43,1889.05,498,1891.67,497.63Z" fill="#999"/>
          <path d="M1837,519.78c2.38-.08,4.72,0,7.07.06l6.83,1.32,13.66,2.6,13.66,2.61,6.83,1.31c2.21.8,4.42,1.6,6.6,2.53-2.37.06-4.72,0-7.06-.08l-6.84-1.3-13.66-2.61-13.66-2.61-6.83-1.29C1841.41,521.52,1839.2,520.72,1837,519.78Z" fill="#999"/>
          <path d="M1850.56,113.42c-9.27,2.5-18.58,4.83-27.87,7.24-4.63,1.28-9.35,2.1-14,3.17l-14.05,3.07-56.33,11.7.86-.66-7.47,14.68-1.34-1.8,62.4-11.74,31-7c10.41-1.92,20.8-3.94,31.24-5.78-10.22,2.81-20.48,5.45-30.73,8.18l-31,7-62.45,11.75a1.25,1.25,0,0,1-1.46-1,1.22,1.22,0,0,1,.11-.8l7.47-14.67a1.29,1.29,0,0,1,.86-.66l56.32-11.7,14.05-3.06c4.69-1,9.33-2.19,14.07-3C1831.66,116.76,1841.09,115,1850.56,113.42Z" fill="#999"/>
          <path d="M1740.51,150.77c.28,3.64.56,7.27.78,10.91l.71,10.91c.34,3.63.21,7.27.32,10.91l.18,10.92-1.43-1.22,57.07-8.36L1855,175l-1,1.24-.07-9.87c0-1.64-.1-3.29,0-4.93l.21-4.94c.28-6.58.52-13.16.88-19.73.44,6.57.76,13.14,1.13,19.72l.27,4.93c.09,1.65,0,3.29,0,4.93l.07,9.87a1.26,1.26,0,0,1-1,1.24l-56.86,9.88-57.1,8.37a1.25,1.25,0,0,1-1.42-1.06c0-.05,0-.11,0-.16l-.18-10.92c0-3.63-.27-7.27-.06-10.91l.34-10.93C1740.2,158.06,1740.35,154.42,1740.51,150.77Z" fill="#999"/>
          <path d="M1740.43,194.8a26.58,26.58,0,0,1,3.24,1c.8.67,1.63,1.31,2.44,2l4.89,3.92,4.89,3.91,2.45,2c.58.95,1.16,1.89,1.67,2.93a32,32,0,0,1-3.23-1l-2.45-2-4.89-3.91-4.89-3.92c-.82-.64-1.63-1.31-2.46-1.94A28.87,28.87,0,0,1,1740.43,194.8Z" fill="#999"/>
          <path d="M1855.39,176.16a38.3,38.3,0,0,1,3.21,2.27c.71,1,1.45,2,2.18,3l4.37,6,4.38,6,2.19,3c.42,1.23.85,2.45,1.17,3.75-1.13-.71-2.16-1.5-3.2-2.28l-2.19-3-4.37-6-4.38-6c-.73-1-1.45-2-2.2-3A37.35,37.35,0,0,1,1855.39,176.16Z" fill="#999"/>
          <path d="M1760.52,147.89c.56,1.74,1,3.48,1.36,5.22l.09,5.24.2,10.47.2,10.48.1,5.24c-.35,1.76-.68,3.51-1.15,5.27-.54-1.74-.94-3.48-1.35-5.22l-.1-5.24-.2-10.48-.2-10.48-.11-5.24C1759.7,151.4,1760,149.65,1760.52,147.89Z" fill="#999"/>
          <path d="M1799.44,140.17c.45,1.77.76,3.53,1.06,5.29l-.2,5.24-.39,10.48-.39,10.48-.2,5.24c-.44,1.73-.88,3.46-1.44,5.19-.44-1.76-.74-3.52-1.06-5.28l.2-5.24.39-10.48.39-10.48.18-5.24C1798.42,143.63,1798.85,141.9,1799.44,140.17Z" fill="#999"/>
          <path d="M1778,144.1c.54,1.4.93,2.81,1.33,4.21,0,1.41,0,2.81.05,4.22l.12,8.45.13,8.44.07,4.22c-.36,1.41-.71,2.83-1.19,4.24-.52-1.4-.91-2.8-1.31-4.2l-.07-4.22L1777,161l-.13-8.44c0-1.41,0-2.82-.08-4.22C1777.15,146.93,1777.5,145.52,1778,144.1Z" fill="#999"/>
          <path d="M1836.74,134.67a42.83,42.83,0,0,1,1.18,4.25c-.05,1.41-.06,2.82-.1,4.23l-.17,8.44-.17,8.44-.09,4.23c-.4,1.4-.8,2.8-1.33,4.19-.47-1.41-.81-2.83-1.16-4.24L1835,160l.17-8.44.17-8.45c0-1.4.07-2.81.08-4.22C1835.8,137.47,1836.2,136.07,1836.74,134.67Z" fill="#999"/>
          <path d="M1813.71,136.39c.49,1.42.81,2.84,1.15,4.26-.07,1.4-.09,2.81-.13,4.22l-.24,8.44-.24,8.45-.11,4.22c-.42,1.4-.83,2.79-1.37,4.19-.46-1.42-.79-2.84-1.13-4.26l.12-4.22.23-8.45.24-8.44c0-1.41.09-2.82.1-4.22C1812.75,139.18,1813.15,137.78,1813.71,136.39Z" fill="#999"/>
          <path d="M1743.88,178.22c4.53-1.33,9.09-2.52,13.65-3.71l13.87-2.46,27.74-4.94,27.86-4.19,13.93-2.09c4.7-.33,9.4-.66,14.11-.86-4.56,1.19-9.15,2.25-13.73,3.33l-13.92,2.09-27.85,4.19-27.72,4.94L1758,177C1753.28,177.46,1748.6,177.91,1743.88,178.22Z" fill="#999"/>
          <path d="M1863.42,127.56c1,.78,2,1.6,3,2.43l1.46,1.26a2.81,2.81,0,0,1,.62.71c.18.25.39.5.58.75l4.7,5.92a1.27,1.27,0,0,1,.28.76l.27,16.23.14,8.12c0,.68,0,1.35,0,2l-.34,2c-.24,1.36-.52,2.71-.82,4.07-.35-1.35-.68-2.69-1-4l-.42-2c0-.67,0-1.35,0-2l-.14-8.11-.27-16.24.27.75-4.71-5.92a8.3,8.3,0,0,0-.6-.73,2.75,2.75,0,0,1-.54-.77l-.9-1.71C1864.52,129.9,1864,128.75,1863.42,127.56Z" fill="#999"/>
          <path d="M1850.28,228.62c-9.31,2.41-18.65,4.65-28,7-2.33.59-4.67,1.15-7,1.59s-4.7,1-7,1.45l-14.11,2.93-56.94,9,.94-.71-6.49,14.06-1.36-1.76,62.18-11,31.29-4.18c10.49-1,21-2.08,31.45-3-10.36,1.9-20.74,3.61-31.1,5.42l-31.26,4.22-62.12,11a1.26,1.26,0,0,1-1.45-1,1.16,1.16,0,0,1,.1-.74l6.49-14.06a1.3,1.3,0,0,1,.94-.71l56.88-9,14.09-2.93c2.35-.5,4.7-1,7-1.48s4.71-1,7.08-1.34C1831.32,231.77,1840.78,230.11,1850.28,228.62Z" fill="#999"/>
          <path d="M1742.1,262.75c.17,3.38.35,6.77.47,10.15l.41,10.15c0,1.69.15,3.38.18,5.08s-.07,3.38-.07,5.07l-.08,10.15-1.4-1.26,56.75-6.68,56.79-6.32-1.11,1.26-.2-11c0-3.65-.27-7.3-.06-11,.23-7.32.39-14.64.71-22,.58,7.3,1,14.61,1.51,21.91.34,3.65.23,7.31.34,11l.2,11a1.26,1.26,0,0,1-1.11,1.26l-56.78,6.32-56.75,6.69a1.26,1.26,0,0,1-1.39-1.1v-.16l.08-10.14c0-1.69,0-3.38,0-5.08s.18-3.38.27-5.07l.58-10.14C1741.64,269.51,1741.87,266.13,1742.1,262.75Z" fill="#999"/>
          <path d="M1740.63,304.31a26.06,26.06,0,0,1,3.2,1.05c.79.69,1.6,1.35,2.39,2l4.79,4,4.79,4,2.4,2c.55,1,1.11,1.92,1.59,3-1.12-.29-2.16-.68-3.2-1.06l-2.4-2-4.79-4-4.79-4c-.8-.67-1.59-1.36-2.4-2A27.13,27.13,0,0,1,1740.63,304.31Z" fill="#999"/>
          <path d="M1854.09,289.88a25.68,25.68,0,0,1,2.75,1.82c.54.85,1.12,1.69,1.69,2.53l3.4,5,3.4,5.05,1.7,2.52c.25,1.06.51,2.11.67,3.23a30.35,30.35,0,0,1-2.74-1.83l-1.7-2.52-3.41-5.05-3.4-5.05c-.57-.84-1.12-1.69-1.71-2.52A25.29,25.29,0,0,1,1854.09,289.88Z" fill="#999"/>
          <path d="M1759.57,259.33c.57,1.73,1,3.47,1.43,5.2l.15,5.24.34,10.48.33,10.48L1762,296c-.32,1.76-.63,3.52-1.08,5.28-.56-1.73-1-3.47-1.42-5.2l-.16-5.24-.34-10.48-.34-10.48-.18-5.24C1758.79,262.85,1759.1,261.09,1759.57,259.33Z" fill="#999"/>
          <path d="M1798.36,253.33c.5,1.75.84,3.5,1.19,5.26l-.08,5.24-.13,10.47-.14,10.48-.07,5.24c-.4,1.75-.79,3.49-1.32,5.23-.47-1.76-.82-3.51-1.18-5.26l.07-5.24.14-10.48.13-10.48.06-5.24C1797.42,256.81,1797.81,255.07,1798.36,253.33Z" fill="#999"/>
          <path d="M1777.8,256.4c.55,1.4,1,2.8,1.36,4.19,0,1.41.07,2.82.1,4.23l.21,8.44.21,8.45.1,4.22c-.34,1.41-.68,2.83-1.14,4.25-.54-1.39-.94-2.79-1.36-4.19l-.1-4.22-.21-8.45-.21-8.44c0-1.41-.06-2.82-.12-4.22C1777,259.24,1777.31,257.82,1777.8,256.4Z" fill="#999"/>
          <path d="M1837,247.83c.46,1.43.76,2.85,1.08,4.28-.09,1.4-.13,2.81-.19,4.22l-.36,8.44-.36,8.45-.18,4.22c-.44,1.39-.87,2.78-1.43,4.17-.44-1.43-.75-2.85-1.07-4.28l.18-4.22.36-8.44.36-8.45c0-1.41.13-2.81.16-4.22C1836,250.61,1836.44,249.22,1837,247.83Z" fill="#999"/>
          <path d="M1814,249.54c.47,1.43.79,2.85,1.12,4.27-.07,1.4-.1,2.81-.15,4.22l-.27,8.44-.28,8.45-.14,4.22c-.42,1.4-.84,2.79-1.39,4.18-.45-1.42-.77-2.84-1.11-4.26l.14-4.22.28-8.45.27-8.44c0-1.41.11-2.82.13-4.22C1813,252.33,1813.42,250.94,1814,249.54Z" fill="#999"/>
          <path d="M1743.7,286.78c4.59-1.13,9.19-2.11,13.79-3.09l14-1.84,27.91-3.7,27.76-4.7L1841,271.1c4.69-.41,9.38-.83,14.09-1.12-4.55,1.28-9.12,2.43-13.68,3.59l-13.89,2.35-27.79,4.71-27.93,3.7-14,1.85C1753.13,286.44,1748.43,286.68,1743.7,286.78Z" fill="#999"/>
          <path d="M1862.16,238.47c1.14.65,2.24,1.33,3.34,2l1.62,1.06a3.11,3.11,0,0,1,.72.63c.21.24.45.45.68.67l5.49,5.3a1.29,1.29,0,0,1,.38.91l-.14,16.27-.06,8.14c0,.68,0,1.36,0,2l-.4,2c-.27,1.35-.58,2.7-.92,4.06-.32-1.36-.6-2.72-.85-4.08l-.37-2c0-.68,0-1.36,0-2l.07-8.14.13-16.27.38.91-5.49-5.3c-.23-.22-.45-.44-.69-.65a2.88,2.88,0,0,1-.66-.69l-1.12-1.59C1863.57,240.67,1862.85,239.59,1862.16,238.47Z" fill="#999"/>
          <path d="M1808,394.76c.51,3.08.88,6.15,1.24,9.22l0,9.22-.05,18.43,0,18.43,0,9.21c-.38,3.08-.76,6.15-1.27,9.22-.49-3.08-.85-6.15-1.23-9.22l0-9.22,0-18.43.05-18.43V404C1807.07,400.91,1807.44,397.83,1808,394.76Z" fill="#999"/>
          <path d="M1833.09,393.91c.57,2.34,1,4.69,1.42,7l.15,7.07.33,14.15.32,14.14.16,7.07c-.32,2.37-.64,4.74-1.08,7.1-.56-2.34-1-4.69-1.42-7l-.16-7.07-.32-14.15-.33-14.14L1832,401C1832.31,398.64,1832.62,396.28,1833.09,393.91Z" fill="#999"/>
          <path d="M1725.34,98.16c7.08-1.77,14.19-3.39,21.28-5.08,1.78-.4,3.54-.86,5.33-1.22l5.37-1,10.74-2,43-8.06,85.5-18.15a1.28,1.28,0,0,1,.73.07l4.06,1.65c1.35.55,2.73,1,4.06,1.64l8,3.67,8,3.67,8,3.71L1921,74.2l-8.27-2.93-8.27-2.93c-1.38-.49-2.71-1.1-4.06-1.65L1896.31,65l.73.07-85.53,18.15-43,8.07-10.74,2-5.37,1c-1.79.32-3.61.53-5.41.8C1739.79,96.18,1732.58,97.25,1725.34,98.16Z" fill="#999"/>
          <path d="M1897.14,97l.28,9.15.22,9.14.43,18.3-.33,36.57.49,73.15.67,73.18-.86,36.58c-.71,12.19-1.32,24.37-2.11,36.55-.22-12.2-.26-24.4-.39-36.59l.86-36.56-.67-73.14-.49-73.17.31-36.59.76-18.28.38-9.15Z" fill="#999"/>
          <path d="M1783.75,46.73c-.16-2.69-.37-5.38-.5-8.07l-.43-8.07c-.14-2.69-.31-5.38-.42-8.07V14.45a1.26,1.26,0,0,1,.86-1.19l56.27-18.65,56.89-16.69a1.25,1.25,0,0,1,1,.15l8.25,5.18c1.36.89,2.77,1.69,4.13,2.58s2.67,1.86,4,2.79c5.32,3.75,10.67,7.45,16,11.25-5.72-3.1-11.37-6.32-17-9.49-1.41-.8-2.84-1.58-4.25-2.4s-2.73-1.76-4.11-2.61l-8.25-5.19,1,.14L1840.28-3l-56.23,18.64.85-1.2,0,8.08c-.09,2.69-.25,5.38-.37,8.07l-.38,8.07C1784.09,41.35,1783.9,44,1783.75,46.73Z" fill="#999"/>
          <path d="M1724.77,63l-.23-33.95-.16-33.94,1.5,1c-4.91,1.94-9.83,3.89-14.78,5.75l-14.85,5.57-29.68,11.2.81-1.18.84,67.32.23,67.33v.11l-.9,11.14v-.11l.44,87.24.19,43.63c0,3.64.09,7.27,0,10.91l-.25,10.9-.51,21.79-.51,21.8-.26,10.9c-.07,1.82,0,3.64,0,5.45l.13,5.45,1.06,43.6q.17,5.46.22,10.9l0,5.45c0,1.82-.07,3.64-.1,5.46l-.55,21.81-1.14,43.62-.3-43.62-.12-21.8c0-1.82,0-3.63-.07-5.45l-.21-5.45q-.21-5.46-.31-10.9l-1-43.61-.13-5.46c-.05-1.81-.1-3.63,0-5.45l.26-10.9.51-21.81.51-21.8.25-10.91c.11-3.63,0-7.26,0-10.9l-.19-43.61-.44-87.22v-.11l.91-11.15v.11l-.23-67.32-.84-67.31a1.25,1.25,0,0,1,.81-1.19l29.68-11.19L1710.2-.47c5-1.87,9.92-3.65,14.9-5.44h0a1.11,1.11,0,0,1,1.41.66,1.39,1.39,0,0,1,.06.4l-.88,33.94Z" fill="#999"/>
          <path d="M1726.48-4.33c2.63.31,5.22.76,7.8,1.21l7.41,2.42,14.82,4.82,14.83,4.81,7.41,2.41c2.35,1.17,4.71,2.32,7,3.6-2.63-.33-5.21-.78-7.8-1.22l-7.41-2.41-14.83-4.82-14.82-4.81-7.42-2.4C1731.15-1.88,1728.79-3,1726.48-4.33Z" fill="#999"/>
          <path d="M1682.93,42.87c.35,1.57.66,3.15.92,4.72.12.79.29,1.57.37,2.36s0,1.57,0,2.36V80.64l-1.52-1.22,8.14-1.85a17.06,17.06,0,0,1,4.17-.49c1.42-.07,2.83-.12,4.26-.14q-1.93.9-3.9,1.71a17,17,0,0,1-4,1.36l-8.15,1.85a1.26,1.26,0,0,1-1.5-.94,1.33,1.33,0,0,1,0-.28V52.31c0-.79,0-1.57,0-2.36s.25-1.57.37-2.36C1682.28,46,1682.58,44.44,1682.93,42.87Z" fill="#999"/>
          <path d="M1682.93,102.87c.34,1.36.63,2.72.88,4.08l.38,2a5.79,5.79,0,0,1,0,1v25.45l-1.52-1.22,8.14-1.85c.69-.14,1.36-.33,2-.48l2.12-.09c1.41-.06,2.84-.07,4.27-.06-1.29.62-2.58,1.22-3.88,1.78l-1.95.84c-.68.16-1.37.28-2,.45l-8.15,1.85a1.26,1.26,0,0,1-1.5-.94,1.33,1.33,0,0,1,0-.28V110a5,5,0,0,1,0-1l.37-2C1682.31,105.59,1682.6,104.23,1682.93,102.87Z" fill="#999"/>
          <path d="M1684.22,358.76c.34,1.75.65,3.5.91,5.25.12.88.29,1.75.37,2.63s0,1.75,0,2.62v31.5l-1.25-1.25h9.43a21.47,21.47,0,0,1,4.71.44c1.58.24,3.15.51,4.72.81-1.57.31-3.14.57-4.72.82a20.86,20.86,0,0,1-4.71.43h-9.43a1.24,1.24,0,0,1-1.25-1.25v-31.5c0-.87,0-1.75,0-2.62s.25-1.75.37-2.63C1683.57,362.26,1683.88,360.51,1684.22,358.76Z" fill="#999"/>
          <path d="M1666.17,33.75q-13.89,4.95-27.83,9.77c-4.63,1.63-9.3,3.17-13.86,5l-6.88,2.66c-2.3.86-4.62,1.66-6.93,2.49l-55.43,20.16.82-1.2.6,36.46-1.45,36.44v-.11l1.65,36.14v.13l-2.24,36.06-1.32,36.07-.13,36.1.54,18.05c.12,6,.08,12,.13,18.06v.08l-1.42,18-1.47,18,.51-18.07L1552,326v.07c-.29-6-.67-12-.88-18.06l-.48-18.07.13-36.18,1.32-36.15,2.24-36.08v.13l-1.64-36.13v-.11l1.44-36.36-.6-36.39a1.25,1.25,0,0,1,.82-1.2l55.45-20.17c2.31-.85,4.61-1.72,6.94-2.53l7-2.36c4.64-1.63,9.38-2.95,14.1-4.37Q1652,37.87,1666.17,33.75Z" fill="#999"/>
          <path d="M1637.18,78.78q-7.11,2.82-14.26,5.48c-1.2.44-2.38.91-3.58,1.32s-2.44.71-3.65,1.1l-7.28,2.24-29.35,8.22a1.29,1.29,0,0,1-.85-.06l-11.16-5a1.25,1.25,0,0,1-.64-1.65,1.31,1.31,0,0,1,.69-.66L1602.79,76l.11,0,18.42-5.2c6.24-1.37,12.49-2.73,18.77-4-6,2.22-12,4.32-18.09,6.4l-18.43,5.17.12,0L1568,92.14l.06-2.31,11.17,5-.85-.06,29.32-8.21,7.28-2.24c1.22-.35,2.41-.79,3.63-1.13s2.47-.61,3.7-.91Q1629.72,80.44,1637.18,78.78Z" fill="#999"/>
          <path d="M1579.9,96.63c.44,3.79.78,7.58,1.09,11.36.07,1,.2,1.9.18,2.84s0,1.9,0,2.85v5.68l0,22.72-1.48-1.23,29.29-5.54-.18,0,14.69-5.1c.61-.21,1.21-.47,1.83-.66s1.26-.32,1.89-.49l3.78-1c2.53-.62,5.06-1.24,7.6-1.82-2.35,1.12-4.72,2.21-7.09,3.29l-3.56,1.59c-.6.26-1.19.54-1.79.79s-1.23.4-1.84.62l-14.69,5.11-.18,0-29.28,5.55a1.27,1.27,0,0,1-1.47-1,2,2,0,0,1,0-.23l0-22.73v-5.68c0-.94,0-1.89,0-2.84s.11-1.89.18-2.84C1579.11,104.21,1579.47,100.42,1579.9,96.63Z" fill="#999"/>
          <path d="M1567.64,97.49c.52,1.86.88,3.71,1.26,5.57v33.43c-.38,1.86-.75,3.72-1.25,5.58-.51-1.86-.88-3.72-1.25-5.58V108.63l0-5.57C1566.75,101.2,1567.11,99.35,1567.64,97.49Z" fill="#999"/>
          <path d="M1638.33,173c-4.86,1.38-9.73,2.67-14.62,3.94-1.22.3-2.43.66-3.67.88s-2.48.45-3.71.69l-7.42,1.42-.1,0L1579,183.14a1.28,1.28,0,0,1-.93-.28l-7.73-6.45a1.24,1.24,0,0,1,.58-2.19l34.88-6.36,17.34-3.67c5.85-.85,11.71-1.71,17.6-2.44-5.68,1.72-11.39,3.3-17.1,4.9l-17.36,3.66-34.91,6.37.57-2.19,7.73,6.45-.93-.29,29.81-3.16-.1,0,7.41-1.43c1.24-.23,2.48-.47,3.7-.74s2.5-.37,3.75-.54C1628.3,174.16,1633.31,173.55,1638.33,173Z" fill="#999"/>
          <path d="M1577.3,183c.48,3.91.87,7.82,1.24,11.73a54.73,54.73,0,0,1,.18,5.87l0,5.87L1579,230l-1.42-1.23,30.1-4.13h.13l7.33-.2c2.44-.07,4.88-.11,7.33-.21a34.5,34.5,0,0,1,3.66.11l3.68.22c2.44.16,4.89.33,7.34.53-2.43.34-4.87.64-7.3.94l-3.66.41a32.09,32.09,0,0,1-3.66.32c-2.44,0-4.88.12-7.32.18l-7.33.2h.14l-30.1,4.13a1.26,1.26,0,0,1-1.41-1.07s0-.11,0-.16l-.23-23.48,0-5.88a52.28,52.28,0,0,1,.06-5.87C1576.57,190.86,1576.88,187,1577.3,183Z" fill="#999"/>
          <path d="M1567.64,183.88c.52,1.86.88,3.72,1.26,5.58v33.43c-.38,1.86-.75,3.71-1.25,5.57-.51-1.86-.88-3.71-1.25-5.57V195l0-5.57C1566.75,187.6,1567.11,185.74,1567.64,183.88Z" fill="#999"/>
          <path d="M1636.59,266.8c-4.78,1-9.58,1.89-14.37,2.75-1.2.21-2.4.47-3.61.6s-2.42.24-3.63.38l-7.25.83-29,3.16a1.25,1.25,0,0,1-1.18-.56l-6.07-9.34a1.25,1.25,0,0,1,.82-1.91l32.89-6,.12,0,8.43-.67c2.81-.2,5.62-.48,8.43-.66l8.47-.09q4.25,0,8.48,0c-2.79.46-5.58.91-8.37,1.33l-8.38,1.24c-2.81.27-5.62.44-8.43.68l-8.43.67.13,0-32.89,6.06.82-1.91,6.07,9.34-1.18-.57,29-3.15,7.25-.83c1.21-.14,2.42-.27,3.62-.45s2.43-.16,3.65-.23C1626.84,267.13,1631.71,266.93,1636.59,266.8Z" fill="#999"/>
          <path d="M1578.47,272.18c.47,3.86.84,7.73,1.18,11.59a56.09,56.09,0,0,1,.14,5.8l0,5.79.09,23.19-1.36-1.24,29.17-2.49,14.61-.83a25,25,0,0,1,3.67,0l3.67.11q3.67.14,7.35.33-3.63.61-7.26,1.15l-3.63.53a26.31,26.31,0,0,1-3.64.4l-14.59.81-29.14,2.49a1.25,1.25,0,0,1-1.35-1.14v-.1l-.09-23.19,0-5.8a53.27,53.27,0,0,1,.09-5.79C1577.7,279.91,1578,276.05,1578.47,272.18Z" fill="#999"/>
          <path d="M1567.64,273c.52,1.85.88,3.71,1.26,5.57V312c-.38,1.86-.75,3.72-1.25,5.57-.51-1.85-.88-3.71-1.25-5.57V284.18l0-5.57C1566.75,276.75,1567.11,274.89,1567.64,273Z" fill="#999"/>
          <path d="M1676.5,152.17a14.86,14.86,0,0,1-.3,2.39c-.44.52-.86,1.07-1.29,1.6l-2.57,3.22-2.57,3.21-1.29,1.61a18.21,18.21,0,0,1-2.26.82,17.76,17.76,0,0,1,.31-2.38l1.28-1.61,2.57-3.22,2.58-3.21c.42-.54.86-1.06,1.27-1.62A15.92,15.92,0,0,1,1676.5,152.17Z" fill="#999"/>
          <path d="M1552.2,385.33c.52,4,.9,8,1.26,12v72c-.38,4-.74,8-1.25,12-.5-4-.87-8-1.25-12v-72C1551.31,393.34,1551.69,389.33,1552.2,385.33Z" fill="#999"/>
          <path d="M1666.22,376.75c-3,.39-6,.75-9,1.06l-4.49.46c-.75.08-1.5,0-2.25,0l-2.25,0-18,.35h-.1l-36-2.07h.15l-18,1.15-2.25.14c-.75,0-1.5.16-2.25.14l-4.53-.09q-4.52-.1-9-.3,4.47-.77,8.94-1.44l4.48-.66c.74-.12,1.5-.09,2.25-.15l2.25-.14,18-1.14h.15l36,2.08h-.1l18-.35,2.25,0c.75,0,1.5-.1,2.25-.05l4.51.28C1660.21,376.24,1663.21,376.48,1666.22,376.75Z" fill="#999"/>
          <path d="M1895.82,21.87c-.49-1.82-.84-3.63-1.19-5.44l.09-5.42.14-10.83.14-10.84.07-5.42c.4-1.8.79-3.6,1.32-5.4.48,1.81.82,3.62,1.18,5.43l-.07,5.42L1897.36.21,1897.22,11l-.06,5.42C1896.76,18.26,1896.37,20.07,1895.82,21.87Z" fill="#999"/>
          <path d="M1892.2,410.19l.38,4.91.32,4.9.63,9.8.06,19.61.14,39.23-.38,39.22-.19,19.61c-.49,6.53-.87,13.07-1.44,19.6-.45-6.54-.71-13.08-1.06-19.62l.19-19.61.38-39.21-.14-39.22-.08-19.6.56-9.81.28-4.9Z" fill="#999"/>
          <g>
            <path d="M1542.78,112.38c-2.57.07-5.13.17-7.69.21l-7.69.14-7.68.14c-1.28,0-2.56,0-3.84-.08s-2.56-.09-3.84-.14a1.21,1.21,0,0,1-.72-.25l-27.56-21.19L1454.8,72l1.81-.48-13.42,26.79-.07.13-17.19,25.75.09-.15-1.68,3.47c-.54,1.17-1.1,2.33-1.74,3.44l-3.71,6.77-3.72,6.76-3.77,6.74,3-7.12,3-7.1,3-7.1c.48-1.19,1-2.35,1.63-3.49l1.7-3.46a.71.71,0,0,1,.08-.15L1441,97l-.08.14,13.41-26.79a1.25,1.25,0,0,1,1.68-.56l.13.08,29,19.29,27.63,21.23-.72-.25c1.28,0,2.56.09,3.84.1s2.56.06,3.83.17l7.67.63,7.66.63C1537.67,111.89,1540.22,112.15,1542.78,112.38Z" fill="#999"/>
            <path d="M1421,134.36l.49,5,.42,5.05.84,10.1.49,20.24-1.74-1.12,22.74-9.67c7.65-3.06,15-6.87,22.43-10.39s14.78-7.31,22.44-10.39l22.77-9.71-.76,1.13c0-1.9.2-3.8.36-5.7l.49-5.69c.32-3.8.66-7.59,1-11.38.17,3.8.3,7.61.41,11.42l.17,5.71c.05,1.91.17,3.81,0,5.71v.06a1.23,1.23,0,0,1-.76,1.06l-22.74,9.67c-7.65,3.07-15,6.84-22.43,10.39s-14.77,7.33-22.44,10.39L1422.47,176a1.25,1.25,0,0,1-1.64-.66,1.09,1.09,0,0,1-.1-.46l-.5-20.24.34-10.13.18-5.07Z" fill="#999"/>
            <path d="M1541.9,137.08c-2.44,0-4.89,0-7.33-.08l-7.32-.14-7.32-.14c-1.22,0-2.44-.1-3.65-.21s-2.43-.19-3.65-.28l1.34-1.3,1.47,38.4v.12l-1.67,28.71c-.84,9.56-.37,19.16-.34,28.74s.24,19.17.27,28.77c0,2.4.14,4.8,0,7.2l-.34,7.2c-.22,4.8-.44,9.6-.73,14.4-.34-4.8-.61-9.6-.88-14.39l-.42-7.2c-.19-2.39-.06-4.79-.11-7.19,0-9.59-.17-19.2-.27-28.81s-.5-19.21.35-28.8l1.66-28.77v.12l-1.47-38.4a1.26,1.26,0,0,1,1.2-1.3h.14c1.22.09,2.43.19,3.65.25s2.43.15,3.64.31l7.27.91,7.27.91C1537.06,136.4,1539.48,136.76,1541.9,137.08Z" fill="#999"/>
            <path d="M1513.41,328.76c.35,5.83.56,11.66.76,17.49l-.51,17.47-.5,34.91.69,34.9.77,17.45c-.12,5.83-.23,11.67-.48,17.51-.75-5.8-1.38-11.61-2-17.41l-.77-17.47-.69-35,.51-35,.48-17.47C1512.18,340.38,1512.72,334.56,1513.41,328.76Z" fill="#999"/>
            <path d="M1499.86,197.6q-.45-6.67-.77-13.34l-.16-3.33c0-1.12-.12-2.23-.07-3.34.08-2.22.08-4.44.12-6.66l.74,1.16-7.86-3.46.89.05-38.85,12.58-38.05,14.83.72-.74-3.2,8.77.07-.33c0,1.71-.37,3.4-.59,5.1l-.72,5.1c-.5,3.39-1,6.79-1.56,10.18,0-3.43,0-6.87.07-10.3l.08-5.15c0-1.71,0-3.43.21-5.13v-.08a1.11,1.11,0,0,1,.07-.26l3.22-8.76a1.26,1.26,0,0,1,.72-.73l38.11-14.86L1492,166.3a1.24,1.24,0,0,1,.89,0l7.86,3.46a1.27,1.27,0,0,1,.75,1.16h0c0,2.22-.09,4.45-.07,6.67,0,1.11-.08,2.22-.16,3.33l-.25,3.33C1500.67,188.72,1500.28,193.16,1499.86,197.6Z" fill="#999"/>
            <path d="M1495.11,202.85c-2.89,1.51-5.84,2.86-8.78,4.23l-9.18,3-18.35,6-18.33,6.11-9.16,3.06c-3.17.66-6.35,1.32-9.56,1.87,2.9-1.5,5.84-2.87,8.77-4.24l9.17-3.06,18.33-6.11,18.35-6,9.17-3C1488.72,204,1491.89,203.37,1495.11,202.85Z" fill="#999"/>
            <path d="M1416.06,205.73c2.88-1.58,5.8-3,8.73-4.48L1434,198l18.31-6.6,18.15-7,9.08-3.5c3.16-.82,6.32-1.64,9.53-2.34-2.85,1.64-5.75,3.15-8.64,4.67l-9.08,3.5-18.17,7-18.32,6.59-9.16,3.31C1422.47,204.37,1419.29,205.13,1416.06,205.73Z" fill="#999"/>
            <path d="M1430.88,200.6a11.94,11.94,0,0,1,1.1,1.78c-.08.55-.12,1.11-.17,1.66l-.33,3.32-.32,3.33-.16,1.66a16.26,16.26,0,0,1-1.4,1.54,15.81,15.81,0,0,1-1.09-1.78l.16-1.67.33-3.32.32-3.32c0-.55.12-1.11.15-1.66A11.72,11.72,0,0,1,1430.88,200.6Z" fill="#999"/>
            <path d="M1447.89,194a12.34,12.34,0,0,1,1.32,1.59c0,.54,0,1.09,0,1.63l.12,3.27.13,3.26.06,1.64a14.32,14.32,0,0,1-1.18,1.68,14.24,14.24,0,0,1-1.32-1.59l-.06-1.63-.12-3.27-.13-3.26c0-.55,0-1.09-.07-1.64A12.87,12.87,0,0,1,1447.89,194Z" fill="#999"/>
            <path d="M1468.86,186.86a12.75,12.75,0,0,1,1.35,1.6c0,.56.06,1.11.08,1.66l.18,3.33.18,3.33.09,1.66a14.52,14.52,0,0,1-1.16,1.73,15.57,15.57,0,0,1-1.33-1.59l-.09-1.67-.19-3.32-.18-3.33c0-.55,0-1.11-.1-1.66A12.33,12.33,0,0,1,1468.86,186.86Z" fill="#999"/>
            <path d="M1433.26,237.45c2.3-1.56,4.68-3,7-4.4l7.56-3.22,15.1-6.46,15.49-5.46,7.75-2.73c2.71-.55,5.42-1.11,8.16-1.55-2.41,1.38-4.86,2.64-7.31,3.9l-7.73,2.72-15.45,5.45-15.07,6.45-7.53,3.23C1438.63,236.11,1436,236.85,1433.26,237.45Z" fill="#999"/>
            <path d="M1499,303.9c-.26-4.43-.49-8.85-.66-13.27l-.14-3.31a30.64,30.64,0,0,1,0-3.32c.08-2.2.11-4.41.17-6.62l.6,1.1-7.38-4.5.92.15L1454,282.37l-37.75,10.82.71-.54-4.73,7.56.19-.74c0,.86.17,1.72.17,2.58v2.59l0,5.18c0,3.45-.06,6.91-.13,10.36-.49-3.42-1-6.84-1.4-10.27l-.64-5.13-.32-2.57c-.11-.86-.1-1.72-.15-2.58a1.24,1.24,0,0,1,.18-.74l4.73-7.56a1.25,1.25,0,0,1,.72-.54l37.84-10.85,38.48-8.25a1.27,1.27,0,0,1,.92.15l7.37,4.5a1.27,1.27,0,0,1,.6,1.1c0,2.21-.12,4.41-.14,6.62a28.11,28.11,0,0,1-.16,3.31l-.28,3.31C1499.86,295.08,1499.42,299.49,1499,303.9Z" fill="#999"/>
            <path d="M1495,309.1c-3,1-6,1.79-9,2.61l-9.21,1.35-18.42,2.72-18.19,4-9.1,2c-3.11.29-6.22.58-9.36.75,2.93-1.14,5.89-2.17,8.85-3.2l9.12-2,18.23-4,18.46-2.72,9.23-1.38C1488.68,309.13,1491.82,309,1495,309.1Z" fill="#999"/>
            <path d="M1415.9,301.32c3-1.1,6-2.06,9-3l9.19-1.77,18.39-3.57-.14,0,17.9-5.62,9-2.81c3.09-.57,6.19-1.15,9.32-1.61-2.83,1.41-5.71,2.7-8.58,4l-9,2.81-17.9,5.61-.14,0L1434.53,299l-9.19,1.8C1422.2,301,1419.07,301.24,1415.9,301.32Z" fill="#999"/>
            <path d="M1430.41,299.18a11.41,11.41,0,0,1,1.15,1.75c-.06.55-.08,1.1-.12,1.65l-.21,3.33-.22,3.32-.11,1.66a14.87,14.87,0,0,1-1.36,1.58,15.08,15.08,0,0,1-1.13-1.74l.1-1.66.22-3.33.22-3.32c0-.55.08-1.1.09-1.66A13,13,0,0,1,1430.41,299.18Z" fill="#999"/>
            <path d="M1448.18,294.26a11.57,11.57,0,0,1,1.17,1.71c-.06.54-.07,1.08-.11,1.63l-.19,3.26-.19,3.27-.09,1.63a14.53,14.53,0,0,1-1.34,1.57,15.2,15.2,0,0,1-1.16-1.71l.1-1.63.19-3.27.18-3.27c0-.54.08-1.08.09-1.63A11.84,11.84,0,0,1,1448.18,294.26Z" fill="#999"/>
            <path d="M1468.12,289.73a12.07,12.07,0,0,1,1.35,1.6c0,.56,0,1.11.07,1.66l.18,3.33.18,3.33.09,1.66a15.37,15.37,0,0,1-1.16,1.73,14.76,14.76,0,0,1-1.34-1.6l-.09-1.66-.18-3.33-.17-3.32c0-.56,0-1.11-.1-1.67A12.87,12.87,0,0,1,1468.12,289.73Z" fill="#999"/>
            <path d="M1433.71,334.37c2.34-1.24,4.73-2.33,7.12-3.43l7.47-2.22,15-4.45.21,0,15.66-1.81,7.83-.9c2.65.07,5.3.14,8,.34-2.55.8-5.12,1.47-7.68,2.15l-7.83.9-15.66,1.8.21,0-15,4.45-7.47,2.23C1439,333.73,1436.35,334.12,1433.71,334.37Z" fill="#999"/>
            <path d="M1441.9,147.5c-.3-.95-.54-1.89-.78-2.84l-.32-1.42a3.61,3.61,0,0,1-.18-1.41v-5.68l0-11.36a1.26,1.26,0,0,1,.62-1.08l3.43-2,.86-.5c.3-.13.51-.44.92-.38a17.23,17.23,0,0,1,2.28,0,20.65,20.65,0,0,1-1.16,2c-.15.39-.52.42-.79.61l-.85.5-3.43,2,.62-1.08,0,11.35,0,5.68a3.85,3.85,0,0,1-.17,1.42l-.32,1.42C1442.42,145.61,1442.18,146.55,1441.9,147.5Z" fill="#999"/>
            <path d="M1458,135.5c-.23-.82-.4-1.64-.58-2.45l-.22-1.22a2.64,2.64,0,0,1-.08-1.21l.38-4.78.76-9.57a1.25,1.25,0,0,1,.35-.78l2.75-2.78.68-.69c.26-.21.37-.55.79-.6a18.85,18.85,0,0,1,2.16-.61,19.06,19.06,0,0,1-.58,2.17c0,.42-.38.54-.59.79l-.68.7-2.75,2.78.36-.78L1460,126l-.37,4.79a2.64,2.64,0,0,1-.27,1.18l-.41,1.17C1458.62,134,1458.32,134.73,1458,135.5Z" fill="#999"/>
            <path d="M1467.27,66.76c3.21-.38,6.4-.62,9.6-.86l9.55.42,19.09.8,19.08,1.23,9.53.61c3.16.58,6.31,1.15,9.46,1.86-3.21.29-6.41.46-9.61.63l-9.53-.61-19.06-1.22-19.08-.8-9.54-.39C1473.6,67.92,1470.43,67.42,1467.27,66.76Z" fill="#999"/>
            <path d="M1500.84,68.94c1.43.68,2.75,1.46,4.07,2.25,1,1.06,2.11,2.1,3.17,3.15l6.35,6.3,6.35,6.29,3.18,3.15c.79,1.31,1.59,2.63,2.3,4-1.41-.69-2.73-1.48-4.06-2.26L1519,88.71l-6.36-6.3-6.35-6.29c-1.07-1.05-2.11-2.11-3.19-3.14C1502.33,71.67,1501.54,70.36,1500.84,68.94Z" fill="#999"/>
            <path d="M1529.2,69.65a17.74,17.74,0,0,1,2.65.75c.57.57,1.18,1.12,1.77,1.68l3.58,3.34,3.57,3.35,1.79,1.67a21.94,21.94,0,0,1,.93,2.59,26.15,26.15,0,0,1-2.64-.76l-1.78-1.68-3.58-3.34-3.57-3.35c-.6-.55-1.19-1.12-1.8-1.66A19.74,19.74,0,0,1,1529.2,69.65Z" fill="#999"/>
            <path d="M1430.27,243c.44,1.88.72,3.76,1,5.63l-.27,5.58-.53,11.14-.53,11.14-.26,5.58c-.47,1.84-.92,3.68-1.51,5.51-.41-1.88-.69-3.76-1-5.63l.27-5.57.52-11.15.53-11.14.25-5.57C1429.21,246.71,1429.67,244.87,1430.27,243Z" fill="#999"/>
            <path d="M1429.89,342.47c.58,4.14,1,8.28,1.44,12.41l.16,12.43.4,24.87c.12,8.29.35,16.57.25,24.87l-.05,12.43c-.4,4.15-.78,8.29-1.3,12.43-.49-4.14-.84-8.29-1.2-12.43l.05-12.42c.1-8.29-.13-16.57-.25-24.86l-.4-24.85-.18-12.43C1429.11,350.77,1429.43,346.62,1429.89,342.47Z" fill="#999"/>
            <path d="M1451.34,447.06c-.32-6.84-.43-13.67-.66-20.5.11-6.82.37-13.63.51-20.45L1450,365.19a1.26,1.26,0,0,1,1.14-1.28l31.64-2.9a1.25,1.25,0,0,1,1.36,1.13.65.65,0,0,1,0,.14l-.7,28.52v-.09l.37,7.36,0,7.38,0,7.38c0,2.46-.08,4.93-.12,7.39-.27-2.45-.57-4.89-.81-7.34l-.77-7.34-.74-7.34-.34-7.37v-.09l.71-28.52,1.36,1.28-31.64,2.9,1.13-1.28,1.19,41c-.18,6.83-.33,13.67-.58,20.5C1452.52,433.42,1452,440.24,1451.34,447.06Z" fill="#999"/>
          </g>
          <path d="M1421.9,333c-3.42.35-6.85.67-10.28.93-1.72.11-3.43.32-5.15.35s-3.42,0-5.14,0h-20.57L1382,333v34.29l-1.25-1.25h20.57c1.72,0,3.43,0,5.14,0s3.43.24,5.15.36c3.43.26,6.86.57,10.28.91q-5.13.51-10.28.92c-1.72.11-3.43.31-5.15.36s-3.42,0-5.14,0h-20.57a1.25,1.25,0,0,1-1.25-1.25V333a1.25,1.25,0,0,1,1.25-1.25h20.57c1.72,0,3.43,0,5.14,0s3.43.24,5.15.35C1415.05,332.37,1418.48,332.69,1421.9,333Z" fill="#999"/>
          <path d="M1371.33,333c.52,1.65.89,3.29,1.26,4.93v29.58c-.38,1.64-.75,3.28-1.25,4.93-.5-1.65-.87-3.29-1.25-4.93V342.9l0-4.93C1370.44,336.33,1370.81,334.69,1371.33,333Z" fill="#999"/>
          <path d="M1223,376.76l-.38-5.57-.31-5.57c-.19-3.72-.44-7.43-.59-11.15.06-7.43,0-14.85,0-22.28V287.61a1.23,1.23,0,0,1,.79-1.16l39-15.43c6.51-2.56,13-5.18,19.52-7.68,6.65-2.2,13.26-4.48,19.94-6.59-6.32,3-12.7,5.89-19,8.84-6.48,2.64-13,5.16-19.49,7.75l-39,15.43.79-1.16v44.58c0,7.43,0,14.85,0,22.28-.16,3.72-.4,7.43-.6,11.15l-.31,5.57Z" fill="#999"/>
          <path d="M1396.19,95.88c-6.93,3-13.9,6-20.86,9l-10.45,4.44c-1.74.73-3.53,1.36-5.29,2l-5.3,2L1312,129.62l.8-1.16v79.43l-1.67-1.18L1341,196l7.48-2.69c1.23-.48,2.49-.88,3.77-1.25l3.81-1.13c5.09-1.5,10.17-3,15.28-4.46-4.86,2.13-9.76,4.17-14.64,6.24l-3.67,1.55c-1.21.53-2.44,1-3.7,1.43l-7.48,2.67-29.92,10.71a1.25,1.25,0,0,1-1.6-.75,1.28,1.28,0,0,1-.07-.42V128.46a1.24,1.24,0,0,1,.8-1.17L1353.39,111l5.3-2c1.76-.68,3.51-1.41,5.29-2l10.72-3.71C1381.86,100.75,1389,98.27,1396.19,95.88Z" fill="#999"/>
          <path d="M1396.19,98.5c.33,3.38.63,6.76.88,10.14l.38,5.07c.06.84,0,1.69,0,2.53V179.6l-.93-1.21,6.43,1.71.8.22a3.71,3.71,0,0,1,.81.21l1.51.8c1,.53,2,1.1,3,1.7-1.16,0-2.31,0-3.44,0l-1.71-.06a3.8,3.8,0,0,1-.8-.22l-.81-.21-6.42-1.71a1.26,1.26,0,0,1-.93-1.21V116.24c0-.84-.07-1.69,0-2.53l.37-5.07C1395.56,105.26,1395.85,101.88,1396.19,98.5Z" fill="#999"/>
          <path d="M1401.33,97q4,.74,8,1.61a.86.86,0,0,1,.66.75l0,.21.25,2.37c0,.79,0,1.58,0,2.37v59.15a11.77,11.77,0,0,1-.11,2.37c-.34,3.15-.7,6.31-1.14,9.46-.45-3.15-.81-6.31-1.14-9.46a11.09,11.09,0,0,1-.12-2.37V104.33c0-.79-.07-1.58,0-2.37l.26-2.37.69,1C1406.24,99.43,1403.78,98.25,1401.33,97Z" fill="#999"/>
          <path d="M1324.18,205.46a13.53,13.53,0,0,1,1.26,1.93c0,.64,0,1.28,0,1.93V219a17.51,17.51,0,0,1-1.25,1.93,17.51,17.51,0,0,1-1.25-1.93v-9.64c0-.65,0-1.29,0-1.93A13.53,13.53,0,0,1,1324.18,205.46Z" fill="#999"/>
          <path d="M1332.18,200.46a13.53,13.53,0,0,1,1.26,1.93c0,.64,0,1.28,0,1.93V214a17.51,17.51,0,0,1-1.25,1.93,17.51,17.51,0,0,1-1.25-1.93v-9.64c0-.65,0-1.29,0-1.93A13.53,13.53,0,0,1,1332.18,200.46Z" fill="#999"/>
          <path d="M1382.18,185.46a13.53,13.53,0,0,1,1.26,1.93c0,.64,0,1.28,0,1.93V199a17.51,17.51,0,0,1-1.25,1.93,17.51,17.51,0,0,1-1.25-1.93v-9.64c0-.65,0-1.29,0-1.93A13.53,13.53,0,0,1,1382.18,185.46Z" fill="#999"/>
          <path d="M1390.18,183.46a13.53,13.53,0,0,1,1.26,1.93c0,.64,0,1.28,0,1.93V197a17.51,17.51,0,0,1-1.25,1.93,17.51,17.51,0,0,1-1.25-1.93v-9.64c0-.65,0-1.29,0-1.93A13.53,13.53,0,0,1,1390.18,183.46Z" fill="#999"/>
          <g>
            <path d="M1409.17,193.66q-8.82,3.66-17.7,7.21l-8.86,3.57c-3,1.18-5.9,2.44-8.9,3.51-6.13,1.72-12.24,3.54-18.35,5.3L1337,218.58l.11,0-35.28,13.92.79-1.1-1.83,33.83v-.12l1.07,25.58c.51,8.52.33,17.06.39,25.6s.17,17.08-.27,25.61l-.45,12.79-.22,6.39c0,2.14-.11,4.27-.28,6.4v-.1l.26,17.07q.15,8.54.2,17.08-.76-8.5-1.46-17l-1.41-17v-.1c0-2.13.07-4.26.18-6.39l.23-6.38.45-12.78c.44-8.51.25-17,.27-25.54s.12-17-.39-25.55l-1.07-25.54a.49.49,0,0,1,0-.12l1.82-33.82a1.27,1.27,0,0,1,.79-1.1l35.29-13.91.11,0,18.3-5.32c6.1-1.78,12.21-3.52,18.29-5.35,3-1.1,6-2,9-3l9.06-3Q1400.07,196.55,1409.17,193.66Z" fill="#999"/>
            <path d="M1395.86,235.75c-3.11,1.34-6.27,2.54-9.42,3.74l-9.74,2.5-19.47,5-19.43,5.22-9.71,2.61c-3.34.5-6.67,1-10,1.4,3.11-1.36,6.25-2.58,9.39-3.82l9.72-2.61,19.43-5.22,19.48-5,9.74-2.53C1389.15,236.56,1392.49,236.08,1395.86,235.75Z" fill="#999"/>
            <path d="M1322.4,255.35c-.24-1.07-.42-2.14-.58-3.2l-.23-1.59a2,2,0,0,1,.06-.78c0-.26,0-.52.07-.78l.57-6.24a1.26,1.26,0,0,1,1-1.1l13.9-3.35a1.27,1.27,0,0,1,1.55,1.25l-.17,5.85c0,.24,0,.49,0,.73a3.79,3.79,0,0,1-.15.73l-.39,1.45c-.27,1-.56,1.93-.88,2.9-.27-1-.51-2-.72-2.95l-.31-1.47a3.07,3.07,0,0,1-.11-.73,6.11,6.11,0,0,0,0-.73l.17-5.85,1.54,1.25-13.91,3.35,1-1.1-.57,6.24c0,.26,0,.51-.06.78a2.23,2.23,0,0,1-.09.77l-.51,1.53C1323.2,253.32,1322.82,254.34,1322.4,255.35Z" fill="#999"/>
            <path d="M1346.29,236.44c1.68-.9,3.38-1.76,5.09-2.56a11.1,11.1,0,0,1,2.62-1l2.69-.79,10.76-3.2,21.52-6.39a1.26,1.26,0,0,1,1.56.85,1.55,1.55,0,0,1,0,.21l.38,3.35.18,1.67c0,.27.1.55.12.83a8,8,0,0,1,0,.85c-.09,2.26-.2,4.53-.4,6.81-.71-2.18-1.31-4.36-1.91-6.55a8.34,8.34,0,0,1-.2-.83c0-.27,0-.56-.07-.84l-.19-1.67-.37-3.34,1.6,1.06-21.52,6.39-10.76,3.19-2.69.81a10.74,10.74,0,0,1-2.76.56Q1349.14,236.2,1346.29,236.44Z" fill="#999"/>
            <path d="M1396.42,275.8c-3.13,1.26-6.29,2.36-9.45,3.47l-9.74,2.23L1357.77,286l-19.52,4.24-9.76,2.12c-3.33.33-6.66.68-10,.9,3.15-1.2,6.32-2.27,9.49-3.35l9.75-2.11,19.51-4.24,19.46-4.47,9.73-2.25C1389.73,276.43,1393.06,276,1396.42,275.8Z" fill="#999"/>
            <path d="M1322.51,290.49c-.2-1-.35-2.08-.48-3.12l-.18-1.55a1.81,1.81,0,0,1,.05-.77c0-.25.07-.5.1-.75l.73-6a1.25,1.25,0,0,1,.93-1.06l13.91-3.64a1.25,1.25,0,0,1,1.53.89,1.65,1.65,0,0,1,0,.31l0,5.8q0,.36,0,.72a2.59,2.59,0,0,1-.12.73l-.34,1.45c-.24,1-.5,1.94-.79,2.91-.3-1-.57-1.93-.82-2.9l-.35-1.45a2.6,2.6,0,0,1-.13-.72c0-.24,0-.48,0-.73l0-5.8,1.56,1.2-13.91,3.65.92-1.06-.73,6c0,.25-.06.5-.08.76a1.8,1.8,0,0,1-.13.75l-.55,1.46C1323.35,288.55,1323,289.53,1322.51,290.49Z" fill="#999"/>
            <path d="M1346.77,273.43q2.51-1.24,5.06-2.34l1.28-.55a6.74,6.74,0,0,1,1.32-.34l2.66-.68,10.63-2.69,21.26-5.39a1.25,1.25,0,0,1,1.52.9,1.29,1.29,0,0,1,0,.39l-.19,3c0,.51-.08,1-.08,1.52a8.76,8.76,0,0,1-.27,1.5c-.46,2-.94,4-1.49,6-.29-2-.51-4.08-.7-6.12a7.14,7.14,0,0,1-.08-1.53c.07-.5.08-1,.12-1.51l.2-3,1.55,1.29-21.26,5.39-10.63,2.69-2.65.68a7.83,7.83,0,0,1-1.33.33l-1.39.12C1350.49,273.24,1348.64,273.36,1346.77,273.43Z" fill="#999"/>
            <path d="M1396.06,311.78c-3.13,1.2-6.28,2.26-9.44,3.32l-9.7,2.08-19.41,4.17-19.46,3.9-9.73,1.95c-3.32.27-6.64.56-10,.72,3.15-1.14,6.32-2.15,9.48-3.17l9.73-1.95,19.45-3.9,19.4-4.17,9.69-2.1C1389.41,312.3,1392.72,312,1396.06,311.78Z" fill="#999"/>
            <path d="M1322.37,325.64c-.28-1.1-.51-2.19-.71-3.28l-.29-1.64a3.13,3.13,0,0,1,0-.81l0-.81.33-6.48a1.27,1.27,0,0,1,1.06-1.18l15.07-2.3a1.26,1.26,0,0,1,1.43,1.05,1.31,1.31,0,0,1,0,.28L1339,316a3.56,3.56,0,0,1-.29,1.38l-.42,1.37c-.31.91-.62,1.82-1,2.72-.2-.94-.38-1.89-.54-2.84l-.22-1.41a3.55,3.55,0,0,1-.07-1.41l.43-5.57,1.43,1.33-15.07,2.31,1.06-1.17-.34,6.48,0,.81a3.16,3.16,0,0,1-.05.81l-.46,1.6C1323.1,323.51,1322.76,324.58,1322.37,325.64Z" fill="#999"/>
            <path d="M1346.32,306.52c3.47-1.37,6.92-2.78,10.56-3.2l10.78-2,21.56-3.95a1.25,1.25,0,0,1,1.46,1,1.56,1.56,0,0,1,0,.3l-.21,3.47-.11,1.73a7.78,7.78,0,0,1-.15,1.73c-.5,2.28-1,4.57-1.62,6.85-.34-2.34-.56-4.67-.79-7a8.3,8.3,0,0,1,.07-1.73l.1-1.74.21-3.46,1.47,1.31-21.56,3.95-10.78,2C1353.78,306.68,1350.05,306.58,1346.32,306.52Z" fill="#999"/>
          </g>
          <path d="M661.38,244.41c5.36,1.16,10.7,2.38,16,3.61l8,1.84c1.34.31,2.68.6,4,.93s2.63.73,3.95,1.1L725,260.82l31.31,10,31.47,9.37a1.26,1.26,0,0,1,.9,1.18l.39,23.88c.27,8-.08,15.9-.24,23.85L788.58,341c0,2-.14,4,0,5.95s0,4,0,6c0,8,0,15.9-.14,23.86-.73-7.93-1.33-15.88-2-23.83-.14-2-.35-4-.44-6s0-4,0-6l.28-11.93c.16-8,.51-15.92.24-23.86l-.39-23.84.89,1.18-31.49-9.38-31.3-9.95-31.59-8.91c-1.32-.35-2.65-.68-4-1.07s-2.61-.83-3.92-1.24L677,249.49C671.79,247.83,666.58,246.15,661.38,244.41Z" fill="#999"/>
          <path d="M1409.05,238.75c.52,1.68.89,3.35,1.26,5V274c-.38,1.68-.75,3.36-1.25,5-.51-1.68-.87-3.36-1.25-5V248.82l0-5C1408.16,242.1,1408.52,240.43,1409.05,238.75Z" fill="#999"/>
          <path d="M1409.05,379.33c.52,1.72.89,3.43,1.26,5.15v30.86c-.38,1.71-.75,3.43-1.25,5.14-.51-1.71-.87-3.43-1.25-5.14V389.62l0-5.14C1408.16,382.76,1408.52,381.05,1409.05,379.33Z" fill="#999"/>
          <path d="M1225.6,307.33c2.31-1.25,4.67-2.36,7-3.46l7.4-2.24,14.79-4.5,14.78-4.5,7.4-2.25c2.57-.39,5.15-.79,7.76-1.06-2.32,1.23-4.68,2.34-7,3.45l-7.4,2.25-14.78,4.5-14.79,4.5-7.39,2.26C1230.79,306.68,1228.21,307.07,1225.6,307.33Z" fill="#999"/>
          <path d="M1226.88,323.85c2.32-1,4.66-1.76,7-2.58l7.23-1.32,14.47-2.68,14.46-2.68,7.24-1.34c2.48-.07,5-.16,7.46-.11-2.32.94-4.67,1.75-7,2.57l-7.23,1.34L1256,319.73l-14.46,2.67-7.23,1.36C1231.87,323.84,1229.39,323.92,1226.88,323.85Z" fill="#999"/>
          <path d="M1225.6,339.81c2.41-.77,4.84-1.39,7.26-2l7.4-.73,14.79-1.5,14.78-1.51,7.4-.75c2.5.13,5,.25,7.52.5-2.42.75-4.84,1.36-7.27,2l-7.39.75-14.79,1.5-14.79,1.5-7.39.76C1230.62,340.19,1228.11,340.08,1225.6,339.81Z" fill="#999"/>
          <path d="M1593.35,398c-2.2.25-4.41.47-6.61.67l-3.31.27a20.25,20.25,0,0,1-3.3.14l-13.21-.19,1.27-1.32,1.86,34.39v.12l-.73,16.93c0,1.41-.14,2.82-.15,4.23s-.36,2.81-.54,4.22c-.38,2.81-.82,5.62-1.28,8.43q-.35-4.26-.56-8.51c0-1.42-.21-2.84-.17-4.25s.13-2.82.21-4.23l.72-16.93v.12l-1.86-34.39a1.25,1.25,0,0,1,1.18-1.31h.09l13.2.16a21.45,21.45,0,0,1,3.3.23l3.3.37Q1590.06,397.57,1593.35,398Z" fill="#999"/>
          <path d="M1654.28,446.52c-3.48,0-6.94-.06-10.4-.16L1633.64,445l-20.48-2.78a1.25,1.25,0,0,1-1.08-1.25l.16-21.38.07-10.69c.4-3.56.8-7.12,1.34-10.68.48,3.57.84,7.14,1.18,10.7l-.09,10.69-.16,21.38-1.08-1.25L1634,442.5l10.24,1.39C1647.57,444.72,1650.94,445.55,1654.28,446.52Z" fill="#999"/>
          <path d="M1582.79,410.19c.55,1.82,1,3.63,1.36,5.45l.08,5.46.2,10.93.2,10.93.1,5.47c-.35,1.82-.69,3.65-1.16,5.48-.53-1.81-.93-3.62-1.34-5.44l-.1-5.46-.2-10.93-.2-10.93-.11-5.47C1582,413.85,1582.3,412,1582.79,410.19Z" fill="#999"/>
          <path d="M1239-40.37a84.69,84.69,0,0,0-10.23.44,63.57,63.57,0,0,0-10,.73,46.34,46.34,0,0,0-18.63,6.87,38.29,38.29,0,0,0-7.46,6.51,40.37,40.37,0,0,0-5.49,8.28,43.45,43.45,0,0,0-2,4.6c-.26.79-.61,1.55-.82,2.36-.11.41-.23.81-.33,1.21a8.35,8.35,0,0,1-.34,1.21,22.63,22.63,0,0,0-.85,2.39l-.79,2.43c-.47,1.63-.88,3.3-1.36,5,0-1.72,0-3.46,0-5.2l.23-2.61a23,23,0,0,1,.32-2.61,44.85,44.85,0,0,1,1.57-5,48.89,48.89,0,0,1,2-4.85,42.63,42.63,0,0,1,5.85-8.8,40.93,40.93,0,0,1,8-7,48.81,48.81,0,0,1,19.63-7.25,70,70,0,0,1,10.43-.78A50.91,50.91,0,0,1,1239-40.37Z" fill="#999"/>
          <path d="M1173.88-6.94a55.74,55.74,0,0,0-7.28.73,42.11,42.11,0,0,0-7.09.65,37.61,37.61,0,0,0-13.19,5,33.39,33.39,0,0,0-5.6,4.31,36.41,36.41,0,0,0-4.66,5.33l-1,1.46c-.33.49-.62,1-.93,1.52-.65,1-1.15,2.07-1.72,3.12-.73,1-1.32,2-2,3l-1.92,3.17c.2-1.22.4-2.46.68-3.68s.61-2.44,1-3.64a37.53,37.53,0,0,1,1.81-3.29c.33-.54.63-1.09,1-1.61L1134,7.59a38.91,38.91,0,0,1,5-5.69,35.76,35.76,0,0,1,6-4.61A40,40,0,0,1,1159.08-8a48.2,48.2,0,0,1,7.49-.71A29.73,29.73,0,0,1,1173.88-6.94Z" fill="#999"/>
          <path d="M1129.31,40.21A20.54,20.54,0,0,0,1122.1,39a14.57,14.57,0,0,0-6.83.78,19.32,19.32,0,0,0-10.19,9.3,19,19,0,0,0-1.77,13.66,16.14,16.14,0,0,0,3.2,6.14,24.54,24.54,0,0,0,4.79,5.58,19.85,19.85,0,0,1-6.71-4,18.67,18.67,0,0,1-3.7-7.07,21.55,21.55,0,0,1,2-15.48,21.78,21.78,0,0,1,11.55-10.49,17.56,17.56,0,0,1,8-.94A16.88,16.88,0,0,1,1129.31,40.21Z" fill="#999"/>
          <path d="M1103.75,67.17a12.55,12.55,0,0,0-4.86,3.87,14.24,14.24,0,0,0-2.47,5.5,27.79,27.79,0,0,0-.7,5.95,16.43,16.43,0,0,0,1.17,5.8,12.83,12.83,0,0,0,3.38,4.7,16,16,0,0,0,5.12,3,22.88,22.88,0,0,0,5.93,1.26,26.17,26.17,0,0,0,3.07.09c1,0,2.13-.06,3.19-.07l51.3-.57,51.3-.56c17.1-.44,34.22.21,51.32.69-17.09.84-34.17,1.87-51.27,1.81l-51.28.56-51.28.57c-1.07,0-2.12,0-3.22.07a28.67,28.67,0,0,1-3.34-.1,25,25,0,0,1-6.57-1.4,18.56,18.56,0,0,1-5.92-3.43,15.15,15.15,0,0,1-4-5.62,18.77,18.77,0,0,1-1.37-6.69,15.79,15.79,0,0,1,1.06-6.7,16.08,16.08,0,0,1,3.73-5.61A12,12,0,0,1,1103.75,67.17Z" fill="#999"/>
          <path d="M1180.74,23.92a23.42,23.42,0,0,0-2.89,3.34c-.22.27-.54.42-.78.67s-.48.49-.73.74a15.58,15.58,0,0,0-1.35,1.56,22.79,22.79,0,0,0-3.69,7.43l-.54,2-.32,2.09-.16,1-.06,1.07-.11,2.12c0,1.43.1,2.86.16,4.28-.19,1.46-.37,2.94-.67,4.48-.69-1.37-1.25-2.8-1.82-4.26-.07-1.51-.16-3-.17-4.53l.12-2.27.06-1.14.17-1.13.35-2.27.59-2.22a25,25,0,0,1,4.12-8.25,16.1,16.1,0,0,1,1.52-1.77,19.86,19.86,0,0,1,1.69-1.62A12.72,12.72,0,0,1,1180.74,23.92Z" fill="#999"/>
          <path d="M213.83,333.9l.06,6.65v19.94c-.67,8.84-.2,17.69-.12,26.54s.19,17.69.66,26.54l1.12,26.56v.14l-2.47,35.42v-.09l.3,35.42.2,4.43c.06,1.47.19,2.94.19,4.42v8.86c0,5.91,0,11.82-.08,17.74-.59-5.89-1.1-11.78-1.62-17.68l-.79-8.84c-.13-1.48-.13-3-.19-4.43l-.2-4.43-.3-35.47v-.1l2.47-35.43v.14l-1.12-26.57c-.47-8.86-.49-17.74-.66-26.61s-.53-17.76.1-26.62l1.19-13.27.6-6.63Z" fill="#999"/>
          <path d="M377.1,497.63l.32-36.28-1.37-36.26V425c.2-6.06.55-12.1.87-18.15l.92-18.14v.14l-2.32-36.29a.38.38,0,0,1,0-.15l2-36.28v.09l-.55-31.81c-.2-10.6-.55-21.21-.82-31.81l-.22-8c-.05-2.65,0-5.3,0-8l.07-15.93,0-8c0-2.65,0-5.31.14-8l.77-15.89.76-15.88.1-2v-6l0-7.94-.06-15.88c0-2.65,0-5.3-.07-7.95l-.23-8-.46-15.91c-.11-5.3-.43-10.6-.25-15.91l.45-31.83.83,1.19L342.78,50.44,307.33,38.76l-17.48-6.52c-5.82-2.17-11.6-4.47-17.39-6.74L237.07,13.72l.38,0L213.33,26.39,237,12.84a.42.42,0,0,1,.36,0h0l35.79,10.69c5.85,2.09,11.72,4.15,17.54,6.35l17.47,6.56,35.43,11.68,35.17,12.5a1.23,1.23,0,0,1,.83,1.19l-.45,31.79c-.18,5.3.14,10.6.25,15.9l.46,15.89.23,8c.09,2.65,0,5.31.07,8l.06,15.93,0,8v6l-.1,2L379.4,189l-.77,15.89c-.18,2.65-.11,5.29-.14,7.94l0,7.94-.07,15.89c0,2.65-.05,5.3,0,7.94l.22,7.95c.27,10.6.62,21.19.82,31.8l.55,31.81v.09l-2,36.29v-.15l2.33,36.29v.14L379.42,407c-.29,6-.55,12.1-1,18.14V425l.23,36.32Z" fill="#999"/>
          <path d="M236.23,363.05c.5,5.81.85,11.62,1.26,17.43l.29,4.35c.08,1.46,0,2.91.05,4.36l.12,8.72.79,34.88v.09L237.5,459c-.65,8.71-.22,17.44-.15,26.16s.44,17.45,0,26.18l-.08,3.27c0,1.1-.16,2.18-.24,3.28l-.56,6.53c-.37,4.36-.74,8.72-1.17,13.07-.17-4.37-.28-8.73-.38-13.1l-.15-6.54c0-1.1-.07-2.19-.05-3.28l.13-3.26c.44-8.71.09-17.45,0-26.18s-.5-17.46.15-26.18l1.24-26.19v.09L235.45,398l-.12-8.72c0-1.46-.1-2.91-.07-4.37l.18-4.36C235.69,374.69,235.89,368.87,236.23,363.05Z" fill="#999"/>
          <path d="M355.31,135q-.53-7.38-.92-14.78l-.43-7.39-.21-3.69c-.06-1.23,0-2.47,0-3.7h0v-.09l2.07-30.1.92,1.29-32.47-9-.1,0L293.39,56.18,262.28,45.84l1.64-1.2.14,12.09.06,6.05.07,3c0,1,0,2-.08,3l-.42,12.1c-.13,4-.3,8.06-.48,12.1-.27-4-.53-8.06-.75-12.09l-.69-12.08c-.06-1-.12-2-.14-3v-3l-.06-6-.14-12.09a1.26,1.26,0,0,1,1.24-1.27,1.29,1.29,0,0,1,.4.07l31.15,10.35L325,65.2l-.1,0,32.46,9a1.25,1.25,0,0,1,.92,1.29l-2,30.1v-.1c0,1.23.08,2.47,0,3.7l-.12,3.7-.26,7.4C355.73,125.16,355.55,130.09,355.31,135Z" fill="#999"/>
          <path d="M264.12,56.81c3.55.72,7.07,1.53,10.58,2.37a40.87,40.87,0,0,1,5.15,1.6L285,62.49l20.44,6.87,41.13,13-1.15.2c.89-.59,1.81-1.14,2.71-1.71l2.72-1.7q2.73-1.68,5.5-3.32c-1.54,1.49-3.1,3-4.66,4.42l-2.36,2.17c-.79.72-1.56,1.46-2.37,2.16a1.12,1.12,0,0,1-1.08.23l-.07,0-41.14-13-20.46-6.87-5.11-1.72A43.86,43.86,0,0,1,274,61.31C270.68,59.85,267.38,58.38,264.12,56.81Z" fill="#999"/>
          <path d="M345.83,84.44c.48,1.73.8,3.46,1.13,5.18l-.15,5.14-.27,10.29-.27,10.29-.13,5.14c-.43,1.71-.84,3.41-1.39,5.11-.45-1.73-.78-3.45-1.11-5.17l.13-5.15L344,105l.27-10.28.12-5.15C344.85,87.85,345.27,86.14,345.83,84.44Z" fill="#999"/>
          <path d="M287.55,67.3c.58,1.44,1,2.89,1.44,4.34,0,1.47.12,2.93.17,4.39l.36,8.79.36,8.79.17,4.39c-.32,1.48-.62,3-1.07,4.45-.56-1.45-1-2.9-1.42-4.35l-.18-4.39L287,84.92l-.36-8.78c-.06-1.47-.1-2.93-.19-4.4C286.78,70.26,287.09,68.78,287.55,67.3Z" fill="#999"/>
          <path d="M316.64,76.73c.55,1.45,1,2.9,1.37,4.36,0,1.47.07,2.93.1,4.39l.22,8.79.22,8.79.12,4.39c-.35,1.47-.68,3-1.14,4.43-.54-1.46-.95-2.91-1.36-4.37l-.11-4.39-.23-8.79-.22-8.78c0-1.47-.06-2.93-.12-4.4A45.67,45.67,0,0,1,316.64,76.73Z" fill="#999"/>
          <path d="M355,231.89c-.28-4.89-.5-9.79-.7-14.68l-.32-7.35-.15-3.67c-.05-1.22,0-2.44,0-3.67l.71-29.35.91,1.23-23.41-6.62c-7.79-2.26-15.65-4.26-23.49-6.36s-15.74-4-23.44-6.6l-23.23-7.3,1.63-1.18-.16,12.18-.08,6.09c0,2,0,4.06-.14,6.08l-.73,12.17c-.23,4.06-.5,8.11-.78,12.17q-.27-6.09-.47-12.19l-.4-12.18c-.12-2,0-4.06,0-6.09l.08-6.08.16-12.18a1.24,1.24,0,0,1,1.26-1.23,1.36,1.36,0,0,1,.36,0l23.19,7.3c7.69,2.58,15.58,4.47,23.4,6.58s15.69,4.1,23.48,6.36L356.14,172a1.26,1.26,0,0,1,.91,1.24l-.69,29.33c0,1.22,0,2.44,0,3.66l-.17,3.67-.37,7.33C355.57,222.11,355.32,227,355,231.89Z" fill="#999"/>
          <path d="M263.5,161c3.56.29,7.1.68,10.64,1.09a25.79,25.79,0,0,1,2.64.37c.86.22,1.73.39,2.6.57l5.21,1.09,20.83,4.35.1,0,40.89,12.17-1.26.33a23.58,23.58,0,0,1,2.55-2.34l2.64-2.24c1.78-1.49,3.56-3,5.36-4.42-1.41,1.83-2.85,3.65-4.29,5.46l-2.19,2.69a24.54,24.54,0,0,1-2.27,2.61l-.08.06a1.22,1.22,0,0,1-1.18.26l-40.88-12.19.1,0-20.83-4.35-5.21-1.09c-.87-.18-1.74-.37-2.61-.51a25.08,25.08,0,0,1-2.57-.72C270.28,163.18,266.88,162.11,263.5,161Z" fill="#999"/>
          <path d="M347.43,181.31c.47,1.73.79,3.45,1.12,5.18l-.15,5.14-.28,10.29-.28,10.29-.14,5.14c-.42,1.7-.84,3.41-1.38,5.11-.46-1.73-.78-3.45-1.12-5.18l.14-5.14.28-10.29.28-10.29.13-5.14C346.44,184.72,346.86,183,347.43,181.31Z" fill="#999"/>
          <path d="M287.37,166.74c.57,1.34,1,2.68,1.42,4,0,1.36.1,2.72.15,4.07l.31,8.15.32,8.14.16,4.07c-.33,1.38-.64,2.75-1.09,4.12-.55-1.33-1-2.68-1.41-4l-.16-4.07-.31-8.15-.32-8.14c-.06-1.36-.09-2.71-.17-4.07A42.61,42.61,0,0,1,287.37,166.74Z" fill="#999"/>
          <path d="M316.32,173.6c.64,1.42,1.13,2.85,1.62,4.29.1,1.46.23,2.93.35,4.39l.72,8.79.71,8.78.36,4.4c-.26,1.49-.5,3-.88,4.49-.62-1.42-1.11-2.85-1.61-4.29l-.36-4.39-.72-8.79-.71-8.79c-.13-1.46-.23-2.92-.38-4.39C315.68,176.6,315.92,175.1,316.32,173.6Z" fill="#999"/>
          <path d="M357.43,332.19c-.69-4.91-1.32-9.82-1.93-14.73l-.94-7.37c-.15-1.23-.33-2.45-.44-3.68s-.18-2.47-.27-3.7h0v-.11l.52-28.94.93,1.23-30.55-8.14.11,0-31.45-5.37-31.06-7.29,1.54-1.17-.43,12.1-.21,6.06c-.08,2-.05,4-.26,6l-1,12.09c-.32,4-.68,8.06-1.05,12.09q-.15-6.07-.21-12.13l-.15-12.13c-.06-2,.1-4,.16-6.06L261,265l.43-12.11a1.25,1.25,0,0,1,1.29-1.21l.24,0,31,7.27,31.37,5.35.11,0,30.55,8.14a1.25,1.25,0,0,1,.93,1.23l-.52,28.93v-.12c.11,1.23.24,2.46.32,3.7s.09,2.47.14,3.71l.23,7.42C357.19,322.28,357.34,327.23,357.43,332.19Z" fill="#999"/>
          <path d="M264,264.82c3.5.3,7,.69,10.44,1.1a18.68,18.68,0,0,1,2.58.39c.84.22,1.7.39,2.55.57l5.1,1.09,20.41,4.38,20.57,3.49c6.86,1.15,13.72,2.35,20.59,3.45l-1,.29c.82-.67,1.74-1.23,2.6-1.85l2.63-1.82q2.64-1.8,5.32-3.56-2.17,2.34-4.41,4.64l-2.23,2.29c-.76.75-1.47,1.55-2.26,2.25a1.22,1.22,0,0,1-1,.29h0c-6.86-1.21-13.73-2.34-20.6-3.51l-20.61-3.49-20.43-4.39-5.11-1.09c-.85-.18-1.7-.38-2.56-.52a18.92,18.92,0,0,1-2.52-.71C270.67,267.06,267.34,266,264,264.82Z" fill="#999"/>
          <path d="M346,281.61c.56,1.7,1,3.4,1.39,5.11l.12,5.14.27,10.29.26,10.29.13,5.14c-.33,1.72-.66,3.45-1.12,5.18-.54-1.71-.95-3.41-1.38-5.12l-.13-5.14-.26-10.29L345,291.93l-.14-5.15C345.22,285.06,345.54,283.34,346,281.61Z" fill="#999"/>
          <path d="M289.6,270.46a38.32,38.32,0,0,1,1.24,3.65c0,1.22,0,2.43,0,3.65l0,7.28,0,7.29,0,3.64c-.39,1.22-.76,2.43-1.27,3.64-.49-1.22-.86-2.43-1.23-3.65l0-3.64,0-7.29,0-7.28c0-1.22,0-2.43,0-3.65A35.93,35.93,0,0,1,289.6,270.46Z" fill="#999"/>
          <path d="M316.77,276.47c.53,1.35.92,2.7,1.31,4,0,1.36,0,2.72,0,4.07l.1,8.15.1,8.14.05,4.07c-.36,1.36-.71,2.73-1.2,4.09-.52-1.35-.9-2.7-1.3-4.06l-.05-4.07-.1-8.14-.1-8.15c0-1.35,0-2.71-.07-4.07A42.09,42.09,0,0,1,316.77,276.47Z" fill="#999"/>
          <path d="M340.57,405.05c.52,4.27.88,8.54,1.25,12.81l0,12.81c.06,8.54-.2,17.07-.35,25.6s-.55,17.06,0,25.57l.48,12.78c-.22,4.28-.42,8.55-.76,12.83-.66-4.24-1.19-8.5-1.74-12.76l-.48-12.81c-.54-8.53-.1-17.08,0-25.62s.41-17.08.35-25.61v-12.8C339.67,413.58,340.05,409.32,340.57,405.05Z" fill="#999"/>
          <path d="M354.61,405.59q-4,.29-7.93.45l-4,.16c-.66,0-1.31-.09-2-.12l-2-.11L323,405.09l-31.34-3.64h.1L276,400.94l-2-.07a18.44,18.44,0,0,1-2-.07l-3.93-.51c-2.61-.34-5.23-.72-7.84-1.13,2.63-.23,5.27-.44,7.9-.6l3.95-.25a18.52,18.52,0,0,1,2,.06l2,.07,15.74.52H292l31.27,3.63,15.71.87,2,.11c.65,0,1.31,0,2,.1l3.91.59C349.4,404.67,352,405.12,354.61,405.59Z" fill="#999"/>
          <path d="M378.14,139.78c12.18,3.52,24.31,7.14,36.44,10.74l9.27,2.13c3.09.71,6.18,1.4,9.24,2.2,6.12,1.62,12.27,3.14,18.4,4.71l36.67,10.17,36.25,11.53a1.26,1.26,0,0,1,.87,1.16l1.09,34-.32,34.05.1,34,.64,34-.95,34,.27,34-.34,8.5c-.09,2.84-.21,5.67-.41,8.5l-1.08,17-.75,34-.9-34,.32-17c0-2.84.15-5.67.3-8.5l.36-8.5-.27-34,.95-34-.64-34-.1-34.05.32-34-1.09-34,.87,1.15-36.2-11.52L450.85,162c-6.15-1.55-12.28-3.14-18.44-4.65-3.08-.75-6.13-1.62-9.18-2.47l-9.16-2.55C402.08,148.14,390.09,144,378.14,139.78Z" fill="#999"/>
          <path d="M524.32,182.34c8.26,2.14,16.5,4.38,24.74,6.61l6.19,1.67a61.76,61.76,0,0,1,6.11,1.91l12,4.45c8,2.93,16,6,24,8.73l24.11,8.51a1.23,1.23,0,0,1,.83,1.11l1.87,38.27v.09l-1,38.92-.5,38.92-.34,38.9c.2,6.47.28,13,.42,19.44s.25,13,.33,19.47q-.9-9.7-1.7-19.42c-.53-6.48-1.12-13-1.6-19.44l.39-39,.5-38.94,1-38.93v.09l-1.86-38.26.83,1.11-24.13-8.5c-8.06-2.79-16-5.81-24-8.73l-12-4.38c-3.93-1.66-8-2.91-12-4.31C540.44,187.9,532.37,185.17,524.32,182.34Z" fill="#999"/>
          <path d="M394.76,84.91c10.91,3.63,21.75,7.42,32.61,11.13,2.76.8,5.5,1.67,8.2,2.63l8.17,2.75,16.34,5.47,32,12.82,32.71,10.7a1.24,1.24,0,0,1,.86,1.2l-.38,33.79a1.26,1.26,0,0,1-1.26,1.24,1.64,1.64,0,0,1-.32,0L492,158l-.09,0-31.41-11.19.1,0-16.14-4.17c-5.37-1.42-10.79-2.67-16.09-4.32-10.52-3.64-21-7.22-31.51-10.93,10.73,2.87,21.41,5.87,32.11,8.81,5.44,1.13,10.77,2.67,16.15,4l16.11,4.16.11,0,31.4,11.2-.09,0,31.66,8.56-1.58,1.19.38-33.79.86,1.2L491.2,122.06l-32-12.83-16.33-5.46L434.72,101c-2.74-.86-5.44-1.82-8.13-2.83C416,93.78,405.33,89.43,394.76,84.91Z" fill="#999"/>
          <path d="M396.64,95.59a24.9,24.9,0,0,1,1.25,3c0,1,0,2,0,3l0,6,0,6,0,3a31.75,31.75,0,0,1-1.26,3,30.8,30.8,0,0,1-1.24-3l0-3,0-6,0-6c0-1,0-2,0-3A26.61,26.61,0,0,1,396.64,95.59Z" fill="#999"/>
          <path d="M388.91,84.13a12.67,12.67,0,0,1-.1,2.08c-.38.4-.72.83-1.08,1.24L385.6,90l-2.14,2.51-1.06,1.26a14.65,14.65,0,0,1-2,.44,15.08,15.08,0,0,1,.11-2.06l1.07-1.26,2.13-2.51,2.14-2.51c.35-.43.72-.83,1.05-1.27A11.89,11.89,0,0,1,388.91,84.13Z" fill="#999"/>
          <path d="M506.42,161.17a13.7,13.7,0,0,1,1.3,1.79c0,.61,0,1.22,0,1.82l.06,3.64.06,3.64,0,1.82a16.35,16.35,0,0,1-1.22,1.85,16.64,16.64,0,0,1-1.28-1.8l0-1.82-.06-3.64-.06-3.64c0-.61,0-1.22-.05-1.82A13.11,13.11,0,0,1,506.42,161.17Z" fill="#999"/>
          <path d="M395,136.68c4.13.79,8.21,1.73,12.28,2.66l11.9,3.88c7.88,2.74,16,4.73,24,7s16.06,4.47,24.18,6.4l12.14,3c3.95,1.37,7.91,2.74,11.84,4.23-4.17-.52-8.31-1.17-12.45-1.81l-12.16-3c-8.12-1.93-16.15-4.23-24.22-6.42s-16.17-4.23-24.06-7l-11.92-3.86C402.61,140.11,398.76,138.46,395,136.68Z" fill="#999"/>
          <path d="M391.93,126.25a14.3,14.3,0,0,1-1,2c-.54.27-1.05.57-1.58.85l-3.13,1.73-3.13,1.72-1.57.86a15.31,15.31,0,0,1-2.17-.24,16.1,16.1,0,0,1,1-1.95l1.56-.86,3.14-1.73,3.13-1.72c.52-.29,1.05-.56,1.56-.87A13.38,13.38,0,0,1,391.93,126.25Z" fill="#999"/>
          <path d="M409.7,245.71A15.16,15.16,0,0,1,410,248c-.31.58-.58,1.18-.87,1.76l-1.73,3.55-1.73,3.54-.87,1.77a18.15,18.15,0,0,1-2,1.22,19.38,19.38,0,0,1-.26-2.32l.87-1.77,1.73-3.54,1.73-3.54c.28-.6.59-1.18.85-1.78A16.1,16.1,0,0,1,409.7,245.71Z" fill="#999"/>
          <path d="M507.9,263.42a11.32,11.32,0,0,1,.09,2c-.33.39-.62.81-.93,1.22l-1.84,2.44-1.83,2.45-.92,1.23a12.84,12.84,0,0,1-1.92.47,15,15,0,0,1-.08-2l.92-1.23,1.83-2.45,1.84-2.44c.3-.42.62-.81.91-1.24A11.15,11.15,0,0,1,507.9,263.42Z" fill="#999"/>
          <path d="M428.08,207.89a39.53,39.53,0,0,1,1.29,4c0,1.33,0,2.65,0,4l0,7.93,0,7.93,0,4c-.37,1.33-.73,2.65-1.22,4-.51-1.31-.89-2.63-1.28-3.95l0-4,0-7.93,0-7.93c0-1.32,0-2.64,0-4C427.21,210.53,427.57,209.21,428.08,207.89Z" fill="#999"/>
          <path d="M460.65,217.32a30.63,30.63,0,0,1,1.17,3.46c-.05,1.14-.06,2.28-.1,3.43l-.17,6.85-.17,6.86-.09,3.43c-.41,1.13-.8,2.27-1.33,3.4-.48-1.16-.82-2.31-1.17-3.46l.09-3.43.17-6.86.17-6.86c0-1.14.07-2.28.08-3.43A31.87,31.87,0,0,1,460.65,217.32Z" fill="#999"/>
          <path d="M487.7,224.17a34.19,34.19,0,0,1,1.06,3.51c-.09,1.14-.14,2.28-.21,3.42l-.4,6.86-.41,6.86-.2,3.43c-.44,1.12-.88,2.24-1.44,3.36-.44-1.18-.74-2.34-1.05-3.51l.2-3.43.4-6.85.4-6.86c.06-1.14.15-2.29.19-3.43A32.54,32.54,0,0,1,487.7,224.17Z" fill="#999"/>
          <path d="M423.78,190.38l5.65,9.83-.22-.16,21.93,4.19,11,2.1c3.66.7,7.32,1.37,10.94,2.22l43.13,11.57-1.5,1.64-5.27-14.18.9.78-22.57-5.15c-7.51-1.73-15.06-3.38-22.6-5.06S450,195,442.63,192.7l-22.3-6.31,1.09-.2L409.52,195l.5-1,1.79,51-1-1.18L435.13,249l6.08,1.31c1,.23,2,.4,3.06.55l3.07.48,12.3,1.91,12.3,1.92,3.07.48c1,.15,2,.41,3.06.61l6.1,1.27c4.07.79,8.12,1.73,12.16,2.69l12.14,2.84-1,.85-.41-16.43L506.7,231l1.24,16.4,1.18,16.39a.81.81,0,0,1-.75.87.66.66,0,0,1-.2,0h0l-12.24-2.26c-4.07-.75-8.15-1.5-12.19-2.42l-6.09-1.28c-1-.2-2-.46-3-.61l-3.08-.48-12.3-1.92-12.3-1.91-3.07-.48c-1-.15-2.05-.32-3.07-.56l-6.09-1.3-24.36-5.22a1.25,1.25,0,0,1-1-1.18l-1.79-51A1.28,1.28,0,0,1,408,193l11.9-8.85A1.24,1.24,0,0,1,421,184l22.25,6.3c7.36,2.29,14.95,3.72,22.46,5.44s15,3.33,22.57,5.07l22.57,5.14a1.28,1.28,0,0,1,.9.79L517,220.9a1.25,1.25,0,0,1-.74,1.61,1.24,1.24,0,0,1-.76,0L472.46,211c-3.65-.74-7.25-1.63-10.86-2.5l-10.84-2.61-21.68-5.24h0a.32.32,0,0,1-.2-.16Z" fill="#999"/>
          <path d="M396.31,192.5c.74,2.34,1.33,4.7,1.92,7.06l.65,7.17,1.33,14.35v.14l-.28,14.35-.15,7.17c-.42,2.39-.84,4.77-1.38,7.15-.46-2.4-.78-4.8-1.11-7.2l.14-7.17.28-14.35v.14L396.39,207l-.68-7.17C395.86,197.36,396,194.94,396.31,192.5Z" fill="#999"/>
          <path d="M427.81,227c3.46.3,6.88.74,10.31,1.18l10,2.43,20,4.83,20.07,4.65,10,2.33c3.26,1.14,6.52,2.28,9.75,3.54-3.45-.29-6.88-.7-10.31-1.11l-10-2.33-20.07-4.65-20-4.83-10-2.4C434.28,229.51,431,228.35,427.81,227Z" fill="#999"/>
          <path d="M409.24,350.9a15.06,15.06,0,0,1,.3,2.32c-.31.58-.57,1.18-.86,1.78L407,358.56l-1.7,3.56-.84,1.78a19.07,19.07,0,0,1-2,1.24,17.71,17.71,0,0,1-.28-2.31L403,361l1.7-3.56,1.69-3.56c.28-.59.58-1.18.84-1.78A14.19,14.19,0,0,1,409.24,350.9Z" fill="#999"/>
          <path d="M507.66,363.05a11.92,11.92,0,0,1-.17,2c-.37.34-.72.71-1.08,1.07l-2.13,2.15-2.13,2.16-1.07,1.07a14.43,14.43,0,0,1-1.95.2,13.33,13.33,0,0,1,.18-2l1.06-1.07,2.14-2.16,2.13-2.15c.35-.36.72-.71,1.06-1.09A11.78,11.78,0,0,1,507.66,363.05Z" fill="#999"/>
          <path d="M427.57,313.33a39.5,39.5,0,0,1,1.22,4c0,1.32,0,2.64-.06,4l-.1,7.93-.1,7.93,0,4c-.39,1.32-.78,2.64-1.29,4-.49-1.32-.84-2.65-1.2-4l0-4,.1-7.93.1-7.93c0-1.32,0-2.64,0-4C426.65,316,427,314.64,427.57,313.33Z" fill="#999"/>
          <path d="M487.09,322.76a33.7,33.7,0,0,1,1.24,3.43c0,1.15,0,2.29,0,3.43l-.05,6.86-.05,6.86,0,3.43c-.39,1.14-.76,2.28-1.27,3.42a36.36,36.36,0,0,1-1.23-3.44l0-3.43.05-6.86.05-6.86c0-1.14,0-2.28,0-3.42A31.75,31.75,0,0,1,487.09,322.76Z" fill="#999"/>
          <path d="M422.9,296.38l6.6,9.22-.28-.18,21.63,2.76c3.6.46,7.21.9,10.78,1.55l10.76,1.72,43.34,4.69-1.32,1.63L510,304.21l1,.85-22.74-3.14c-7.6-.93-15.11-2.39-22.65-3.69s-15.07-2.68-22.61-3.9l-22.63-3.76,1.09-.34L411,300.61l.37-.89v25.44c0,8.48,0,17,0,25.44l-1.11-1.26c3.4.46,6.78,1.07,10.17,1.6l10.17,1.64q10.17,1.64,20.32,3.35-10.26-.94-20.5-2l-10.25-1c-3.41-.36-6.84-.64-10.24-1.06a1.27,1.27,0,0,1-1.12-1.27c.06-8.48,0-17,0-25.44V299.72a1.22,1.22,0,0,1,.37-.88l10.43-10.39a1.28,1.28,0,0,1,1.09-.34l22.63,3.76c7.56,1.22,15.07,2.58,22.6,3.89s15,2.76,22.63,3.69l22.7,3.13a1.25,1.25,0,0,1,1,.85L516.78,317a1.25,1.25,0,0,1-.8,1.58,1.32,1.32,0,0,1-.52.05l-43.4-4.69-10.78-1.7c-3.6-.5-7.17-1.2-10.75-1.89l-21.44-4.1h0a.36.36,0,0,1-.25-.18Z" fill="#999"/>
          <path d="M396.74,292.5c.61,2.6,1.07,5.21,1.53,7.82l.27,7.86.54,15.72-.22,15.72-.12,7.87c-.41,2.61-.82,5.23-1.36,7.84-.46-2.62-.79-5.25-1.14-7.87l.12-7.84.22-15.7L396,308.23l-.29-7.84C396,297.76,396.31,295.14,396.74,292.5Z" fill="#999"/>
          <path d="M441.67,468.49c-.3-3.14-.6-6.28-.85-9.42l-.79-9.41c-.12-1.57-.29-3.14-.32-4.71s0-3.15-.1-4.72l-.27-9.43v-.1l2.07-38.27,1.24,1.31-37.5-.51,1.26-1.23L406,423.39c0,2.61,0,5.23,0,7.84s-.27,5.23-.4,7.85c-.3,5.23-.58,10.46-1,15.69-.34-5.23-.58-10.47-.85-15.7-.11-2.62-.32-5.24-.35-7.86s0-5.23.06-7.85l.45-31.39a1.25,1.25,0,0,1,1.27-1.24l37.5.51a1.25,1.25,0,0,1,1.23,1.27v.05l-2.07,38.28v-.11l.27,9.43c0,1.57.09,3.15.16,4.72s0,3.14,0,4.72L442,459C441.91,462.19,441.79,465.34,441.67,468.49Z" fill="#999"/>
          <path d="M469.52,391c2.29-.52,4.58-.88,6.88-1.25l6.87,0,13.75,0,13.72.9,6.86.46c2.27.53,4.53,1.05,6.78,1.7-2.32.35-4.62.57-6.92.79l-6.84-.45-13.68-.91H476.38C474.09,391.87,471.81,391.5,469.52,391Z" fill="#999"/>
          <path d="M469.51,406.12c2.28-.55,4.56-.94,6.85-1.34l6.85-.07,13.72-.15h.1l13.71.91,6.86.45c2.26.53,4.52,1.05,6.78,1.71-2.32.35-4.63.56-6.94.79l-6.86-.45-13.72-.91h.1l-13.72.15-6.86.09C474.09,407,471.8,406.61,469.51,406.12Z" fill="#999"/>
          <path d="M469.49,423.15c2.24-.7,4.5-1.26,6.76-1.81l6.87-.55,13.73-1.11,13.76-.54,6.89-.28c2.31.29,4.62.57,6.93,1-2.27.59-4.55,1.05-6.82,1.52l-6.87.27-13.74.55-13.71,1.11-6.85.56C474.13,423.67,471.82,423.48,469.49,423.15Z" fill="#999"/>
          <path d="M469.57,441c2.16-1,4.36-1.82,6.55-2.67l6.82-1.42L496.57,434l.12,0,13.87-1.48,6.93-.74c2.36.13,4.71.25,7.07.51-2.26.74-4.53,1.36-6.8,2l-6.93.74L497,436.46l.12,0-13.62,2.87-6.82,1.44C474.3,440.87,472,441,469.57,441Z" fill="#999"/>
          <path d="M469.77,457.64c2.15-1.05,4.34-1.95,6.53-2.86l6.84-1.62,13.66-3.26,13.71-3.07,6.85-1.53c2.37-.14,4.73-.29,7.13-.32-2.18,1-4.38,1.88-6.58,2.76l-6.85,1.53-13.7,3.07-13.65,3.26-6.83,1.64C474.52,457.42,472.16,457.6,469.77,457.64Z" fill="#999"/>
          <path d="M535.57,306.13c3.69,0,7.37.05,11.06.12l5.52.09c1.84,0,3.69,0,5.52.24l22,1.72a1.27,1.27,0,0,1,1.16,1.22l1.22,44a1.24,1.24,0,0,1-1.22,1.28h-.13L534.65,351a1.25,1.25,0,0,1-1.15-1.21v0l-.06-2.95c0-1,.08-2,.13-2.94l.29-5.9c.19-3.93.39-7.86.65-11.79.34,3.92.62,7.85.89,11.77l.42,5.89c.06,1,.15,2,.18,2.95v2.94l-1.14-1.23,46,3.8-1.35,1.28-1.22-44,1.15,1.21-22-1.7a53.1,53.1,0,0,1-5.48-.62l-5.48-.76C542.86,307.22,539.21,306.7,535.57,306.13Z" fill="#999"/>
          <path d="M524.76,314.31a10.09,10.09,0,0,1,1.16-1.24c.38,0,.77,0,1.15,0l2.29,0,2.29,0,1.14,0a10.91,10.91,0,0,1,1.13,1.26,11.72,11.72,0,0,1-1.16,1.24l-1.15,0-2.29,0-2.29,0c-.38,0-.76,0-1.14,0A9.1,9.1,0,0,1,524.76,314.31Z" fill="#999"/>
          <path d="M524.71,342.89a8.92,8.92,0,0,1,1.09-1.33c.39,0,.77,0,1.16-.05l2.32-.12,2.32-.12,1.16-.07a12.31,12.31,0,0,1,1.23,1.19,11.5,11.5,0,0,1-1.1,1.31l-1.16.06-2.32.13-2.32.12c-.38,0-.77,0-1.16.07A10.38,10.38,0,0,1,524.71,342.89Z" fill="#999"/>
          <path d="M588.68,310.75c.46,1.31.77,2.62,1.09,3.92-.09,1.29-.13,2.57-.2,3.86l-.36,7.71-.36,7.72-.17,3.86c-.44,1.26-.87,2.53-1.43,3.8-.44-1.31-.75-2.62-1.07-3.92l.18-3.86.36-7.71.36-7.72c0-1.28.13-2.57.16-3.86C587.68,313.29,588.1,312,588.68,310.75Z" fill="#999"/>
          <path d="M562,388.76c.54,2.11.92,4.21,1.31,6.32l0,6.32.1,12.64.09,12.65.05,6.32c-.37,2.11-.72,4.22-1.21,6.33-.51-2.1-.9-4.21-1.29-6.31l-.05-6.32L561,414.06l-.1-12.64-.06-6.33C561.16,393,561.51,390.87,562,388.76Z" fill="#999"/>
          <path d="M595.26,434.2c-.41-4.13-.73-8.27-1.06-12.4l-.5-6.2c-.09-1-.09-2.06-.09-3.1s0-2.06,0-3.1l-.45-24.82,1.2,1.23-40.69-1.66,1.3-1.27.61,31.69-.29,7.92c-.06,1.32,0,2.64-.12,4s-.25,2.63-.37,3.95c-.53,5.27-1,10.54-1.61,15.8-.22-5.29-.32-10.57-.47-15.85,0-1.31-.09-2.63-.09-4s.13-2.63.16-3.95l.29-7.9-.61-31.62a1.27,1.27,0,0,1,1.23-1.28h.07l40.69,1.66a1.25,1.25,0,0,1,1.2,1.23l.45,24.82c0,1,0,2.06.07,3.1s.07,2.07,0,3.1l-.28,6.21C595.7,425.91,595.52,430.05,595.26,434.2Z" fill="#999"/>
          <path d="M545.15,221.13c4.68,1,9.49,2,14.44,3l7.5,1.52c1.26.27,2.54.5,3.8.8s2.53.66,3.78,1c4.87,1.43,9.64,3,14.46,4.43l14.43,4.46a1.26,1.26,0,0,1,.88,1.2l-.11,39.81a1.24,1.24,0,0,1-1.26,1.24h-.18l-14.22-2.2c-4.75-.71-9.48-1.49-14.23-2.16l-28.66-2.6a1.24,1.24,0,0,1-1.14-1.22l-.16-12.59-.06-6.31c0-2.1-.1-4.24,0-6.28l.41-12.2Zm0,0,.66,12.72.78,12.44c.18,2.06.16,4,.21,6l.13,6,.21,12L546,269.17l28.74,2.61c4.76.67,9.51,1.45,14.27,2.17l14.26,2.2-1.44,1.23.11-39.81.88,1.2-14.4-4.45c-4.79-1.47-9.61-3-14.38-4.41-1.18-.3-2.36-.57-3.53-.9s-2.33-.73-3.5-1.09l-7.1-2.26C555.12,224.16,550.2,222.63,545.15,221.13Z" fill="#999"/>
          <path d="M578.44,231c.45,1.8.76,3.6,1.06,5.4l-.2,5.36-.39,10.72-.39,10.71-.19,5.36c-.45,1.78-.88,3.55-1.44,5.32-.44-1.81-.74-3.61-1.06-5.41l.19-5.36.39-10.71.39-10.72.18-5.36C577.42,234.57,577.85,232.79,578.44,231Z" fill="#999"/>
          <path d="M606.67,190.47l7.55-.76,7.56-.69a.63.63,0,0,1,.31,0l0,0,10,5c3.39,1.58,6.57,3.53,9.86,5.28l19.63,10.75a1.23,1.23,0,0,1,.65,1.12l-.45,21.71-.23,10.85c-.11,3.62,0,7.24,0,10.86l0,21.72c0,1.81,0,3.62,0,5.43l-.13,5.43-.27,10.85L661,308.92l-.13,5.43c-.08,1.81,0,3.62,0,5.42l.4,21.7c.49,14.45.34,28.94.19,43.44-.64-7.23-1.18-14.45-1.74-21.68l-.42-5.42c-.16-1.81-.28-3.62-.3-5.43-.12-3.61-.15-7.24-.23-10.86l-.4-21.74c0-1.81-.13-3.63,0-5.44l.13-5.43.26-10.86.27-10.86.13-5.43c0-1.81,0-3.62,0-5.43l0-21.72c0-3.62-.07-7.23,0-10.86l.23-10.86.45-21.72.65,1.12-19.63-10.74c-3.25-1.83-6.6-3.47-9.76-5.47l-9.6-5.77.37.08-7.59.07Z" fill="#999"/>
          <path d="M621.25,206.17a15.88,15.88,0,0,1-1.08-2.25c.08-.71.12-1.42.19-2.14l.36-4.28.35-4.29.18-2.14a18.75,18.75,0,0,1,1.42-2,19.77,19.77,0,0,1,1.07,2.24l-.18,2.15-.35,4.28-.36,4.29c-.05.71-.13,1.43-.16,2.14A16.52,16.52,0,0,1,621.25,206.17Z" fill="#999"/>
          <path d="M646.44,228.46c.56,1.27,1,2.54,1.39,3.82,0,1.28.07,2.57.11,3.85l.24,7.72.25,7.72.12,3.85c-.34,1.3-.66,2.6-1.12,3.9-.54-1.27-.95-2.54-1.38-3.82l-.12-3.85-.25-7.72-.24-7.72c-.05-1.28-.07-2.57-.14-3.85A38.25,38.25,0,0,1,646.44,228.46Z" fill="#999"/>
          <path d="M648.68,282.47a38.59,38.59,0,0,1,1.07,3.91c-.09,1.29-.13,2.58-.2,3.86l-.37,7.72-.37,7.71-.19,3.86c-.44,1.27-.87,2.53-1.43,3.8-.44-1.31-.75-2.62-1.07-3.92l.19-3.86.37-7.71.38-7.72c.05-1.29.13-2.57.17-3.86A35.9,35.9,0,0,1,648.68,282.47Z" fill="#999"/>
          <path d="M647,331.33a30.16,30.16,0,0,1,1.31,3.3c0,1.11,0,2.22,0,3.32l.1,6.65.11,6.64,0,3.32c-.37,1.11-.72,2.23-1.2,3.34a34.69,34.69,0,0,1-1.3-3.3l0-3.32-.11-6.65-.1-6.64c0-1.11,0-2.21-.06-3.32A29.85,29.85,0,0,1,647,331.33Z" fill="#999"/>
          <path d="M550.11,320.65a7.2,7.2,0,0,1,4.84-3.81,13.23,13.23,0,0,1,6.24,1,13.1,13.1,0,0,1,5,3.82,12.41,12.41,0,0,1,2.5,5.8A13.81,13.81,0,0,1,565,339.08a13,13,0,0,1-5.37,3.28,12.61,12.61,0,0,1-6.06-.74,15.15,15.15,0,0,0,5.32-1.65,10.4,10.4,0,0,0,4.33-2.65,11.28,11.28,0,0,0,3.07-9.48,10,10,0,0,0-2-4.63,10.62,10.62,0,0,0-4-3.07,10.26,10.26,0,0,0-5-.79A7.09,7.09,0,0,0,550.11,320.65Z" fill="#999"/>
          <path d="M545.81,291c2.46-.11,4.92-.19,7.38-.26l3.69-.07a25,25,0,0,1,3.67.08l14.66,1h.09l30,4.28a1.25,1.25,0,0,1,1.08,1.26l-.4,22.59-.1,5.64c0,.94-.05,1.89,0,2.83a26.79,26.79,0,0,1-.23,2.82c-.39,3.76-.8,7.52-1.29,11.27-.36-3.77-.64-7.54-.89-11.31-.06-.94-.17-1.89-.14-2.83s.06-1.88.08-2.82l.1-5.65.4-22.59,1.07,1.26-30-4.27H575l-14.66-1a23.59,23.59,0,0,1-3.65-.42l-3.64-.57Q549.44,291.68,545.81,291Z" fill="#999"/>
          <path d="M529.89,168.75c2-1,4.07-1.89,6.12-2.81l3.08-1.35a21.24,21.24,0,0,1,3.13-1.2L555,159.24l25.46-8.32a1.24,1.24,0,0,1,1.32.35l13.76,15.46,3.44,3.86c.57.64,1.13,1.3,1.73,1.92a21.13,21.13,0,0,1,1.6,2c2.05,2.8,4.08,5.61,6.05,8.47q-3.92-3.45-7.7-7a19.88,19.88,0,0,1-1.85-1.82c-.54-.67-1.13-1.3-1.7-1.95l-3.44-3.86-13.76-15.46,1.33.36-25.47,8.33L543,165.79a19.87,19.87,0,0,1-3.24.88l-3.28.73C534.29,167.87,532.1,168.33,529.89,168.75Z" fill="#999"/>
          <path d="M572.36,150.44c-2,.47-4,.78-6,1.09l-5.93-.18L548.62,151l-11.86-.35-5.92-.17c-2-.44-3.93-.87-5.9-1.43,2-.44,4-.75,6-1.07l5.93.17,11.85.35,11.86.35,5.92.16C568.44,149.44,570.4,149.86,572.36,150.44Z" fill="#999"/>
          <path d="M542.45,163.6a23.76,23.76,0,0,1,1.34,2.86c0,1,.05,1.93.07,2.89l.17,5.79.17,5.8.08,2.89a27.5,27.5,0,0,1-1.17,2.93,29.36,29.36,0,0,1-1.33-2.86L541.7,181l-.17-5.79-.16-5.8c0-1,0-1.93-.1-2.89A23.92,23.92,0,0,1,542.45,163.6Z" fill="#999"/>
          <path d="M606.5,177.94a33.58,33.58,0,0,1,1.27,3.69c0,1.24,0,2.47,0,3.7v18.48c-.38,1.23-.74,2.47-1.24,3.7-.51-1.23-.88-2.46-1.26-3.7V185.33c0-1.23,0-2.46,0-3.69A37.54,37.54,0,0,1,606.5,177.94Z" fill="#999"/>
          <path d="M682.09,348.75c3.67-.37,7.33-.6,11-.82l10.94.44,21.88.87,21.85,1.45,10.92.72c3.62.62,7.24,1.23,10.85,2-3.68.26-7.34.38-11,.52l-10.92-.72-21.83-1.44-21.86-.87L693,350.44C689.35,349.93,685.72,349.41,682.09,348.75Z" fill="#999"/>
          <path d="M769.58,330.71c-3.74,0-7.45-.25-11.17-.45l-11-1.69-22-3.38-21.85-4-10.92-2c-3.57-1.05-7.15-2.08-10.7-3.24,3.73.18,7.44.48,11.14.77l10.92,2,21.82,4,21.94,3.37,11,1.68C762.4,328.7,766,329.63,769.58,330.71Z" fill="#999"/>
          <path d="M769.34,309.83c-3.75-.46-7.47-1.07-11.18-1.68l-10.86-2.9-21.71-5.79-21.83-5.37-10.91-2.68c-3.55-1.26-7.09-2.51-10.61-3.89,3.75.41,7.48.94,11.21,1.47l10.92,2.68L726.21,297l21.73,5.79,10.86,2.88C762.33,307,765.86,308.36,769.34,309.83Z" fill="#999"/>
          <path d="M-1.2-50.66q18.5,5.07,37,10.24h-.06l18.86,3.6L64-35c3.14.58,6.28,1.23,9.41,1.87l.14,0,36.51,11.89-.1,0,37.26,9,37,9.87h0a1.26,1.26,0,0,1,.93,1.17c.41,17.38,1.45,34.77.6,52.15-.49,17.39-.68,34.75-1,52.11,0,17.37.15,34.75-.09,52.11-.48,17.37.53,34.71,1,52.07h0v.1l-1.84,34.76V242l.62,34.77.37,17.4c.12,5.79.32,11.59.34,17.39l-1.38,34.76.81,34.75-2.08-34.74.22-34.77c-.23-5.79-.28-11.58-.41-17.37l-.37-17.37L181.43,242v-.09l1.83-34.76v.11c-.51-17.38-1.52-34.75-1-52.14.24-17.38.11-34.76.09-52.13.3-17.38.49-34.78,1-52.12.85-17.36-.19-34.7-.6-52L183.62,0l-37-9.86-37.23-9-.1,0L72.77-30.7l.13,0c-3.13-.67-6.26-1.32-9.38-2l-9.37-2.12L35.42-39h-.06Q17.08-44.82-1.2-50.66Z" fill="#999"/>
          <path d="M-1.09,54.35C8.27,56,17.62,57.68,27,59.39c4.68.83,9.33,1.76,14,2.4s9.36,1.68,14.05,2.46c9.34,1.72,18.75,3.12,28,5.5l27.79,6.48-.09,0,37.41,5.94-1.45,1.26-.44-27.17.2-27.17,1,1.23L110,21.82,72.92,11.89,54.29,7.26,45,5c-3.11-.77-6.18-1.67-9.26-2.52C23.54-1.5,11.35-5.4-.79-9.43,11.59-6.22,23.93-2.89,36.28.39c3.12.71,6.26,1.34,9.36,2.12l9.29,2.34L73.54,9.47l37,9.93L148,27.86a1.26,1.26,0,0,1,1,1.23l-.2,27.14.44,27.14A1.25,1.25,0,0,1,148,84.64l-.22,0-37.4-5.94-.09,0L82.51,72.19c-9.19-2.37-18.6-3.77-27.94-5.48-4.67-.88-9.38-1.55-14-2.54S31.27,62.09,26.64,61C17.39,58.81,8.14,56.62-1.09,54.35Z" fill="#999"/>
          <path d="M148.07,28.77c-1.41,1.23-2.88,2.35-4.35,3.46a4.39,4.39,0,0,1-.56.4c-.2.11-.42.18-.62.28l-1.22.59-2.44,1.21-9.74,4.81.69-1-.6,5.55c-.09.93-.23,1.85-.28,2.78a14.8,14.8,0,0,1-1.57,2.64,15.06,15.06,0,0,1-1-2.91c.15-.92.22-1.85.33-2.78l.6-5.55a1.3,1.3,0,0,1,.69-1l9.75-4.81,2.43-1.2,1.22-.6c.2-.11.39-.24.59-.33a5,5,0,0,1,.66-.2C144.46,29.64,146.24,29.15,148.07,28.77Z" fill="#999"/>
          <path d="M118.18,36.71c-3.39-.37-6.78-.77-10.16-1.22L103,34.81c-.84-.12-1.66-.36-2.49-.53L98,33.75,78,29.56,38.12,21.27l-.1,0-19.6-5.67L16,14.86c-.82-.24-1.66-.4-2.46-.71L8.72,12.37Q3.92,10.59-.84,8.7q5,.95,10,2l5,1.05c.84.17,1.63.48,2.45.71l2.45.71,19.61,5.67-.09,0,39.91,8.3L98.49,31.3l2.49.53c.83.17,1.68.28,2.5.51l4.91,1.41Q113.29,35.18,118.18,36.71Z" fill="#999"/>
          <path d="M-1.18,170.65c12.56.95,25.11,2,37.66,3.09,3.14.32,6.27.59,9.4,1l9.36,1.21L74,178.29l37.51,4.63,37.23,6.49-1.47,1.23V163.3c-.14-4.55-.42-9.1-.63-13.65L146,136l1,1.16-36.29-8.66.09,0-28.08-4.58c-9.36-1.48-18.69-3.15-28-4.76l-7-1.2c-2.34-.37-4.68-.79-7-1.27-4.64-1-9.31-1.78-14-2.63-9.32-1.7-18.65-3.39-28-5.15,9.42,1.13,18.83,2.33,28.24,3.53,4.71.61,9.42,1.16,14.11,1.88,2.35.33,4.69.72,7,1.15l7,1.23c9.35,1.6,18.68,3.28,28.05,4.76L111.12,126l.09,0,36.29,8.66a1.25,1.25,0,0,1,1,1.15l.67,13.69c.21,4.56.49,9.12.63,13.68v27.4a1.25,1.25,0,0,1-1.25,1.25l-.21,0-37.17-6.48-37.45-4.61-18.73-2.36-9.37-1.15c-3.12-.39-6.23-.91-9.34-1.38C23.76,174.15,11.29,172.49-1.18,170.65Z" fill="#999"/>
          <path d="M148.39,135.54q-2.28,2-4.62,3.85a32.41,32.41,0,0,1-5,3.22l-10.24,6,.6-1.32,1,5,.5,2.49c.23.38,0,.87-.17,1.33s-.34.93-.55,1.41c-.38-.36-.73-.72-1.06-1.09s-.73-.71-.67-1.16l-.5-2.49-1-5a1.24,1.24,0,0,1,.59-1.32l10.24-6a31.67,31.67,0,0,1,5.25-2.77C144.61,136.92,146.49,136.21,148.39,135.54Z" fill="#999"/>
          <path d="M118.2,145.25c-3.37-.21-6.73-.44-10.09-.72l-5-.43c-.84-.07-1.66-.27-2.49-.4l-2.48-.4L78.2,140.11l-40-5.05-.12,0L18.4,130.7l-2.46-.55c-.82-.18-1.66-.29-2.46-.54l-4.85-1.46q-4.85-1.45-9.66-3,5,.62,10,1.32l5,.72c.84.11,1.64.37,2.47.54l2.46.54,19.7,4.35-.11,0,40,5.06,19.92,3.18,2.49.4c.83.14,1.67.2,2.49.39l4.92,1.17Q113.3,144,118.2,145.25Z" fill="#999"/>
          <path d="M251.89,338.38,197.66,335a.85.85,0,0,1-.81-.84v0l-.2-16.82-.1-8.41V300.5c.1-5.61-.07-11.22-.16-16.83l-.29-16.83-.15-8.41c0-1.4,0-2.81,0-4.21l.08-4.21.33-16.84c.12-5.61.18-11.23.36-16.83l.62-16.8.61-16.81c.18-2.8,0-5.6-.05-8.4l-.21-8.4c-.25-11.21-.61-22.4-.73-33.63l-.27-16.84c-.06-5.61-.25-11.22-.12-16.83L197,65a1.24,1.24,0,0,1,1.26-1.23h.12L252,69.35a1.25,1.25,0,0,1,1.12,1.28L251.88,109v-.09l1.64,38.2a.48.48,0,0,1,0,.12l-1.83,38.2v-.11l1.69,38.21a.21.21,0,0,1,0,.11l-.91,19.11c-.29,6.36-.53,12.74-1,19.1v-.12L252.87,300Zm0,0-.27-38.32-2.49-38.24v-.12c.17-6.37.54-12.74.85-19.1l.91-19.11v.12l-1.7-38.21v-.12l1.84-38.2v.11L249.39,109v-.09l1.25-38.34,1.11,1.29-53.63-5.62L199.5,65l-.41,33.64c-.13,5.6.06,11.21.12,16.81l.27,16.82c.12,11.2.48,22.44.73,33.66l.21,8.41c0,2.81.23,5.62.05,8.42l-.61,16.83-.62,16.83c-.18,5.6-.24,11.2-.36,16.81l-.33,16.8-.08,4.21c0,1.4-.06,2.8,0,4.2l.15,8.41.29,16.82c.09,5.61.24,11.21.21,16.82l0,8.42-.15,8.41-.3,16.84-.8-.88Z" fill="#999"/>
          <path d="M237.49,12.44c.52,1.89.89,3.78,1.26,5.68V52.19c-.38,1.89-.75,3.79-1.26,5.68-.5-1.89-.86-3.79-1.24-5.68V18.11C236.6,16.22,237,14.33,237.49,12.44Z" fill="#999"/>
          <path d="M184,84.59a13.24,13.24,0,0,1,1.92-1.12c.6.07,1.21.1,1.82.15l3.64.27,3.64.27,1.82.14a17.32,17.32,0,0,1,1.72,1.38,15.16,15.16,0,0,1-1.91,1.11l-1.82-.13-3.64-.28-3.64-.27c-.61,0-1.21-.1-1.82-.12A13.45,13.45,0,0,1,184,84.59Z" fill="#999"/>
          <path d="M190.82,103.28a9.49,9.49,0,0,1,.92-1.3c.33,0,.66,0,1,0l2-.09,2-.09,1,0a11.82,11.82,0,0,1,1,1.2,12.19,12.19,0,0,1-.93,1.3l-1,0-2,.09-2,.08c-.32,0-.65,0-1,.06A9.52,9.52,0,0,1,190.82,103.28Z" fill="#999"/>
          <path d="M184,293.82a13.33,13.33,0,0,1,1.76-1.37c.62,0,1.23-.06,1.84-.09l3.67-.2,3.66-.21,1.84-.1a16.75,16.75,0,0,1,1.9,1.14,15,15,0,0,1-1.76,1.35l-1.84.11-3.66.2-3.67.21c-.61,0-1.23.06-1.84.12A14.47,14.47,0,0,1,184,293.82Z" fill="#999"/>
          <path d="M190.91,311.61a9.6,9.6,0,0,1,.94-1.29c.33,0,.66,0,1,0l2-.06,2-.06,1,0a11,11,0,0,1,1,1.22,11.82,11.82,0,0,1-.94,1.28l-1,0-2,.06-2,.06c-.33,0-.66,0-1,0A8.88,8.88,0,0,1,190.91,311.61Z" fill="#999"/>
          <path d="M90.94,31.18c.56,1.65,1,3.31,1.38,5l.1,5,.23,10,.24,10,.11,5c-.34,1.67-.67,3.35-1.13,5-.54-1.66-1-3.31-1.37-5l-.11-5-.23-10-.24-10-.12-5C90.13,34.53,90.46,32.86,90.94,31.18Z" fill="#999"/>
          <path d="M36.69,18.28c.52,1.8.9,3.61,1.28,5.41V29.1l0,10.83,0,10.83,0,5.41c-.37,1.8-.74,3.61-1.23,5.42-.51-1.81-.88-3.61-1.27-5.41l0-5.42,0-10.82,0-10.83,0-5.41C35.81,21.89,36.17,20.09,36.69,18.28Z" fill="#999"/>
          <path d="M92.38,141.58c.51,1.64.86,3.28,1.23,4.92l0,4.91-.06,9.82-.06,9.82,0,4.92c-.39,1.63-.77,3.26-1.28,4.9-.5-1.64-.85-3.28-1.22-4.92l0-4.91.06-9.83.06-9.82,0-4.91C91.46,144.85,91.84,143.21,92.38,141.58Z" fill="#999"/>
          <path d="M35.38,134.59c.53,1.71.91,3.43,1.29,5.14l0,5.14.06,10.29.06,10.29,0,5.14c-.37,1.72-.72,3.43-1.22,5.15-.51-1.71-.89-3.42-1.28-5.14l0-5.14-.06-10.29-.06-10.28,0-5.15C34.51,138,34.87,136.31,35.38,134.59Z" fill="#999"/>
          <path d="M6.17,288.09c5.94.14,11.88.29,17.81.49l17.81.55h.1l6.59.84c2.2.28,4.38.67,6.56,1l13.13,2.06c8.72,1.62,17.56,2.11,26.37,2.94l13.21,1.18,3.31.29c.55,0,1.1.12,1.65.14l1.66,0,6.64.17,26.56.66-1.28,1.18L147.61,273v.09L147,245.38l1.06,1.21-71-11.13.1,0L59.3,234.14l-8.95-.63c-3-.24-6-.59-8.94-.88h-.08L23.7,229.39c-5.88-1.06-11.75-2.17-17.62-3.28,5.93.71,11.87,1.42,17.79,2.17l17.79,2.24h-.09c3,.15,6,.24,9,.45l9,.69L77.39,233h.1l71,11.13a1.24,1.24,0,0,1,1.06,1.21l.58,27.68v.09l-1.32,26.69a1.25,1.25,0,0,1-1.28,1.18l-26.62-.66-6.66-.16-1.66,0c-.56,0-1.11-.1-1.66-.14l-3.32-.29-13.28-1.19c-8.84-.84-17.73-1.33-26.47-2.95l-13.15-2.05c-2.2-.33-4.4-.63-6.58-1l-6.55-1.2h.1l-17.75-1.56C18,289.2,12.08,288.64,6.17,288.09Z" fill="#999"/>
          <path d="M127.37,258.44c.47,1.62.79,3.24,1.11,4.86l-.16,4.82-.29,9.64-.29,9.65-.14,4.82c-.43,1.59-.85,3.19-1.4,4.78-.45-1.62-.77-3.24-1.1-4.86l.14-4.82.29-9.64.3-9.64.13-4.83C126.38,261.63,126.8,260,127.37,258.44Z" fill="#999"/>
          <path d="M117.93,257.68c-4.72-.06-9.42-.27-14.13-.48l-14-1.74c-9.3-1.22-18.66-2.15-28-3.18s-18.7-1.86-28-3.43l-13.94-2.1c-4.59-1.08-9.18-2.14-13.75-3.34,4.72.21,9.41.54,14.1.86l13.92,2.1c9.26,1.57,18.62,2.37,27.94,3.42s18.67,2,28,3.19l14,1.72C108.72,255.64,113.34,256.59,117.93,257.68Z" fill="#999"/>
          <path d="M87.86,253.87c.63,1.61,1.1,3.22,1.58,4.84l.29,4.91.63,9.82.62,9.82.32,4.92c-.28,1.66-.54,3.32-.94,5-.6-1.61-1.08-3.22-1.56-4.84l-.31-4.91-.63-9.82-.62-9.82-.32-4.91C87.18,257.21,87.45,255.54,87.86,253.87Z" fill="#999"/>
          <path d="M35.67,249.46c.58,1.7,1,3.4,1.44,5.1l.17,5.15L37.63,270,38,280.28l.18,5.14c-.32,1.73-.63,3.46-1.08,5.19-.56-1.7-1-3.4-1.42-5.1l-.18-5.14-.35-10.29-.35-10.29-.19-5.14C34.9,252.92,35.21,251.19,35.67,249.46Z" fill="#999"/>
          <circle cx="553.95" cy="327.47" r="1.82" fill="#231815" stroke="#999" stroke-miterlimit="10"/>
          <circle cx="562.52" cy="326.61" r="1.82" fill="#231815" stroke="#999" stroke-miterlimit="10"/>
          <path d="M551.38,332.61l1.33-.82a6.32,6.32,0,0,0,.84.27,11.82,11.82,0,0,0,1.76.32,10.6,10.6,0,0,0,1.78.07c.3,0,.59,0,.88-.07.34.35.7.65,1.12,1.09a6.07,6.07,0,0,1-.82,1.39c-.36,0-.72.08-1.08.09a14.14,14.14,0,0,1-2.16-.08,13.77,13.77,0,0,1-2.11-.4l-1-.3A5,5,0,0,1,551.38,332.61Z" fill="#999"/>
          <path d="M419.79,42.44A84.54,84.54,0,0,1,436.6,18.8,91.82,91.82,0,0,1,461,2.84c17.86-8,37.77-10.6,57.11-9a132.82,132.82,0,0,1,55.3,17.11A111.18,111.18,0,0,1,596.58,28.6a90.29,90.29,0,0,1,16.09,24.13,93.93,93.93,0,0,0-17.86-22.37,109.2,109.2,0,0,0-22.63-17.29A130.44,130.44,0,0,0,517.93-3.71c-19-1.53-38.48,1-55.88,8.83A88.59,88.59,0,0,0,438.3,20.66c-1.73,1.62-3.56,3.15-5.25,4.83s-3.29,3.45-4.85,5.27-3,3.74-4.41,5.67Z" fill="#999"/>
          <path d="M618.13,36.68a68.16,68.16,0,0,1,15.49.61,90.66,90.66,0,0,1,14.88,4.55,87.12,87.12,0,0,1,13.87,7.09,79.59,79.59,0,0,1,12.28,9.6c1.93,1.75,3.65,3.7,5.4,5.62l2.48,3c.84,1,1.55,2.09,2.32,3.13L686,71.87c.38.53.7,1.1,1.06,1.64l2.07,3.3L691,80.24,691.9,82l.83,1.76,1.64,3.53,1.42,3.62c1,2.4,1.73,4.89,2.53,7.35.34,1.25.53,2.54.78,3.81l.69,3.81c.36,2.56.61,5.13.88,7.68l-2.19-7.37-1.25-3.62c-.42-1.2-.79-2.42-1.3-3.58-.82-2.4-1.48-4.85-2.48-7.18l-1.38-3.53-1.6-3.44-.8-1.72-.9-1.66L687,78.08l-2-3.21c-.34-.53-.66-1.08-1-1.59l-1.11-1.53c-.76-1-1.43-2.08-2.25-3l-2.4-2.92c-1.71-1.86-3.37-3.76-5.24-5.45A77,77,0,0,0,661,51a84.47,84.47,0,0,0-13.46-6.88,86,86,0,0,0-14.47-4.41A90.54,90.54,0,0,0,618.13,36.68Z" fill="#999"/>
          <path d="M705.25,105a30.05,30.05,0,0,1,7,1.33,52.14,52.14,0,0,1,6.17,3.51,44.17,44.17,0,0,1,10.4,9.65,40.58,40.58,0,0,1,6.52,12.63c.31,1.15.68,2.28.9,3.45s.48,2.32.63,3.5-.13,2.39-.21,3.57-.35,2.35-.55,3.51c-.28-1.16-.51-2.3-.79-3.42s-.54-2.23-.93-3.31a3.34,3.34,0,0,1-.13-.83c0-.28-.08-.56-.12-.83-.1-.56-.23-1.1-.35-1.65-.2-1.1-.55-2.17-.84-3.25A38,38,0,0,0,726.84,121a41.44,41.44,0,0,0-9.81-9.1,45.65,45.65,0,0,0-5.86-3.31A62.21,62.21,0,0,0,705.25,105Z" fill="#999"/>
          <path d="M736.11,146.17a48.37,48.37,0,0,1,20.4,7,33.47,33.47,0,0,1,4.26,3.54,33.89,33.89,0,0,1,3.68,4.16,31.86,31.86,0,0,1,5,9.91,33.76,33.76,0,0,1,1.16,5.42l.18,1.38v.12a1.47,1.47,0,0,0,0,.16v.19l0,.37,0,.74c-.07.48-.13,1-.23,1.46s-.22,1-.39,1.42a13.25,13.25,0,0,1-8,8.22,8.85,8.85,0,0,1-1.41.44,12.35,12.35,0,0,1-1.45.28,14.52,14.52,0,0,1-1.48.11c-.49,0-.9,0-1.36,0H676.1c-7.14-.42-14.28-.74-21.42-1.25,7.14-.5,14.28-.82,21.42-1.25h80.36a16.76,16.76,0,0,0,2.51-.1,7.81,7.81,0,0,0,1.17-.23,6.63,6.63,0,0,0,1.15-.35,10.77,10.77,0,0,0,6.45-6.67c.14-.37.21-.77.32-1.15s.12-.79.18-1.19l0-.6,0-.3v-.19l-.16-1.28a31.83,31.83,0,0,0-1.08-5,29.45,29.45,0,0,0-4.6-9.14,33.18,33.18,0,0,0-7.39-7.14A49.84,49.84,0,0,0,736.11,146.17Z" fill="#999"/>
          <path d="M1581.21,61.76c-.4-3.4-.72-6.79-1-10.19-.06-.84-.18-1.69-.19-2.54s0-1.7,0-2.55l0-5.08.13-20.35a1.24,1.24,0,0,1,.76-1.15l26.2-11.15,13.35-4.93c.56-.2,1.1-.46,1.67-.63s1.15-.31,1.72-.47l3.46-.92c2.31-.59,4.62-1.18,6.95-1.73-2.13,1.09-4.26,2.14-6.4,3.18l-3.22,1.54c-.54.25-1.07.52-1.61.76s-1.12.39-1.67.6l-13.33,4.92L1582,22.2l.76-1.14-.13,20.35,0,5.09c0,.85,0,1.7,0,2.54s-.14,1.7-.22,2.55C1582,55,1581.66,58.37,1581.21,61.76Z" fill="#999"/>
          <path d="M1605.25,178.6a36.77,36.77,0,0,1,1.45,3.55c0,1.2.11,2.41.17,3.61l.36,7.23.37,7.22.18,3.61c-.32,1.23-.63,2.45-1.07,3.68-.56-1.18-1-2.36-1.43-3.55l-.18-3.61-.37-7.23-.36-7.22c-.07-1.2-.11-2.41-.2-3.61A36.52,36.52,0,0,1,1605.25,178.6Z" fill="#999"/>
          <path d="M1605.49,269.64c.55,1.27,1,2.55,1.36,3.82,0,1.29.05,2.57.08,3.86l.19,7.72.19,7.71.09,3.86c-.35,1.3-.69,2.59-1.16,3.89-.53-1.27-.93-2.55-1.34-3.83l-.09-3.86-.19-7.71-.19-7.72c0-1.28,0-2.57-.11-3.86A38.14,38.14,0,0,1,1605.49,269.64Z" fill="#999"/>
          <path d="M1606.29,88.14a42.06,42.06,0,0,1,1.1,4.09c-.08,1.35-.12,2.7-.17,4l-.33,8.09-.32,8.09-.17,4c-.43,1.34-.85,2.67-1.41,4-.44-1.37-.76-2.73-1.08-4.1l.16-4,.32-8.09.33-8.09c0-1.35.12-2.7.15-4.05A42.33,42.33,0,0,1,1606.29,88.14Z" fill="#999"/>
          <path d="M1627.09,17.52c-2.38,1.56-4.79,3-7.22,4.49a9.72,9.72,0,0,1-1.87,1c-.65.27-1.28.57-1.93.85l-3.84,1.72-15.37,6.88.74-1.16.17,8.4-1.66-1.16,15.58-5.35,3.89-1.34a24.57,24.57,0,0,1,3.94-1.21c2.7-.58,5.41-1.14,8.15-1.62-2.46,1.3-4.94,2.52-7.43,3.72a24.23,24.23,0,0,1-3.85,1.47l-3.89,1.34-15.58,5.35a1.24,1.24,0,0,1-1.58-.77,1.06,1.06,0,0,1-.07-.38l-.17-8.4a1.26,1.26,0,0,1,.74-1.17l15.37-6.88,3.84-1.72c.64-.29,1.29-.57,1.92-.87a9,9,0,0,1,2-.76C1621.63,19.07,1624.34,18.24,1627.09,17.52Z" fill="#999"/>
          <path d="M1654.5,46.5l.36,7,.31,7,.59,13.94V241.63c-.42,9.29-.74,18.58-1.25,27.87-.51-9.29-.83-18.58-1.25-27.87V74.38l.59-13.94.31-7Z" fill="#999"/>
          <g>
            <path d="M846.33,424.19,830,431.83h0l-15,10.18h0l-12.08,6.12c-4.05,2-8,4.21-11.94,6.36-7.94,4.3-15.83,8.69-23.73,13h0L750.63,475l.08-.05-15,10.33,0,0-.06,0-16.83,6.82.09,0-15.56,9.18,0,0h0c-8.15,3.88-16.39,7.59-24.37,11.8-8.13,3.9-15.8,8.67-23.63,13.16-7.75,4.65-15.38,9.5-23.37,13.73s-15.64,9-23.4,13.63l-.07,0-.09,0-17,6.45.15-.07-15.66,9.06-16.08,8.26.11-.06-15.09,10.06,0,0-.05,0L532.78,594c-3.92,2.28-8.07,4.12-12.15,6.1s-8.15,4-12.33,5.72l-12.48,5.36.16-.09-15.42,9.46-.08.05-.08,0-16.69,7.07.24-.14-14.77,10.72-.12.08-.12,0-16.84,6.77.13-.06-9.81,5.61c-3.27,1.87-6.54,3.74-9.88,5.5-6.63,3.58-13.25,7.17-19.94,10.65l-5,2.63-2.5,1.32c-.83.44-1.71.78-2.57,1.17l-10.34,4.58c-3.46,1.49-6.85,3.11-10.25,4.73l-10.21,4.83-.11-.22,10.09-5.1c3.35-1.7,6.71-3.41,10.13-5L382,671c.84-.42,1.72-.78,2.54-1.23l2.47-1.36,4.95-2.71c6.61-3.58,13.16-7.28,19.72-11,3.3-1.81,6.53-3.73,9.77-5.65l9.74-5.71.06,0,.06,0,16.78-6.94-.24.13,14.67-10.86.1-.08.13-.05,16.63-7.2-.16.09,15.39-9.5.08,0,.08,0,12.43-5.38c4.18-1.72,8.21-3.72,12.29-5.64s8.19-3.76,12.1-6l11.9-6.46-.09.06,15.16-10,.06,0,.05,0,16.09-8.13,15.67-8.92.08,0,.08,0,17.06-6.35-.16.08c7.8-4.57,15.51-9.33,23.5-13.53s15.66-9,23.43-13.59,15.59-9.21,23.78-13.08c8-4.19,16.3-7.85,24.48-11.69l-.05,0,15.6-9.1,0,0h0l16.87-6.71-.11.06,15-10.22,0,0,0,0,16.56-7.3,0,0c7.93-4.3,15.86-8.63,23.83-12.88,4-2.12,7.93-4.32,12-6.28l12.12-6,0,0,15.1-10.07h0L846.22,424Z" fill="#fff"/>
            <path d="M710.16,460.66l-15.93,7.89-16.39,6.86-16,7.81-16.35,7h0L628.67,496l.05,0-12.15,5.45c-4.06,1.8-8.09,3.67-12.13,5.51s-8.07,3.71-12.13,5.51l-12.16,5.45,0,0-.07,0-17.33,4.58.17-.06L547,530.61l0,0h0l-24.72,10c-4.13,1.66-8.21,3.42-12.43,4.84l-12.56,4.49.17-.08-15.63,8.64-.07,0-.07,0-12.38,4.88c-4.15,1.57-8.2,3.39-12.28,5.12L432.51,579l-.06,0,0,0-16.91,5.58.2-.09L400,593.16l-.11.06-.1,0-17.18,4.91.12,0-16.18,7.35,0,0h0l-16.81,5.75-16.65,6.21-.09-.23,16.43-6.81,16.66-6.21-.06,0,16-7.72.06,0,.07,0,17.1-5.18-.22.09,15.55-8.83.08,0,.11,0,16.85-5.77-.1,0c8.15-3.52,16.28-7.1,24.47-10.55,4.11-1.71,8.17-3.51,12.34-5.06l12.44-4.79-.14.06,15.71-8.49.08,0,.08,0,12.58-4.36c4.22-1.38,8.31-3.1,12.44-4.71L546.29,529l-.08,0,15.9-8,.08,0,.09,0,17.36-4.45-.11,0,12.19-5.35c4.07-1.77,8.12-3.61,12.17-5.43s8.09-3.65,12.17-5.42l12.2-5.33h0l16.89-5.62h0l16.4-6.83,16-7.64,16.46-6.7,16-7.73Z" fill="#fff"/>
            <path d="M1171.62,433.23l12.57,5.52c4.25,1.71,8.23,4,12.3,6.08s8.1,4.25,12.17,6.33l12.21,6.26,0,0,17.53,5.66h.05l0,0,15.42,10.18-.1,0,17.3,6.15,0,0,0,0,15.85,9.26,0,0,16.59,7.68,16.8,7.24,16.81,7.18,17.09,6.5,0,0,0,0,16.11,8.68,16.19,8.54-.06,0,16.81,7.2,0,0,0,0,14,7.61,1.76,1c.59.31,1.2.57,1.8.86l3.62,1.69,7.23,3.39c4.81,2.29,9.72,4.37,14.58,6.55,2.44,1.06,4.84,2.2,7.32,3.2l7.45,3c10.1,3.54,19.39,8.93,29.32,12.85l7.42,3c2.46,1,5,1.92,7.38,3.1l14.56,6.72,0,0,0,0,16,8.89-.1,0,12.53,5.58,12.55,5.52c4.18,1.85,8.38,3.67,12.55,5.55l12.51,5.62.09,0,.07,0L1601.73,636l-.19-.1,17.08,6.65,0,0h0l24.58,12.25c4.09,2.06,8.24,4,12.2,6.3l12,6.65-.06,0,17.28,6.3-.08.23-17.43-5.86h0L1655,662c-4-2.18-8.19-4.07-12.31-6l-24.77-11.83.08,0-17.17-6.44-.09,0-.1-.06-15.54-10,.16.08-12.57-5.49c-4.18-1.85-8.39-3.62-12.6-5.43-8.42-3.56-16.76-7.32-25.13-11l0,0-.05,0-16-8.86.08,0-14.48-6.75c-2.39-1.19-4.91-2.09-7.37-3.13l-7.41-3c-9.9-4-19.16-9.44-29.22-13l-7.42-3c-2.46-1-4.88-2.18-7.32-3.26-4.86-2.22-9.77-4.34-14.58-6.68l-7.25-3.44-3.62-1.72c-.6-.29-1.22-.56-1.8-.88l-1.76-1-14.06-7.71.08,0-16.78-7.29h0l0,0-16.15-8.62-16.08-8.77.09,0-17.08-6.59-16.8-7.29L1303,497.52l-16.56-7.81h0l-15.79-9.38.09.05-17.26-6.28,0,0-.05,0L1238,463.74l.1,0-17.5-5.78h0l0,0-12.17-6.35c-4.06-2.1-8.09-4.26-12.12-6.41s-8-4.42-12.25-6.16l-12.52-5.6Z" fill="#fff"/>
            <path d="M1338.57,476.87l17.49,5.44,17.14,6.44h0l16.28,8.55-.07,0,17.48,5.52h0l16.91,7,16.63,7.67-.1,0,17.7,4.95h.07l17.06,6.59,0,0,0,0,16.2,8.76-.13-.06c8.64,3,17.34,5.9,25.87,9.22s17.15,6.37,25.72,9.55,17.33,6,25.59,10l25.09,11.15-.2-.07,17.82,4.7.17,0,.14.08,15.92,9.39-.24-.1,17.59,5.27.1,0,.07,0,16.27,8.53-.07,0,16.92,7,17.08,6.56-.09.23-17.32-5.91L1660.71,607h0l0,0-16.47-8.13.17.06-17.67-5-.12,0-.12-.07-16.06-9.17.31.13-17.85-4.58-.1,0-.1,0c-8.38-3.67-16.76-7.36-25-11.18-8.21-4-16.87-6.95-25.39-10.23s-17.11-6.48-25.63-9.8-17.15-6.32-25.76-9.4l-.07,0-.06,0-16.13-8.88.09,0-17-6.72.06,0L1440,518.79l0,0,0,0L1423.4,511l-16.82-7.2,0,0-17.42-5.69,0,0h0l-16.2-8.73,0,0-17.06-6.61-17.42-5.63Z" fill="#fff"/>
            <path d="M951.63,511.91,942,527.44,931.8,542.6l0,0-8.74,16.13v0l0,0-11.28,14.43,0,0-8,13.85-1,1.73c-.33.57-.73,1.11-1.1,1.67l-2.23,3.33L895,600.35c-5.92,8.89-13.07,16.88-18.72,25.92-6.22,8.64-11.29,18.08-17.36,26.86l-4.54,6.61c-1.53,2.18-3,4.45-4.62,6.54L840,679l0-.06-9.89,15.36,0-.06-8.62,16.21,0,.06,0,.06-10.81,14.8-11.49,14.28.06-.07c-5.08,7.59-10.19,15.18-15.2,22.8-2.48,3.81-5.13,7.53-7.29,11.56L770,785.82l0,0,0,0L759.48,801l-11.32,14.41.09-.13L741.14,827c-2.3,4-4.91,7.71-7.43,11.53s-5.16,7.56-7.34,11.57L719.5,862l0,.09-.06.07-11.85,13.94-11,14.63.07-.11-8.51,16.32,0,.07,0,0-11.74,14.1,0-.05-9.27,15.81-.22-.12,8.83-16.07v0l0,0,11.37-14.4-.07.11,8.2-16.48,0-.05,0-.06L706,874.87l11.73-14.11-.1.15,6.77-12c2.15-4.05,4.77-7.81,7.23-11.67s5.05-7.64,7.34-11.59l7-11.74,0-.07,0-.06,11.24-14.38,10.46-15-.06.08,6.91-11.87c2.19-4,4.88-7.74,7.41-11.56,5.09-7.63,10.25-15.16,15.39-22.72l0,0,0,0,11.57-14.16L820,709.47l-.07.11,8.71-16.16,0,0,0,0,10-15.3,0,0,0,0,9.75-12.66c1.68-2.07,3.11-4.32,4.65-6.49l4.55-6.55c6.11-8.73,11.22-18.12,17.52-26.77,5.73-9,13-17,18.92-25.79l4.51-6.59,2.25-3.3c.37-.55.77-1.08,1.11-1.65l1-1.72,8.13-13.76v0l0,0,11.4-14.34,0,0,8.87-16.06h0l10.32-15.07,9.74-15.45Z" fill="#fff"/>
            <path d="M972.37,578.58l.2,13.24c.06,4.41.18,8.81.28,13.22s.1,8.82.56,13.21l1,13.18v.07L974,649.13l-.13,17.64v-.13l2,17.61v.18c-.06,2.94-.2,5.88-.31,8.82s-.24,5.88-.4,8.82l-.51,8.81c-.16,2.94-.37,5.88-.58,8.82v-.09c.1,2.94.23,5.89.31,8.84s.17,5.89.2,8.84l-.25,0c-.37-2.93-.66-5.86-1-8.79s-.59-5.87-.88-8.8v-.09c0-2.94.06-5.89.14-8.83l.21-8.82q.06-4.41.21-8.82c.09-2.94.15-5.88.29-8.82v.17l-1.62-17.65v-.13l.49-17.64.71-17.63v.07l-.75-13.23c-.35-4.4-.21-8.82-.23-13.24s0-8.83.06-13.24l.13-13.24Z" fill="#fff"/>
            <path d="M1089.6,423.86l8.22,16.08v0h0l5.21,17.32,0,0,8.55,15.95,0,0v0l5.48,17.22,0-.09,9.17,15.7,0,.06,0,.07,4.3,17.7,0-.09,5.42,12.37c1.77,4.15,3.72,8.21,5.61,12.29s3.77,8.18,5.77,12.22l5.89,12.16,0,0,0,.05,5.5,17.2,0,0,6.7,16.7,7.49,16.36,0,.05,0,.06,5.36,17.26-.08-.2L1187.5,656l.07.12,0,.14,4.63,17.56-.1-.25,8.81,15.85,0,.06,0,.08,6.47,16.87,5.63,17.16-.08-.19,8.24,16.09,0,.06,0,.08,4.7,12.65c1.49,4.24,3.29,8.36,5,12.52s3.37,8.33,5.27,12.41l5.54,12.3,0,.05,0,0,6.05,17,0-.08,7.32,16.47,0,.06,0,.06,4.9,17.44-.07-.17,8.75,15.91,0,.07,0,.07,4.47,17.6,0-.09,8.55,16-.21.12-9-15.75,0,0v0l-4.94-17.47.06.14-9-15.74,0-.07,0-.09-5.22-17.34,0,.12-7.5-16.38v0l0,0-6.23-16.91,0,.08L1234,778.21c-2-4.06-3.61-8.27-5.36-12.43s-3.54-8.32-5.05-12.57l-4.73-12.67.06.14-8.17-16.12,0-.1,0-.09-5.5-17.14-6.32-16.85.07.14-8.69-15.91-.07-.12,0-.12-4.49-17.6.1.25-9.15-15.72,0-.1,0-.1-5.27-17.3,0,.11-7.42-16.44-6.61-16.78v-.05l-5.4-17.24,0,.1-5.82-12.21c-2-4-3.82-8.17-5.7-12.26s-3.81-8.18-5.54-12.34l-5.32-12.43,0,0v-.05l-4.17-17.73.05.13-9-15.76,0,0v-.05L1111,473.46l0,.05-8.42-16v-.05l-5.08-17.36v0l-8.1-16.15Z" fill="#fff"/>
          </g>
          <g>
            <path d="M425.32,105.49l-3.26,8.11c-.13.32.3.52.54.54l12.86,1.19c.84.08,3.37-1.34,1.79-1.49l-12.85-1.18.55.54,3.26-8.1c.34-.84-2.52-.52-2.89.39Z" fill="#999"/>
            <path d="M433,111.48l5.42,7.1c.59.77,3.39-.22,2.7-1.13l-5.42-7.09c-.59-.78-3.39.21-2.7,1.12Z" fill="#999"/>
            <path d="M426,126.11l.28-5.88-2.64.93,10,.89a6.36,6.36,0,0,1,1.87.17c.28.15.07-.15.09.61,0,1.68.12,3.37.18,5.06,0,.42,2.93,0,2.9-.78l-.21-5.79a.28.28,0,0,0-.26-.15l-12.15-1.09c-.54,0-2.61.15-2.64.93l-.29,5.88c0,.34,2.86,0,2.9-.78Z" fill="#999"/>
            <path d="M450.64,125.2l5,1.9a2.85,2.85,0,0,0,1.86-.19c.1,0,1.31-.61.88-.77l-5-1.91a2.92,2.92,0,0,0-1.86.2c-.1,0-1.3.61-.88.77Z" fill="#999"/>
            <path d="M455.17,121.64l-.14,8.3,1.23-1-6,1.5c-1.78.44-1.31,2.19.46,1.75l6-1.5c.4-.1,1.21-.49,1.22-1l.14-8.29c0-1.38-2.9-.87-2.92.28Z" fill="#999"/>
            <path d="M463.27,129.27l6.22-.33c1.67-.09,2.21-2.31.24-2.2l-6.22.33c-1.67.09-2.21,2.3-.24,2.2Z" fill="#999"/>
            <path d="M463.16,120.73,461.27,133a.25.25,0,0,0,.08.29l6.87,3.59c.79.41,3.22-.78,2.71-1l-4.75-2.49c-.47-.25-1.78-.63-2.06-1.07.21.33.15-.64.22-1,.65-3.66,1.13-7.35,1.69-11,.13-.85-2.75-.34-2.87.46Z" fill="#999"/>
            <path d="M480.77,132.61l5.84,2c1.09.37,3.4-1.21,1.71-1.79l-5.83-2c-1.1-.38-3.4,1.21-1.72,1.79Z" fill="#999"/>
            <path d="M483.85,128.64l-.47,11c-.06,1.37,2.87.87,2.92-.28l.47-11c.06-1.37-2.87-.87-2.92.28Z" fill="#999"/>
            <path d="M481.61,140.81l6-1.77c.26-.07,1.45-.38,1.34-.81s-1.37-.09-1.56,0l-6,1.76c-.26.08-1.45.39-1.34.81s1.37.1,1.56,0Z" fill="#999"/>
            <path d="M489.54,136.52l12,2.89-.55-.56-.6,2.84c-.21,1,2.7.56,2.89-.38l.6-2.85c.07-.36-.27-.49-.55-.56l-12-2.88c-.87-.21-3.4,1.12-1.79,1.5Z" fill="#999"/>
            <path d="M495.79,131.89l1.74,10c.2,1.2,3.11.48,2.89-.78l-1.74-10c-.2-1.2-3.11-.48-2.89.78Z" fill="#999"/>
            <path d="M491.1,132.6l-.17,12.82a.91.91,0,0,0,.65.91L504.5,150c1.31.37,3.42-1.53,1.66-2l-12.93-3.69.65.91L494,132.4c0-1.56-2.92-1.18-2.94.2Z" fill="#999"/>
          </g>
          <path d="M133.21,79.33c-.4-2.36-.74-4.72-1-7.08L132,70.48a10.46,10.46,0,0,1,0-1.77V65.16l0-14.17,1,1.22-28.31-5.4,1.48-1.33.51,6.48c.09,1.08.16,2.16.28,3.24s0,2.17.07,3.26q0,6.51-.05,13c-.76-4.29-1.45-8.58-2.11-12.87-.15-1.08-.37-2.15-.45-3.23s-.15-2.16-.23-3.24l-.52-6.48a1.25,1.25,0,0,1,1.15-1.34,1.93,1.93,0,0,1,.34,0l28.3,5.4a1.26,1.26,0,0,1,1,1.23l0,14.17V68.7a11.62,11.62,0,0,1,0,1.77l-.21,1.77C133.93,74.61,133.59,77,133.21,79.33Z" fill="#999"/>
          <path d="M105.69,44.42c-.37.81-.78,1.59-1.19,2.36A6.15,6.15,0,0,1,103,48.86l-3.55,3.55.36-.95.46,8,.22,4a8.28,8.28,0,0,0,.09,1c-.08.34-.22.68-.32,1-.23.68-.49,1.37-.79,2-.37-.65-.71-1.29-1-1.94-.14-.33-.32-.65-.43-1s0-.67,0-1l-.23-4-.45-8a1.21,1.21,0,0,1,.36-1l3.55-3.56a6.06,6.06,0,0,1,2.08-1.48C104.1,45.2,104.87,44.79,105.69,44.42Z" fill="#999"/>
          <path d="M124.42,61.49a23.1,23.1,0,0,1-3.49,5.82,13.59,13.59,0,0,1-1.82,1.74l-.24.19a.67.67,0,0,1-.94-.09.93.93,0,0,1-.11-.19,20.8,20.8,0,0,0-.77-1.95,13.2,13.2,0,0,0-1-1.78c-.79-1.13-1.8-2.13-2.68-3.2a.84.84,0,0,1,0-1l.16-.21c.63-.82,1.31-1.6,2-2.38a24.94,24.94,0,0,1,2.12-2.24l.09-.09a1.25,1.25,0,0,1,1.65,0A30.41,30.41,0,0,1,124.42,61.49Zm-.25,0a57.46,57.46,0,0,1-6.47-3.37l1.74-.13a26.51,26.51,0,0,1-2.2,2.17c-.77.68-1.53,1.38-2.34,2L115,61c.56,1.27,1,2.62,1.64,3.84a13.15,13.15,0,0,0,1.11,1.74,20.53,20.53,0,0,0,1.39,1.57L117.89,68A10.4,10.4,0,0,1,119,66.25a15.56,15.56,0,0,1,1.43-1.52C121.45,63.73,122.66,62.73,124.17,61.51Z" fill="#999"/>
          <path d="M132.65,187.33c-.47-2.26-.87-4.53-1.23-6.8l-.27-1.7c-.06-.57,0-1.14,0-1.71l-.1-3.42L130.64,160l1,1.2L106,156.68l1.47-1.19-.21,6c0,1-.08,2-.08,3s-.2,2-.3,3c-.46,4-1,8-1.51,12-.28-4-.48-8-.66-12.06,0-1-.12-2-.08-3s.09-2,.13-3l.21-6a1.25,1.25,0,0,1,1.3-1.2l.17,0,25.69,4.55a1.23,1.23,0,0,1,1,1.19l.38,13.67.1,3.42c0,.57.09,1.14.06,1.71l-.18,1.71C133.27,182.76,133,185,132.65,187.33Z" fill="#999"/>
          <path d="M105.9,152.69c-.5.79-1,1.54-1.56,2.27a6.56,6.56,0,0,1-1.81,2l-4.13,3.25.47-1.14,1,8.16.5,4.08a9,9,0,0,0,.15,1c-.06.35-.17.71-.25,1.06-.18.71-.4,1.43-.65,2.15-.41-.64-.8-1.28-1.14-1.93-.17-.33-.36-.65-.5-1a8.8,8.8,0,0,0-.1-1l-.49-4.08-1-8.16a1.21,1.21,0,0,1,.47-1.13L101,155a6.68,6.68,0,0,1,2.33-1.28C104.16,153.32,105,153,105.9,152.69Z" fill="#999"/>
          <path d="M125.73,169.49a27,27,0,0,1-2.72,3.79,23.71,23.71,0,0,1-1.81,2,15.25,15.25,0,0,1-2.12,1.67l-.25.17a.68.68,0,0,1-1-.19.21.21,0,0,1-.06-.1,18,18,0,0,0-2-3.67A29.64,29.64,0,0,0,113,170a.7.7,0,0,1-.08-.88l.15-.23c.67-1,1.43-1.89,2.18-2.8s1.5-1.81,2.32-2.65l0,0a1.33,1.33,0,0,1,1.73-.13A41,41,0,0,1,125.73,169.49Zm-.25,0c-3.23-1.63-5.65-2.59-7.77-4.06l1.77-.16c-.79.87-1.66,1.67-2.52,2.48s-1.72,1.61-2.66,2.33l.07-1.11a30,30,0,0,0,2,3.7,18.05,18.05,0,0,0,2.79,3.15l-1.26-.12a11.74,11.74,0,0,1,1.43-1.74,19.29,19.29,0,0,1,1.74-1.44C122.34,171.6,123.75,170.67,125.48,169.51Z" fill="#999"/>
          <path d="M-1.34,196.29l4.72.55,4.71.6,9.41,1.23,18.6,3.62-.1,0,28.21,3.15c9.41,1,18.79,2.19,28.19,3.31s18.78,2.32,28.18,3.28l7.06.75a31.79,31.79,0,0,1,3.51.46l3.51.61c4.67.81,9.34,1.58,14,2.46-4.74-.13-9.48-.37-14.21-.57l-3.55-.16a34.71,34.71,0,0,1-3.54-.29l-7.06-.78c-9.41-1-18.79-2.16-28.19-3.27s-18.78-2.31-28.18-3.32l-28.21-3.14-.1,0L17,201.14l-9.19-2.39-4.59-1.2Z" fill="#999"/>
          <path d="M-.39,82.87c4.71.53,9.4,1.19,14.1,1.8s9.32,1.59,14,2.47c9.27,1.91,18.58,3.45,27.88,5.11s18.58,3.46,28,4.55l28.1,3.79.09,0,18.51,3.94,9.12,2.56c3,.84,6.09,1.72,9.12,2.61q-4.69-.61-9.39-1.33L129.7,107l-18.51-4,.09,0L83.15,99.28c-9.4-1.1-18.69-3-28-4.56S36.47,91.5,27.21,89.59c-4.63-1-9.31-1.67-13.87-3S4.17,84.17-.39,82.87Z" fill="#999"/>
          <path d="M239.7,103l-14.26,14.12a.62.62,0,0,1-.87,0l0,0-14.11-13.39a1,1,0,0,1,0-1.41l0,0,0,0,6.77-6.45,3.43-3.18c1.15-1.05,2.32-2.07,3.49-3.1l0,0a1.22,1.22,0,0,1,1.61,0c2.49,2.26,4.95,4.56,7.27,6.85l3.4,3.39Zm-.25,0-8-5.9c-2.53-1.9-4.94-3.78-7.3-5.71l1.65,0c-1.14,1.07-2.27,2.14-3.43,3.18l-3.5,3.1-7.07,6.1,0-1.47,13.58,13.92h-.89C229.41,111.87,234.41,107.54,239.45,103Z" fill="#999"/>
          <path d="M237.26,145l-11.82,13.12a.6.6,0,0,1-.84,0h0l0,0-13.41-12.42a1,1,0,0,1-.06-1.39h0l0,0,6.41-6.5c2.14-2.15,4.32-4.28,6.57-6.32l.05-.05a1.2,1.2,0,0,1,1.7.08c2.06,2.27,4.08,4.57,6,6.86l2.78,3.38Zm-.25,0-6.75-6c-2.15-1.93-4.17-3.84-6.15-5.79l1.75,0c-2.13,2.18-4.34,4.26-6.57,6.33l-6.74,6.15v-1.44l12.88,13-.87,0Z" fill="#999"/>
          <path d="M239.05,187,225.48,202.3a.64.64,0,0,1-.9,0l-.06-.06,0,0L212,187.66a1,1,0,0,1,0-1.35l0,0,6-6.44c1-1.06,2-2.14,3-3.18s2.06-2.07,3.1-3.1l0,0a1.2,1.2,0,0,1,1.68,0c2.38,2.25,4.71,4.55,6.92,6.83l3.24,3.39Zm-.25,0-7.63-5.92c-2.43-1.91-4.74-3.8-7-5.74l1.72,0c-1,1.06-2,2.13-3,3.18s-2.07,2.07-3.1,3.11l-6.28,6.13.06-1.38,11.95,15.1-1,0Z" fill="#999"/>
          <path d="M238.89,229l-13.44,13.91a.63.63,0,0,1-.88,0l0,0,0,0L212,229.69a1,1,0,0,1,0-1.37l0,0,6-6.39,3-3.15c1-1,2.05-2.06,3.1-3.08l0,0a1.24,1.24,0,0,1,1.69,0c2.35,2.24,4.67,4.52,6.85,6.79l3.2,3.36Zm-.25,0-7.56-5.86c-2.41-1.88-4.68-3.74-6.92-5.66l1.73,0q-1.5,1.59-3,3.15l-3.11,3.07-6.3,6.06,0-1.4,12,13.7-.92,0C229.13,237.74,233.87,233.47,238.64,229Z" fill="#999"/>
          <polyline points="198.4 58.56 226.02 59.75 253.61 61.27" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <polyline points="331.65 373.62 353.97 379.21 354.18 391.5" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <polyline points="295.55 426.81 266.97 425.98 238.59 422.25" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <polyline points="295.56 445.84 266 445.63 236.53 443.46" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <polyline points="295.47 463.73 266.92 464.4 238.45 467.15" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <polyline points="295.66 481.46 266.76 484.2 238.74 491.97" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <polyline points="295.22 495.8 267.08 505.87 238.58 514.77" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <g>
            <polygon points="-1.15 406.87 36 406.05 73.47 404 110.97 402.77 147.8 404.31 148.91 436.46 147.82 469.43 148.39 502.39 148.69 535.36 148.56 568.37 110.78 579.16 73.46 591.27 35.76 602.15 -0.72 613.24 -1.7 579.84 -1.68 545.17 -0.13 510.5 -1.03 475.84 -2.99 441.16 -1.15 406.87" fill="#231815"/>
            <polygon points="-1.15 406.87 36 406.05 73.47 404 110.97 402.77 147.8 404.31 148.91 436.46 147.82 469.43 148.39 502.39 148.69 535.36 148.56 568.37 110.78 579.16 73.46 591.27 35.76 602.15 -0.72 613.24 -1.7 579.84 -1.68 545.17 -0.13 510.5 -1.03 475.84 -2.99 441.16 -1.15 406.87" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          </g>
          <path d="M97.54,441.5c.4,2.95.77,5.89,1.12,8.84l1,8.85.85,17.75v.1l-1.3,35.5v-.12l2,35.5V548l-.32,17.75-.74,8.87c-.25,3-.53,5.91-.82,8.86-.19-3-.37-5.92-.52-8.89l-.42-8.88L98.75,548v.09l-2-35.5v-.11L98,477v.11l-.86-17.75.14-8.9C97.35,447.44,97.43,444.47,97.54,441.5Z" fill="#999"/>
          <path d="M88.44,444.38c-3.67,1-7.36,1.78-11.06,2.59l-11.21,1.34c-7.45,1.08-15,1.34-22.49,1.86L21.16,451.6l-11.26.7c-3.77-.14-7.55-.27-11.33-.54C2.29,451,6,450.42,9.75,449.81l11.25-.7,22.49-1.43c7.48-.52,15-.78,22.44-1.86l11.18-1.35C80.87,444.39,84.64,444.31,88.44,444.38Z" fill="#999"/>
          <path d="M112,435c1.14-1.73,2.38-3.35,3.61-5l4.47-4,8.91-8.09,8.92-8.1,4.45-4c1.75-1.07,3.48-2.15,5.3-3.13-1.15,1.72-2.38,3.35-3.61,5l-4.46,4-8.92,8.1-8.91,8.09-4.45,4.06C115.61,432.94,113.88,434,112,435Z" fill="#999"/>
          <line x1="148.72" y1="333.5" x2="147.64" y2="371.5" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <path d="M77.45,479c-2.76.67-5.53,1.29-8.3,1.88l-4.15.86c-.7.14-1.4.16-2.1.24l-2.1.25L44,484.2l-33.58,4.12h-.1L-6.62,489l-2.11.09c-.71,0-1.41.13-2.12.09l-4.25-.2q-4.24-.21-8.5-.52,4.22-.66,8.43-1.22l4.21-.56c.71-.1,1.42,0,2.12-.09l2.12-.08,16.93-.71h-.1l33.59-4.11,16.8-2,2.1-.25c.7-.08,1.39-.24,2.1-.26l4.24-.12C71.78,479,74.61,479,77.45,479Z" fill="#999"/>
          <path d="M77.78,506.89q-3.91,1-7.84,1.89l-3.93.87c-.65.14-1.32.16-2,.24l-2,.25-15.9,2,.17,0-30.9,8.48L-.27,523.88l-2,.41a18.91,18.91,0,0,1-2,.4l-4,.45c-2.67.29-5.35.55-8,.78,2.54-.86,5.09-1.68,7.65-2.48l3.83-1.19c.64-.2,1.3-.26,2-.4l2-.41,15.65-3.27,30.82-8.46.09,0h.09l15.9-2,2-.25c.67-.08,1.32-.23,2-.26l4-.13C72.4,506.93,75.09,506.9,77.78,506.89Z" fill="#999"/>
          <path d="M73.76,538.65q-3.72,1.11-7.46,2.1l-3.74,1c-.62.16-1.27.19-1.9.3l-1.9.3-15.19,2.47.1,0-29.85,7.32-14.85,4-1.86.49a16.25,16.25,0,0,1-1.85.5l-3.81.63c-2.54.41-5.09.8-7.65,1.15q3.58-1.47,7.2-2.83l3.62-1.37c.6-.23,1.24-.32,1.86-.49l1.86-.5,14.85-4,29.88-7.33h.1l15.19-2.46,1.9-.31c.64-.1,1.26-.27,1.9-.32L66,539Q69.88,538.78,73.76,538.65Z" fill="#999"/>
          <path d="M73.19,561.36c-3.83,1.75-7.72,3.37-11.61,5l-12,3.77c-7.94,2.73-16.14,4.53-24.26,6.63s-16.25,4.19-24.43,6l-12.25,2.8c-4.17.56-8.34,1.14-12.53,1.58,4-1.42,8-2.71,12-4l12.24-2.8c8.19-1.78,16.26-3.93,24.38-6s16.27-3.89,24.19-6.61l12-3.78C64.94,563,69.05,562.12,73.19,561.36Z" fill="#999"/>
          <polyline points="164.5 405.39 194.7 405.37 193 450.5" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <line x1="548.7" y1="149.67" x2="524.92" y2="159.63" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <line x1="565.55" y1="171.29" x2="589.19" y2="178.74" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <line x1="564.69" y1="178.65" x2="573.76" y2="180.33" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <line x1="581.59" y1="183.16" x2="589.45" y2="185.7" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <line x1="756.02" y1="316.5" x2="757.99" y2="327.47" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <line x1="757.63" y1="341.5" x2="756.93" y2="350.62" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <line x1="757.19" y1="293.5" x2="757.22" y2="305.38" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <line x1="734.61" y1="288.5" x2="733.82" y2="297.9" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <line x1="732.41" y1="313.5" x2="734.79" y2="324.54" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <line x1="732.48" y1="340.5" x2="732.79" y2="350.62" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <path d="M682.35,299.5c.47,3.47.78,6.93,1.09,10.39l-.18,10.38L682.91,341l-.1,20.75-.06,10.37c-.4,3.46-.78,6.91-1.3,10.37-.48-3.46-.84-6.92-1.2-10.38l.06-10.38.1-20.76.35-20.75.16-10.38C681.35,306.4,681.78,303,682.35,299.5Z" fill="#999"/>
          <path d="M1711.61,179a23,23,0,0,1-.37,4.24,9.25,9.25,0,0,1-1.85,4.15,12,12,0,0,1-8.39,3.47,11.86,11.86,0,1,1,8.38-20.24,9.54,9.54,0,0,1,1.85,4.14A23,23,0,0,1,1711.61,179Zm0,0a6.83,6.83,0,0,0-1.24-3.88,12.31,12.31,0,0,0-2.75-2.74,9.36,9.36,0,1,0-6.62,16,9.32,9.32,0,0,0,6.61-2.75,12.92,12.92,0,0,0,2.75-2.73A6.77,6.77,0,0,0,1711.61,179Z" fill="#999"/>
          <path d="M1711.61,215a23,23,0,0,1-.37,4.24,9.25,9.25,0,0,1-1.85,4.15,12,12,0,0,1-8.39,3.47,11.86,11.86,0,1,1,8.38-20.24,9.54,9.54,0,0,1,1.85,4.14A23,23,0,0,1,1711.61,215Zm0,0a6.83,6.83,0,0,0-1.24-3.88,12.31,12.31,0,0,0-2.75-2.74,9.36,9.36,0,1,0-6.62,16,9.32,9.32,0,0,0,6.61-2.75,12.92,12.92,0,0,0,2.75-2.73A6.77,6.77,0,0,0,1711.61,215Z" fill="#999"/>
          <path d="M1711.61,251a23,23,0,0,1-.37,4.24,9.25,9.25,0,0,1-1.85,4.15,12,12,0,0,1-8.39,3.47,11.86,11.86,0,1,1,8.38-20.24,9.54,9.54,0,0,1,1.85,4.14A23,23,0,0,1,1711.61,251Zm0,0a6.83,6.83,0,0,0-1.24-3.88,12.31,12.31,0,0,0-2.75-2.74,9.36,9.36,0,1,0-6.62,16,9.32,9.32,0,0,0,6.61-2.75,12.92,12.92,0,0,0,2.75-2.73A6.77,6.77,0,0,0,1711.61,251Z" fill="#999"/>
          <path d="M1711.61,287a23,23,0,0,1-.37,4.24,9.25,9.25,0,0,1-1.85,4.15,12,12,0,0,1-8.39,3.47,11.86,11.86,0,1,1,8.38-20.24,9.54,9.54,0,0,1,1.85,4.14A23,23,0,0,1,1711.61,287Zm0,0a6.83,6.83,0,0,0-1.24-3.88,12.31,12.31,0,0,0-2.75-2.74,9.36,9.36,0,1,0-6.62,16,9.32,9.32,0,0,0,6.61-2.75,12.92,12.92,0,0,0,2.75-2.73A6.77,6.77,0,0,0,1711.61,287Z" fill="#999"/>
          <path d="M1301.5,11.5s56,6,56,66" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <path d="M1726.28,80.61l5.15-1.48,5.17-1.41L1747,74.9l20.93-4.47,41.87-9,41.62-10.07,20.81-5c7-1.27,14-2.63,21.1-3.82-6.82,2.17-13.68,4.17-20.52,6.25l-20.82,5-41.63,10.08-41.89,9-20.94,4.49L1736.89,79l-5.3.82Z" fill="#999"/>
          <path d="M1756.68,56.05a17.11,17.11,0,0,1,1.21,2.26c-.05.74-.05,1.48-.07,2.23l-.1,4.46-.11,4.45,0,2.23a20.1,20.1,0,0,1-1.3,2.2,18.91,18.91,0,0,1-1.2-2.25l0-2.23.11-4.46.1-4.46c0-.75,0-1.49,0-2.23A16.42,16.42,0,0,1,1756.68,56.05Z" fill="#999"/>
          <path d="M1779.39,69.38a17.35,17.35,0,0,1,1.25,2.18c0,.72,0,1.45,0,2.17l0,4.36v6.53a18.68,18.68,0,0,1-1.26,2.17,21.94,21.94,0,0,1-1.24-2.18V78.08l0-4.35c0-.73,0-1.45,0-2.18A15.28,15.28,0,0,1,1779.39,69.38Z" fill="#999"/>
          <path d="M1743.24,77.57a14.6,14.6,0,0,1,1.42,2c0,.69.11,1.37.16,2l.33,4.11.33,4.11.16,2.06a18,18,0,0,1-1.08,2.15,20.33,20.33,0,0,1-1.41-2L1743,90l-.33-4.11-.34-4.11c-.06-.68-.09-1.37-.17-2A15,15,0,0,1,1743.24,77.57Z" fill="#999"/>
          <path d="M1802.4,46.5a18.28,18.28,0,0,1,1.24,2.21c0,.74,0,1.47,0,2.2l0,4.4,0,4.4,0,2.2a20.72,20.72,0,0,1-1.28,2.19,21.2,21.2,0,0,1-1.22-2.21l0-2.2,0-4.4,0-4.4c0-.74,0-1.47,0-2.2A16.68,16.68,0,0,1,1802.4,46.5Z" fill="#999"/>
          <path d="M1827.94,58.55a16.72,16.72,0,0,1,1.13,2.45c-.06.79-.09,1.58-.14,2.37l-.26,4.76-.26,4.75-.13,2.38a21.64,21.64,0,0,1-1.38,2.31,22.94,22.94,0,0,1-1.11-2.45l.13-2.38.26-4.75.26-4.75c0-.8.1-1.59.11-2.38A19.38,19.38,0,0,1,1827.94,58.55Z" fill="#999"/>
          <path d="M1845.28,54.26c-.4-3.49-.72-7-1-10.48-.06-.87-.17-1.75-.17-2.62s.05-1.74.05-2.62c0-1.74,0-3.49.06-5.23l.77-20.93,1.69,1.22-38.16,14.25.81-1.08-.15,2.08c-.07.69,0,1.39-.15,2.08l-.69,4.12q-.69,4.13-1.49,8.25-.18-4.2-.26-8.38l-.07-4.18c0-.7.12-1.38.16-2.08l.16-2.07a1.26,1.26,0,0,1,.81-1.08l38.15-14.25a1.26,1.26,0,0,1,1.61.73,1.4,1.4,0,0,1,.08.49l-.77,20.89c0,1.74,0,3.48-.06,5.22,0,.87,0,1.74,0,2.61s-.15,1.74-.22,2.61C1846.11,47.29,1845.73,50.77,1845.28,54.26Z" fill="#999"/>
          <path d="M1867.43,49.25a21.32,21.32,0,0,1,1.32,2.61c0,.88,0,1.76,0,2.64l.11,5.27L1869,65l.06,2.64c-.37.88-.72,1.77-1.2,2.66a24.37,24.37,0,0,1-1.3-2.61l-.06-2.64-.1-5.26-.11-5.27c0-.88,0-1.76-.07-2.64A23.1,23.1,0,0,1,1867.43,49.25Z" fill="#999"/>
          <path d="M1533.5,154.5c.52,4.5.89,9,1.26,13.5v81c-.38,4.5-.75,9-1.25,13.5-.5-4.5-.87-9-1.25-13.5V168C1532.61,163.5,1533,159,1533.5,154.5Z" fill="#999"/>
          <path d="M1337.5,136.5s-8,7-5,20" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <path d="M1346.5,132.5s12-4,17,7" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <circle cx="1337" cy="149" r="2.12" fill="#231815" stroke="#999" stroke-miterlimit="10"/>
          <circle cx="1350" cy="145" r="2.12" fill="#231815" stroke="#999" stroke-miterlimit="10"/>
          <path d="M1341.5,153.5s7,1,11-4" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <path d="M1334.5,160.5a9,9,0,0,0,9,4v5" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <path d="M1338.5,169.5a28.61,28.61,0,0,0-8,24" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <line x1="1345.5" y1="172.5" x2="1352.5" y2="175.5" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <line x1="1362.5" y1="166.5" x2="1355.5" y2="174.5" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <path d="M1367.5,162.5s13,4,15,15" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <path d="M1365.5,148.5a10.67,10.67,0,0,0,11,1" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <path d="M1365.5,142.5s5,2,8,0" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
          <path d="M1365.5,154.5s5,5,15,5" fill="none" stroke="#999" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
        </g>
        <g>
          <rect x="1262" y="415" width="99" height="29" rx="14.5" ry="14.5" fill="#e0e4e5" style={{ mixBlendMode: 'multiply' }}/>
          <rect x="532.25" y="490.41" width="67.3" height="13.59" rx="6.79" ry="6.79" fill="#e0e4e5" style={{ mixBlendMode: 'multiply' }}/>
          <rect x="1174.25" y="616.41" width="355.75" height="29.59" rx="12.98" ry="12.98" fill="#e0e4e5" style={{ mixBlendMode: 'multiply' }}/>
          <g>
            <path d="M600.12,375l-3.5-13.88-.07,0h0l.07,0a72.16,72.16,0,0,0-4.81-15.35c-3.71-8.47-8.75-10.48-15-13.25l.11.35-.43-.33.71-1.42c1.32-.88,2.65-3.48,2.38-4.23a.76.76,0,0,0-.09-.18l.15-.27a13.74,13.74,0,0,0,1.41-2.61v0h0a11.66,11.66,0,0,0,.4-8.19c-2-6-7.54-14.59-22.63-10.56,0,0-10.57,1-8.56,15.09l.09-.12a5.68,5.68,0,0,0,1.39,7.5l-.46,3.46A92.67,92.67,0,0,0,537,336.38a18,18,0,0,0-8.55,9A150.77,150.77,0,0,0,522,363.45l6.62,1.74v.33L522,363.45l-1.8,9a12.7,12.7,0,0,0,2.55,10.43,1.82,1.82,0,0,0-2.42,1.5L517.73,407a7.34,7.34,0,0,0,4.77,7.73L534.37,419l.3.08c-.48,5.26-.6,10.74.41,13.93,2.39,7.5-.07,16.66,2.06,24s4.52,11,3.53,16.3,1.83,4.25,2,6.89-1.73,3.64-.25,5.57a4.32,4.32,0,0,0,2,1.26h-.74l-2.44,4.27a5.49,5.49,0,0,0-.71,2.44,4.82,4.82,0,0,0,3.09,5c2.13.62,4.89,1.06,7,.1,3.93-1.81,6.45-3.13,7-7.08v-4.66a4,4,0,0,0,2.45-4.4c-.38-5.28-2.48-5.87-1.49-9.49s.64-8.94.76-12.51,4.35-24.1,4.35-24.1a60.68,60.68,0,0,1,3.11,14.9c.64,7.61-1.9,17.68-1.55,19.31.75,3.5,4,5.83,3.09,7.58s-1.83,3.26.35,5.23h1.65c-.26,2.24-.63,6.63.18,8.65a27.82,27.82,0,0,0,2.85,4.93,4.22,4.22,0,0,0,2.89,1.77,13.75,13.75,0,0,0,6.18-.48c3.82-1.32,1.89-6.22,1.89-6.22l-2.63-9.06c1.16-.55,2.65-1.73,1.67-4-1.5-3.53-.19-5.07.31-8.25s2.44-6.22,2.39-11.1,3.81-28.7,2.36-39.24-.77-15.45-.77-15.45l-.09.08c.19-.85.4-1.83.61-2.88,0-.17.05-.33.07-.49l.09.34s.52-3.31-1.36-5.54l-.09-.14-1-3.5-1-3.65,1.53-2,3,.24a10.19,10.19,0,0,0,8.32-3.26h0A10.17,10.17,0,0,0,600.12,375Zm-47.57-56V319a2.42,2.42,0,0,0-.47-.06A1.86,1.86,0,0,1,552.55,319Zm-1.29,12.28h0v0Z" fill="#f2f2f2"/>
            <path d="M552.55,335.76s8.41,11,11,13.59l3.24-.65s5.82-8.41,6.47-9.71l3.23-6.47,9.71,4.53,5.82,9.71,4.53,13.59-11.65,7.11v7.12h-9.06l.65-5.17-.65-1.3-11-.65-.64,1.3-1.94,4.53-3.89-1.3-7.76,2.59-4.53-.64h-6.47l-1.3-6.48-16.17-3.88L528,346.11l5.82-7.76,9.06-4.53,9.71-3.24Z" fill="#ccc"/>
            <path d="M537,336.38l5.86-2.56s3.89,27.17,3.24,40.12l-5.18-.65S538.23,338.3,537,336.38Z" fill="#f2f2f2"/>
            <polygon points="584.9 387.52 584.9 393.35 586.2 395.94 588.14 398.52 587.49 405 564.84 416.64 538.31 410.82 535.08 387.52 563.55 384.94 584.9 387.52" fill="#ccc"/>
            <g>
              <path d="M553.73,310c2.2-2.75,6.84-4.53,10.32-4.8,9.65-.74,17.28,7.6,15.19,16.29,0,0-1.25,3.41-2,5.85s-.71,10.93-7.59,13.55c0,0-8.26,3.36-13.46-6.16" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M570.16,327.56l-1.07,3.12s1.51,4.56-2.52,3.59" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M564.69,336.7a28.44,28.44,0,0,0,3,.28" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <circle cx="563.32" cy="330.39" r="1.31" fill="#231815"/>
              <circle cx="572.73" cy="331.19" r="1.31" fill="#231815"/>
              <path d="M554.08,319.9s-2.07-2.17-3.65,0-1.56,6.6,3.3,8.87" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <line x1="552.4" y1="323.85" x2="554.32" y2="325.16" fill="#f5f1df" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M552.4,328a30.35,30.35,0,0,1,0,7.45" fill="#f5f1df" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M554.08,337.39a73.17,73.17,0,0,0,9.41,11.95" fill="#f5f1df" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M567.05,348.88s5.43-7,6.65-11" fill="#f5f1df" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M551.35,331A92.62,92.62,0,0,0,537,336.38a17.93,17.93,0,0,0-8.55,9A150.77,150.77,0,0,0,522,363.45l16.28,4.28" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M522,363.45l-1.8,9a12.69,12.69,0,0,0,3.71,11.7h0a12.7,12.7,0,0,0,9.7,3.43l19-1.47,11-1.18s1.33-.12,1.15-2.45a2.89,2.89,0,0,0-.32-3.15l5.34-1.62,1.34-2.52-8.9,1.51-2.62-1.51" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M533.41,375.74a19.36,19.36,0,0,1,7.2-2.18v-3.22" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <line x1="550.54" y1="374.28" x2="550.11" y2="377.73" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M576.81,332.5c6.25,2.77,11.29,4.78,15,13.25a72.16,72.16,0,0,1,4.81,15.35l-9.6,5.35" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <line x1="564.79" y1="350.81" x2="564.79" y2="364.51" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M551.38,337l-1.88,5.09L557,353s1.25-5.61,3.82-5.73" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M575.42,337.44l1.64,2.91-4.26,11.88a4.18,4.18,0,0,0-3.19-2.89" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M587.46,405.37c-4.09,5-12.71,7.56-25.73,7.85-8.25.18-17.82-1.81-23.72-3.29a10.35,10.35,0,0,1-7.8-11.45,11.48,11.48,0,0,1,1.42-4.33c1.86-3,.78-6.79.78-6.79" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M535.15,392.73s7.67,8,18.7,9.53" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M584.92,389.39s-1.28,4.51,1.37,6.68,2,6.19,2,6.19" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <line x1="563.2" y1="389.18" x2="563.2" y2="402.26" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M596.62,361.1l3.5,13.88a10.17,10.17,0,0,1-2.38,9.4h0a10.19,10.19,0,0,1-8.32,3.26l-17.17-1.39s-5.31.61-8.67-1.3" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M577.93,325.16a4.86,4.86,0,0,1,1.65,1.71c.32.86-1.5,4.19-3,4.51" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M536.62,405.21s-3.93,20.31-1.54,27.82-.07,16.66,2.06,24,4.52,11,3.53,16.3,1.83,4.25,2,6.89-1.73,3.64-.25,5.57,6.4,2.21,9.34,2.18,8.64.05,8.26-5.24-2.48-5.87-1.49-9.49.64-8.94.76-12.51,4.35-24.1,4.35-24.1a60.68,60.68,0,0,1,3.11,14.9c.64,7.61-1.9,17.68-1.55,19.31.75,3.5,4,5.84,3.09,7.58s-1.83,3.26.35,5.23H580.5s4.34-.9,2.83-4.44-.19-5.07.31-8.25,2.44-6.22,2.39-11.1,3.81-28.7,2.36-39.24-.77-15.45-.77-15.45-8.28,7.7-25.89,8C561.73,413.22,542.61,412.77,536.62,405.21Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M543.64,487.09l-2.44,4.27a5.5,5.5,0,0,0-.71,2.45h0a4.81,4.81,0,0,0,3.09,5c2.13.62,4.89,1.06,7,.1,3.93-1.81,6.45-3.13,7-7.08v-4.74Z" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M581.45,482.57l2.84,9.77s1.93,4.9-1.89,6.22a13.75,13.75,0,0,1-6.18.48,4.22,4.22,0,0,1-2.89-1.77,27.82,27.82,0,0,1-2.85-4.93c-1.11-2.77,0-10,0-10Z" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M571.09,422.15a10,10,0,0,1-.28,2.5,12.42,12.42,0,0,1-.81,2.41,10.73,10.73,0,0,1-1.37,2.22,7.1,7.1,0,0,1-2.13,1.77l.31-.26c-.29.38-.64.85-.92,1.3s-.59.94-.85,1.43-.51,1-.75,1.52-.43,1.06-.64,1.63a6.16,6.16,0,0,1-.18-1.82,8.08,8.08,0,0,1,.26-1.83,10.09,10.09,0,0,1,.61-1.75,9.53,9.53,0,0,1,.92-1.67,1,1,0,0,1,.21-.21l.1-.05a10.94,10.94,0,0,0,2.95-3.12C569.42,425,570.19,423.57,571.09,422.15Z" fill="#f5f1df"/>
              <line x1="571.45" y1="360.2" x2="579.58" y2="360.2" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M584.91,353.24a23.37,23.37,0,0,0-1.36,8.81c.32,4.49,1.46,5.73,1.36,8.76a33.55,33.55,0,0,0,0,3.77,91.67,91.67,0,0,0-9.28,0l1.2-3.85a2,2,0,0,0-1.77-2.64l-8.92-.72a2,2,0,0,0-2.08,1.33l-2.76,7.55" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M569.76,375.21,567.27,373l.34-.34a2,2,0,0,1,2.12-.45l5.9,2.29v3.94" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M563.35,312.58a41.84,41.84,0,0,1,1.75,8.89,3,3,0,0,0,2.38,2.82c3.2.67,8.5,1.39,10.91-.44l.2,1.8.86,1s4-5,2-11.07S573.92,301,558.83,305c0,0-10.57,1-8.56,15.09,0,0,2-3,4.53.5L556,325a.45.45,0,0,0,.84.08l1-2S564.86,322.14,563.35,312.58Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M537.19,336.73s3.81,26.87,3.42,36.83l9.93.72,6.81-2.23a2.69,2.69,0,0,1,1.66,0l3.18,1" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M542.82,333.83s3.93,22.52,3.43,40.13" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M522.82,382.92l23.43,9.65-2.06,20.3a7.34,7.34,0,0,1-9.82,6.15l-11.87-4.34a7.34,7.34,0,0,1-4.77-7.73l2.59-22.55A1.82,1.82,0,0,1,522.82,382.92Z" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <line x1="546.25" y1="386.62" x2="546.25" y2="392.57" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <line x1="522.1" y1="396.6" x2="540.72" y2="403.64" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </g>
            <polygon points="584 370 584.26 373.94 577 374 584 370" fill="#ccc"/>
          </g>
          <g>
            <g>
              <path d="M1335.15,485.37c-1.51-12.08-8.32-34.37-11.15-43.37l3-1.82.42-.19-6.4-27-7-15-26.18-10h0l-1.37-4.42-2.61,1.2-.23-3.14a6.61,6.61,0,0,0,.64,1.06l-.9-9.48a3.59,3.59,0,0,0-.29.59L1283,373l.43-.39a7.67,7.67,0,0,0,8.34-7.41l.23-.2-.08-.48c.93-.62,5.53-4.17,6.09-13.45.63-10.32-7.2-15.06-15.73-16.51s-20.07-7-20.4-10.56l-2.63,2.18a7.13,7.13,0,0,0-2.48,7.09,9.49,9.49,0,0,0,.77,2.11c-1.54,1.87-3.53,5.56-4.12,12.55-1,11.63-9.86,20.27,3.21,27.76,0,0-.19-.35,2.37.31l-2,12,3.68-.34h-4.13l-1.43,2.48L1239,395l-2.26,4.62-.09-.58L1220,431.14l3.13,1.19L1219,444l-1,4.91-.19-.06s-7.75,15.49-11.07,28.77l-8.37,8.37a9.24,9.24,0,0,0-2.69,6.52v2.82l9.1,4.14a2.59,2.59,0,0,0,3.62-1.93l.56-3.32,3.32-2.21,1.66,5a1.75,1.75,0,1,0,3.32-1.11l-.56-3.87,2.22-7.75s14.39-19.92,17.71-28.77l-16.11-5.69L1236,456l7-16,.29,0c1.09,11.07-5.54,31-3.32,37.6l-1.11,60.88s-13.28,47.59-12.17,64.19c0,0-5.54,7.75-3.33,16.6l-12.67,7.24a5.61,5.61,0,0,0,2.78,10.47h33.47a8.52,8.52,0,0,0,8.52-8.52v-9.19s5.54-8.85,3.32-15.49c-1.92-5.76,8.63-27.31,12.2-40.16,7.17,11.29,23.46,37.44,24.33,43.48,1.1,7.74,7.74,7.74,7.74,7.74l-6.38,8a6.79,6.79,0,0,0,8.79,10.06l27.92-16.75a5.09,5.09,0,0,0,2-6.57l-5.28-10.93.65-.39c-1.1-8.85-8.85-7.75-10-14.39s-11.07-22.13-11.07-22.13l-12.78-39.21c4-6,6.92-11.91,7.25-17.24,1.11-17.71,1.11-26.56,1.11-26.56s2.65-13.14,1.1-22.14C1303,437,1308.13,448.41,1308,444l4,17,.25.88-.34-.86s1.27,5.41,3.48,11.79h0a68.13,68.13,0,0,0,6.7,14.62l-.22,5.69-1,11.86A2.12,2.12,0,0,0,1322,507a2.14,2.14,0,0,0,1.88,0l.26-.14a2.1,2.1,0,0,0,1.15-1.62l1-7.74,3.32,2.22V505a3.27,3.27,0,0,0,3.62,3.25l3.69-.41a3.26,3.26,0,0,0,2.89-2.95l.86-9.52Zm-13.09,1,.11-1,0,1.16Z" fill="#fff"/>
              <path d="M1239,395l15-6-1,5,9,4,4-2,2-5h3l2,4,4,2,12-5-1-5,26,11,8,17,5,25s-15,6-22,4l1,9,1,11-2,15h-65l-.5-7.5,4-23v-9l-22-8v-3Z" fill="#fff"/>
              <line x1="1267.7" y1="390.77" x2="1271.23" y2="390.77" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <line x1="1288.97" y1="386.86" x2="1307.08" y2="394.56" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.9" opacity="0.14"/>
              <g opacity="0.14">
                <path d="M1241.75,490.09c.89,0,2.8-1.35,1-1.35-.9,0-2.81,1.35-1,1.35Z" fill="#231815"/>
              </g>
              <g opacity="0.14">
                <path d="M1230.71,608.58c-.33-.62-.13-1.44-.94-1.71a2.07,2.07,0,0,0-2.37,1.07.53.53,0,0,0,0,.62c1.12,1.49,2.32,2.93,3.38,4.46.78,1.11,1.94,1.87,3.28,1a.86.86,0,0,0,.47-.84c-.1-.58-.76-.93-.85-1.54-.14-1-2.76-.4-2.61.7.08.58.77,1.13.84,1.54l.47-.83h0l1.62-.3c.2.05-.14-.07-.18-.13-.18-.23-.42-.47-.6-.73l-1.1-1.5c-.76-1-1.54-2-2.29-2.95l0,.62-.11,0-1.61.3c-.31-.07-.25-.37-.32-.08a1.63,1.63,0,0,0,.12.47,4.22,4.22,0,0,0,.35.85c.47.87,3,0,2.41-1.09Z" fill="#231815"/>
              </g>
              <g opacity="0.14">
                <path d="M1302.13,487.56c-2.26,1.57-.89-.34-1.12,1.95a24.7,24.7,0,0,0,.26,3.41c.14,2.88.11,5.77,0,8.65-.25,6.31-1.3,13.4-5.18,18.53-.72,1,1.91.8,2.45.09,5.16-6.83,5.51-16.44,5.43-24.69,0-1.64.75-11.7-3.47-8.78-1.46,1,.77,1.43,1.63.84Z" fill="#231815"/>
              </g>
              <g opacity="0.14">
                <path d="M1299.78,506.8c.88,0,2.82-1.32,1.07-1.32-.88,0-2.82,1.32-1.07,1.32Z" fill="#231815"/>
              </g>
              <g opacity="0.14">
                <path d="M1244.67,477.6c6.69,4.75,15.52,5.48,23.48,5.91,11.26.61,22.54.37,33.8.34,1,0,2.77-1.51,1-1.5-9.92,0-19.84.13-29.76-.18-8.58-.27-18.83-.43-26.1-5.6-.76-.54-3.16.51-2.43,1Z" fill="#231815"/>
              </g>
              <path d="M1259.46,333.67s-5,2.41-6,14.26c-1,11.63-9.86,20.27,3.21,27.76,0,0,7.24,3.35,13.95,2" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <circle cx="1254.77" cy="354.98" r="1.85" fill="#231815" stroke="#231815" stroke-miterlimit="10" stroke-width="0.54"/>
              <circle cx="1268.03" cy="358.3" r="1.85" fill="#231815" stroke="#231815" stroke-miterlimit="10" stroke-width="0.54"/>
              <path d="M1266.63,351.3a8.64,8.64,0,0,1,5,3.12" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1281.1,360.24s4.32-4.28,9,.86-1.5,13.39-8,11.23" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <line x1="1258.71" y1="376.6" x2="1257.74" y2="382.76" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1256.12,362.42l11.56,2.94S1260.36,369.42,1256.12,362.42Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1256.77,333.27c.69,2.92,2.88,6.35,9,8.12,0,0,11.48.56,12.21,6.89s-.15,9.13,5.51,10.58c0,0,6.94-2,8.27,5.78,0,0,5.65-3.25,6.27-13.57s-7.2-15.06-15.73-16.51-20.07-7-20.4-10.56l-2.63,2.18A7.13,7.13,0,0,0,1256.77,333.27Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1261.56,328.39s-3.83,5.61,3.1,9.44" fill="#231815" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.57"/>
              <path d="M1287.39,343.53s4,9,.8,16.22" fill="#231815" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.57"/>
              <line x1="1281.8" y1="365.86" x2="1287.48" y2="365.01" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1260.68,387.66l7,3.11-.81,3a5.08,5.08,0,0,1-7.16,3.22l-6.65-3.3,3.47-6Z" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1271.23,390.59l15.22-7,2.52,8.14-10.1,4.59a5.08,5.08,0,0,1-7-3.32Z" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1258.31,352.26s.59,2.93,0,3.72-1.7,3.83,1.62,3.74" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1282.13,373.16c-1.62,2.93-1,7.36.91,10,.52.73,3-.16,2.43-1-1.64-2.27-2.29-6.41-.9-8.93.6-1.09-2-.83-2.44-.06Z" fill="#231815"/>
              <line x1="1256.57" y1="387.97" x2="1238.86" y2="394.61" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1245.5,409s-3.32,19.92-2.21,31-5.54,31-3.32,37.63c0,0,52,6.64,65.3,1.11,0,0,3.32-13.28,1.1-22.14s-1.1-26.56-1.1-26.56" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <polyline points="1236.65 399.04 1220.05 431.14 1243.29 439.99" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <polyline points="1223.65 432.51 1218.94 444.42 1223.37 446.63" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1217.83,448.85s-7.75,15.49-11.07,28.77l-8.37,8.37a9.24,9.24,0,0,0-2.69,6.52v2.82l9.1,4.14a2.59,2.59,0,0,0,3.62-1.93l.56-3.32,3.32-2.21,1.66,5a1.75,1.75,0,0,0,2.21,1.11h0a1.76,1.76,0,0,0,1.11-2.22l-.56-3.87,2.22-7.75s14.39-19.92,17.71-28.77" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1247.71,453.27a57.88,57.88,0,0,0,26.57,8.86" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <line x1="1287.39" y1="386.86" x2="1311.91" y2="396.83" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <line x1="1316.34" y1="402.36" x2="1321.87" y2="414.54" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1325.19,430l2.21,10s-11.5,5.28-22.35,4.3" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1323.89,441.37s9,26.29,11.26,44l5.53,10-.86,9.52a3.26,3.26,0,0,1-2.89,2.95l-3.69.41a3.27,3.27,0,0,1-3.62-3.25v-5.2l-3.32-2.22-1,7.74a2.1,2.1,0,0,1-1.15,1.62l-.26.14a2.14,2.14,0,0,1-1.88,0h0a2.12,2.12,0,0,1-1.16-2.06l1-11.86" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <line x1="1308.59" y1="444.68" x2="1310.8" y2="454.38" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1311.91,461s4.43,18.82,11.07,27.67" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <line x1="1335.15" y1="485.37" x2="1330.72" y2="486.48" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1240,477.62l-1.11,60.88s-13.28,47.59-12.17,64.19c0,0-5.54,7.75-3.33,16.6h32.1s5.54-8.85,3.32-15.49,12.18-34.31,13.28-45.38,31-35.42,32.1-53.13,1.11-26.56,1.11-26.56Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1296.61,521.62l13.08,40.12s10,15.49,11.07,22.13,8.86,5.54,10,14.39l-27.67,16.6s-6.64,0-7.74-7.74-27.67-48.7-27.67-48.7Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1223.36,619.29l-12.67,7.24a5.62,5.62,0,0,0-2.82,4.87h0a5.6,5.6,0,0,0,5.6,5.6h33.47a8.52,8.52,0,0,0,8.52-8.52v-9.19Z" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1303.05,614.86l-6.38,8a6.77,6.77,0,0,0-1,6.76h0a6.78,6.78,0,0,0,9.79,3.3l27.92-16.75a5.09,5.09,0,0,0,2-6.57l-5.45-11.27Z" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <line x1="1245.5" y1="528.53" x2="1261" y2="498.65" fill="#231815" stroke="#e0e4e5" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
              <polyline points="1292.64 527.39 1278.33 544.84 1272.03 562.23" fill="#231815" stroke="#e0e4e5" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
              <line x1="1300.84" y1="411.21" x2="1311.91" y2="400.15" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
            </g>
            <g>
              <g>
                <path d="M1381.07,486.14c.09-1.59.15-3.18.19-4.76.2-9.82-2.15-19.35-1.18-29.23.7-7.18,1.93-14.38,1.93-21.61a13.8,13.8,0,0,0-2.1-7.32l.09-.22-.23-.38c.67-.84,3.68-4.8,3.83-7.71l0,0,0,0a3,3,0,0,0-.34-1.62c-1.41-2.42-4.39-7.19-5.53-9v0l-.1-.19.1.16L1375.44,382l-2.12-6.71s-8.25-23.09-34.33-11.87l-3.38,2.83-5.4,11.17L1327.08,394l.92,2.8v.81l-.28-.73L1331,405l-3,6,.18.2-1,2.5,1.3,5.43-2.31,10.92,4.09,4.4-8.51-7.59-6.84-6.83-1.69-5.55a3.88,3.88,0,0,0-3.69-2.76c0,1.32.26,2.65.11,4-.2,1.67-1.78,1.85-2.71.38-.5-.78-1-1.56-1.49-2.34l-2.25-3.55a1.54,1.54,0,0,0-2.63,1.59l1.32,2.27-1.87.83s-3.55.6-2.46,4a3.08,3.08,0,0,0-.21,3.82,2.41,2.41,0,0,0,.21,3.45l14.18,11.41s11.64,15.57,16.41,19.45l.81,46.1h0l-.91,75h8.59c2,8.87,3.64,33.63,3.64,33.63l-6.61,3.85a7.64,7.64,0,0,0-3.27,9.36l.41,1h38.09a5.22,5.22,0,0,0,5.22-5.22,7.6,7.6,0,0,0-.89-3.59l-2.91-5.45V578H1378s-2-43.38,0-54.88c.45-2.63.89-5.83,1.28-9h10.3a9,9,0,0,0,9-9v-19Zm-48.62-51.92-.08.32c-.72,0-1.43,0-2.16-.09Zm21.18,180.17-1.45-2.72V578h2.11c2,8.87,4,33.63,4,33.63Z" fill="#fff"/>
                <g>
                  <path d="M1373.32,375.25s-8.25-23.09-34.33-11.87l-3.38,2.83-5.4,11.17L1327.08,394l3.67,11.15-3.62,8.6,1.3,5.43-2.31,10.92,4.09,4.4,8.43-.88,11.3-8.8s10.15-10.12,17.9-10.75c0,0,10.58,3.81,11.69,8.59l4.09-7.74-6-10.82L1375.44,382Z" fill="#ccc"/>
                  <path d="M1352.18,578v33.63h-12s-1.68-24.76-3.64-33.63Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <path d="M1352.18,611.67l2.9,5.45a7.61,7.61,0,0,1,.9,3.59h0a5.22,5.22,0,0,1-5.22,5.22h-20l-.41-1a7.64,7.64,0,0,1,3.27-9.36l6.61-3.85Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <path d="M1343.28,412l-1.56-8.14c-18.17-8.61-8.58-22.62-4.57-31a11.48,11.48,0,0,1,8.12-6.49s1.68,14.42,9.39,23.13l1.5,2.14-1.5,13.11Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <path d="M1339.21,376.15s2.31,1.62.22,4.74-2.5,4.4-.22,5.57" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <path d="M1344.66,390.84a10.17,10.17,0,0,1-8-1.3" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <circle cx="1334.86" cy="380.46" r="1.85" fill="#231815"/>
                  <path d="M1336.71,500h42.93a152.24,152.24,0,0,0,1.62-18.58c.2-9.82-2.15-19.35-1.18-29.23.7-7.18,1.93-14.38,1.93-21.61,0-11.71-14.17-16.52-14.17-16.52s-12.84,3.41-17.9,10.75-12,10.33-19.73,9.68" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <path d="M1328.94,500.15,1328,578h50s-2-43.38,0-54.88a190,190,0,0,0,2.3-20.59c.09-3.4-43.6-2.61-43.6-2.61" fill="#ccc" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <polyline points="1362.61 431 1338.78 438.83 1338.78 444.38" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <path d="M1368.79,419.84a28.1,28.1,0,0,0-7.81,7.89" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <path d="M1334.86,441.75c.15-.2-2.61,4.45-2.61,4.45" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <path d="M1334.77,438.52l-13.07-11.66s-8.71,4.57-10,10.67c0,0,13.34,17.85,17.31,20.09" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <path d="M1370.26,452.44c-7.52,7.25-36,8.4-36,8.4" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <path d="M1321.7,426.86l-6.84-6.83-1.69-5.55a3.88,3.88,0,0,0-3.69-2.76c0,1.32.26,2.65.11,4-.2,1.67-1.78,1.85-2.71.38-.5-.78-1-1.56-1.49-2.34l-2.25-3.55a1.54,1.54,0,0,0-2.63,1.59l1.31,2.27-1.86.83s-3.55.6-2.46,4a3.08,3.08,0,0,0-.21,3.82,2.41,2.41,0,0,0,.21,3.45l14.18,11.41" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <line x1="1362.61" y1="456.64" x2="1362.61" y2="486.14" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <path d="M1398.6,486.14h-43.89v19a9,9,0,0,0,9,9h25.87a9,9,0,0,0,9-9Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <line x1="1392.56" y1="486.14" x2="1380.31" y2="452.44" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <line x1="1394.11" y1="490.73" x2="1379.64" y2="499.96" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <line x1="1374.93" y1="499.1" x2="1359.36" y2="491.84" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <path d="M1370.26,578v33.63h-12s-2.06-24.76-4-33.63Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <path d="M1370.26,611.67l2.91,5.45a7.6,7.6,0,0,1,.89,3.59h0a5.22,5.22,0,0,1-5.22,5.22h-20l-.41-1a7.64,7.64,0,0,1,3.28-9.36l6.6-3.85Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <path d="M1344.18,381.67a6.74,6.74,0,0,1-.45-2l2.16,1.73,0,0a1.84,1.84,0,1,1-2.46,1.72A1.8,1.8,0,0,1,1344.18,381.67Z" fill="#231815"/>
                  <path d="M1335.68,403c-.07,3.94-.66,8.26,1.79,11.65.58.79,3.7-.27,3-1.22-2.32-3.19-1.64-7.6-1.58-11.29,0-.85-3.2-.31-3.22.86Z" fill="#231815"/>
                  <path d="M1338.38,406c.38,2.41.71,4.84,1.31,7.21.27,1,3.15.28,3.22-.64.2-2.68.49-7.06-1.52-9.16-.69-.71-2.85-.09-3.11.83-.75,2.65-.43,5.42-.41,8.13,0,1,3.22.36,3.22-.86v-1.32c0-.95-3.22-.36-3.22.87v1.31l3.22-.86c0-2.57-.3-5.17.41-7.69l-3.11.83c1.75,1.83,1.47,6,1.3,8.34l3.22-.64c-.6-2.38-.94-4.8-1.31-7.22-.17-1-3.4-.3-3.22.87Z" fill="#231815"/>
                  <path d="M1359.36,392.93c-11.16-5.61-14.1-26.51-14.1-26.51" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <line x1="1356.16" y1="390.73" x2="1354.66" y2="404.05" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <path d="M1362.61,399.32s-12.27,8.71-17.95,11.7" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <path d="M1373.32,375.25s-8.44-22.59-34.33-11.87" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <path d="M1375.44,382l1.83,21.51s4.22,6.72,6,9.75-3.62,9.56-3.62,9.56" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <path d="M1357,367.33s3,13.07,10.12,19.19" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <path d="M1359.36,408.43s-14.45,7.13-20.58,14.35" fill="#f5f1df" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <path d="M1327.72,396.83l2,5.22a10.31,10.31,0,0,1,0,7.26l-.83,2.21a10.46,10.46,0,0,0-.35,6.14l.33,1.3" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                  <line x1="1335.47" y1="366.94" x2="1329.94" y2="376.9" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
                </g>
              </g>
              <path d="M1329,467s-6.46,17.93-.23,28.47" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.07"/>
            </g>
            <g>
              <path d="M1524.53,497l-14.81-2.78,0,.21-5.4-2.47c-14.63-8.21-24-11.81-28.66-13.28l-2.78-2.15.84-1.67-15.49-10-.06.26L1437,455l0-.07-5-8.94,2.52-.15s5.28-7.61,4.17-12.8-5.45-10.55-9.7-12.41c0,0-13.85-13.23-29.53,4.52,0,0,.24.57.67,1.41a16.18,16.18,0,0,0-2.33,7.44c-.2,2.37-1.58,4.74-3,4.16s-3.11,3.65,1.77,4.57a16.54,16.54,0,0,0,.12,3.39c.07.93.15,1.74.18,1.89,1.4,7.2,8.71,10.85,15.59,10.42l0,0L1415,463l-9.87,4.69-1.11.52.36,0L1402,470l-1.38,1.38-9.88,7.9.69,1.29-.43.43,1,2-12.33,9.56L1367,502l-13.67,3.91a2.52,2.52,0,0,0,.69,4.95h8.55l.41-.39-.27.39-2.4,1.53a1.4,1.4,0,0,0,1.2,2.51L1367,513s8.85-1.11,10-5.53l23.79-9.41-.37-.7.67.6,3,3h0c-.12,7.52-2.23,14.94-3.31,19.23-.8,3.2.71,4.66,1.61,5.23l-6.91,31.47a3.11,3.11,0,0,1-.76,1.3l-3.87,4a3.16,3.16,0,0,0-.78,1.42,3.13,3.13,0,0,0,2.81,3.88l5.9.41.12,0-14.17,43.57-15.63,4.6a4.44,4.44,0,0,0,1.25,8.69h31v-10s16.47-32.09,22.07-43.16l-13.51-2.46,21.32,2.46,3.29-9.47.22-.32L1445.53,575l3.36-2,32.73,29.29-6.72,11.14a4.66,4.66,0,0,0,6.81,6.11l15.08-11.49a7,7,0,0,0,1.25-9.88l-3.27-4.12.41-.3A173.29,173.29,0,0,0,1467.92,562l4.17-2.44L1459.92,544l-6.07-16.28a14.11,14.11,0,0,0,.45-2h.64c5.53-3.32,4.42-8.86,3.32-15.5-1-6.11-5.11-21-5.26-22.27l6.87,4.42,3.92,2.36,1-2,5.32,1.49c6.92,2.2,19.07,5.94,26.12,7.3h0c.88.17,1.69.3,2.38.39l-1-.26,3.19.28c1.76,1.73,8.84,4.12,11.87,5.08a1.92,1.92,0,1,0,1.17-3.66l-1.55-.5h0a8.71,8.71,0,0,0,4-2h7.81a1.95,1.95,0,0,0,.36-3.86Z" fill="#fff"/>
              <polygon points="1391 480 1404 469 1409 466 1417 474 1422 468 1427 474 1442 458 1457 464 1474 475 1463 495 1454.5 489.5 1456.5 501.5 1459.5 518.5 1454.5 525.5 1401.5 525.5 1400.5 521.5 1404.5 500.5 1400.5 497.5 1391 480" fill="#ccc"/>
              <path d="M1464.93,559.42s14.75,11.46,30.25,34.39l-12.65,9.35-35.18-31.48Z" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1399,567.27l-14.39,44.27,16.61,3.32s16.47-32.09,22.07-43.16Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1404.85,422.43c-4.62,2.2-6.63,6.65-7,11.58-.2,2.37-1.58,4.74-3,4.16s-3.11,3.65,1.77,4.57c-.21,0,.22,4.88.3,5.28,1.49,7.65,9.65,11.29,16.88,10.29" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1416.76,438.38s3.12-5.75,8.06-2.46,2.92,9.67-2,11.59" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <line x1="1419.3" y1="442.21" x2="1421.95" y2="440.53" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <circle cx="1401.52" cy="437.65" r="1.95" fill="#231815"/>
              <path d="M1399.46,425.16s2.29,5.44,5.8,8.13l1.09-3.9s4.5,7.11,11.49,7.53c0,0,3.23-4,7-1a6.87,6.87,0,0,1,2.43,7.2l7.27,2.73s5.28-7.61,4.17-12.8-5.45-10.55-9.7-12.41C1429,420.64,1415.14,407.41,1399.46,425.16Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1423.78,413.56a26,26,0,0,1,5.21,7.08,11.87,11.87,0,0,0,.13-6" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1433.43,448.3s.32,4.74,3.64,6.81" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <line x1="1411.69" y1="458.57" x2="1414.79" y2="463.72" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1396.71,446.13s3.46.07,5.22-2" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <line x1="1440.55" y1="457.15" x2="1454.94" y2="462.68" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <polyline points="1458.26 464.89 1473.75 474.86 1463.79 494.78 1452.72 488.14" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1456,494.78s1.11,8.85,2.22,15.49,2.21,12.18-3.32,15.5h-52s-3.32-1.11-2.22-5.54,3.32-12.17,3.32-19.92" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <polyline points="1404.02 468.21 1414.54 463.23 1422.29 467.66 1416.75 474.3 1411.22 468.77" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1404,481.5s1.11,6.64,0,11.06" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <polyline points="1435.57 457.7 1422.29 467.66 1426.71 474.3 1437.78 462.13" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <line x1="1422.29" y1="475.41" x2="1422.29" y2="492.01" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1410.11,513s17.71,5.53,25.46,5.53" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1402.36,525.21l-7,31.72a3.11,3.11,0,0,1-.76,1.3l-3.87,4a3.16,3.16,0,0,0-.78,1.42h0a3.13,3.13,0,0,0,2.81,3.88l5.9.41,32.44,3.75,8.85-25.46s13.29-11.06,14.39-21Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <polygon points="1453.32 526.32 1459.92 544.03 1472.09 559.52 1445.53 575.02 1430.75 557.2 1434.31 537.01 1453.32 526.32" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
              <g>
                <path d="M1434.43,562.23l5.56-16s10-4.42,12.18-10" fill="#231815"/>
                <path d="M1434.43,562.23c.22-1.42.54-2.8.84-4.19s.69-2.75,1.08-4.1.8-2.71,1.2-4.06l1.35-4a1.14,1.14,0,0,1,.6-.67h0c1.15-.51,2.33-1.13,3.48-1.73s2.27-1.28,3.37-2a29.67,29.67,0,0,0,3.14-2.29,13.72,13.72,0,0,0,2.66-2.91,9.65,9.65,0,0,1-1.85,3.67,18.4,18.4,0,0,1-2.89,3,26.92,26.92,0,0,1-3.34,2.41,30,30,0,0,1-3.62,2l.62-.68-1.44,4c-.52,1.31-1,2.63-1.57,3.93s-1.12,2.6-1.71,3.89S1435.15,561,1434.43,562.23Z" fill="#f2f2f2"/>
              </g>
              <path d="M1384.65,611.54l-15.63,4.6a4.43,4.43,0,0,0-3.18,4.25h0a4.43,4.43,0,0,0,4.43,4.44h31v-10Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M1482.07,601.6l-7.17,11.89a4.63,4.63,0,0,0,.9,5.89h0a4.65,4.65,0,0,0,5.91.22l15.08-11.49a7,7,0,0,0,1.24-9.88l-3.52-4.46Z" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.36"/>
              <line x1="1444.42" y1="475.41" x2="1447.74" y2="484.26" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.36"/>
              <path d="M1472.31,477.73s10.85,2.11,34.09,15.39" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.36"/>
              <path d="M1509.72,494.22l14.81,2.78a2,2,0,0,1,1.27,3h0a2,2,0,0,1-1.63.86h-7.81a8.44,8.44,0,0,1-5.53,2.22" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.36"/>
              <path d="M1506.4,498.65l6,4.26,1.55.5a1.92,1.92,0,0,1,1,2.9h0a1.94,1.94,0,0,1-2.19.76c-3-1-10.16-3.37-11.89-5.1" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.36"/>
              <path d="M1464.89,492.57s24.91,8.29,33.76,9.4" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.36"/>
              <path d="M1392.4,483.16,1367,502l-13.67,3.91a2.53,2.53,0,0,0-1.83,2.42h0a2.52,2.52,0,0,0,2.52,2.53h8.55" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.36"/>
              <path d="M1365.84,506.4l-3.18,4.43-2.4,1.53a1.41,1.41,0,0,0-.5,1.81h0a1.41,1.41,0,0,0,1.7.7L1367,513s8.85-1.11,10-5.53l23.79-9.41-10-18.82,11.07-8.85" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
            </g>
          </g>
          <g>
            <rect x="1476.25" y="826.41" width="406.75" height="29.59" rx="13.88" ry="13.88" fill="#e0e4e5" style={{ mixBlendMode: 'multiply' }}/>
            <g>
              <path d="M1545,394.69s.94,12.29,7.57,14.19c0,0-1,16.08-3.79,18.92H1545l-14.19,10.41a53.74,53.74,0,0,0-36.9,37.84l-6.62,30.27-8.52,52,15.14,2.84,23.65,7.57-4.73,75.68,126.78-20.81L1632,600.93V589.58L1651,608.5l53,23.65,2.84-27.43-13.25-6.63-.94-8.51,12.3-20.81L1773,530.92l-57.72-42.57s-20.81,10.4-32.16,31.22c0,0-16.09,25.54-19.87,48.25l-1.89,1.89-8.52-25.54L1650,532.81l4.73-2.83L1651,514.84s-7.57-43.52-13.24-52-16.09-22.7-35-29.33l-27.44-29.32Z" fill="#fff"/>
              <path d="M1742,404.29s35.54-11.13,46.44,14.35,10.83,31,10.83,31l-9.73,22.73s3.38,10.82,9.73,11.61c0,0,12.74-1.35,33.09,7.63l-10.93,29-83.71,3.87-24.48-34.63s21.45-5.56,24.63-5.78c0,0,3.4.18,2.9-3l-2.06-3.47s-16.45-7.7-16.6-17.09,2.64-20.72,2.24-25.18-1.29-10.54-1.29-10.54Z" fill="#fff"/>
              <path d="M1732.31,484.56s7.57,21.76,34.06,22.71c0,0,28.39-2.84,33.12-23.65,0,0,34.06,0,49.19,19.87l28.39,35-32.17,28.38s2.84,23.66-1,36l1,10.41s4.73,9.46,2.84,15.14l-67.18,22.7-75.68-11.35s-1.89-4.73,0-18,2.84-17,1.89-26.49-2.84-26.49-2.84-26.49l4.73-11.36s0-10.4-11.35-17a37.14,37.14,0,0,0-22.71-4.73S1694.47,486.46,1732.31,484.56Z" fill="#ccc"/>
              <path d="M1635,621.19s.48,41.33-2.74,65.49-1.41,36.39,3.55,59-6.37,46.48-.2,54.22,9.56,8.07,3,19.52H1508s-5.36-5.76,0-16.51,4.92-37.79,5.14-47.68-1.48-86.75,3.78-114.46l41-22.13,41.62-5.44Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <line x1="1740.25" y1="477.88" x2="1741.98" y2="488.75" fill="#f3f1ee"/>
              <path d="M1847.5,628s5.77,29.76,0,53.06-2.63,48.47,1.28,75.56-5.78,38.27-1.58,46.23S1844,816.33,1837,819.39h-123.9s-13.34.69-9.46-16.51,6.45-64.29,3.23-93.32-8.93-48.06-1.56-69.83Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1649.7,533.71l15.91,46.42S1694,597,1701,603.09l25.87,10.08-1.43,24.73-25.89-6.66s-37.17-18.73-45-21.25-22.87-19.94-22.87-19.94" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1667.73,550.47s-6.45,21.14-9.42,35.72l18.4-2-1.49-30Z" fill="#fff"/>
              <rect x="1912.43" y="604.86" width="37.52" height="16.63" rx="8.1" ry="8.1" fill="#f3f1ee" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1542.82,392.74c-4.48-17.32,5.33-38.93,22.21-43.8a52.14,52.14,0,0,1,22-1.64c10.74,1.6,20.73,7.89,26.17,17,0,0,4.84,7.65,4.8,21,0,10.59,6.06,21.83,1,32.05-5,10-16.46,16.66-27.54,13.92" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1600.81,387.36s5.49,6.43,1.53,12" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1594.32,390.92c.7,2.65-1.64,2.52-4.29,3.22s-4.62,2-5.32-.68,1.42-3.35,4.07-4S1593.62,388.27,1594.32,390.92Z" fill="#231815"/>
              <path d="M1615.06,391.3c-.64,2.37-2.39,1.23-4.76.59s-4.45-.52-3.82-2.89,2.59-2,5-1.33S1615.69,388.94,1615.06,391.3Z" fill="#231815"/>
              <line x1="1580.01" y1="503.61" x2="1581.4" y2="558.25" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1544.86,427.17a227.53,227.53,0,0,1,30,61.77" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1544.86,427.17,1532,437s-.16,27-1.46,38.13l23.78-12.54,6.92,3.49" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <line x1="1599.52" y1="434.16" x2="1582.49" y2="492.48" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <polyline points="1602.75 433.16 1608.82 436.58 1611.39 459.8 1601.99 457.33 1609.47 470.84 1587.77 492.73" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <line x1="1510.6" y1="462.94" x2="1524.78" y2="488.75" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1530.5,505.3s-1.61,35.92,1.46,43.31,0,12.43,0,12.43a184.57,184.57,0,0,1-52.84-2.39c3.33-13.54,6.3-58.19,15.38-85.73,6.62-20.05,19.15-28,37.48-35.95" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1626.52,482.9s1.94,29.09.29,41.81a114.41,114.41,0,0,0-1.12,20.4,277.79,277.79,0,0,1,28.86-14.76s-8.76-46.76-15.42-64.38-30.32-29.39-30.32-29.39" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1517,640.74l57.92-.14,8.65-25.13,12,20.18,43.65-10s-7.26-21.52-7.45-35.59-6-44.94-6-44.94" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1574.87,391.64a47.94,47.94,0,0,0,7.6.67" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1576,397.43s2.54-2.65,5.32-2.88" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1789.52,472.35s12.58-2.05,21.23-19.19-9.85-21.56-11.5-38.87c-1.11-11.65-10.39-22.21-22.51-22.11-6,0-11.67,2-17.61,2.39-6.14.42-12.31.63-18.42,1.42-12,1.55-16,5.08-21.57,14.7s2.5,18.24,2.5,18.24,13.48-3.58,17.66-10.82,20.65-8.89,25-.74c0,0-.56,19.26,15.06,22,0,0,7.73-10.14,14.59-2.29,0,0,6.9,8.76-3.09,16.07l-1.37,1.07S1786.26,459.22,1789.52,472.35Z" fill="#231815"/>
              <path d="M1747.78,480.15c-9.69-1.6-20.21-6.86-24.5-16.17-5.41-11.73,4.15-23.83,0-35.68,0,0,9.71-.6,13.39-7s10.1-15.49,29.49-8.12" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1780.47,437.9c3.63-5.34,13-4,15.34,1.86,2.6,6.42-4.33,17.18-11.72,14.47" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <line x1="1782.3" y1="446.68" x2="1788.67" y2="442.85" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1787.93,454.66s-2.34,25.1,11.32,29.3h0a30.88,30.88,0,0,1-19.31,20.11l-4.63,1.62a30.87,30.87,0,0,1-18.15.7l-2.68-.71a30.88,30.88,0,0,1-19.36-15.4l-.71-1.35" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1784.15,455.53s-4.29,8-7.36,9.91" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1734.94,434.54s-4.43,5.69.33,9.71" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1732.54,451.87l14.55-2.25S1739.82,459,1732.54,451.87Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <circle cx="1744.69" cy="434.23" r="3.55" fill="#231815"/>
              <circle cx="1728.23" cy="437.08" r="3.4" fill="#231815"/>
              <path d="M1767.51,417.37s-2.76,15.94,9.63,19.73" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1778.53,417.37s-2.19,9.67,7.81,17.17" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1804.26,440.89s-2.27,19.59-15.84,24.55" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1730,410.42c-.3,4,4.51,5.33,7.66,5.25" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1725.3,416.53a8,8,0,0,0,8,4.18" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1751.22,432s4.29.4,6.87-1.37" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1751.22,434.77a14.39,14.39,0,0,1,6.19.53" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1746.69,444.25s4.5,1.45,6.52,5.37" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1810,484.62c39.72,6.22,46.69,32.54,66.78,55.33l-32,26.55s1.63,25.63-.73,33.3,4.45,16.07,3.44,28.23c0,0-26,4-45.22,12.05a54.3,54.3,0,0,1-35.86,2.28c-18.3-5.14-46.52-6.37-61.07-2.63,0,0-3-4.66.35-18.16s-.15-40.73-2-52.63" fill="#ccc" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1839.65,531.19s7,35-24.45,42.7" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1715.61,514.57s2.56,18.72,2,27.52" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <line x1="1709.11" y1="558.25" x2="1715.61" y2="547.86" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1726.72,602.37s8.83,18,49.14,15.61" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1876.81,540s27.12,24.72,37,27.76c11.7,3.59,34.81,5.33,41.8,24.28h0l1.35,17.56a4.24,4.24,0,0,1-5.35,3.43,2.55,2.55,0,0,1-1.69-1.56s-3.94,1.78-5.44-1.35a5.24,5.24,0,0,1-5.1,0s-3.14,2.41-7.41-1.53l-12.5-9a2.28,2.28,0,0,0-2.19.73h0a2.29,2.29,0,0,0,.51,3.44l3.76,2.17a3.61,3.61,0,0,1,1.59,4.36h0a3.61,3.61,0,0,1-5.1,1.93l-5.64-3a15.67,15.67,0,0,1-7.92-10.77l-2.53-13" fill="#f3f1ee" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1844.79,566.5c14.35,18.43,38.22,27.8,59,28" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <line x1="1904.36" y1="574.89" x2="1911.06" y2="567.71" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1741.84,426a9.56,9.56,0,0,1,6.8,1.28" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1676.71,535.64s24.49-2,30.2,16.9" fill="#f9fbf8" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1741,483.81a87,87,0,0,0-25.42,4.94c-17.35,6.61-37.36,33.89-46,56.36,0,0-10.75,29.59-11.3,41.08l22.71-.76" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1706.91,562.39l-14.18,27.67,1.28,9.72a3.4,3.4,0,0,1-.06,1.27l-1.69,7.26a3.56,3.56,0,0,1-3.45,2.74h0a3.54,3.54,0,0,1-3.52-4l.84-6.48a2.1,2.1,0,0,0-2.08-2.37h0a2.1,2.1,0,0,0-2.08,1.88l-1.24,11.72a2.63,2.63,0,0,1-2.62,2.36h-.73a2.64,2.64,0,0,1-2.62-2.94l.84-7.14a1.43,1.43,0,0,0-1.42-1.59h-.13a1.42,1.42,0,0,0-1.41,1.26l-1.24,10.58a2.62,2.62,0,0,1-2.61,2.32h-.88a2.63,2.63,0,0,1-2.61-2.91l1-9.08a1.42,1.42,0,0,0-1.26-1.57h0a1.42,1.42,0,0,0-1.56,1.29l-.8,8.7a2.63,2.63,0,0,1-2.54,2.39h0a2.64,2.64,0,0,1-2.7-2.86l.82-9.27a1.42,1.42,0,0,0-1.59-1.53l-.23,0a1.42,1.42,0,0,0-1.23,1.18l-.9,7.73c-.16,1-1.63,1.73-2.64,1.73h0a2.07,2.07,0,0,1-2.06-2.28l.8-9.91a20.37,20.37,0,0,1,7.91-14.1l7.72-3.82" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1544.86,500.51h16.34v14.73a16.22,16.22,0,0,1-9.33,14.67h0a16.21,16.21,0,0,1-12.29.59l-9.08-3.22" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1730.79,805.05A26,26,0,0,0,1716,819.47l-2.87,7.27a10.39,10.39,0,0,0-.72,3.81v1.29c0,6.2,5.48,11.23,12.24,11.23h34.71c6.7,0,12.16-5,12.24-11.1h0a10.61,10.61,0,0,0-.72-3.93l-3.4-8.65a25.9,25.9,0,0,0-14.6-14.38h0a29.46,29.46,0,0,0-21.79-.08Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1530,805.05a26,26,0,0,0-14.79,14.42l-2.86,7.27a10.2,10.2,0,0,0-.73,3.81v1.29c0,6.2,5.48,11.23,12.24,11.23h34.71c6.7,0,12.16-5,12.24-11.1h0a10.41,10.41,0,0,0-.72-3.93l-3.39-8.65A26,26,0,0,0,1552.06,805h0a29.46,29.46,0,0,0-21.79-.08Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1601.91,805.05a26,26,0,0,0-14.78,14.42l-2.87,7.27a10.39,10.39,0,0,0-.72,3.81v1.29c0,6.2,5.48,11.23,12.24,11.23h34.71c6.7,0,12.16-5,12.24-11.1h0A10.61,10.61,0,0,0,1642,828l-3.4-8.65A25.9,25.9,0,0,0,1624,805h0a29.46,29.46,0,0,0-21.79-.08Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1800.34,805.05a26,26,0,0,0-14.79,14.42l-2.86,7.27a10.4,10.4,0,0,0-.73,3.81v1.29c0,6.2,5.48,11.23,12.24,11.23h34.71c6.71,0,12.17-5,12.24-11.1h0a10.22,10.22,0,0,0-.72-3.93l-3.39-8.65a26,26,0,0,0-14.6-14.38h0a29.49,29.49,0,0,0-21.8-.08Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1606.05,410.18a20,20,0,0,1-16.54-2.18l16.88-1.24Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1780.5,671.91s9.75,40.29-.94,147.48" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1792,667.34s-2.39,10.75-9.71,16.95" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1520.21,563.25v76.48l8,21.27a4.93,4.93,0,0,1-1,5.09h0a4.93,4.93,0,0,1-7.54-.36l-3.8-5-1,13.61s3.91,4.34-1.76,6.74c0,0-1.31,4.07-6.33,2.48,0,0,.22,3.85-4.58,3l-2-1.6a27.87,27.87,0,0,1-10.61-20.73,62.83,62.83,0,0,1,.62-13.29c5.27-30-1.66-59.92-1.66-90.09" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1508.08,666.47v5.71a8.56,8.56,0,0,0,2.22,5.76l2.87,3.15" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1554.51,385.94s-11.16-1.24-9.65,11.4,9.61,12.17,15,11.88c0,0-1,7.6,3.65,10.93" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1546.07,365.89s5.45,8.12,12.68,7.25,12.77,10.54,4.76,14.22-15.9,0-15.9,0-7.6,2.45-1.54,16.93c0,0-21.55-2.14-11.08-29.22,0,0,4.94-11.53,12.62-13.94Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <line x1="1551.59" y1="397.34" x2="1558.75" y2="398.05" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1552.75,414.43a69,69,0,0,1-3,12.74" fill="#f9fbf8" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1596.5,444.77s-9.27,11.67-33,2.32" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <line x1="1506.84" y1="683.57" x2="1501.48" y2="675.74" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1582.36,388.05a7,7,0,0,1,2.32-7.47,13.62,13.62,0,0,1,6.78-3.16c5.57-.58,4.56,4.8,1,5.53s-10.1,5.1-10.1,5.1Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1619.75,387.36a7,7,0,0,0-2.32-7.46,13.46,13.46,0,0,0-6.77-3.16c-5.58-.59-4.57,4.8-1,5.53s10.09,5.09,10.09,5.09Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
              <path d="M1595.31,654.09l-11.77,19.69s1.36,110.94-2.14,145.61" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.84"/>
            </g>
          </g>
          <g>
            <path d="M-144.23,629.57a33.31,33.31,0,0,0,31.71,31l17,.77,1.16,9.31,1.41,1.93,1.54,80s-1.55,85.46,1.55,129,20.2,69.92,26.42,101-3.11,38.84,0,52.83-4.66,15.54,1.55,29.52h13.47l-4.15,15.54L-67.06,1105a19.77,19.77,0,0,0-.38,19.41A19.75,19.75,0,0,0-50,1134.8h10.66A19.76,19.76,0,0,0-22,1124.51l18.42-33.78A32.18,32.18,0,0,0,.3,1075.32v-11.66a16.55,16.55,0,0,0,9-8.65h0a16.55,16.55,0,0,0,1.33-8.59L5,995.35a127.87,127.87,0,0,1,0-28.74l9.46-82.12L19,926.59s-4.66,91.68-4.66,111.88,3.1,31.07-1.56,45.06a36.87,36.87,0,0,0-1.55,15,14.12,14.12,0,0,0,6.21,10.39v3.13a46.35,46.35,0,0,0,7.18,24.78,39,39,0,0,0,33,18.17H84.65a16.19,16.19,0,0,0,14-24.31l-11.62-20.08a14.69,14.69,0,0,0,4.3-2.48,14.29,14.29,0,0,0,4.82-14.57c-1.51-5.66-4.87-5.78-2.64-14.7,3.11-12.43,6.21-28,6.21-60.59s24.87-124.31,29.53-160c3.08-23.67,3.44-58.22,3.34-78.87h0l10,13.27,2.19,21.9-6.19,11.08v26.89h3.92a13.5,13.5,0,0,0-10.14,13V986.1a13.52,13.52,0,0,0,13.53,13.52H167a13.52,13.52,0,0,0,13.52-13.52V865.53a13.5,13.5,0,0,0-10.16-13h.17V825.84l.3.35,1-3-.62,3.93,6.21-12.43,2.23-33.45,1.8-11.64-.77-1.85c5.91-12.18-1.74-16.88-6.36-29.19s7.77-18.65,4.66-49.73-21.76-186.45-21.76-186.45l-4.06-36.84-77.78-49.5L57.86,429.75,56,431l-8.43,3c.39-.11.8-.19,1.18-.3,22.45-6.59,32.38-29.1,28.83-51.16C76,372.39,75.46,362,74.15,351.86l0,.08s6.54-23.22-6.06-40.82c-11-15.41-33.31-27.07-53.5-25.76A62,62,0,0,0-10.16,292,40.11,40.11,0,0,0-21,300c-1.17,1.27-8.48,10.29-7,12l-6.87,7a38.15,38.15,0,0,0-10.79,29.74,38.22,38.22,0,0,0,5.81,17.38l4.36,6.87a17.5,17.5,0,0,1,2-.94l-.47,1c-6.47,3.7-6.1,12.61-3.52,19.32,3,7.76,8.88,14,16.59,14.76l.76,1.89,3.54,10.61-95.46,53L-122,490.13C-127.26,505.31-148.35,571-144.23,629.57ZM146.36,836.78a2.63,2.63,0,0,0,.3.08c6,.74,13.45-.11,15.24-8.16V852H146.36Z" fill="#fff"/>
            <path d="M102.14,434.75l-18.7,28.06,18.7,21.82L36.67,568.8,5.5,578.15l-59.23-99.76,15.59-12.47-15.59-28.05L-106,465.46l-12.43,18.15S-141,556.33-141,606.21v31.17l18.18,17.14L-88,659.2v90.4L21.09,790.13l115.34-34.29,6.24,9.35h40.52l3.12-12.47L177,730.9l6.23-31.17L158.25,500.21l-9.35-37.4Z" fill="#fff"/>
            <path d="M64.73,565.68l-74.82,15.59v9.35l-12.46,6.23-9.36,21.82S-35,643.61-25.67,659.2l49.88-9.35,3.11-9.35-3.11-9.36,49.87-9.35-9.35-53Z" fill="#fff"/>
            <g>
              <path d="M-43.86,1053.85l-6.22,23.31-14.53,24.59a19.77,19.77,0,0,0-.38,19.41h0a19.75,19.75,0,0,0,17.39,10.38h10.66a19.74,19.74,0,0,0,17.34-10.29l18.42-33.78a32.13,32.13,0,0,0,3.93-15.41V1043Z" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <path d="M24,295.26S60.35,294.19,72.1,328c5.73,16.5,5.18,34.2,7.93,51.27C83.58,401.3,77.5,424.5,55.5,431.5c-16.15,5.14-42.14-1.85-52.28-16" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <path d="M57.5,360.5l-.14,13.7a10,10,0,0,1,5.14,8.3c.16,4.45-2,9-8.48,7.91" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <circle cx="36.73" cy="380.5" r="5.1" fill="#231815"/>
              <circle cx="69.67" cy="374.01" r="5.1" fill="#231815"/>
              <path d="M.3,338.74h0a40.88,40.88,0,0,1,39.33-4.45c8.82,3.67,17.85,8.24,21.89,12.86,0,0,1.44-6.8-3.13-12,0,0,15.91,3.58,18.24,13.56,0,0,6.54-23.22-6.06-40.81-11-15.41-33.31-27.08-53.5-25.77a62,62,0,0,0-24.78,6.63,40.08,40.08,0,0,0-10.8,8c-1.17,1.27-8.48,10.29-7,12Z" fill="#221714" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <path d="M-25.52,308.77l-6.87,7A38.13,38.13,0,0,0-43.18,345.5h0a38.18,38.18,0,0,0,5.81,17.38l4.36,6.88s8.39-5,13.53.86l8.61,13h0a7.66,7.66,0,0,0,7.2-8.34l-1-10.94c4.39-14,5.71-33.59,5.71-33.59" fill="#221714" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <path d="M40.5,404.5s9,4,17-2" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
              <polyline points="60.5 429.5 60.24 444.77 27.61 471.19 50.12 498.25" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <line x1="75.5" y1="416.5" x2="145.7" y2="461.86" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <path d="M74.23,429.23,29.17,564.41" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <polyline points="-12.79 443.22 19.84 469.63 -4.32 506.33" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <path d="M38,483.61H11.26S7.41,488.28,9,492.94s-4.66,12.43,0,14,24.86,0,24.86,0" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <path d="M15.18,494.49s10.88,0,17.09,3.11" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <line x1="30.28" y1="507.16" x2="38.49" y2="533.34" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <line x1="16.74" y1="569.07" x2="-28.32" y2="435.45" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <line x1="12.59" y1="508.17" x2="4.31" y2="531.78" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <line x1="103.3" y1="434.92" x2="83.55" y2="463.42" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <polyline points="75.78 461.86 100.64 482.06 49.37 551.98" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <path d="M145.5,482.5s-22.34,25.14-27,50" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <path d="M159.68,499.15s18.65,155.38,21.76,186.46-9.32,37.29-4.66,49.72,12.43,17.09,6.21,29.52H145.7" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <polyline points="122.5 598.5 134.82 758.63 27.61 785.05 28.34 727.48" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <polyline points="2.75 785.05 -88.92 749.31 -90.47 668.51" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <polyline points="182.99 764.85 179.88 811.46 173.67 823.89" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <path d="M134.82,758.63s1.56,60.6-3.1,96.34-29.53,127.41-29.53,160-3.1,48.17-6.21,60.6c-2.23,8.91,1.13,9,2.64,14.69a14.29,14.29,0,0,1-4.82,14.57h0a14.49,14.49,0,0,1-9.29,3.37H28.07a14.42,14.42,0,0,1-14.44-13,36.87,36.87,0,0,1,1.55-15c4.66-14,1.56-24.86,1.56-45.06S21.4,923.34,21.4,923.34l-9.32-88.57,15.53-49.72Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <path d="M-88.92,749.31s-1.55,85.46,1.55,129,20.2,69.92,26.42,101-3.11,38.85,0,52.83-4.66,15.54,1.55,29.52h56a16.63,16.63,0,0,0,15.19-9.87h0a16.52,16.52,0,0,0,1.33-8.58L7.46,992.09a127.87,127.87,0,0,1,0-28.74l10.78-93.62,9.32-84.68H7.41Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <path d="M-15.45,613l39.69-15a5.91,5.91,0,0,1,7.59,3.39h0A5.92,5.92,0,0,1,29,608.84L14,616.37s10.62,2.36,8.6,11.46a9.77,9.77,0,0,1,2.2,11.63s5.39,4.38-.84,10.95l-30,6.08" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <path d="M-6.86,609.77l-3.45-15.55a11.68,11.68,0,0,1,8.63-13.88l52.79-12.93a11.68,11.68,0,0,1,14.31,9.43l5.47,32.83a11.68,11.68,0,0,1-8.73,13.27L25.37,632" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <rect x="134.82" y="848.75" width="48.17" height="147.61" rx="13.52" ry="13.52" transform="translate(317.82 1845.12) rotate(-180)" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <polygon points="173 849.24 173 821.45 165.5 811.23 147.25 811.23 141.04 822.34 141.04 849.24 148.81 849.24 148.81 823.89 151.92 819.23 162.79 819.23 164.35 825.45 164.35 848.75 173 849.24" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
              <path d="M144.15,780.39l3.1,31.07-1.55,14h0a5.11,5.11,0,0,0,3.41,8.15c6,.74,13.45-.11,15.24-8.15a119.27,119.27,0,0,0,3.1-23.31l7.77-14" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
            </g>
            <path d="M36.67,359.93a11.07,11.07,0,0,0-9.35,6.24" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.26"/>
          </g>
          <path d="M1901.5,488.5S1914,506,1951,495" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.18"/>
          <path d="M522.15,602.08H357.39a18,18,0,0,0-18,17.95c0,.36,0,.71.06,1.06H290.85a18,18,0,0,0,0,35.91H455.61a18,18,0,0,0,18-18c0-.36,0-.71-.06-1.06h48.64a18,18,0,0,0,0-35.91Z" fill="#e0e4e5" style={{ mixBlendMode: 'multiply' }}/>
          <rect x="621.25" y="634.41" width="112.75" height="22.77" rx="11.38" ry="11.38" fill="#e0e4e5" style={{ mixBlendMode: 'multiply' }}/>
          <g>
            <path d="M515,269c0-3-4-3-4-3l-3.34,3.34a.55.55,0,0,1-.92-.56l1.47-4.41a2.31,2.31,0,0,0-4.11-2l-3.18,5.93a.55.55,0,0,1-1-.23l-.2-3.45a2.32,2.32,0,0,0-2.3-2.6h-.51a2.31,2.31,0,0,0-2.26,1.83L492,276l-.21.61-.13-.73.16-.26a3.2,3.2,0,0,0-.11-.73,1.74,1.74,0,0,0-1.07-1.61,3.56,3.56,0,0,0-1.95-.52,11.79,11.79,0,0,0-1.88,0l.14,2.65-.12,1.9.93,4.73.65,4.51L481,308l-12.17,2.55L456,314.29a63.07,63.07,0,0,0-8,2.34l.3-4.95a19,19,0,0,0,.8-6.75,52.61,52.61,0,0,1-.05-5.62L450,283l0-.13,4.77-1.68s-2.34-22.4-22.4-18.39c-33.43-5.34-32.09,17.72-32.09,17.72l1.32.13c-.33,1.15-.67,2.54-1,4.22-1.34,7,5.57,14.92,5.57,14.92v0a6.86,6.86,0,0,0,3.65,3.61L406,317l-28,7-3,76-1,68,.72-.27,30.27,60h5.63a143.94,143.94,0,0,1-.08,30c-1.62,16.15.17,43.44,1.09,55.3h0v19h35a5.11,5.11,0,0,0,2.73-9.43l-15.08-9.53h-.14c-.34-15.94-1.15-59.88.14-68.46a123.17,123.17,0,0,0,1.13-16.87c4.76-.11,10.52-4.12,10.27-8.9-.53-10-.93-26.49,1.13-38.49,3.16-18.43,2.47-39,2.47-39h0l.14-.1c3.08-2.5,1.95-7.75,1.7-11.09-.23-2.94-.13-5.88-.24-8.82-.52-14.22-2.21-11.06.42-20s-.52-39.5-.52-39.5L445,352l.69-2.71L445,352l50-23c6-12,16-41,16-41l7-16A2.94,2.94,0,0,0,515,269ZM405.66,298a6.23,6.23,0,0,0,.45,1.72A5.48,5.48,0,0,1,405.66,298Zm.94-4.45a.67.67,0,0,0,.06-.11.1.1,0,0,0,0-.05A1.4,1.4,0,0,1,406.6,293.5Zm.33-.48a6.46,6.46,0,0,1,2.55-2.15v0A6.9,6.9,0,0,0,406.93,293Z" fill="#fff"/>
            <path d="M378,324l28-7,1,3,11,10,5-1,9-9s10,0,13-3l23-5,8,29-29,11,3,33s5,11,0,20c0,0,1,15,1,20s2,13-2,16H375Z" fill="#fff"/>
            <g>
              <path d="M430.67,287.88a11.58,11.58,0,0,1,4-.34" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M442.7,287.21a6.66,6.66,0,0,1,2.68,1" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M448.72,279.85c1.86,8.13-.22,16.81.34,25.08.33,5-1.34,14.37-11.37,15a27.59,27.59,0,0,1-9.36-1.33" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M441.37,291.22s-.34,3.65-.34,3.68a8.45,8.45,0,0,1,1.17.94,2.78,2.78,0,0,1,.77,2,2.59,2.59,0,0,1-3.6,2.35" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <circle cx="432.03" cy="294.39" r="2.32" fill="#231815"/>
              <circle cx="446.07" cy="294.73" r="2.32" fill="#231815"/>
              <path d="M414.34,290.48a7,7,0,0,0-1.89-.26,6.86,6.86,0,1,0,0,13.71s.17,7.35,5.85,9.69" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <line x1="410.61" y1="296.9" x2="414.62" y2="297.57" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M427,281.19a14.42,14.42,0,0,1-4.89,9,5.64,5.64,0,0,0-2.13,4.37v.12a2.05,2.05,0,0,1-2.25,2h0a2.05,2.05,0,0,1-1.61-1.08l-2.84-5.33a6.78,6.78,0,0,0-6.61,3.12s-2,3.26-.51,6.4c0,0-6.91-7.9-5.57-14.92s3-9,3-9l24.07,2.34Z" fill="#ccc" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M408,303s.94,10.29-1.74,13.63" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <line x1="416.86" y1="328.7" x2="406.7" y2="319.6" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
              <polyline points="422.65 333.44 424.23 340.81 437.4 327.64" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
              <polyline points="416.86 332.38 413.7 339.23 408.43 334.49" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
              <path d="M447.4,342.39,450,385s2,8,0,20c-1.53,9.21.3,2.17.82,16.39.11,2.94,0,5.88.24,8.82.25,3.34,1.38,8.59-1.7,11.09-2.43,2-11.64.67-14.46.67-4.08,0-8.17,0-12.25,0" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
              <path d="M441.08,422.44s-6.84,2.64-20,4.22" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
              <line x1="441.08" y1="430.34" x2="427.92" y2="429.29" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
              <line x1="420.54" y1="337.65" x2="420.54" y2="358.19" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
              <path d="M445,318s3.6-2.52,17.08-5,5.92-1.19,5.92-1.19l5,15.61" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
              <path d="M449.24,441.4s.69,20.54-2.47,39c-2.06,12-1.66,28.45-1.13,38.49.25,4.85-5.61,8.91-10.47,8.91H392l-17.28-60,27.39-26.07Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
              <path d="M435.29,522a138.12,138.12,0,0,1-1.05,22.65c-1.58,10.53,0,74.26,0,74.26H412.12s-3.69-40-1.58-61.1-.53-34.23-.53-34.23Z" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
              <path d="M434.24,613.09H411.59v19h35a5.11,5.11,0,0,0,3.93-1.84h0a5.11,5.11,0,0,0-1.2-7.59Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
              <line x1="427.92" y1="548.31" x2="422.65" y2="543.57" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
              <line x1="433.65" y1="586.22" x2="410.39" y2="586.22" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
              <path d="M488.41,286.52,487,277l-.23-4.24s5.79-.51,4.8,2.88l.38,2.07-.28,9.31Z" fill="#fff"/>
              <path d="M491.82,275.62h0c-.23-3.92-4.71-2.67-4.71-2.67l-.28,4.33.52,10.77L480.68,308,468,311.85" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
              <path d="M439.7,271.16s-10.12-1.67-13.09,11.87l-26.36-2.51s-1.34-23.06,32.09-17.72Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M439.7,271.16s9.93,4.68,9.81,11.87l5.23-1.84s-2.34-22.4-22.4-18.39" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M442.7,270.16s-8,6-8.35,10.36c0,0-1.34-6.35,1-10.36Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path d="M433.34,305.6s2,1.67,6.69,1" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <line x1="424" y1="328" x2="432.49" y2="319.6" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
              <line x1="449" y1="351" x2="489" y2="334" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
              <path d="M492,276l2.61-12.17a2.31,2.31,0,0,1,2.26-1.83h.51a2.32,2.32,0,0,1,2.3,2.6l.2,3.45a.55.55,0,0,0,1,.23l3.18-5.93a2.32,2.32,0,0,1,2.48-1h0a2.31,2.31,0,0,1,1.63,3l-1.47,4.41a.55.55,0,0,0,.92.56L511,266s4,0,4,3a2.94,2.94,0,0,1,3,3l-6,13-4,4s-6,26-12,38" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
              <line x1="515" y1="269" x2="514" y2="271" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
              <line x1="508" y1="289" x2="504" y2="289" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            </g>
          </g>
          <g>
            <g>
              <path d="M392.79,396.61c-1.15-6.46-2.42-12.61-2.42-12.61Z" fill="#f2f2f2"/>
              <path d="M413.54,443.51l-6.84-2.64,5.79-1.58c2.11-1.58,0-4.21,0-4.21H398.8a28.37,28.37,0,0,1,3.16-4.21c2.63-3.16,0-4.21,0-4.21l-2.86,1.42a40.25,40.25,0,0,0-1.17-7.4,11.32,11.32,0,0,0-1.52-5.43l-1.13-5.62-2.49-13c.23,1.3.46,2.61.67,3.88l-4.93-21-13.17-8.43,2.11-5.79c14.22,0,14.48-14,14.48-14,1.89-.71,5.79-1,3.69-4.74-1.11-1.93-2-2.77-1.58-5.26a37.52,37.52,0,0,0,.4-8.27,3.47,3.47,0,0,0,2.23,0l3.69-1.22c-3.13-1.25-4.18-6-4.9-8.87a15.69,15.69,0,0,0-7.85-9.8c-.32-.18-4-2.41-6.43-2-4.36-2.08-19.14-7.71-32.44,3.81-15.8,13.7-2.1,29.5-2.1,29.5a7.33,7.33,0,0,0,2.45,3.18l-.87,3.54-6.06-6.46-32.13,22.65-.06.34-.2-.08-1.58,10v4.74s-4.74,2.1-5.79,5.26a12.8,12.8,0,0,0-.82,5.81l.28-.46-.14.4a12.38,12.38,0,0,0-7.17,9.43c-1.32,8.66,1.09,17.27-.12,25.88-1.15,8.1-4.37,16.6-2,24.77,2.23,7.82,10.9,8.4,15.8,8.07l0-1,1.81,1.14,1.17,1.75a7,7,0,0,0,2.9,5.09,100.75,100.75,0,0,0-1.7,29.76c1.56,17.38,4.15,18.43,4.67,40s4.16,45.29.52,55.82-3.12,11.06-2.6,20.54h2.68V628a6.66,6.66,0,0,0,6.66,6.66h38.78a3.67,3.67,0,0,0,3.52-2.66l.66,0h29.4a4.19,4.19,0,0,0,1.8-8l-19.73-9.4h4.36c4.68-10.53,1.56-13.16,1.56-28.44s4.13-43.71,7.25-58.46c2.81-13.27-.68-50.87-1.4-58.24,2.59-6.25,1.68-9.85.28-12.86h16.39c2.64-1.58,1.06-3.68,1.06-3.68,2.63-2.64,0-4.22,0-4.22l4.74-1.58A2.57,2.57,0,0,0,413.54,443.51Zm-66.26-88.36-.29,1.2-.2.06C347,356,347.13,355.56,347.28,355.15Zm42.53,99.48.07-.2.13.59Z" fill="#fff"/>
              <path d="M291.36,410.86v15.27A43.13,43.13,0,0,0,291.36,410.86Z" fill="#f2f2f2"/>
              <polygon points="293.46 427.18 289.78 427.18 289.25 432.98 291.88 432.98 293.46 427.18" fill="#f2f2f2"/>
            </g>
            <path d="M330.07,366.35s4.21,13.7,3.16,19l6.84,10,2.64,3.69v24.22H310.58l-.53-19.48,1.06-17.91,2.37,1.84,3.16-1.58h1.58l4.74-1.58,4.21-2.1v-2.11l2.63-2.63-1.58-7.38-1.58-2.63Z" fill="#fff"/>
            <path d="M341.39,372.41s1.58,19-1.58,22.65l3.16,4.21-.53,33.7,43.72,4.74,2.63-2.63,6.85-4.74,3.68-2.1-1.58-10-2.63-6.32L390.37,384l-2.11-4.74-7.37-4.74-2.63,3.68-7.38,31.08L354,382.42Z" fill="#fff"/>
            <path d="M315.58,443.51s2.11,10,18.44,12.11H385.1l5.27,1.05s3.16,4.22,1.05,11.06H313.48l-2.64-4.21L311.9,454S315.58,445.61,315.58,443.51Z" fill="#fff"/>
            <path d="M380.36,602.56l-1.58,12.11,19.73,9.4a4.19,4.19,0,0,1,2.39,3.79h0a4.2,4.2,0,0,1-4.19,4.19h-29.4a14,14,0,0,1-10.63-4.87l-12.13-14.09v-9.48Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <circle cx="389.4" cy="343.53" r="1.93" fill="#231815"/>
            <path d="M390.57,354.86a5.55,5.55,0,0,1-4.94-1.41" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <path d="M371.94,341.34s-4.22-4.22-8.43-.53S359.82,354.5,369.3,354" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <line x1="369.83" y1="347.13" x2="364.56" y2="345.55" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <path d="M377.73,334.49l-1.58,7.9-.24.05a2.26,2.26,0,0,1-3.84.06l-1.36-2.11a6,6,0,0,0-3.51-1.16l.52-6.32,10.54-.53Z" fill="#221714" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <path d="M394.06,328.7a45,45,0,0,1,0,12.64c-.46,2.49.47,3.33,1.58,5.26,2.1,3.69-1.8,4-3.69,4.74,0,0-1.05,14.75-14.22,13.7l-2.11,5.79-7.37,5.79.48,2.05a4,4,0,0,0,6.64,1.89l5-5" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <path d="M348.76,349.76a32.53,32.53,0,0,1-5.26,12.64l23.17,14.22-.72,1.69a8,8,0,0,1-12.18,3.25l-12.91-9.68" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <line x1="367.2" y1="382.42" x2="369.3" y2="401.38" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <polyline points="378.48 377.46 370.88 409.27 353.5 381.36" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <line x1="341.18" y1="362.41" x2="326.64" y2="367.67" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <line x1="376.38" y1="371.3" x2="388.26" y2="379.78" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <path d="M390.37,384s3.69,17.9,4.21,24.75,4.22,7.9,3.16,13.69" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <path d="M390.74,456.48c1.47,3.09,2.5,6.78-.32,13.36H318.8s-8.18.53-7.67-8.95,5.43-12.64,2.87-23.18" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <path d="M386.33,394.53s-2.56,10-.51,19A141.77,141.77,0,0,1,389,434" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <path d="M390.42,468.26S395.07,513,392,527.77,384.7,571,384.7,586.23s3.12,17.91-1.56,28.44H354l-3.22-146.4Z" fill="#221714" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <path d="M314.31,467.7a98.5,98.5,0,0,0-1.89,30.58c1.56,17.38,4.15,18.43,4.67,40s4.16,45.29.52,55.82-3.12,11.06-2.6,20.54h39s5.72-3.68,3.64-13.16-5.72-41.08-3.64-53.2,8.47-80.61,8.47-80.61Z" fill="#221714" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <path d="M342.44,410.5v12.73H310.32s-1.58-27.21.52-35.55" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <polyline points="342.44 423.5 342.44 432.98 386.16 437.19 385.1 444.56" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <path d="M385.1,455.62l-52.7-.46A18.91,18.91,0,0,1,314,437.91L313,423.5" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <path d="M386.16,437.19l9.48-7.37,6.32-3.16s2.63,1,0,4.21a28.37,28.37,0,0,0-3.16,4.21h13.69s2.11,2.63,0,4.21l-5.79,1.58,6.84,2.64a2.57,2.57,0,0,1-.52,3.68l-4.74,1.58s2.63,1.58,0,4.22c0,0,1.58,2.1-1.06,3.68H390.37" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <path d="M382.47,311.84s-17.91-10.53-33.71,3.16-2.1,29.5-2.1,29.5h0a7.44,7.44,0,0,0,7.74,4.63l6.73-.76s-1.31-8.61,6.07-9.14l11.06-8.43Z" fill="#221714" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <path d="M344.55,613.09,365.28,628a3.65,3.65,0,0,1,1.45,3.7h0a3.67,3.67,0,0,1-3.6,3H324.35a6.66,6.66,0,0,1-6.66-6.66V613.09Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <polygon points="389.84 330.82 388.79 335.54 375.62 336.6 376.15 330.8 389.84 330.82" fill="#221714" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <path d="M337.63,363.71s8.4,12.27,2.5,31.6" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <path d="M330.07,366.58s4.2,9.33,3.28,18.59" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <path d="M310.1,391.54l-5.52,1a12.36,12.36,0,0,0-10,10.31c-1.32,8.66,1.09,17.27-.12,25.88-1.15,8.1-4.37,16.6-2,24.77,2.23,7.82,10.9,8.4,15.8,8.07" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <line x1="308.21" y1="427.18" x2="296.62" y2="427.18" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <path d="M303.47,399.27a7.05,7.05,0,0,0-3.69,6.85c.53,4.74,2.11,10.53,1.06,16.32" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <path d="M291.36,410.86a43.13,43.13,0,0,1,0,15.27" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <polyline points="293.46 427.18 289.78 427.18 289.25 432.98 291.88 432.98" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <path d="M308.21,382.42s-4.74,2.1-5.79,5.26a12.8,12.8,0,0,0-.82,5.81" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <path d="M309.79,386.1s-3.16-.52-4.74,6.32" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <path d="M376.15,334s.15-18.58,3.69-22.12c2.1-2.1,7.4,1.14,7.79,1.35a15.69,15.69,0,0,1,7.85,9.8c.72,2.83,1.77,7.62,4.9,8.87l-3.69,1.22a3.49,3.49,0,0,1-4.09-1.49l-1.34-2.18C387.61,331.59,382.32,334.89,376.15,334Z" fill="#221714" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <line x1="342.44" y1="399.27" x2="331.91" y2="382.94" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <path d="M367.2,488.8l-6.32,20s3.16,21.07,1.58,36.87-3.69,26.33-1.58,40.55-.89,28.44-.89,28.44" fill="#231815" stroke="#f2f2f2" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <line x1="341.98" y1="344.94" x2="312.42" y2="365.04" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <path d="M325.06,374l2.11,7.9a5.84,5.84,0,0,1-4.74,2.63s-3.69,3.16-5.79,1.58c0,0-2.64,2.11-5.27.53l-3.16-8.95,1.58-10" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
            <path d="M324.54,365l3.68,5.26,1.58,7.38a2.8,2.8,0,0,1-2.63,2.1" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.11"/>
          </g>
          <g>
            <path d="M718,448c.73-3.66.34-8.87-.46-14a89.42,89.42,0,0,0-6.85-25.15l-.34.26a28.24,28.24,0,0,0-4.07-5.82l.08-.11-.07-.18a6.16,6.16,0,0,0-1.13-3.09l-1-4.3v-5.2c.07-.61.14-1.22.22-1.83a32.93,32.93,0,0,0-.22-10v-1.92s-1.56-4.45-3.46-9.62l-3.2-9.46a17.59,17.59,0,0,0-2.51-4.78c-.21-.41-.4-.76-.55-1-1.82-2.72-5.52-4.3-6.67-4.74A17.58,17.58,0,0,0,684,346L673.13,344l.06.29-4.65.52-15.67,14.05.81,6.21-3.49,23.45c.47-.48.79-.75.79-.75l-.94,1.75.15-1a12.12,12.12,0,0,0-3.54,7.36h0A9,9,0,0,0,648,401l-25.06-4,5.1-19.64,7.15-16a1.74,1.74,0,0,0,0-1.36h0a1.77,1.77,0,0,0-3.27,0l-1.9,3a1.13,1.13,0,0,1-2.09-.87l3.24-9.16a2.69,2.69,0,0,0-1-3.14c-.71-.46-1.57-.54-2.35.65L625.59,355l-.35-5.06a1.74,1.74,0,0,0-3.42-.32l-1.07,4.12-1.93-4.3a1.86,1.86,0,0,0-3.07-.55,1.56,1.56,0,0,0-.31,1.41l1.18,3.4s-3.36-2.94-4.37.93l.35,14.56L605,398l-1,6a9.79,9.79,0,0,0,0,3.51v.74a4.15,4.15,0,0,0,2.53,3.83l9,2.88,9.63,3.35L625,420l28.07,8.71.7,6.71.6,9.36L653.19,451l-.32,1.07.11.2-.09,1.42h0v.42L652,468l.3.05-1.59,19.15,1.95,76.26a8.24,8.24,0,0,0,6.72,7.89l-1.18.06L661,629.85h-.54l-12.3,4.56a3.5,3.5,0,0,0,1.22,6.79h27.29V629.85s2.07-42.31,1.15-58.36h9.05l2.77,58.36-12,10.46a3,3,0,0,0,2,5.21h9.31a3.08,3.08,0,0,0,1.3-.29l12.43-6a4.66,4.66,0,0,0,2.62-4.19v-5.19s2.12-43.48,1.11-59a8.24,8.24,0,0,0,5.05-7.91l-2.91-75.74v-13l-.32,0-.71-5.2.06-.11a12.24,12.24,0,0,0,10.69-12.17v-8.42l-.41,0Zm-63.39-4.5Z" fill="#fff"/>
            <g>
              <path d="M652.87,358.86l15.67-14.05,4.86-.54,14.05,2.7s4.86,1.62,7,4.87,9.72,24.85,9.72,24.85v18.92l1.08,4.86,1.08,2.7-15.67,21.07-31.34-3.78-12.7-13.24,1.62-5.94-1.62-5.4,4.33-8.11,2.7-22.7Z" fill="#ccc"/>
              <path d="M670.16,350.75s4.32,3.79,8.65,13c1.48,3.15,4.7,2.49,8.1,6.49,0,0,1.08,19.45-1.08,23.77,0,0-8.65,8.65-13.51,10.27h-2.16L668,351.84Z" fill="#f2f2f2"/>
              <polygon points="689.88 434.51 689.88 443.16 673.67 444.78 673.67 436.13 689.88 434.51" fill="#f6e105"/>
              <line x1="653.68" y1="399.12" x2="647" y2="406" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M670.14,350.53c-3.69,2-9.12,5.92-10.52,11.84-2.16,9.19-8.64,25.4,4.87,30.8l1.62,6.49,4.32,4.86" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M665.33,365.54l.9,5.9s-3,1.3-1.07,4.72" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <circle cx="674.82" cy="371.83" r="2.04" fill="#231815"/>
              <circle cx="660.8" cy="371.39" r="2.04" fill="#231815"/>
              <path d="M674,379.27l-10,.23S666.91,384.35,674,379.27Z" fill="#221714" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M657.73,449.91v-3.78l3.24-1.62,7,6.48h31.34v-4.86l18.91-1.08a91.69,91.69,0,0,0-7.56-36.21" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <polyline points="657.73 446.13 654.49 446.67 652.87 452.07 655.03 455.86" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M652.87,453.69v8.11l3.24,3.78,9.73.54L705.09,469a12.28,12.28,0,0,0,9.57-3.56h0a12.31,12.31,0,0,0,3.59-8.69v-8.42" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <polyline points="660.97 444.51 660.97 424.51 692.32 420.19 695.02 424.51 695.02 450.99" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <line x1="668" y1="429.38" x2="690.69" y2="426.14" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <line x1="666.92" y1="434.78" x2="666.92" y2="444.51" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <line x1="704.74" y1="407.22" x2="696.1" y2="416.41" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <polyline points="652.33 467.74 650.71 487.2 708.53 487.2 708.53 474.23" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M650.71,487.2l1.95,76.26a8.24,8.24,0,0,0,8.24,8h42.3a8.24,8.24,0,0,0,8.24-8.55l-2.91-75.74Z" fill="#221714" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M686.84,571.41l2.77,58.44h15.67s2.17-44.31,1.08-59.44Z" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M652.87,421.27l1.47,7.72a32.67,32.67,0,0,1,.55,7.32l-.4,10.36" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M689.61,629.85l-12,10.46a3,3,0,0,0-1,2.24h0a3,3,0,0,0,3,3h9.31a3.08,3.08,0,0,0,1.3-.29l12.43-6a4.66,4.66,0,0,0,2.62-4.19v-5.19Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M658.2,571.41,661,629.85h15.67s2.17-44.31,1.08-59.44Z" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M660.43,629.85l-12.3,4.56a3.52,3.52,0,0,0-2.29,3.29h0a3.51,3.51,0,0,0,3.51,3.5h27.29V629.85Z" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M682.86,392.63a17.63,17.63,0,0,1-9.73,1.08" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <polygon points="673.4 445.05 689.61 443.43 689.61 434.78 673.4 436.4 673.4 445.05" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <line x1="689.61" y1="439.24" x2="673.4" y2="440.73" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M669.35,349.94a22,22,0,0,1,6.81,8.49,25.8,25.8,0,0,0,13.54,12.83l.72.3" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M693.67,389.39a68.11,68.11,0,0,0-14,10.27c-6,5.94-13,5.94-18.92,10.26" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M673.13,344,684,346a17.6,17.6,0,0,1,13.55,11.66l5.36,15.85a33.42,33.42,0,0,1,1.52,15.11,39.79,39.79,0,0,0-.49,8.39c.54,3.78,5.48,5.4-.77,11.07l-7.87,9.46-4.87,7" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <line x1="668.27" y1="345.08" x2="653.14" y2="359.13" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M653.68,365.07l-2.7,22.7s-7.58,6.28-2.71,13.68" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <polygon points="659.47 389.85 659.62 398.04 665.35 406.84 670.43 404.52 666.11 399.66 664.49 393.17 659.47 389.85" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M696.37,398c-3.46,1.95-7.21,4.28-9.84,7.3-4.44,5.07-5.32,9.64-12.32,12.15" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M686.64,369.94s1.72,18.91-.54,24.31" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M652.6,395.88a95.1,95.1,0,0,1,5.94,9.72" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <polyline points="643 401 627 398 625 420 654 429" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M622.51,398.61,628,377.32l7.15-16a1.74,1.74,0,0,0,0-1.36h0a1.77,1.77,0,0,0-3.27,0l-1.9,3a1.13,1.13,0,0,1-1.76.35h0a1.12,1.12,0,0,1-.33-1.22l3.24-9.16a2.69,2.69,0,0,0-1-3.14c-.71-.46-1.57-.54-2.35.65L625.59,355l-.35-5.06a1.75,1.75,0,0,0-1.3-1.57h0a1.74,1.74,0,0,0-2.12,1.25l-1.07,4.12-1.93-4.3a1.86,1.86,0,0,0-3.07-.55,1.56,1.56,0,0,0-.31,1.41l1.18,3.4s-3.36-2.94-4.37.93l.35,14.56L605,398" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
              <path d="M604,404v4.25a4.15,4.15,0,0,0,2.53,3.83L625,418" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.17"/>
            </g>
          </g>
          <PulseCircle animationDelay="-0.5s" onClick={() => handleOnClick(1)} cx="425" cy="294" r="41" fill="#f6e105" style={{ mixBlendMode: 'darken' }}/>
          <PulseCircle onClick={() => handleOnClick(0)} cx="1416.5" cy="444.38" r="34.62" fill="#f6e105" style={{ mixBlendMode: 'darken' }}/>
          <PulseCircle animationDelay="-1s" onClick={() => handleOnClick(2)} cx="1578.5" cy="387.88" r="61.12" fill="#f6e105" style={{ mixBlendMode: 'darken' }}/>
          <g>
            <g>
              <path d="M2103.28,691a53.26,53.26,0,0,0,15.6-49.08L2111.31,604l-1.33.42v-.06l1.34-.36c-2.51-9-4.92-18.47-7.59-27.94l0-.11h0c-7-24.77-15.86-49.71-33.65-67.79-21.83-22.17-52.75-32.25-81.94-40.54l.83,2.35-1.43-1.33-5-6.65h0c16-7.9,17.67-23.62,12.9-32.55a14.12,14.12,0,0,1,2.46,6c-.09-.46,7.74-9.72,8.36-11.22,8.55-20.61-9.35-43.06-27.13-51.37a2.59,2.59,0,0,0-3.53.88,76.16,76.16,0,0,0-28.25-13.25c-26.88-5.88-52.31,6.12-64.79,26.07h0s-28.39,11.69-14.71,45.13v0l.3.65c.35.83.7,1.66,1.1,2.52l0-.09,2.29,4.93a7.93,7.93,0,0,0-1.36,1.79c-1.8,3.37,3.82,18.27,9.76,20.45l-.35-4.92,1.59,17.09c-26.22,11.62-47.38,20.08-63,55.6-9.59,21.86-14.75,39.2-19.68,62l-1.12,1.5v3.29l-14.11,55.91a42.77,42.77,0,0,0,10,39.44h0a42.79,42.79,0,0,0,34.92,13.68l8.8-.71,11.06,8.75-.45,1.58c-.27-1.4.32,17.8.32,17.8l-8.74,10c.28-.31,8.74-8,.9-.92l-6.81,24.32,2.11,12.67,1.25.85-.23.07a61.26,61.26,0,0,0,14.89,12.86,39.66,39.66,0,0,1-5.54-3.88s-6.09,30.57-12.17,74.8,9,102,8.82,122.44,8,33.25,10.07,46.58,7.59,19.82,1.28,34.64c-4.1,9.63,2.14,14.6,7,16.9l-11,38s-8.09,20.57,8,26.14c9.36,3.25,19.29,2.87,25.94,2a17.54,17.54,0,0,0,12.09-7.4c3.8-5.44,9.17-13.71,12-20.73,3.4-8.45,1.85-26.9.75-36.3h.43c9.17-8.28,5.48-14.61,1.49-21.94s1-22,4.13-36.63,21.88-116.07,21.88-116.07,3.19,81.31,3.66,96.29-1,37.31,3.19,52.49-4.66,17.67-6.27,39.86c-1.4,19.39,13,25.29,24.87,26.55v16.34c2.91,16.55,15.57,22.11,35.35,29.71,10.5,4,24.4,2.19,35.12-.43a20.33,20.33,0,0,0,12-31.26L2032.05,1123h-18.38c5.69-1.3,10.09-3,11.83-5.29,6.21-8.11-1.78-12.33-1-23.39s12.57-6.71,8.42-28.92,5.86-37.43,14.81-68.4S2059,914.1,2069,882.58c8.77-27.58,5.92-85.45,5.08-99.47v-9.93a42.8,42.8,0,0,0,2.75-22.09c-1-7.26-3.18-13.69-6-18.16-6.6-10.68-4.44-23.53-3.58-27.36A53.3,53.3,0,0,0,2103.28,691Z" fill="#fff"/>
              <path d="M1833,766.94l6.3-23.93C1830.65,752.38,1833,766.94,1833,766.94Z" fill="#f2f2f2"/>
            </g>
            <path d="M1893.53,499.29s27.45,46.46,38,50.68c0,0,44.34-44.34,46.45-57l4.22-27.45L2016,478.18s80.24,8.44,95,126.69l-79.18,20.06,2.11,19-42.23,6.33-29.57-10.56-19,4.23-8.45-21.12h-46.45l-8.45,8.45,6.34,21.11s-33.79-4.22-38-2.11V618.6l-50.68-25.34s16.89-57,31.67-82.35c0,0,23.23-31.68,52.79-35.9C1881.91,475,1890.36,489.79,1893.53,499.29Z" fill="#fff"/>
            <path d="M1847.07,706.23l2.11,21.11-12.67,19s-16.89,21.11,8.45,42.23l101.36,38,118.25-31.68,12.66-27.45L2075.12,740l-8.44-16.89V706.23l-130.92-12.67L1903,701h-8.45l-46.45,2.11Z" fill="#fff"/>
            <g>
              <path d="M1987.45,386.41c-7.52-12.67-25.77-22.77-40.11-25.91-39.66-8.68-76.22,21.56-72.58,58.87,0,0,3.23,14.9,5.07,25.52s-3.38,45.85,23.69,60.74c0,0,32.36,18.77,59.54-17.79" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.09"/>
              <path d="M1909,449.83l2.64,13.59s-8.94,18.1,8.38,16.4" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.09"/>
              <path d="M1926.41,491a115.79,115.79,0,0,1-12.62-.57" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.09"/>
              <circle cx="1896.18" cy="463.46" r="5.48" fill="#231815"/>
              <path d="M1882.82,447.48c2.69-.1,22.22-6.48,17.53,2.86a5.74,5.74,0,0,1-2.94,2.52c-3.27,1.44-7.7,1.37-11.11.43-1.24-.34-3.48-.9-4.14-2.17C1881.8,450.44,1881.31,447.54,1882.82,447.48Z" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.09"/>
              <path d="M1922.18,542.88s-22.46-25.75-27.6-42.53" fill="#f5f1df" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.09"/>
              <path d="M2111.31,604l7.57,37.89a53.26,53.26,0,0,1-15.6,49.08h0a53.28,53.28,0,0,1-40.72,14.42l-79.77-6.18-46-4.93s-5.59-.54-4.82-10.28a12.14,12.14,0,0,1,1.35-13.26L1910.83,664l-5.63-10.59,37.36,6.37,11-6.37" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.09"/>
              <path d="M1881.2,474.14c-26.22,11.62-47.38,20.08-63,55.6-9.92,22.62-15.11,40.4-20.2,64.44l40.31,22.45" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.09"/>
              <path d="M1887.05,494.87l-6.89,12.21,17.89,49.86s2.64-10.16,13.38-12.11" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.09"/>
              <path d="M1836.48,780c17.18,21.06,53.37,31.74,108,33,34.65.77,74.81-7.58,99.59-13.79a43.54,43.54,0,0,0,32.73-48.08c-1-7.26-3.18-13.69-6-18.16-7.84-12.7-3.14-23.54-3.14-23.54" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.09"/>
              <path d="M1847.17,712.92s5.38,18.94-5.76,28-8.45,26-8.45,26" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.09"/>
              <path d="M1798.05,594.18l-14.71,58.28a42.77,42.77,0,0,0,10,39.44h0a42.79,42.79,0,0,0,34.92,13.68l72.07-5.81s22.28,2.55,36.4-5.45" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.09"/>
              <path d="M1882.55,386.56s-30.2,12.43-13.31,48.33" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.09"/>
              <path d="M1878.05,435.35s-6.06,2.79-7.85,6.17S1874,459.79,1880,462" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.09"/>
              <path d="M2073.87,780s5.14,71.07-4.88,102.58S2056.66,966.06,2047.7,997s-19,46.19-14.81,68.4-7.67,17.85-8.42,28.92,7.25,15.28,1,23.39-46.32,9.26-58.66,9.13-31.4-4.65-29.8-26.83,10.42-24.67,6.27-39.86-2.72-37.51-3.19-52.49-3.66-96.29-3.66-96.29S1927.15,1014.34,1924,1029s-6.55,27.78-2.55,35.11,7.68,13.66-1.49,21.94h-54.25s-18.2-3.79-11.88-18.61.79-21.32-1.28-34.64-10.25-26.1-10.07-46.58-14.91-78.21-8.82-122.44,12.17-74.8,12.17-74.8,24.75,22.67,98.67,24C1944.5,813,2048.72,811.72,2073.87,780Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.09"/>
              <path d="M1861.71,1081.35l-11.9,41s-8.09,20.57,8,26.14c9.36,3.25,19.29,2.87,25.94,2a17.54,17.54,0,0,0,12.09-7.4c3.8-5.44,9.17-13.71,12-20.73,4.67-11.6,0-42.07,0-42.07Z" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.09"/>
              <path d="M1905.2,850.45c1.39,3.14,2.82,6.22,4.33,9.23a97.72,97.72,0,0,0,4.87,8.76,59.2,59.2,0,0,0,5.79,7.92,31.8,31.8,0,0,0,7.21,6.1l.12.07a1.65,1.65,0,0,1,.51.47,47.74,47.74,0,0,1,3.87,6.6,52.2,52.2,0,0,1,2.79,7,41.29,41.29,0,0,1,1.64,7.35l.21,1.86c0,.63,0,1.25.06,1.88a14.43,14.43,0,0,1,0,1.86l-.13,1.85a62.76,62.76,0,0,0-1.83-7.08c-.16-.57-.38-1.14-.59-1.7s-.39-1.14-.62-1.69c-.45-1.11-.92-2.21-1.42-3.3a70.42,70.42,0,0,0-3.29-6.34,64.18,64.18,0,0,0-3.86-5.88l.63.55a30.66,30.66,0,0,1-8.34-7l-1.66-2.09c-.52-.72-1-1.47-1.48-2.2a26.08,26.08,0,0,1-1.38-2.25c-.42-.77-.88-1.52-1.25-2.31a62.48,62.48,0,0,1-3.91-9.63A56.36,56.36,0,0,1,1905.2,850.45Z" fill="#f5f1df"/>
              <line x1="1903.7" y1="590.42" x2="1869.56" y2="590.42" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.09"/>
              <path d="M1847.19,561.2s7,18.15,5.72,37-6.11,24-5.72,36.76,0,15.83,0,15.83,25.89-1.36,39-.2l-5-16.12a8.56,8.56,0,0,1,7.47-11.08l37.43-3a8.54,8.54,0,0,1,8.73,5.59l11.58,31.68" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.09"/>
              <path d="M1910.81,653.4l10.45-9.1-1.42-1.43a8.35,8.35,0,0,0-8.94-1.9l-24.76,9.61v16.54" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.09"/>
            </g>
          </g>
        </g>
      </g>
    </SVG>
  );
};

export default Background;
