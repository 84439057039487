import React from 'react';
import Modal from 'react-modal';
import { FiX } from 'react-icons/fi'
import merge from 'lodash/merge'

import Box from './Box'
import Circle from './Circle'
import theme from './ThemeProvider/theme'

import {
  themeGet,
} from 'styled-system';

const defaultStyles = {
  overlay: {
    zIndex: theme.zOrder[3],
    backgroundColor: 'rgba(0,0,0,0.3)'
  },
  content: {
    width: '90%',
    maxWidth: '50em',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '3em',
    border: '1.5px solid',
    padding: '0',
    backgroundColor: '#F2F2F2',
    overflow: 'unset',
  }
};

Modal.setAppElement('#___gatsby')

export default ({ children, noXX, border, borderRadius, customStyles, ...props }) => (
  <Modal
    style={merge({}, defaultStyles, { content: customStyles })}
    {...props}
  >
    <Box p="1em">
      {!noXX && (
        <Circle width="4em" bg="white" position="absolute" border="1px solid" top="-0.5em" right="0em" onClick={props.onRequestClose}>
          <FiX size="2em" />
        </Circle>
      )}
      {children}
    </Box>
  </Modal>
)
