import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import injectReducer from '../../utils/injectReducer';
import reducer, { setIndex } from './reducer';

const key = 'result';

const mapStateToProps = (state) => {
  const result = state.get(key);
  return result ? result.toJS() : {};
};

export const withReducer = injectReducer({ key, reducer });

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setIndex,
}, dispatch);

export const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withReducer, withConnect);
