import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ContainerDimensions from 'react-container-dimensions';
import isIE from './utils/isIE'

import Box from './Box';

const parseViewbox = (vb) => {
  const [x1, y1, x2, y2] = vb.split(' ');
  return (y2 - y1) / (x2 - x1);
};

const Container = styled(Box)`
  svg {
    vertical-align: bottom;
    width: 100%;
  }
`;

const SVG = ({ viewBox, children, ...props }) => (
  <Container {...props}>
    {isIE ? (
      <ContainerDimensions>
        {({ width }) => (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} width={width} height={parseViewbox(viewBox) * width}>
            {children}
          </svg>
        )}
      </ContainerDimensions>
    ) : (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
        {children}
      </svg>
    )}
  </Container>
);

SVG.propTypes = {
  viewBox: PropTypes.string,
  children: PropTypes.node.isRequired,
};

SVG.defaultProps = {
  display: 'inline-block'
}

SVG.displayName = 'SVG';

export default SVG;
