import React from 'react';

import SVG from '../../components/SVG'
import PulseCircle from './PulseCircle'
import GrowUp from './GrowUp'

const Background = ({ handleOnClick }) => {
  return (
    <SVG viewBox="0 0 774 1358" display="block">
      <defs>
        <clipPath id="a">
          <rect x="514.07" y="673" width="345.6" height="493" fill="none"/>
        </clipPath>
        <clipPath id="b">
          <rect x="-122.22" y="637" width="272.43" height="368" fill="none"/>
        </clipPath>
        <clipPath id="c">
          <rect x="579.76" y="757" width="313.47" height="336" fill="none"/>
        </clipPath>
      </defs>
      <g>
        <g>
          <polygon points="-43.48 827.74 351.44 708.54 420.27 708.54 768.64 819.22 768.64 1145.36 -43.48 1145.36 -43.48 827.74" fill="#e0e4e5" stroke="gray" stroke-miterlimit="10" stroke-width="0.5"/>
          <path d="M49.45,798l75.65-22.73,37.84-11.36,37.89-11.16,151.55-44.65a.55.55,0,0,1,.18,0h67.75l.19,0,167.44,53,83.72,26.51,41.86,13.24c14,4.44,27.86,9,41.78,13.51l-.11,0,52.81.38-52.81.37-.11,0c-14-4.34-28-8.6-41.94-13L671.28,788.8l-83.72-26.51-167.43-53,.18,0H352.56l.17,0L201.18,753.92l-37.89,11.17-37.94,11Z" fill="gray"/>
          <path d="M726.23,325.92a43.65,43.65,0,0,0-5.13.22,31.37,31.37,0,0,0-5,.37,23.1,23.1,0,0,0-9.33,3.44,19.38,19.38,0,0,0-3.74,3.26,20.12,20.12,0,0,0-3.72,6.45,22.24,22.24,0,0,0-.75,2.4c-.34.78-.55,1.6-.83,2.41L697,347c0-.86,0-1.73,0-2.6s.11-1.75.26-2.62a24.35,24.35,0,0,1,.79-2.51,23.6,23.6,0,0,1,1-2.43,21.27,21.27,0,0,1,2.93-4.41,20.41,20.41,0,0,1,4-3.49,24.45,24.45,0,0,1,9.83-3.63,33.91,33.91,0,0,1,5.23-.39A24.61,24.61,0,0,1,726.23,325.92Z" fill="gray"/>
          <path d="M693.59,342.67a28.92,28.92,0,0,0-3.65.37c-.59,0-1.19,0-1.78.09s-1.18.15-1.77.23a18.33,18.33,0,0,0-6.6,2.51,17.22,17.22,0,0,0-5.12,4.83l-.54.71c-.17.24-.3.52-.45.77l-.87,1.56c-.72,1-1.27,2.06-2,3.1.12-.6.25-1.21.39-1.82a16.57,16.57,0,0,1,.48-1.84l.92-1.64a8.21,8.21,0,0,1,.48-.81l.56-.76a18.48,18.48,0,0,1,5.48-5.16,19.47,19.47,0,0,1,7-2.68c.62-.08,1.25-.18,1.87-.25s1.26-.12,1.88-.1A14.8,14.8,0,0,1,693.59,342.67Z" fill="gray"/>
          <path d="M671.25,366.29a10.25,10.25,0,0,0-3.61-.58,7.26,7.26,0,0,0-3.42.39,9.63,9.63,0,0,0-5.1,4.66,9.46,9.46,0,0,0-.89,6.84,8,8,0,0,0,1.6,3.08,11.64,11.64,0,0,0,2.4,2.79,10.34,10.34,0,0,1-3.36-2,9.37,9.37,0,0,1-1.85-3.55,10.75,10.75,0,0,1,1-7.75,10.91,10.91,0,0,1,5.78-5.26,8.92,8.92,0,0,1,4-.47A8.32,8.32,0,0,1,671.25,366.29Z" fill="gray"/>
          <path d="M658.45,379.8a6.62,6.62,0,0,0-3.68,4.7,15,15,0,0,0-.34,3,8.43,8.43,0,0,0,.58,2.91,7,7,0,0,0,4.26,3.83,11.57,11.57,0,0,0,3,.64c1,.1,2,0,3.13,0l25.7-.28,25.71-.28c8.56-.22,17.14.1,25.71.34-8.56.43-17.12.94-25.69.91l-25.69.28-25.69.28a30.13,30.13,0,0,1-3.29,0,12.47,12.47,0,0,1-3.29-.7,8.24,8.24,0,0,1-5-4.53,9.33,9.33,0,0,1-.68-3.36,7.82,7.82,0,0,1,.53-3.35A6.89,6.89,0,0,1,658.45,379.8Z" fill="gray"/>
          <path d="M697,358.13a10.7,10.7,0,0,0-1.45,1.68,8.41,8.41,0,0,0-1.43,1.49,11.21,11.21,0,0,0-1.86,3.72,15.88,15.88,0,0,0-.59,4.19c0,.71,0,1.43.08,2.14s-.19,1.47-.34,2.24c-.34-.68-.62-1.4-.91-2.13,0-.76-.08-1.51-.08-2.27a17.09,17.09,0,0,1,.64-4.53,12.54,12.54,0,0,1,2.07-4.13,10.75,10.75,0,0,1,1.61-1.7A6.32,6.32,0,0,1,697,358.13Z" fill="gray"/>
          <path d="M-29.19,130.62a42.49,42.49,0,0,1,8.42-11.84,46.09,46.09,0,0,1,12.24-8,58.75,58.75,0,0,1,28.62-4.53,66.62,66.62,0,0,1,27.7,8.57,55.86,55.86,0,0,1,11.6,8.87,45,45,0,0,1,8.06,12.09,47.26,47.26,0,0,0-8.94-11.21,54.67,54.67,0,0,0-11.35-8.66A65.27,65.27,0,0,0,20,107.5a57.33,57.33,0,0,0-28,4.43,44.09,44.09,0,0,0-11.89,7.78A44.69,44.69,0,0,0-29.19,130.62Z" fill="gray"/>
          <path d="M70.19,127.74A35.42,35.42,0,0,1,78,128a46.53,46.53,0,0,1,7.46,2.28,41.07,41.07,0,0,1,13.1,8.36,38.31,38.31,0,0,1,5.11,5.9c.79,1,1.43,2.17,2.14,3.27s1.29,2.26,1.8,3.45l.83,1.77.71,1.82c.5,1.2.87,2.44,1.27,3.68s.48,2.55.72,3.82.32,2.57.46,3.84l-1.09-3.69c-.44-1.21-.8-2.43-1.29-3.61s-.74-2.43-1.24-3.59l-.7-1.77-.8-1.73c-.49-1.16-1.17-2.24-1.75-3.36s-1.31-2.17-2.08-3.17a37,37,0,0,0-5-5.72,39.54,39.54,0,0,0-12.7-8.1,41.82,41.82,0,0,0-7.25-2.21A43,43,0,0,0,70.19,127.74Z" fill="gray"/>
          <path d="M113.84,162a15,15,0,0,1,3.5.67c.55.24,1,.56,1.58.82s1,.63,1.52.94a21.36,21.36,0,0,1,5.19,4.85,19.08,19.08,0,0,1,3.25,6.32,21.78,21.78,0,0,1,.79,3.47,11.89,11.89,0,0,1-.14,1.79c-.06.59-.14,1.18-.23,1.75-.34-1.14-.49-2.28-.87-3.36a21.34,21.34,0,0,0-.74-3.28,17.88,17.88,0,0,0-3-5.93,20.29,20.29,0,0,0-4.9-4.57,23.6,23.6,0,0,0-2.94-1.66A29.93,29.93,0,0,0,113.84,162Z" fill="gray"/>
          <path d="M129.3,182.59a23.57,23.57,0,0,1,5.28,1.18,22.84,22.84,0,0,1,4.94,2.36,18.39,18.39,0,0,1,4,3.85,16.13,16.13,0,0,1,1.44,2.39l.61,1.26L146,195a15,15,0,0,1,.59,2.72l.11.68c0,.36,0,.53,0,.8a7.11,7.11,0,0,1-.31,1.44,6.65,6.65,0,0,1-4,4.12,5.94,5.94,0,0,1-1.44.35,10.06,10.06,0,0,1-1.42.07H99.24c-3.58-.21-7.16-.38-10.74-.63,3.58-.25,7.16-.42,10.74-.63h40.25a10,10,0,0,0,1.26-.05,4.59,4.59,0,0,0,1.16-.29,5.39,5.39,0,0,0,3.24-3.34,5.66,5.66,0,0,0,.25-1.17c0-.17,0-.45,0-.54l-.11-.64a12.56,12.56,0,0,0-.54-2.52l-.39-1.23-.56-1.16a14.15,14.15,0,0,0-1.33-2.2,16.77,16.77,0,0,0-3.71-3.57A24.8,24.8,0,0,0,129.3,182.59Z" fill="gray"/>
          <g>
            <path d="M353.23,743.35l-7.5,4.94h0l-8.07,3.89h0l-7.43,5.05h0l-8.47,3.17,0,0-7.45,5h0l-15.86,8.26h0l-7.61,4.74h0l-7.92,4.16h0l-7.59,4.75h0l-8,4.12-8.08,3.88.06,0-7.44,5.05h0l-7.78,4.45L236.16,809l-7.79,4.38-7.74,4.49h0l0,0-6.07,2.87c-2,.94-4,2-6,3s-4,2-6,3l-5.93,3.14.07,0-7.36,5.2-.06,0-.06,0-8.34,3.4.08,0-5.79,3.42c-1.91,1.18-3.92,2.17-5.9,3.22s-4,2.06-5.92,3.17l-5.87,3.27h0l-8.27,3.52,0,0-7.9,4.22,0,0L134,864.61l-.08-.1,7.16-5.54h0l7.8-4.41h0l8.22-3.64,0,0,5.82-3.36c1.93-1.14,3.91-2.18,5.87-3.25s4-2.1,5.84-3.3l5.73-3.49,0,0,0,0L188.7,834l-.12.06,7.35-5.19,0,0h0l5.95-3.15c2-1.06,4-2,6-3s4-2,6-3l6.09-2.85,0,0,7.78-4.44,7.84-4.33,7.94-4.12,7.79-4.39,0,0,7.47-5,0,0h0l8.09-3.82,8-4.07h0l7.62-4.71h0l7.94-4.11h0l7.64-4.69h0L314.07,765h0l7.5-4.94h0l8.49-3.1,0,0,7.47-5h0l8.11-3.82h0l7.53-4.87Z" fill="#fff"/>
            <path d="M313.09,751l-7.94,4.07h0l-8.57,2.56h0l-7.91,4.13h0l-8.59,2.51h0l-6,2.89c-2,1-4,1.87-6.08,2.78s-4.05,1.85-6.14,2.61L255.65,775h0l-8,4h-.05l-8.45,2.83,0,0-15.94,8h0l-16.78,6,.06,0-8,4-8.2,3.52,0,0h0l-8.6,2.47.06,0-8.2,3.42.06,0-7.84,4.31,0,0-.05,0-8.49,2.74.08,0-7.93,4.12,0,0,0,0-8.72,2.16.06,0L124.4,830l-.06-.11c5.32-2.65,10.62-5.34,16-8h.06l8.67-2.37-.1,0,7.86-4.24,0,0,0,0,8.45-2.86-.1.05,7.8-4.39,0,0h0l8.2-3.42,0,0h0l8.61-2.47-.08,0,8.18-3.47,8-3.92,0,0h0l16.83-5.83-.05,0,16-7.86,0,0h0l8.48-2.77,0,0,8-3.93h0l6.23-2.37c2.1-.75,4.11-1.69,6.15-2.56s4.09-1.73,6.09-2.71l6-2.83h0l8.61-2.43h0l7.95-4.05h0l8.59-2.47h0l8-4Z" fill="#fff"/>
            <path d="M408.45,732.25l7.67,5h0l8.24,3.93,8.49,3.39h0l7.93,4.52,7.83,4.71,0,0,8.43,3.52h0l7.82,4.73-.06,0,8.64,3.13h0l8.21,3.95,0,0,0,0,7.47,5.41-.08-.05,8.49,3.41h0l8.06,4.28,8,4.42-.05,0,8.55,3.29,0,0,0,0,7.67,5-.06,0c4.11,2,8.29,3.86,12.28,6.07s8.14,4.11,12.23,6.11,8.05,4.31,12.13,6.32,8.22,3.93,12.35,5.85l0,0,0,0,7.63,5.1-.08-.05,8.25,3.88,8.43,3.5.06,0,0,0,7.52,5.3-.11,0,8.63,3.17h0l0,0,7.79,4.77,7.87,4.63,0,0,8.51,3.43h0l8,4.46-.06.11-8.08-4.25h0l-8.6-3.21h0l-7.93-4.54L619,841l.07,0L610.39,838l-.06,0-.05,0-7.59-5.2.11.06-8.5-3.4q-4.17-1.9-8.31-3.85l0,0,0,0-7.63-5.11.09.05c-4.14-1.92-8.33-3.75-12.38-5.87s-8.1-4.21-12.12-6.36-8.21-4-12.19-6.2-8.12-4.14-12.21-6.14l0,0,0,0-7.64-5.05.09,0-8.54-3.34,0,0h0L505.32,783l-8-4.31,0,0-8.48-3.45,0,0,0,0-7.44-5.45.06,0-8.19-4,0,0-8.62-3.18h0l0,0-7.78-4.79h0l-8.4-3.58h0l-7.79-4.76-7.9-4.58h0l-8.46-3.45-8.22-4h0l-7.64-5.08Z" fill="#fff"/>
            <path d="M478.39,758.3l8.22,4.13h0l8.9,2.37h0L503.7,769l0,0,6.48,2.29c2.17.74,4.31,1.57,6.45,2.37s4.3,1.59,6.42,2.45l6.39,2.53h0l8.22,4.1-.06,0,8.81,2.62h0l8.55,3.26h0l8.29,3.92-.05,0,8.69,2.91,8.76,2.72h0l0,0,8.34,3.82-.07,0,8.74,2.81h.07l8.31,3.83,8.48,3.43h.05l8.15,4.27-.05,0,8.55,3.29-.05,0,9,2.32h.06l0,0,8,4.75-.06,0,8.86,2.58,0,.12-9-2.25h0l0,0L640,822.62l.1,0-9-2.1h0l0,0-8.61-3.16h0l-8.22-4.15.05,0-8.51-3.42L597.35,806l.07,0-8.74-2.82h0l0,0-8.3-3.9.07,0-8.74-2.8-8.68-3h-.06l-8.26-4,0,0-8.53-3.34h0l-8.79-2.69h0l0,0-8.19-4.16h0l-6.37-2.58c-2.11-.88-4.25-1.68-6.39-2.51s-4.26-1.67-6.41-2.44l-6.45-2.35h0l-8.13-4.31h0l-8.88-2.46h0l-8.17-4.2Z" fill="#fff"/>
            <path d="M382.85,757.85l-4.77,8h0l-5.86,7.31,0,0-4,8.44h0l-4.62,8v0h0L358,797.06l0-.08-3.64,8.63v0l0,0-9.44,16,0,0,0,0-5.8,7.34.07-.11-3.94,8.45,0,0,0,.05L329.69,845l-5.14,7.73.05-.08-4,8.43,0,0,0,0L316.72,867c-1.26,2-2.61,3.84-3.94,5.75s-2.69,3.78-3.9,5.76l-3.75,5.87-.11-.06,3.46-6c1.12-2.05,2.44-4,3.7-5.91s2.55-3.88,3.75-5.87l3.69-5.9,0,.06,3.87-8.5,0,0,0-.05,5.12-7.78,5.5-7.51-.06.1,4-8.4,0-.06,0,0,5.9-7.27,0,.06,9.6-15.88,0,0,3.73-8.6,0,0,0,0,5.72-7.38,0,0,4.73-8h0l4.07-8.38v0l0,0,6-7.22h0l4.87-7.89Z" fill="#fff"/>
            <path d="M404.17,809.55l1.21,8.68,0-.05,3.45,8h0l2.84,8.19,2.36,8.34,2.26,8.36,2.08,8.41,0-.08c.54,1.34,1,2.7,1.56,4.05s1,2.71,1.51,4.07l0,.05v0c.56,2.84,1.16,5.66,1.67,8.51s1.12,5.67,1.62,8.53l-.13,0c-.8-2.78-1.55-5.57-2.28-8.37s-1.35-5.62-2-8.44l0,.09c-.55-1.34-1.09-2.68-1.61-4s-1.06-2.69-1.57-4v-.08l-1.9-8.44L413.12,843l-2.19-8.36-2.66-8.23v0L405,818.32v-.05l-1-8.7Z" fill="#fff"/>
          </g>
          <path d="M757.53,351.91s28.06,3,28.06,33.07" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
          <path d="M47.15,380.92a24.71,24.71,0,0,1,5.13-1,33.88,33.88,0,0,1,5.22.39,24.55,24.55,0,0,1,9.84,3.63,20.7,20.7,0,0,1,4,3.49,21.23,21.23,0,0,1,2.92,4.41,23.67,23.67,0,0,1,1,2.43,22.08,22.08,0,0,1,.78,2.51c.16.87.18,1.76.27,2.62s0,1.74,0,2.6l-.67-2.49c-.28-.81-.49-1.63-.83-2.41a24.72,24.72,0,0,0-.75-2.4,20.37,20.37,0,0,0-1-2.3,19.55,19.55,0,0,0-2.75-4.15A19.32,19.32,0,0,0,66.65,385a23.14,23.14,0,0,0-9.34-3.44,31.29,31.29,0,0,0-5-.37A43.55,43.55,0,0,0,47.15,380.92Z" fill="gray"/>
          <path d="M79.8,397.67a14.62,14.62,0,0,1,3.66-.89c.63,0,1.26.07,1.88.1s1.25.17,1.87.25a19.47,19.47,0,0,1,7,2.68A18.36,18.36,0,0,1,99.73,405l.57.76c.18.25.32.54.48.81l.92,1.64a16.53,16.53,0,0,1,.47,1.84c.15.61.28,1.22.39,1.82-.72-1-1.27-2.12-2-3.1l-.88-1.56a7.23,7.23,0,0,0-.45-.76l-.53-.72a17.37,17.37,0,0,0-5.13-4.83,18.19,18.19,0,0,0-6.6-2.51c-.58-.08-1.17-.17-1.76-.23S84,398,83.44,398A28.76,28.76,0,0,0,79.8,397.67Z" fill="gray"/>
          <path d="M102.13,421.29a8.36,8.36,0,0,1,3.47-1.84,8.88,8.88,0,0,1,4,.47,11,11,0,0,1,5.79,5.26,10.75,10.75,0,0,1,1,7.75,9.25,9.25,0,0,1-1.86,3.55,10.13,10.13,0,0,1-3.36,2,11.83,11.83,0,0,0,2.4-2.79,8.06,8.06,0,0,0,1.61-3.08,9.56,9.56,0,0,0-.89-6.84,9.69,9.69,0,0,0-5.11-4.66,7.24,7.24,0,0,0-3.42-.39A10.29,10.29,0,0,0,102.13,421.29Z" fill="gray"/>
          <path d="M114.94,434.8a6.93,6.93,0,0,1,4.74,4.36,8,8,0,0,1,.53,3.35,9.33,9.33,0,0,1-.68,3.36,8.27,8.27,0,0,1-5,4.53,12.55,12.55,0,0,1-3.29.7,30.14,30.14,0,0,1-3.29,0l-25.69-.28-25.7-.28c-8.56,0-17.12-.48-25.68-.91,8.57-.24,17.14-.56,25.71-.34l25.7.28,25.71.28c1.08,0,2.12.1,3.13,0a11.4,11.4,0,0,0,3-.64,7,7,0,0,0,4.26-3.83,8,8,0,0,0,.58-2.91,13.62,13.62,0,0,0-.34-3A6.61,6.61,0,0,0,114.94,434.8Z" fill="gray"/>
          <path d="M76.36,413.13a6.32,6.32,0,0,1,2.26.7,11.75,11.75,0,0,1,1.61,1.7,12.32,12.32,0,0,1,2.06,4.13,17.52,17.52,0,0,1,.65,4.53c0,.76,0,1.51-.09,2.27a21.3,21.3,0,0,1-.91,2.13c-.15-.77-.24-1.51-.33-2.24s.07-1.43.08-2.14a16.31,16.31,0,0,0-.6-4.19,11.19,11.19,0,0,0-1.85-3.72,9.23,9.23,0,0,0-1.43-1.5A12.38,12.38,0,0,0,76.36,413.13Z" fill="gray"/>
          <path d="M15.85,406.91s-28,3-28,33.07" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
          <g>
            <g opacity="0.5">
              <path d="M672.38,790.9l-.86-29.36c-.08-2.45-.15-4.9-.15-7.35s0-4.89,0-7.34l.11-14.68c.18-19.58-.78-39.15-.32-58.74l.52-58.73c0-9.79.14-19.57.08-29.36L671.47,556a.63.63,0,0,1,.48-.61l42.84-10.43,10.71-2.63c3.57-.88,7.13-1.8,10.72-2.6l21.49-4.94h0a.23.23,0,0,1,.18,0l15.85,8.74-16.1-8.27.18,0-21.33,5.58c-3.55.95-7.13,1.79-10.7,2.66l-10.71,2.63-42.85,10.44.48-.62.26,29.37c.06,9.79-.06,19.59-.08,29.38l-.52,58.73c-.45,19.57.5,39.15.32,58.72l-.1,14.69c0,4.89-.26,9.79-.21,14.68Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M671.72,600.74l-24.59-.21.58-.56c.16,4,.09,8,.13,12l.07,12,.19,23.92c.17,16,.22,31.9.31,47.85l-.57-.62,23.95,2h-.11l39.55-3.5,9.91-.6c3.31-.17,6.62-.26,9.93-.39l19.84-.71-19.78,1.61c-3.3.25-6.6.56-9.9.76l-9.9.57-39.54,3.51h-.1l-24-2a.63.63,0,0,1-.58-.62c-.09-15.95-.14-31.9-.31-47.85l-.19-23.92-.07-12c0-4-.12-8,0-12v0a.55.55,0,0,1,.56-.53h0Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M685.35,721.69c.33,3.12.55,6.25.81,9.38l.18,9.39.36,18.79L686.43,778l-.14,9.4c-.26,3.12-.46,6.25-.76,9.38-.21-3.13-.33-6.27-.49-9.4l.14-9.39.27-18.78-.36-18.77-.19-9.39C685.05,728,685.15,724.82,685.35,721.69Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M693,721.69c.24,2.77.37,5.55.56,8.32l-.08,8.32L693.31,755l.32,16.63.16,8.32c-.16,2.77-.27,5.55-.47,8.32-.31-2.76-.52-5.53-.78-8.3l-.16-8.32L692.06,755l.15-16.65.06-8.32C692.5,727.23,692.69,724.46,693,721.69Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M671.92,715.23c3.27-.44,6.55-.78,9.83-1.16l9.88-.53,19.74-1.06,19.76-.53,9.88-.27c3.3.13,6.6.2,9.9.36-3.28.35-6.57.6-9.86.9l-9.88.26-19.75.53-19.74,1.06-9.86.54C678.52,715.3,675.22,715.32,671.92,715.23Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M676.62,721.53c3.13-.29,6.26-.48,9.39-.71l9.39-.08,18.79-.16,18.78-.43,9.39-.21c3.14.14,6.27.23,9.41.41-3.13.32-6.26.56-9.38.84l-9.4.21-18.78.43-18.79.16-9.4.09C682.89,721.9,679.76,721.77,676.62,721.53Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M755.19,773.21c-1.24.47-2.49.92-3.74,1.35l-1.88.67a12.46,12.46,0,0,1-1.89.57l-7.68,2-15.35,4,.47-.61V796c0,.5,0,1,0,1.49s-.06,1-.09,1.49c-.18,2-.33,4-.54,6-.22-2-.37-4-.55-6,0-.5-.1-1-.09-1.49s0-1,0-1.49V781.15a.63.63,0,0,1,.47-.61l15.35-4,7.67-2a12.62,12.62,0,0,1,1.94-.41l2-.33Q753.23,773.5,755.19,773.21Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M727.82,784.3c1.19,0,2.37,0,3.54,0l3.42.66,6.84,1.3,6.85,1.31,3.42.65c1.1.43,2.21.8,3.3,1.27-1.19,0-2.36,0-3.54,0l-3.42-.65-6.84-1.31-6.84-1.3-3.43-.65C730,785.17,728.91,784.77,727.82,784.3Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M734.47,580.18c-4.62,1.31-9.26,2.54-13.89,3.8-2.3.67-4.66,1.12-7,1.68l-7,1.63-28,6.47.41-.32L675,601.05l-.7-.9,31.13-7L721.08,590c5.25-.86,10.5-1.77,15.75-2.6-5.15,1.32-10.33,2.54-15.49,3.81l-15.62,3.21-31.11,7a.64.64,0,0,1-.75-.48.66.66,0,0,1,0-.42l3.94-7.62a.65.65,0,0,1,.41-.32l28-6.48,7-1.62c2.35-.51,4.66-1.15,7-1.56C725,582,729.74,581,734.47,580.18Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M679.79,599.49c.22,3.69.35,7.38.52,11.07.13,1.85,0,3.69,0,5.54l0,5.53-.72-.62,28.54-4.46,28.41-5.25-.51.62,0-4.86c-.06-1.62,0-3.25.1-4.87.14-3.24.26-6.49.44-9.73.22,3.24.38,6.48.56,9.73.08,1.62.2,3.24.16,4.86l0,4.87a.64.64,0,0,1-.51.62l-28.43,5.25-28.56,4.46a.62.62,0,0,1-.71-.52.34.34,0,0,1,0-.1l0-5.54c0-1.84-.06-3.69.08-5.53C679.38,606.87,679.54,603.18,679.79,599.49Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M679.54,621.44a11.48,11.48,0,0,1,1.59.56l1.18,1,2.36,2.06,2.35,2.07,1.18,1c.27.49.54,1,.77,1.5a14.86,14.86,0,0,1-1.59-.56l-1.18-1-2.36-2.06L681.48,624l-1.18-1A14.2,14.2,0,0,1,679.54,621.44Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M736.76,612.39a17.36,17.36,0,0,1,1.64,1.1l1.13,1.48,2.28,3,2.27,3,1.13,1.48c.23.61.46,1.21.64,1.86-.57-.34-1.1-.72-1.63-1.1l-1.14-1.48-2.27-3-2.27-3-1.14-1.47C737.17,613.65,736.94,613,736.76,612.39Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M689.46,598.05c.28.87.47,1.74.67,2.61l0,2.63.07,5.24.07,5.25,0,2.63c-.17.87-.35,1.75-.59,2.63-.26-.87-.46-1.75-.66-2.62l0-2.62-.07-5.25-.07-5.25,0-2.62C689,599.8,689.21,598.92,689.46,598.05Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M708.67,594.18c.26.88.44,1.75.63,2.63v15.74c-.19.88-.38,1.75-.63,2.62-.25-.87-.43-1.75-.62-2.62V596.81C708.22,595.93,708.41,595.06,708.67,594.18Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M698.11,596.15c.28.7.48,1.4.69,2.1l.06,2.11.12,4.23.13,4.23.06,2.11c-.17.71-.33,1.42-.56,2.14-.27-.7-.48-1.4-.69-2.1l-.06-2.11-.13-4.23-.12-4.23-.07-2.12A21,21,0,0,1,698.11,596.15Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M727.52,591.43c.25.7.43,1.41.61,2.12l0,2.11-.05,4.23,0,4.23,0,2.12c-.2.7-.39,1.4-.65,2.1-.24-.7-.42-1.41-.6-2.12l0-2.11,0-4.23.05-4.23,0-2.12C727.06,592.83,727.25,592.13,727.52,591.43Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M716.22,592.29c.27.7.47,1.4.67,2.1l0,2.11.08,4.23.07,4.23,0,2.12c-.18.71-.35,1.41-.59,2.12-.26-.7-.46-1.4-.66-2.1l0-2.12-.08-4.22-.07-4.23,0-2.12C715.8,593.7,716,593,716.22,592.29Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M681.21,613.45c2.26-.68,4.55-1.26,6.83-1.9l6.94-1.27,13.89-2.56,14-1.68,7-.85c2.36-.07,4.72-.19,7.08-.22-2.3.53-4.62,1-6.92,1.46l-7,.85-14,1.68-13.87,2.56-6.93,1.28C685.91,613,683.57,613.29,681.21,613.45Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M740.74,588.15c.52.37,1,.75,1.54,1.14l.74.6a4.57,4.57,0,0,1,.63.69l2.44,2.84a.62.62,0,0,1,.15.4l.14,8.11.08,4.06,0,1c0,.34-.11.68-.17,1-.12.68-.26,1.36-.41,2-.18-.67-.34-1.35-.48-2-.07-.34-.15-.67-.21-1v-1l-.07-4-.14-8.12.15.4-2.44-2.84a5.23,5.23,0,0,1-.59-.73l-.47-.83C741.32,589.29,741,588.73,740.74,588.15Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M734.58,639.15c-4.69,1-9.4,1.94-14.1,2.92-2.34.52-4.73.8-7.09,1.23l-7.09,1.17-28.21,5.69.43-.32L675,656.36l-.65-.91,31.19-5.17,15.6-2.61c5.23-.67,10.46-1.39,15.7-2-5.16,1.12-10.33,2.15-15.5,3.22l-15.59,2.63-31.2,5.17a.62.62,0,0,1-.72-.51.59.59,0,0,1,.07-.4l3.51-6.52a.68.68,0,0,1,.43-.32l28.23-5.69,7.1-1.18c2.38-.36,4.72-.86,7.11-1.12C725.05,640.34,729.81,639.7,734.58,639.15Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M680.13,655.57c.17,3.46.27,6.92.39,10.38,0,.86.08,1.73.08,2.59l-.05,2.6-.07,5.18-.71-.63,28.58-3.88,28.7-2.89-.56.63L736.4,664c0-1.84-.13-3.69,0-5.53.12-3.69.21-7.39.37-11.08.29,3.69.5,7.37.74,11.06.17,1.84.11,3.69.16,5.53l.09,5.53a.63.63,0,0,1-.56.64l-28.68,2.88-28.56,3.88a.62.62,0,0,1-.7-.53.31.31,0,0,1,0-.09l.07-5.19,0-2.59c0-.87.1-1.73.15-2.59C679.68,662.48,679.87,659,680.13,655.57Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M679.65,676.26a15,15,0,0,1,1.6.55l1.18,1,2.36,2.06,2.36,2.07,1.18,1c.27.49.54,1,.77,1.5a15.68,15.68,0,0,1-1.59-.56l-1.18-1L684,680.86l-2.36-2.07-1.18-1A14.25,14.25,0,0,1,679.65,676.26Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M736.35,669.17a13.63,13.63,0,0,1,1.37.92l.84,1.27,1.69,2.54,1.69,2.54.84,1.27c.13.53.25,1.06.33,1.62a13.63,13.63,0,0,1-1.37-.92l-.84-1.27-1.69-2.54-1.69-2.54-.85-1.27A13.66,13.66,0,0,1,736.35,669.17Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M689.06,653.86c.28.87.49,1.74.69,2.61l.06,2.62.12,5.25.12,5.25.06,2.62c-.17.88-.33,1.76-.57,2.64-.27-.87-.47-1.74-.68-2.61l-.06-2.62-.12-5.25-.13-5.25-.06-2.62C688.65,655.62,688.82,654.74,689.06,653.86Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M708.73,650.85c.26.88.43,1.76.61,2.63l0,2.63,0,5.24,0,5.25,0,2.63c-.19.87-.39,1.74-.64,2.62-.25-.88-.43-1.76-.61-2.63l0-2.63,0-5.25,0-5.24,0-2.63C708.27,652.6,708.46,651.73,708.73,650.85Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M698.87,652.39c.25.71.42,1.42.6,2.13l0,2.11-.07,4.23-.06,4.23,0,2.11c-.2.71-.39,1.41-.66,2.11-.24-.71-.41-1.42-.59-2.13l0-2.11.07-4.23.07-4.23,0-2.11C698.4,653.79,698.6,653.09,698.87,652.39Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M727.75,648.1c.24.71.4,1.42.57,2.13l-.06,2.11-.11,4.23L728,660.8l0,2.12c-.21.7-.42,1.4-.69,2.1-.23-.72-.39-1.43-.57-2.14l.06-2.11.11-4.23.12-4.23,0-2.11A21.44,21.44,0,0,1,727.75,648.1Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M715.91,649a20.58,20.58,0,0,1,.71,2.09l.08,2.11.17,4.23.16,4.23.09,2.12c-.16.71-.32,1.42-.54,2.13-.28-.69-.49-1.39-.71-2.09l-.09-2.11-.16-4.23-.17-4.23-.09-2.11C715.52,650.38,715.68,649.67,715.91,649Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M681.16,668.08c2.29-.59,4.59-1.08,6.89-1.62l7-1,14-2L722.86,661l6.94-1.27c2.35-.22,4.69-.48,7.05-.66-2.27.68-4.56,1.26-6.83,1.89l-6.94,1.27-13.89,2.54-14,2-7,1C685.87,667.84,683.52,668,681.16,668.08Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M741,642.9c.55.37,1.09.75,1.61,1.15l.79.6a7.49,7.49,0,0,1,.66.71l2.59,2.88a.61.61,0,0,1,.16.42V662l-.19,1c-.13.68-.28,1.36-.44,2-.17-.69-.32-1.37-.44-2l-.19-1V648.66l.16.42-2.59-2.88a8.8,8.8,0,0,1-.64-.73l-.52-.84C741.6,644.06,741.27,643.48,741,642.9Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M713.43,721.69c.26,1.54.46,3.07.65,4.61v4.62l0,9.23,0,9.23,0,4.61c-.21,1.54-.37,3.08-.61,4.62-.26-1.54-.43-3.08-.65-4.61v-4.62l0-9.23,0-9.23,0-4.61C713,724.77,713.17,723.23,713.43,721.69Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M726.61,721.26c.24,1.18.4,2.37.55,3.55l-.08,3.55-.16,7.08-.16,7.08-.09,3.55c-.23,1.17-.42,2.35-.7,3.52-.23-1.18-.36-2.37-.55-3.55l.08-3.54.16-7.09.17-7.08.07-3.54C726.11,723.61,726.33,722.43,726.61,721.26Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M671.89,573.17c3.54-.92,7.09-1.78,10.63-2.66.89-.21,1.77-.45,2.66-.64l2.69-.52,5.36-1.07L714.7,564l42.94-8.48h0a.72.72,0,0,1,.33,0c1.37.51,2.75,1,4.12,1.47l4,1.66c2.7,1.1,5.4,2.19,8.09,3.33-2.8-.82-5.59-1.68-8.38-2.53l-4.18-1.28c-1.39-.46-2.74-1-4.12-1.47l.33,0-42.94,8.48-21.46,4.28-5.37,1.07-2.68.55c-.89.17-1.8.29-2.7.43C679.12,572.1,675.51,572.68,671.89,573.17Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M758,572.56c.31,6.1.51,12.21.76,18.32l.12,18.32-.17,36.65L758,682.48l0,18.31c-.21,6.11-.36,12.21-.61,18.32-.26-6.11-.43-12.21-.64-18.32l0-18.33.77-36.63.17-36.63-.13-18.32C757.7,584.77,757.82,578.67,758,572.56Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M701.45,547.38c-.17-2.63-.3-5.25-.43-7.88l-.19-3.94c0-1.31,0-2.62,0-3.94a.62.62,0,0,1,.43-.59h0L729.5,522l28.07-9.57a.63.63,0,0,1,.55.08l4.11,2.74,2.07,1.37c.68.47,1.33,1,2,1.47,2.64,2,5.31,3.92,7.93,5.93-2.86-1.65-5.68-3.37-8.52-5.06-.71-.43-1.42-.83-2.12-1.28l-2.05-1.38-4.12-2.75.55.07-28.08,9.57-28.24,9.08.44-.59c0,1.31,0,2.62,0,3.93l-.2,3.94C701.75,542.13,701.62,544.75,701.45,547.38Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M671.68,555.54l-.34-33.9.73.52c-2.47,1-4.94,1.91-7.42,2.83l-7.47,2.73-14.92,5.49.41-.59.24,62c0,2.58,0,5.16.1,7.74,0,1.3.14,2.59.14,3.88l-.06,3.87-.23,15.5-.23,15.5-.1,15.49-.09,15.49,0,3.87.08,3.87.15,7.75L643,703l.15,7.74-.05,7.75-.11,15.5c0,2.58,0,5.16-.05,7.74s-.12,5.17-.15,7.75l-.42,31-.56-31c-.13-5.17-.14-10.33-.07-15.49l.11-15.49.05-7.75L641.7,703l-.3-15.49-.16-7.74-.07-3.87v-3.88l.1-15.5.1-15.49.23-15.49.23-15.48.06-3.88c0-1.29-.09-2.57-.14-3.86-.16-2.58-.07-5.17-.1-7.75l-.24-62a.63.63,0,0,1,.41-.59l14.92-5.49,7.45-2.76c2.49-.92,5-1.79,7.49-2.66h0a.54.54,0,0,1,.69.33.46.46,0,0,1,0,.2Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M672.33,522.19c1.32.15,2.62.36,3.91.58L680,524l7.44,2.36,7.45,2.36,3.72,1.17c1.17.6,2.37,1.15,3.53,1.78-1.32-.15-2.61-.39-3.91-.58l-3.72-1.18L687,527.51l-7.45-2.36L675.86,524C674.68,523.41,673.49,522.83,672.33,522.19Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M650.65,545.45c.17.79.33,1.57.46,2.36.06.4.15.79.18,1.18s0,.79,0,1.19v14.19l-.77-.61,4.08-.93a8.08,8.08,0,0,1,2.09-.24c.71,0,1.42-.07,2.13-.08-.65.31-1.3.58-1.95.85a8.11,8.11,0,0,1-2,.69l-4.08.93a.64.64,0,0,1-.75-.48.59.59,0,0,1,0-.13V550.18c0-.4,0-.79,0-1.19s.12-.78.18-1.18C650.32,547,650.48,546.24,650.65,545.45Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M650.65,575.5c.17.68.31,1.36.44,2l.19,1c0,.34,0,.68,0,1v12.23l-.77-.61,4.08-.92,1-.24,1.06,0c.71,0,1.42,0,2.14,0-.64.31-1.29.61-1.94.89l-1,.42-1,.23-4.08.93A.64.64,0,0,1,650,592a.59.59,0,0,1,0-.14V579.58c0-.34,0-.68,0-1l.19-1C650.34,576.86,650.48,576.18,650.65,575.5Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M651.29,703.66c.18.87.33,1.75.46,2.63.06.43.14.87.19,1.31s0,.88,0,1.32v15.77l-.63-.62H656a10,10,0,0,1,2.36.22c.79.13,1.57.25,2.36.4-.79.16-1.57.28-2.36.4a10,10,0,0,1-2.36.23h-4.73a.63.63,0,0,1-.62-.63V708.92c0-.44,0-.88,0-1.32s.13-.88.19-1.31C651,705.41,651.12,704.53,651.29,703.66Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M642.1,540.44l-13.48,5.81a129.56,129.56,0,0,1-13.74,5.12c-4.64,1.55-9.33,2.94-13.85,4.81l-13.67,5.27.4-.57L587.38,579,586.9,597l-.4,18,.53,18.05.16,18.09-.67,18.07-.28,9c-.11,3-.32,6-.48,9l-.63,18.06-.28-18.07c0-3,0-6,.11-9l.31-9,.67-18-.16-18-.53-18.07.4-18.08.48-18.06.38-18.06a.62.62,0,0,1,.4-.57L600.6,555c4.53-1.87,9.22-3.25,13.85-4.82,2.31-.79,4.65-1.52,7-2.22s4.62-1.59,6.91-2.42Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M627.62,563.06c-2.32,1.09-4.67,2.1-7,3.14-.59.26-1.17.53-1.76.77s-1.2.43-1.8.66L613.47,569l-.07,0-14.81,3.56a.55.55,0,0,1-.4,0l-5.74-2.59a.63.63,0,0,1-.31-.83.62.62,0,0,1,.39-.34l18.38-5.66,0,0,8.93-3.46c3-1,6.08-2,9.15-2.86-2.88,1.39-5.8,2.7-8.7,4l-8.93,3.44,0,0-18.39,5.66.08-1.17,5.74,2.59-.41,0,14.81-3.56-.07,0,3.59-1.33c.6-.21,1.19-.47,1.79-.67s1.22-.37,1.83-.56C622.71,564.5,625.15,563.75,627.62,563.06Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M597.79,572.37c.3,1.91.52,3.82.78,5.73a14.06,14.06,0,0,1,.15,1.44c0,.47,0,1,0,1.43l.12,2.88.47,11.5-.78-.58,14.91-3.86,7.53-1.64a13.46,13.46,0,0,1,1.9-.32l1.92-.24c1.28-.17,2.56-.32,3.84-.46-1.22.42-2.45.8-3.68,1.18l-1.84.58a11.84,11.84,0,0,1-1.86.49l-7.52,1.63L598.88,596a.62.62,0,0,1-.76-.45.59.59,0,0,1,0-.13l-.47-11.5-.12-2.88c0-.48,0-.95-.07-1.43a13.84,13.84,0,0,1,0-1.44C597.57,576.22,597.64,574.3,597.79,572.37Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M592.91,572.8c.26.93.44,1.86.63,2.79v16.75c-.19.93-.37,1.86-.62,2.79-.26-.93-.44-1.86-.63-2.79V575.59C592.46,574.66,592.64,573.73,592.91,572.8Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M628.42,611.28c-2.5.43-5,.78-7.52,1.16-.62.09-1.25.21-1.88.26l-1.89.14-3.78.31h.09l-14.84,3.44a.62.62,0,0,1-.6-.19L594.07,612a.63.63,0,0,1,0-.89.64.64,0,0,1,.31-.15L612,607.93l8.78-1.45c3-.28,5.91-.61,8.88-.84-2.89.73-5.79,1.37-8.68,2.06l-8.77,1.47-17.53,3.06.35-1,3.93,4.35-.61-.19,14.84-3.44.09,0,3.78-.31,1.89-.17c.63,0,1.26,0,1.9,0C623.35,611.33,625.88,611.27,628.42,611.28Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M598.71,615.64c.24,1.92.38,3.84.57,5.76a23.08,23.08,0,0,1,.06,2.88v14.39l-.7-.62,14.3-1.76H613l7.36,0a12.69,12.69,0,0,1,1.84.1l1.84.18c1.22.11,2.45.23,3.67.38-1.22.13-2.45.24-3.68.35l-1.84.16a12.53,12.53,0,0,1-1.84.09l-7.35,0h.07l-14.3,1.75a.62.62,0,0,1-.7-.54V624.28a23.08,23.08,0,0,1,.06-2.88C598.34,619.48,598.48,617.56,598.71,615.64Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M592.91,616.07c.26.93.44,1.86.63,2.79v16.75c-.19.93-.37,1.86-.62,2.79-.26-.93-.44-1.86-.63-2.79V618.86C592.46,617.93,592.64,617,592.91,616.07Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M627.5,658.22c-2.43.24-4.86.39-7.3.58-.6,0-1.21.11-1.82.12h-1.82l-3.65,0H613l-14.33,1.55a.66.66,0,0,1-.57-.24l-3.83-4.93a.63.63,0,0,1,.11-.88.68.68,0,0,1,.35-.13l17.17-.94h-.06l4.18-.68c1.4-.21,2.78-.49,4.19-.67,2.82-.26,5.63-.56,8.46-.77-2.75.7-5.51,1.32-8.27,2-1.38.27-2.79.45-4.18.69l-4.18.69h-.07l-17.17.94.46-1,3.83,4.93-.56-.24,14.34-1.54h.06l3.65,0,1.82,0c.61,0,1.22.06,1.83.1C622.63,657.89,625.07,658,627.5,658.22Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M597.92,660.29c.28,1.93.47,3.86.7,5.79a25.48,25.48,0,0,1,.13,2.9l.07,2.9.27,11.59-.67-.6L613,681.78l7.32-.09a12.65,12.65,0,0,1,1.84.08l1.83.15c1.22.09,2.44.19,3.66.31-1.21.16-2.43.29-3.65.41l-1.82.19a12.37,12.37,0,0,1-1.82.12l-7.31.08-14.57,1.08a.62.62,0,0,1-.67-.57v0l-.27-11.59-.07-2.9a25.51,25.51,0,0,1,0-2.9C597.63,664.17,597.73,662.23,597.92,660.29Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M592.91,660.72c.26.93.44,1.86.63,2.79v16.75c-.19.93-.37,1.86-.62,2.79-.26-.93-.44-1.86-.63-2.79V663.51C592.46,662.58,592.64,661.65,592.91,660.72Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M647.43,600.19a7.57,7.57,0,0,1-.15,1.2c-.22.26-.43.53-.65.8l-1.29,1.61-1.29,1.61-.64.8a8.83,8.83,0,0,1-1.13.42,10.92,10.92,0,0,1,.15-1.2l.65-.8,1.29-1.61,1.28-1.61c.22-.27.44-.54.64-.81A7.27,7.27,0,0,1,647.43,600.19Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M585.18,717c.26,2,.42,4,.63,6V759c-.21,2-.37,4-.62,6-.26-2-.42-4-.63-6V723C584.75,721,584.91,719,585.18,717Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M642.28,712.63c-2.26.21-4.52.32-6.77.47l-6.76-.16c-4.51-.18-9,0-13.52.06s-9,.11-13.51.33l-6.76.27c-2.26-.12-4.51-.19-6.78-.35,2.25-.35,4.49-.6,6.74-.9l6.76-.27c4.51-.22,9-.24,13.54-.33s9-.24,13.54-.06l6.76.15C637.78,712.1,640,712.31,642.28,712.63Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M757.8,534.93c-.26-.91-.45-1.81-.64-2.71V529.5l0-5.42v-8.15c.19-.9.37-1.81.62-2.71.25.9.44,1.81.63,2.71v2.71l0,5.43v5.43l0,2.71C758.24,533.12,758.06,534,757.8,534.93Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M754.76,729.42c.26,3.27.42,6.54.63,9.82V768.7l.08,19.64,0,9.82c-.2,3.27-.34,6.55-.58,9.82-.27-3.27-.45-6.54-.67-9.82l0-9.82-.08-19.64V739.24C754.33,736,754.49,732.69,754.76,729.42Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <g>
                <path d="M580.45,579.78c-2.58.25-5.17.45-7.75.65l-3.88.31c-1.3.11-2.59.07-3.89.13a.57.57,0,0,1-.4-.13h0l-13.73-11.24.05,0-14.5-9.82.91-.24-5.19,10.32c-1.65,3.48-3.68,6.75-5.57,10.11s-3.8,6.69-5.77,10-4.14,6.49-6.26,9.7c1.7-3.45,3.48-6.86,5.23-10.29s3.81-6.68,5.71-10,3.91-6.62,5.55-10.09l5.19-10.29a.63.63,0,0,1,.84-.28l.07,0,14.49,9.82.05,0,13.72,11.24-.42-.14c1.3,0,2.59-.13,3.89-.1l3.89.08C575.27,579.65,577.86,579.69,580.45,579.78Z" fill="gray"/>
                <path d="M520.45,591.27c0,1.75.07,3.49.08,5.24l0,5.24L520,612.19l-.89-.6,11.43-5.24c3.78-1.81,7.67-3.36,11.53-5s7.8-3.08,11.49-5l11.25-5.56-.35.54c0-.95.16-1.89.24-2.83l.3-2.83q.3-2.82.63-5.64c.05,1.89.07,3.79.09,5.68l0,2.84c0,.94.05,1.89,0,2.84v0a.61.61,0,0,1-.35.52l-11.28,5.56c-3.71,2-7.66,3.4-11.51,5s-7.76,3.17-11.53,5l-11.42,5.24a.64.64,0,0,1-.83-.31.59.59,0,0,1-.05-.29l.52-10.45.56-5.21C520,594.74,520.24,593,520.45,591.27Z" fill="gray"/>
                <path d="M580,592.85c-2.41,0-4.83-.09-7.24-.15l-3.62-.09c-1.2,0-2.4-.19-3.6-.26l.67-.6-.82,19.15.34,19.17v19.19l.1,4.79.06,2.4c0,.8,0,1.6-.06,2.4-.11,3.2-.19,6.4-.33,9.6-.28-3.19-.49-6.39-.73-9.58,0-.8-.14-1.6-.16-2.4l0-2.4-.1-4.8V630.09l-.33-19.2L565,591.7h0a.61.61,0,0,1,.64-.59h0c1.19.12,2.4.13,3.6.3l3.59.48C575.22,592.19,577.61,592.5,580,592.85Z" fill="gray"/>
                <path d="M565.38,688.63c.32,2.91.54,5.82.81,8.73l.18,8.75v0l-1,17.49v0l.43,17.5.08,8.75c-.18,2.92-.31,5.84-.54,8.76-.28-2.91-.47-5.83-.71-8.74l-.08-8.75-.43-17.49v0l1-17.49v0l-.19-8.75C565.08,694.47,565.18,691.55,565.38,688.63Z" fill="gray"/>
                <path d="M559.35,622.94c-.22-2.22-.38-4.45-.55-6.67-.07-1.11-.22-2.23-.21-3.34s0-2.23,0-3.34l.39.58L555,608.62h.43l-19.3,6.66-19.1,7.22.36-.34-1.75,4.07.05-.21c0,.86-.15,1.71-.24,2.56l-.32,2.55c-.2,1.71-.42,3.41-.66,5.11,0-1.72-.06-3.43-.07-5.15l0-2.57a24.44,24.44,0,0,1,.05-2.57v0a1.21,1.21,0,0,1,0-.18l1.76-4.06a.65.65,0,0,1,.35-.34l19.12-7.22L555,607.44a.66.66,0,0,1,.44,0l3.93,1.55a.65.65,0,0,1,.4.58c0,1.11,0,2.22.05,3.34s-.08,2.22-.12,3.34C559.62,618.48,559.52,620.71,559.35,622.94Z" fill="gray"/>
                <path d="M556.36,624.93c-1.44.78-2.9,1.49-4.36,2.2l-4.56,1.61L538.31,632l-9.2,3-4.6,1.5c-1.6.31-3.18.66-4.79.92,1.45-.75,2.93-1.4,4.4-2.11l4.59-1.5,9.19-3,9.13-3.21,4.55-1.62C553.17,625.58,554.75,625.22,556.36,624.93Z" fill="gray"/>
                <path d="M516.77,626.43c1.47-.74,3-1.41,4.46-2.08l4.65-1.48,9.3-3,0,0,9-3.78,4.5-1.89c1.58-.43,3.14-.91,4.74-1.31-1.4.86-2.84,1.64-4.26,2.47l-4.49,1.88-9,3.78-.05,0-9.3,3-4.65,1.49C520,625.87,518.4,626.18,516.77,626.43Z" fill="gray"/>
                <path d="M523.48,624.44a7.17,7.17,0,0,1,.71.78c0,.28,0,.55.07.83l.15,1.66.16,1.67.08.83a8,8,0,0,1-.55.89c-.27-.26-.48-.52-.7-.77l-.08-.84-.15-1.66-.16-1.66c0-.28,0-.56-.08-.84A7.44,7.44,0,0,1,523.48,624.44Z" fill="gray"/>
                <path d="M532.9,621.12a6.7,6.7,0,0,1,.67.79c0,.27,0,.55,0,.82l.07,1.63.08,1.64,0,.82a7.16,7.16,0,0,1-.59.84,6.74,6.74,0,0,1-.66-.79l0-.81-.07-1.64-.07-1.64c0-.27,0-.54-.05-.81A6.32,6.32,0,0,1,532.9,621.12Z" fill="gray"/>
                <path d="M542.94,617.57a5.7,5.7,0,0,1,.73.75c0,.27.07.55.11.83l.22,1.67.22,1.66.11.83a7,7,0,0,1-.51.92,7.28,7.28,0,0,1-.73-.75l-.11-.83-.22-1.67-.22-1.67c0-.27-.07-.55-.12-.83A6.56,6.56,0,0,1,542.94,617.57Z" fill="gray"/>
                <path d="M525.71,643.1c1.19-.73,2.41-1.4,3.64-2.06l3.87-1.47,7.73-3,7.78-2.84,3.89-1.42c1.37-.28,2.72-.6,4.1-.83-1.21.71-2.45,1.33-3.67,2L549.17,635l-7.78,2.84-7.73,3-3.86,1.48C528.44,642.54,527.09,642.85,525.71,643.1Z" fill="gray"/>
                <path d="M558.38,676.18c-.2-2.19-.35-4.38-.51-6.57-.06-1.1-.21-2.19-.17-3.29V663l.33.55-3.13-1.66.42.06-19.26,3.74.06,0-18.77,5.68.33-.25-2.23,3.27.11-.37a20.19,20.19,0,0,1,0,2.58l-.12,2.57c-.07,1.71-.16,3.43-.27,5.15-.18-1.71-.33-3.42-.48-5.14l-.23-2.56a18.44,18.44,0,0,1-.13-2.57v0a.6.6,0,0,1,.11-.34l2.23-3.26a.58.58,0,0,1,.33-.25l18.78-5.68h.06l19.26-3.74a.64.64,0,0,1,.41.06l3.13,1.66a.62.62,0,0,1,.33.55l0,3.29a32.7,32.7,0,0,1-.13,3.28C558.69,671.8,558.57,674,558.38,676.18Z" fill="gray"/>
                <path d="M556.32,677.91c-1.43.65-2.89,1.24-4.34,1.82l-4.51,1.21-9,2.43-9.13,2-4.56,1c-1.56.12-3.12.3-4.69.39,1.46-.59,2.95-1.08,4.42-1.61l4.55-1,9.11-2,9-2.42,4.5-1.22C553.2,678.28,554.75,678.06,556.32,677.91Z" fill="gray"/>
                <path d="M516.75,674.18c1.49-.52,3-1,4.52-1.42l4.63-.78,9.25-1.58-.07,0,9-2.68,4.5-1.34c1.56-.25,3.1-.54,4.67-.74-1.42.69-2.88,1.29-4.32,1.94l-4.49,1.34-9,2.68h-.08l-9.25,1.58-4.63.8C519.91,674.09,518.33,674.17,516.75,674.18Z" fill="gray"/>
                <path d="M523.59,673.82a6.84,6.84,0,0,1,.58.86c0,.28,0,.56,0,.84l-.09,1.66-.1,1.66,0,.84a8,8,0,0,1-.68.79,7.19,7.19,0,0,1-.57-.87l0-.83.1-1.66.09-1.66c0-.28,0-.56,0-.84A6,6,0,0,1,523.59,673.82Z" fill="gray"/>
                <path d="M533.12,671.35a6.22,6.22,0,0,1,.63.82c0,.27,0,.55,0,.82v4.09a7.21,7.21,0,0,1-.63.82,8.42,8.42,0,0,1-.62-.82V673c0-.28,0-.55,0-.82A5.62,5.62,0,0,1,533.12,671.35Z" fill="gray"/>
                <path d="M543.39,669.08a6.46,6.46,0,0,1,.65.82c0,.28,0,.56,0,.84l0,1.66,0,1.67,0,.83a9,9,0,0,1-.61.85,7.77,7.77,0,0,1-.65-.82v-.83l0-1.67,0-1.67c0-.27,0-.55,0-.83A7.15,7.15,0,0,1,543.39,669.08Z" fill="gray"/>
                <path d="M525.48,690.73c1.25-.51,2.53-1,3.8-1.39l3.92-.78,7.85-1.54,7.87-1.44,3.94-.71c1.35,0,2.69-.12,4-.1-1.27.48-2.55.88-3.83,1.33l-3.93.71-7.86,1.44-7.85,1.54-3.92.78C528.18,690.65,526.84,690.73,525.48,690.73Z" fill="gray"/>
                <path d="M529.44,597.85a15.61,15.61,0,0,1-.6-2.87l0-2.86.07-5.73a.68.68,0,0,1,.3-.53l1.8-1.09.45-.27c.15-.08.26-.24.48-.21a7.62,7.62,0,0,1,1.19-.07c-.19.36-.4.7-.61,1s-.27.22-.41.33l-.45.27-1.8,1.09.3-.53-.07,5.73,0,2.87A16.34,16.34,0,0,1,529.44,597.85Z" fill="gray"/>
                <path d="M537.81,591.84a10.83,10.83,0,0,1-.49-2.46l.15-2.42.29-4.84a.65.65,0,0,1,.2-.43l1.54-1.37.38-.34c.14-.1.22-.27.43-.29.37-.1.74-.2,1.14-.27a8.92,8.92,0,0,1-.4,1.1c0,.21-.22.27-.34.4l-.38.34-1.53,1.36.21-.43-.29,4.84-.14,2.43A11.38,11.38,0,0,1,537.81,591.84Z" fill="gray"/>
                <path d="M542.64,557.45c1.6-.25,3.19-.43,4.79-.6l4.79,0,9.58.07h.05l9.54.81,4.77.4c1.57.35,3.14.65,4.71,1-1.61.12-3.21.15-4.82.22l-4.77-.4-9.54-.81h0l-9.58-.07-4.79,0C545.83,557.92,544.23,557.72,542.64,557.45Z" fill="gray"/>
                <path d="M559.9,558c.71.35,1.37.75,2,1.15l1.57,1.6,3.15,3.19,3.14,3.19,1.58,1.59c.39.67.78,1.33,1.13,2-.71-.36-1.36-.76-2-1.16l-1.58-1.59-3.14-3.19-3.15-3.19L561,560.08C560.63,559.42,560.24,558.76,559.9,558Z" fill="gray"/>
                <path d="M574.25,558.33a9.72,9.72,0,0,1,1.27.55l.76,1,1.55,1.88,1.55,1.89.77.95a10.94,10.94,0,0,1,.29,1.34,12,12,0,0,1-1.26-.55l-.77-.94-1.55-1.89-1.55-1.89-.77-.94A9.49,9.49,0,0,1,574.25,558.33Z" fill="gray"/>
                <path d="M524.45,645.7c.25.93.43,1.86.6,2.79l0,2.79-.06,5.59-.07,5.58,0,2.79c-.2.93-.4,1.85-.66,2.78-.24-.93-.41-1.86-.59-2.8l0-2.79.07-5.58.06-5.58,0-2.79C524,647.55,524.18,646.62,524.45,645.7Z" fill="gray"/>
                <path d="M523.91,695.5c.24,2.08.37,4.15.55,6.23l-.08,6.22c-.12,4.15.06,8.29.14,12.44l.27,12.45.11,6.22c-.17,2.08-.3,4.16-.51,6.24-.29-2.07-.49-4.14-.74-6.21l-.11-6.23-.27-12.45c-.08-4.15-.26-8.31-.14-12.46l.07-6.23C523.43,699.65,523.62,697.57,523.91,695.5Z" fill="gray"/>
                <path d="M534.66,747.88c-.2-3.39-.3-6.77-.46-10.15,0-3.38.09-6.76.14-10.14l.3-20.29a.62.62,0,0,1,.54-.61l15.23-1.94a.63.63,0,0,1,.71.54v.09l-.33,14.32v-.07l.33,3.69c.07,1.23.09,2.47.13,3.7.08,2.47.16,4.94.2,7.42-.38-2.45-.73-4.89-1.08-7.34-.17-1.22-.37-2.44-.52-3.67l-.31-3.69v-.07l.33-14.32.7.64-15.23,1.94.55-.61-.3,20.28c-.06,3.38-.08,6.77-.17,10.14C535.16,741.12,535,744.5,534.66,747.88Z" fill="gray"/>
              </g>
            </g>
            <g opacity="0.5">
              <path d="M519.92,690.78c-1.72.18-3.44.31-5.15.45-.86.06-1.72.17-2.58.19s-1.72,0-2.58,0h-10.3l.63-.62V708l-.63-.63h10.3c.86,0,1.72,0,2.58,0s1.72.13,2.58.19c1.71.14,3.43.27,5.15.45-1.72.18-3.44.31-5.15.45-.86.06-1.72.16-2.58.19s-1.72,0-2.58,0h-10.3a.64.64,0,0,1-.63-.63V690.78a.63.63,0,0,1,.63-.63h10.3c.86,0,1.72,0,2.58,0s1.72.13,2.58.19C516.48,690.46,518.2,690.59,519.92,690.78Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M494.59,690.78c.26.82.44,1.64.63,2.46v14.82c-.19.82-.37,1.64-.62,2.46-.25-.82-.44-1.64-.63-2.46V693.24C494.14,692.42,494.33,691.6,494.59,690.78Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M420.31,712.67c-.26-3.72-.44-7.44-.64-11.16l0-11.16V668a.62.62,0,0,1,.39-.58l19.54-7.73,9.77-3.85c3.33-1.09,6.65-2.24,10-3.29-3.16,1.51-6.36,2.95-9.54,4.42l-9.76,3.89-19.54,7.72.4-.58v22.33l0,11.16C420.76,705.23,420.58,709,420.31,712.67Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M507,572l-10.45,4.48-5.23,2.23-5.3,2-21.21,8.16.4-.59v39.78l-.84-.59,15-5.36c2.5-.89,5-1.85,7.54-2.54s5.09-1.52,7.65-2.24c-2.44,1.07-4.89,2.09-7.33,3.13s-4.95,1.92-7.44,2.83l-15,5.36a.62.62,0,0,1-.8-.38.49.49,0,0,1,0-.21V588.31a.63.63,0,0,1,.4-.58l21.21-8.16,5.3-2.05,5.37-1.85Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M507,573.31c.17,1.69.3,3.38.43,5.08l.2,2.53c0,.85,0,1.7,0,2.54v30.47l-.46-.61,3.22.86c.26.09.54.12.81.21l.75.41c.5.27,1,.54,1.5.84-.58,0-1.15,0-1.72,0l-.86,0c-.27,0-.53-.15-.8-.21l-3.22-.86a.63.63,0,0,1-.47-.6V583.46c0-.84,0-1.69,0-2.54l.2-2.53C506.74,576.69,506.86,575,507,573.31Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M509.61,572.57c1.35.24,2.7.51,4,.81a.43.43,0,0,1,.33.37v.11l.13,1.18c0,.4,0,.79,0,1.19v28.44a17.05,17.05,0,0,1-.05,2.37c-.19,1.58-.34,3.16-.57,4.74-.23-1.58-.38-3.16-.57-4.74a18.76,18.76,0,0,1-.06-2.37V576.23c0-.4,0-.79,0-1.19l.12-1.18.35.48C512.08,573.77,510.84,573.18,509.61,572.57Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M471,626.88a6.67,6.67,0,0,1,.64,1c0,.33,0,.65,0,1v4.83a7.52,7.52,0,0,1-.63,1,8.64,8.64,0,0,1-.62-1v-4.83c0-.32,0-.64,0-1A7.52,7.52,0,0,1,471,626.88Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M475,624.37a6.65,6.65,0,0,1,.63,1c0,.32,0,.64,0,1v4.82a8,8,0,0,1-.63,1,9.42,9.42,0,0,1-.63-1v-4.82c0-.33,0-.65,0-1A7.6,7.6,0,0,1,475,624.37Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M500,616.86a6.16,6.16,0,0,1,.63,1c0,.32,0,.64,0,1v4.83a8.87,8.87,0,0,1-.63,1,8.74,8.74,0,0,1-.62-1v-4.83c0-.32,0-.64,0-1A7,7,0,0,1,500,616.86Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M504,615.86a7.52,7.52,0,0,1,.63,1c0,.33,0,.65,0,1v4.83c-.2.32-.38.64-.63,1a9.94,9.94,0,0,1-.63-1v-4.83c0-.32,0-.64,0-1A6.58,6.58,0,0,1,504,615.86Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <g>
                <path d="M513.43,620.64q-4.45,1.74-8.93,3.42c-3,1.11-5.95,2.29-8.95,3.34l-18.16,5.94,0,0L460,640.53l.39-.59.32,16.8v.06l-1.31,17.08v-.09l.86,12.81c.41,4.26.26,8.55.3,12.84l0,6.43a57.17,57.17,0,0,1-.44,6.4l-1.27,12.79.35-12.82c.06-2.14.15-4.27.09-6.4v-6.4c0-4.26.11-8.53-.3-12.79l-.86-12.78v-.09l1.31-17.07v.06L459.1,640a.6.6,0,0,1,.38-.59L477,632.16H477l18.14-6c3-1,6.09-1.87,9.13-2.81S510.37,621.54,513.43,620.64Z" fill="gray"/>
                <path d="M507,642.6c-1.56.68-3.14,1.28-4.72,1.89l-4.88,1.26-9.75,2.54-9.78,2.43L473,651.94c-1.68.2-3.35.44-5,.6,1.57-.65,3.16-1.21,4.74-1.82l4.89-1.21,9.78-2.43,9.74-2.54,4.87-1.27C503.66,643,505.33,642.78,507,642.6Z" fill="gray"/>
                <path d="M470.93,651.86c-.12-.48-.22-1-.31-1.45l-.12-.73a2,2,0,0,1,0-.72l.17-2.86a.63.63,0,0,1,.37-.54l6.92-3.16a.62.62,0,0,1,.88.64l-.38,3.41-.09.86-.28.83c-.2.55-.41,1.11-.63,1.66-.1-.59-.19-1.18-.25-1.76l-.1-.87.1-.86.39-3.41.88.64-6.92,3.16.36-.53-.16,2.86a1.82,1.82,0,0,1-.12.72l-.2.7C471.25,650.92,471.1,651.39,470.93,651.86Z" fill="gray"/>
                <path d="M482.1,642.55c.84-.45,1.69-.87,2.54-1.26a4.92,4.92,0,0,1,1.32-.5l1.34-.38,5.38-1.54,10.75-3.07a.63.63,0,0,1,.78.43s0,.07,0,.1l.17,1.57a7,7,0,0,1,.11,1.58c-.06,1.07-.09,2.14-.2,3.21-.34-1-.6-2.06-.9-3.08a7,7,0,0,1-.25-1.57l-.18-1.57.8.53L493,640.08l-5.38,1.53-1.34.39a5.28,5.28,0,0,1-1.38.27C484,642.39,483,642.48,482.1,642.55Z" fill="gray"/>
                <path d="M507,661.22c-1.58.59-3.17,1.12-4.77,1.64l-4.89,1-9.79,2-9.65,2.59L473,669.8c-1.66.22-3.31.5-5,.69,1.54-.68,3.12-1.27,4.67-1.9l4.84-1.3,9.67-2.59,9.81-2.05,4.9-1C503.6,661.46,505.27,661.31,507,661.22Z" fill="gray"/>
                <path d="M470.49,669.47c-.11-.51-.2-1-.27-1.51l-.1-.74c0-.25.07-.48.07-.73l.33-2.9a.62.62,0,0,1,.47-.54l6.46-1.66a.62.62,0,0,1,.76.45.52.52,0,0,1,0,.17l-.08,2.71a3.77,3.77,0,0,1-.27,1.34c-.14.45-.28.89-.44,1.34-.13-.46-.25-.91-.36-1.36a3.78,3.78,0,0,1-.18-1.36L477,662l.78.63-6.46,1.66.47-.53-.33,2.9c-.05.24,0,.49-.09.73l-.27.7C470.9,668.53,470.71,669,470.49,669.47Z" fill="gray"/>
                <path d="M482.11,660.18c.86-.37,1.71-.73,2.58-1a4.45,4.45,0,0,1,1.32-.39l1.34-.28,5.36-1.11,10.73-2.21a.63.63,0,0,1,.74.48v.06l.17,1.46c0,.24.06.48.1.72s0,.5,0,.74c-.06,1-.09,2-.19,3-.33-1-.59-1.9-.87-2.85a4.79,4.79,0,0,1-.19-.72c0-.24,0-.49-.07-.73l-.17-1.46.74.54L493,658.58l-5.37,1.11-1.34.28a4.74,4.74,0,0,1-1.36.16C484,660.18,483.05,660.19,482.11,660.18Z" fill="gray"/>
                <path d="M507,680c-1.57.59-3.15,1.11-4.74,1.64l-4.86,1-9.73,2-9.71,2.13-4.85,1.06c-1.66.15-3.32.34-5,.45,1.57-.6,3.15-1.11,4.72-1.67l4.86-1.07,9.71-2.12,9.73-2.05,4.86-1C503.61,680.22,505.27,680.06,507,680Z" fill="gray"/>
                <path d="M471,687.07c-.19-.51-.36-1-.52-1.54l-.22-.78c-.05-.26,0-.52,0-.78l-.16-3.13a.63.63,0,0,1,.52-.65l7.21-1.21a.62.62,0,0,1,.72.51v.08l.12,2.7a1.74,1.74,0,0,1-.05.68l-.14.68c-.09.46-.2.92-.32,1.37-.16-.44-.3-.89-.44-1.33l-.19-.67a1.55,1.55,0,0,1-.11-.67l-.12-2.7.73.59-7.21,1.2.52-.64.16,3.13c0,.26.06.52,0,.78l-.15.79Q471.17,686.28,471,687.07Z" fill="gray"/>
                <path d="M482.16,678.05c.86-.34,1.73-.66,2.59-.95a4.49,4.49,0,0,1,1.32-.33l1.34-.22,5.34-.89,10.69-1.77a.63.63,0,0,1,.72.52v.08l.07,1.49c0,.24,0,.49,0,.74a4.74,4.74,0,0,1-.06.75c-.14,1-.24,2-.42,3-.26-1-.45-2-.67-3a3.93,3.93,0,0,1-.13-.75c0-.24,0-.49,0-.74l-.07-1.49.73.59L493,676.9l-5.35.88-1.33.22a4.75,4.75,0,0,1-1.36.11Q483.55,678.11,482.16,678.05Z" fill="gray"/>
              </g>
            </g>
            <g opacity="0.5">
              <path d="M288.64,645.94c2.66.68,5.3,1.39,7.95,2.1l4,1.07c1.33.34,2.64.72,3.94,1.17l15.65,5,0,0,16,3.82.08,0,15.14,5.66a.62.62,0,0,1,.41.57l.24,11.85c.12,3.94,0,7.9,0,11.85l-.05,5.93c0,1,0,2,0,3s-.15,2-.21,3c-.31,3.94-.59,7.88-.94,11.82-.08-3.95-.09-7.9-.12-11.85,0-1,0-2,0-3s.06-2,.06-3l0-5.92c0-3.94.11-7.89,0-11.83l-.23-11.84.41.58-15.15-5.65.07,0-16-3.82h0l-15.65-5c-1.31-.37-2.61-.81-3.89-1.3l-3.86-1.4C293.78,647.84,291.21,646.91,288.64,645.94Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M513.48,643.55c.26.84.44,1.68.63,2.52v15.14c-.19.84-.37,1.68-.62,2.52-.25-.84-.44-1.68-.63-2.52V646.07C513,645.23,513.22,644.39,513.48,643.55Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M513.48,714c.26.86.44,1.72.63,2.58V732c-.19.86-.37,1.72-.62,2.58-.25-.86-.44-1.72-.63-2.58V716.54C513,715.68,513.22,714.82,513.48,714Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M421.6,677.9c1.16-.63,2.34-1.18,3.52-1.73l3.71-1.13,7.4-2.25,7.41-2.26,3.7-1.12c1.3-.18,2.58-.4,3.89-.53-1.16.62-2.35,1.15-3.52,1.73L444,671.73,436.6,674l-7.41,2.25-3.7,1.13C424.2,677.57,422.91,677.77,421.6,677.9Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M422.25,686.17c1.16-.48,2.33-.88,3.5-1.29l3.63-.66,7.24-1.34,7.25-1.35,3.62-.67c1.25,0,2.48-.08,3.74-.05-1.17.47-2.34.85-3.51,1.28l-3.63.67-7.24,1.35-7.25,1.34-3.62.67C424.74,686.17,423.5,686.21,422.25,686.17Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M421.6,694.16c1.21-.38,2.43-.69,3.64-1l3.7-.37,7.41-.75,7.41-.75,3.7-.38c1.26.09,2.51.12,3.77.25-1.21.38-2.43.66-3.64,1l-3.71.37-7.4.75-7.41.76-3.7.38C424.12,694.36,422.86,694.3,421.6,694.16Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M605.79,723.86q-1.6.17-3.21.27c-1.06.06-2.13.21-3.2.18-2.12-.08-4.25-.12-6.38-.19l.65-.62-.22,16.91.25,8.44c0,.71,0,1.41.07,2.12s-.08,1.41-.12,2.11c-.1,1.42-.19,2.83-.33,4.24-.23-1.4-.4-2.81-.59-4.22-.07-.7-.23-1.4-.24-2.11s0-1.41-.05-2.11l-.24-8.46.22-16.94a.61.61,0,0,1,.63-.61h0c2.13,0,4.25.13,6.38.17,1.06,0,2.12.25,3.18.37S604.73,723.69,605.79,723.86Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M636.2,748.41c-1.76,0-3.5-.14-5.25-.24l-5.15-.86-10.3-1.71A.63.63,0,0,1,615,745l.11-10.79,0-5.4c.23-1.79.41-3.59.69-5.39.23,1.81.39,3.61.57,5.41l-.06,5.39-.1,10.79-.53-.63,10.3,1.72,5.15.86C632.84,747.41,634.53,747.87,636.2,748.41Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M600,729.42c.29.9.5,1.81.72,2.71l.09,2.74.18,5.47.18,5.48.09,2.73c-.16.92-.31,1.84-.54,2.76-.28-.9-.49-1.81-.71-2.71l-.09-2.74-.18-5.48-.19-5.47-.09-2.74C599.56,731.25,599.72,730.34,600,729.42Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M64.33,691.21c0,3,0,5.92,0,8.89l0,4.44c0,1.48-.16,3-.21,4.44v-.05l.42,13.32c.24,4.44,0,8.87,0,13.31s-.3,8.87,0,13.3l.56,13.29v.06L64,779.92c0,1.48,0,3,0,4.43l-.16,4.44-.34,8.88L63,788.78l-.24-4.45c0-1.48,0-3,0-4.44l1.06-17.75v.06l-.56-13.32c-.31-4.44-.07-8.87,0-13.31s.28-8.88,0-13.31L62.87,709h0v0c.08-1.48.11-3,.2-4.44l.41-4.42C63.75,697.1,64,694.15,64.33,691.21Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M146,773.21l.92-18.19v0l-1.75-36.33v-.05l.48-15.89c.11-2.65,0-5.3.07-7.95V682.92c0-1.33-.07-2.65-.1-4l-.23-8-.12-4c0-1.33-.13-2.65,0-4l.26-7.95c.47-10.61.37-21.21.67-31.81.12-5.3.32-10.6.36-15.9l.08-15.9v0l-1.39-18.18v-.09l1.17-18.55.43.63L129,549.42l-17.89-5.68c-5.84-2.24-11.69-4.45-17.47-6.84L76,530.52h.18L64,537,76,530.12a.17.17,0,0,1,.17,0h0L94,535.9c5.89,2.1,11.69,4.43,17.53,6.65l17.86,5.68,17.8,5.92a.61.61,0,0,1,.43.63l-1.17,18.55v-.09l1.39,18.18v.05l-.08,15.91c0,5.31-.24,10.61-.35,15.91-.3,10.6-.21,21.22-.68,31.8l-.26,7.95c-.08,1.32,0,2.64,0,4l.12,4,.23,7.95c0,1.32.09,2.64.1,4v11.94c0,2.66.05,5.31-.07,8l-.48,15.91v-.05l.44,9.09c.15,3,.32,6,.41,9.08l.25,18.2h0v0Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M75,705.8c.34,3.64.58,7.28.86,10.91.07,3.64.14,7.28.31,10.92l.44,10.92c.1,1.82.1,3.65.11,5.47l0,5.48,0,5.47v2.74c0,.91-.09,1.81-.13,2.72l-.66,10.91-.17,2.73c-.06.91-.12,1.82-.1,2.73v5.46c-.21,3.63-.37,7.27-.63,10.91-.25-3.64-.41-7.29-.62-10.94v-5.47c0-.91,0-1.82.1-2.73l.17-2.72.66-10.91c0-.91.14-1.82.13-2.73V755l0-5.46,0-5.45c0-1.82,0-3.64-.1-5.46l-.44-10.91c-.17-3.64-.24-7.28-.32-10.93C74.68,713.1,74.77,709.45,75,705.8Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M135.28,591.6c-.15-2.46-.27-4.93-.38-7.39l-.18-3.7c-.06-1.23-.13-2.46-.09-3.7L135,562l.4.6L120,556.71l0,0-16-4.48,0,0-16.22-6,.84-.59.06,6.23,0,3.12c0,1,.06,2.07,0,3.11-.16,4.16-.28,8.32-.48,12.47-.28-4.15-.47-8.3-.71-12.46-.08-1,0-2.07-.06-3.11l0-3.12-.06-6.23A.63.63,0,0,1,88,545a.59.59,0,0,1,.23,0l16.21,6,0,0,16,4.48,0,0,15.47,5.92a.65.65,0,0,1,.4.61l-.39,14.76c0,1.23,0,2.46-.08,3.69l-.17,3.69C135.54,586.67,135.43,589.14,135.28,591.6Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M89.49,552.62c1.79.33,3.55.73,5.33,1.1a19.34,19.34,0,0,1,2.6.75l2.58.82,10.33,3.26,20.47,7.08-.61.11c.43-.35.92-.63,1.38-1l1.4-.93q1.4-.93,2.82-1.83c-.78.8-1.58,1.59-2.38,2.37l-1.2,1.18c-.4.38-.78.8-1.21,1.15a.65.65,0,0,1-.61.11l-20.45-7.11-10.32-3.25L97,555.66a19.34,19.34,0,0,1-2.56-.88C92.81,554.06,91.14,553.38,89.49,552.62Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M129.85,566.27c.29.85.51,1.7.73,2.55l.1,2.58.2,5.15.21,5.15.1,2.58c-.15.86-.3,1.73-.52,2.6-.29-.85-.5-1.7-.73-2.55l-.1-2.58-.21-5.15-.2-5.15-.11-2.58C129.47,568,129.62,567.14,129.85,566.27Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M102.36,557.68c.21.76.34,1.5.48,2.25l-.17,2.2-.31,4.4-.31,4.4-.16,2.2c-.24.72-.48,1.44-.78,2.16-.2-.76-.33-1.5-.47-2.25l.16-2.2.31-4.4.32-4.4.15-2.2C101.81,559.12,102.05,558.4,102.36,557.68Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M115.85,562.41c.31.71.55,1.43.79,2.15l.15,2.2.32,4.4.32,4.4.16,2.2c-.14.75-.27,1.5-.46,2.25-.31-.72-.55-1.44-.79-2.16l-.16-2.2-.32-4.4-.32-4.4-.16-2.2A22.32,22.32,0,0,1,115.85,562.41Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M135.06,640.11c-.16-2.47-.28-4.94-.4-7.42l-.18-3.72c-.07-1.24-.11-2.47-.07-3.71l.76-14.84.47.64-15.92-3.94,0,0-15.47-5.43.07,0L88.67,598l.77-.61,0,6.06,0,3c0,1,0,2,0,3-.21,4-.39,8.08-.65,12.11-.22-4-.35-8.08-.53-12.12-.07-1,0-2,0-3l0-3,0-6.06a.63.63,0,0,1,.63-.62l.14,0,15.59,3.66.07,0,15.46,5.43,0,0,15.91,3.94a.64.64,0,0,1,.48.64l-.76,14.81c0,1.23,0,2.47-.07,3.7l-.16,3.71Q135.26,636.41,135.06,640.11Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M89.44,603.8c1.78.24,3.53.57,5.29.87a19.14,19.14,0,0,1,2.59.62l2.57.71,10.28,2.8,20.58,5.58-.56.11a12.69,12.69,0,0,1,1.42-1l1.45-.92c1-.62,1.94-1.23,2.93-1.82-.82.81-1.65,1.6-2.48,2.39l-1.25,1.18a12.53,12.53,0,0,1-1.29,1.14l0,0a.61.61,0,0,1-.52.09L109.85,610,99.56,607.2,97,606.5a17.79,17.79,0,0,1-2.55-.77C92.77,605.09,91.1,604.48,89.44,603.8Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M130.67,614.78c.26.86.45,1.72.63,2.58v15.46c-.19.86-.37,1.71-.62,2.57-.25-.86-.44-1.72-.63-2.58V617.36C130.22,616.5,130.41,615.64,130.67,614.78Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M102.08,607.49a19.81,19.81,0,0,1,.62,2l0,2,0,4.08,0,4.08,0,2c-.19.68-.38,1.36-.64,2-.24-.68-.42-1.36-.61-2v-2l0-4.08,0-4.08v-2A19.13,19.13,0,0,1,102.08,607.49Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M116.57,610.92a22.47,22.47,0,0,1,.55,2.22l-.08,2.2-.16,4.4-.17,4.41-.08,2.2c-.21.72-.42,1.45-.7,2.17-.23-.74-.38-1.48-.55-2.22l.08-2.2.16-4.4.17-4.4.07-2.2A22.15,22.15,0,0,1,116.57,610.92Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M135.65,690.35c-.19-2.49-.34-5-.49-7.48l-.23-3.74c-.09-1.25-.14-2.5-.11-3.75l.62-15,.5.64-11.79-2.51c-3.91-.9-7.88-1.53-11.84-2.22l-6-1c-1-.18-2-.3-3-.55l-2.94-.69-11.74-2.78.76-.6-.07,6.06,0,3c0,1,0,2-.08,3-.25,4-.46,8.07-.75,12.1-.19-4-.29-8.08-.43-12.12-.06-1,0-2,0-3l0-3,.08-6.05a.63.63,0,0,1,.64-.62H89l11.72,2.77,2.93.7c1,.24,2,.36,3,.55l5.94,1c4,.69,7.92,1.32,11.85,2.22l11.8,2.51a.63.63,0,0,1,.49.64l-.62,14.93c0,1.24,0,2.49,0,3.73l-.12,3.74Q135.81,686.61,135.65,690.35Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M89.45,656.88c1.76.19,3.49.46,5.23.69a18.3,18.3,0,0,1,2.57.53l2.56.62,10.2,2.44h0l20.72,3.26-.54.18a12.17,12.17,0,0,1,1.28-1.1l1.33-1.06c.88-.7,1.76-1.41,2.67-2.09-.71.88-1.44,1.75-2.17,2.61l-1.09,1.29A12.46,12.46,0,0,1,131,665.5l0,0a.64.64,0,0,1-.51.15l-20.72-3.28h0l-10.21-2.45L97,659.32a17.18,17.18,0,0,1-2.53-.68C92.77,658.06,91.1,657.51,89.45,656.88Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M130.27,665c.25.86.43,1.72.61,2.58l0,2.57-.05,5.16-.06,5.15,0,2.57c-.2.86-.39,1.72-.65,2.57-.24-.86-.42-1.72-.6-2.58l0-2.58.05-5.15.06-5.15,0-2.58C129.81,666.73,130,665.87,130.27,665Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M101.14,659.43c.31.59.56,1.18.8,1.77l.17,1.82.35,3.65.35,3.65.18,1.83c-.13.62-.26,1.25-.45,1.88-.31-.58-.55-1.17-.8-1.76l-.17-1.83-.35-3.65-.36-3.65-.18-1.82A19.22,19.22,0,0,1,101.14,659.43Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M115.72,662.44a19.41,19.41,0,0,1,.75,2l.12,2,.24,4.08.25,4.08.12,2c-.15.69-.3,1.38-.51,2.07-.29-.66-.51-1.33-.74-2l-.12-2-.25-4.08-.24-4.07-.13-2C115.36,663.83,115.5,663.14,115.72,662.44Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M127.69,726.84c.11,1.43.18,2.86.26,4.29l.11,2.14c0,.72-.08,1.43-.09,2.14l-.34,8.55v-.06l1,17.09v.07l-.48,8.55c0,.71,0,1.43-.12,2.14l-.32,2.13c-.21,1.41-.42,2.83-.66,4.25-.09-1.44-.14-2.87-.19-4.3l-.08-2.15c0-.71.1-1.42.12-2.14l.48-8.55v.07l-1-17.09v-.06l.35-8.55c0-.71,0-1.42.08-2.14l.28-2.12C127.27,729.68,127.46,728.26,127.69,726.84Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M134.89,726.75c-2,.17-4,.23-5.94.34L123,726.8l-11.82-.55c-3.94-.19-7.89-.28-11.82-.67l-5.9-.49c-2-.37-3.91-.7-5.85-1.11,2-.09,4-.09,5.94-.14l5.89.49c3.93.39,7.87.48,11.81.67l11.81.55,5.91.28C131,726.14,132.93,726.39,134.89,726.75Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M146.28,595.29l18.61,4.45c3.11.68,6.25,1.3,9.35,2l9.31,2.23,18.32,5.51,18.5,4.83a.61.61,0,0,1,.46.61l-.22,17.14-.51,17.11.06,17.1,1.1,17.08v.07l-.89,17L220,717.56v0c.14,2.84.34,5.68.42,8.53l.26,8.54-.31,17.08-.51-17.06-.64-8.51c-.21-2.84-.3-5.68-.47-8.52v0l.35-17.08L220,683.4v.07l-1.1-17.11-.06-17.16.51-17.13.22-17.13.47.62-18.52-4.83-18.31-5.51-9.3-2.2c-3.1-.73-6.17-1.59-9.26-2.38Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M219.69,615.82c5.57,1.4,11.11,2.88,16.66,4.32h0c1.37.45,2.71,1,4.05,1.47l4,1.51,8.06,3-.06,0,17.1,4.43a.62.62,0,0,1,.46.64l-1.18,19.87V651l1,19.5,0,19.53-.68,19.49c-.33,6.5-.65,13-1,19.49-.13-6.51-.17-13-.24-19.52l.71-19.48,0-19.48-1-19.46V651l1.19-19.86.46.64-17.1-4.43-.06,0-8.06-3-4-1.5c-1.35-.48-2.69-1-4-1.55h0C230.58,619.45,225.12,617.68,219.69,615.82Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M155.09,566.21c5.47,1.75,10.91,3.59,16.37,5.38,2.72.92,5.37,2,8,3.06l8,3.1,16.14,6,16.26,5.66a.65.65,0,0,1,.42.58l.36,17.33a.61.61,0,0,1-.61.63.51.51,0,0,1-.21,0L204,602.77l-16.1-4.59-8-2.4c-2.67-.81-5.36-1.54-8-2.47l-16-5,16.29,4c2.71.67,5.37,1.54,8,2.33l8,2.4,16.12,4.59,15.94,5.15-.82.61L219.11,590l.42.58-16.27-5.67-16.16-6-8-3.1c-2.67-1.06-5.38-2-8-3.15C165.73,570.52,160.39,568.41,155.09,566.21Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M156.32,571.85a12.24,12.24,0,0,1,.5,1.56l-.14,1.5-.27,3-.28,3-.13,1.51c-.24.48-.46,1-.76,1.44-.2-.52-.34-1-.49-1.56l.14-1.5.27-3,.27-3,.13-1.5A13.42,13.42,0,0,1,156.32,571.85Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M152.76,567.06a5.44,5.44,0,0,1-.43.94l-.72.38-1.44.76-1.45.76-.72.38a8,8,0,0,1-1-.18,6.68,6.68,0,0,1,.43-.93l.72-.38,1.44-.76,1.45-.76c.23-.13.48-.25.71-.39A6.64,6.64,0,0,1,152.76,567.06Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M210.88,604.69a6.68,6.68,0,0,1,.6.94c0,.3,0,.61,0,.91l-.07,1.82-.07,1.83,0,.91a8.5,8.5,0,0,1-.66.89,9,9,0,0,1-.59-.94l0-.91.08-1.82.07-1.83c0-.3,0-.6,0-.91A7,7,0,0,1,210.88,604.69Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M155.27,591.76c1.37.32,2.72.69,4.08,1l2,.55c.66.22,1.3.5,2,.73l7.85,2.91-.08,0,16.3,3.82h.05l8,2.54c.66.23,1.33.4,2,.64l1.93.82c1.29.55,2.57,1.1,3.85,1.68-1.38-.27-2.74-.57-4.11-.87l-2.05-.44c-.67-.19-1.32-.44-2-.64l-8-2.54h0l-16.29-3.81-.08,0-7.85-2.92c-.65-.26-1.32-.46-2-.72l-1.9-.91C157.78,593,156.52,592.41,155.27,591.76Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M153.08,586.46a7.22,7.22,0,0,1-.31,1c-.25.18-.48.37-.71.55l-1.41,1.12-1.41,1.11-.71.55a7.59,7.59,0,0,1-1.09.07,7.21,7.21,0,0,1,.32-1l.7-.56,1.41-1.11,1.41-1.11c.23-.19.48-.37.7-.56A6.46,6.46,0,0,1,153.08,586.46Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M162.85,647.26a6.58,6.58,0,0,1,0,1.17c-.18.27-.34.56-.52.84l-1,1.68-1,1.68-.52.84a9.17,9.17,0,0,1-1,.52,7.68,7.68,0,0,1,0-1.17l.51-.84,1-1.68,1-1.69.51-.84A7.31,7.31,0,0,1,162.85,647.26Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M212.17,656.44a5.45,5.45,0,0,1-.16,1l-.58.49-1.16,1-1.16,1-.57.49a6.44,6.44,0,0,1-1,0,7,7,0,0,1,.17-1l.58-.49,1.15-1,1.16-1c.19-.16.39-.32.58-.5A6.34,6.34,0,0,1,212.17,656.44Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M170.79,628.09c.27.66.46,1.32.66,2v2l0,4,.05,4,0,2c-.19.66-.36,1.32-.61,2-.26-.66-.45-1.32-.65-2l0-2-.05-4,0-4,0-2C170.36,629.42,170.54,628.76,170.79,628.09Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M188.21,632.82a16.3,16.3,0,0,1,.7,1.69l.07,1.71.15,3.44.14,3.43.08,1.72c-.17.58-.33,1.16-.55,1.74a16.3,16.3,0,0,1-.7-1.69l-.08-1.71-.14-3.44-.15-3.43-.08-1.72A14.64,14.64,0,0,1,188.21,632.82Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M201.67,636.25a17.68,17.68,0,0,1,.59,1.73l0,1.72-.08,3.43-.08,3.44,0,1.72c-.21.56-.4,1.13-.67,1.7a17.57,17.57,0,0,1-.58-1.73l0-1.72.08-3.44.08-3.43,0-1.72A15.06,15.06,0,0,1,201.67,636.25Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M169.17,619.54l3.36,4.16-.08-.05,10.8,2.45c3.59.84,7.2,1.58,10.78,2.5l21.64,4.63-.71.85-2.51-6.19.43.37-11.29-2.73c-3.76-.92-7.54-1.78-11.31-2.67s-7.56-1.69-11.29-2.78L167.78,617l.57-.13-5.54,4.8.22-.46-.22,25.85-.51-.62,12.23,2.33c4.06.87,8.2,1.32,12.32,1.87l6.18.81c1,.16,2.07.24,3.09.45l3.06.58c2,.37,4.09.81,6.12,1.26l6.1,1.32-.48.4.39-15.8.42,15.8a.39.39,0,0,1-.39.4h-.09l-6.15-1c-2-.34-4.09-.68-6.13-1.1l-3.05-.59c-1-.22-2.06-.3-3.09-.45l-6.18-.81c-4.12-.56-8.25-1-12.32-1.88l-12.27-2.33a.62.62,0,0,1-.51-.62l.22-25.85a.66.66,0,0,1,.22-.47l5.54-4.81a.63.63,0,0,1,.57-.13l11.21,3c3.71,1.08,7.5,1.88,11.27,2.78s7.54,1.74,11.3,2.66l11.3,2.73a.63.63,0,0,1,.43.38l2.51,6.19a.63.63,0,0,1-.35.82.74.74,0,0,1-.36,0l-21.67-4.62c-3.59-.89-7.13-2-10.7-2.92l-10.67-3h0a.11.11,0,0,1-.07-.06Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M156.89,620.39c.24,1.2.4,2.4.56,3.61l-.07,3.59-.13,7.2-.37,7.19-.19,3.59c-.27,1.19-.5,2.38-.81,3.56-.2-1.21-.29-2.41-.44-3.62l.19-3.59.37-7.17.13-7.19.06-3.59C156.4,622.77,156.61,621.58,156.89,620.39Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M171.35,638.53c1.74.1,3.46.28,5.18.45l5,1.08,10.09,2.14,10.09,2.13,5.05,1.06c1.64.56,3.29,1.08,4.92,1.68-1.74-.11-3.45-.3-5.18-.45l-5-1.07-10.09-2.12-10.09-2.15-5.05-1.06C174.62,639.68,173,639.14,171.35,638.53Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M161.71,699.43a7,7,0,0,1,.19,1.16c-.14.3-.26.6-.4.9l-.78,1.82-.78,1.82-.4.91a7.71,7.71,0,0,1-1,.66,9.84,9.84,0,0,1-.19-1.15l.4-.91.78-1.82.79-1.82c.12-.31.26-.61.38-.91A7.16,7.16,0,0,1,161.71,699.43Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M212.19,706.47a5.49,5.49,0,0,1-.19,1l-.59.47-1.18,1-1.18,1-.59.48a5.34,5.34,0,0,1-1,0,5.44,5.44,0,0,1,.19-1l.59-.49,1.18-1,1.18-1,.58-.49A5.45,5.45,0,0,1,212.19,706.47Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M172.07,680.9c.21.68.34,1.36.48,2l-.15,2-.3,4-.3,4-.15,2c-.24.65-.47,1.29-.77,1.94-.2-.68-.34-1.36-.48-2l.15-2,.3-4,.3-4,.14-2C171.53,682.19,171.76,681.54,172.07,680.9Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M201.84,685.62a16.83,16.83,0,0,1,.51,1.77l-.13,1.71-.24,3.44-.24,3.43-.12,1.72a16.45,16.45,0,0,1-.75,1.67c-.21-.59-.35-1.17-.5-1.76l.12-1.72.24-3.43L201,689l.12-1.71A15.46,15.46,0,0,1,201.84,685.62Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M169.21,672.33l3.29,4.29-.14-.09,10.89,1.06c1.82.18,3.63.35,5.44.6l5.42.71h0l21.65,4.35-.72.81-2.5-7.56.52.43-15.22-1.87h-.06l-14.88-3.36H183L167.88,670l.48-.14-5.42,4.66.22-.48.1,25.53-.56-.62c1.71.2,3.41.48,5.11.72l5.11.76L183.14,702l-10.3-.85-5.15-.44c-1.71-.16-3.43-.27-5.14-.46a.64.64,0,0,1-.56-.63l-.08-25.54a.63.63,0,0,1,.21-.47l5.43-4.66a.6.6,0,0,1,.47-.15l15.13,1.69h.06l14.88,3.36H198l15.22,1.86a.61.61,0,0,1,.52.43l2.5,7.56a.63.63,0,0,1-.4.79.7.7,0,0,1-.32,0l-21.65-4.36h0l-5.43-.69c-1.81-.21-3.61-.5-5.41-.79l-10.8-1.73h0a.2.2,0,0,1-.13-.08Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M155.61,670.47c.32,1.3.56,2.6.8,3.91l.17,3.93.35,7.87v0l-.23,7.87-.11,3.93c-.25,1.31-.45,2.61-.74,3.92-.22-1.32-.34-2.64-.51-4l.11-3.94.23-7.86v0l-.35-7.86-.18-3.94C155.28,673.11,155.41,671.79,155.61,670.47Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M178.64,758.61c-.22-3.15-.34-6.29-.51-9.43-.13-1.58,0-3.15,0-4.72l0-4.72L178,720.88l.63.62-17.91,0,.62-.6-.69,15.37v0c0,1.29,0,2.57.11,3.86s0,2.58-.08,3.87c-.07,2.58-.13,5.16-.25,7.74-.24-2.57-.44-5.14-.66-7.72-.09-1.28-.24-2.57-.28-3.85s-.07-2.58-.09-3.87v0l.69-15.38a.63.63,0,0,1,.62-.6l17.92,0a.62.62,0,0,1,.62.62l.12,18.87,0,4.72c0,1.57.06,3.15-.09,4.72C179.05,752.32,178.88,755.47,178.64,758.61Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M192.43,719.48c1.16-.17,2.32-.28,3.47-.38l3.42.25,6.85.49L213,720l3.43.07c1.14.23,2.28.42,3.42.69-1.15.24-2.3.37-3.45.56l-3.44-.06-6.88-.14-6.87-.49-3.43-.24C194.69,720.09,193.55,719.82,192.43,719.48Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M192.42,728.19c1.12-.36,2.25-.64,3.38-.92l3.43-.29,6.87-.58h.07l6.87.2,3.44.1c1.14.24,2.28.44,3.41.73-1.15.22-2.3.34-3.45.52l-3.43-.1-6.87-.2h.07l-6.87.58-3.44.3C194.74,728.44,193.58,728.35,192.42,728.19Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M192.47,736.72c1.1-.44,2.2-.8,3.31-1.15l3.42-.53L206,734h.08l6.89-.19,3.45-.1c1.16.18,2.31.31,3.47.53-1.15.29-2.29.48-3.43.72L213,735l-6.9.19h.08l-6.83,1.06-3.41.53C194.81,736.81,193.65,736.8,192.47,736.72Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M192.34,744.21c1.12-.4,2.25-.73,3.38-1.05l3.46-.41,6.92-.84,6.89-1.07,3.44-.53c1.18,0,2.35,0,3.54.08-1.11.43-2.23.77-3.35,1.15l-3.45.54-6.9,1.07-6.93.84-3.46.42C194.7,744.37,193.52,744.33,192.34,744.21Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M192.47,752.83c1.05-.58,2.13-1.09,3.2-1.6l3.39-1,6.76-2h.05l6.9-1.4,3.44-.7c1.19,0,2.37-.1,3.57-.09-1.1.49-2.21.88-3.32,1.32l-3.44.7-6.9,1.4,0,0-6.76,2-3.38,1C194.85,752.6,193.67,752.75,192.47,752.83Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M225.46,678.15q2.82-.17,5.64-.27l2.82-.12c.94,0,1.88-.08,2.81,0L248,678a.63.63,0,0,1,.61.63l.1,22.38a.63.63,0,0,1-.62.63H248l-22.73-2.23a.62.62,0,0,1-.56-.58v0a24.45,24.45,0,0,1,0-2.84l.08-2.86c.05-1.9.11-3.8.19-5.7.21,1.89.38,3.79.55,5.68l.27,2.84a27.24,27.24,0,0,1,.17,2.85l-.57-.61,22.74,2.24-.69.62-.1-22.38.61.63L236.71,679a26.31,26.31,0,0,1-2.81-.14l-2.81-.23C229.21,678.49,227.34,678.33,225.46,678.15Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M220.08,681.43a4.74,4.74,0,0,1,.61-.59l.57,0,1.13.08,1.14.08.56,0a.63.63,0,0,1-.08,1.25l-.57,0-1.14-.08-1.13-.08-.57,0A5.58,5.58,0,0,1,220.08,681.43Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M220.1,695.5a4.88,4.88,0,0,1,.67-.52l.55.11,1.11.2,1.11.2.56.1a6.86,6.86,0,0,1,.44.72,7.18,7.18,0,0,1-.67.52l-.55-.11-1.11-.2-1.11-.2c-.19,0-.37-.08-.56-.1A5.27,5.27,0,0,1,220.1,695.5Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M252.58,679.61a18.77,18.77,0,0,1,.57,2l-.06,1.93-.13,3.86-.12,3.87-.06,1.93c-.21.63-.42,1.27-.69,1.91-.23-.65-.39-1.3-.56-2l.06-1.93.12-3.87.13-3.86,0-1.94C252.1,680.89,252.3,680.25,252.58,679.61Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M239.3,718.68c.22,1.07.37,2.13.51,3.19l-.13,3.17-.24,6.33-.25,6.33-.12,3.17c-.25,1-.45,2.1-.75,3.14-.21-1.06-.33-2.12-.5-3.19l.12-3.16.25-6.34.24-6.33.12-3.17C238.77,720.78,239,719.73,239.3,718.68Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M255.45,741.44c-.17-2.11-.3-4.21-.43-6.31l-.2-3.15c-.07-1.05,0-2.11,0-3.16l0-12.61.6.62L234.23,716l.66-.65.69,16.07-.05,4c0,.67,0,1.34,0,2l-.15,2c-.21,2.68-.39,5.36-.64,8-.17-2.68-.27-5.36-.39-8l-.09-2c0-.67,0-1.34,0-2l.05-4-.7-16a.63.63,0,0,1,.6-.65h.06l21.14.88a.62.62,0,0,1,.6.62l0,12.62c0,1,.06,2.1,0,3.15l-.19,3.16C255.74,737.23,255.62,739.33,255.45,741.44Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M231.19,635.36l7,1.3,3.65.66c1.22.23,2.48.43,3.68.77l13.94,4.32a.63.63,0,0,1,.44.58l.46,20.24a.63.63,0,0,1-.61.64h-.13l-14.44-2.55h.07l-15.3-.93a.63.63,0,0,1-.59-.65l.32-6.23.17-3.12c0-1,.08-2.1.21-3.1C230.49,643.23,230.85,639.24,231.19,635.36Zm0,0c-.05,4.26-.09,8.4-.07,12.48,0,1,0,2-.09,3l-.12,3-.28,6-.58-.66,15.3.93h.07l14.44,2.55-.73.63L258.67,643l.44.58-13.9-4.29c-1.16-.24-2.27-.58-3.41-.91l-3.45-1C236,636.72,233.64,636,231.19,635.36Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M246.73,639.68c.27.89.47,1.79.67,2.68l0,2.68.07,5.37.07,5.36,0,2.69c-.18.89-.35,1.79-.59,2.69-.26-.89-.46-1.78-.66-2.67l0-2.69-.07-5.36-.08-5.37,0-2.68C246.31,641.48,246.48,640.58,246.73,639.68Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M261.11,619c2.61-.39,5.23-.75,7.85-1.1a.34.34,0,0,1,.2,0l0,0,4.9,2.71c1.65.88,3.2,1.93,4.8,2.87l9.57,5.82a.66.66,0,0,1,.3.55c-.15,7.24-.4,14.48-.47,21.7l-.13,10.85c0,.9,0,1.81,0,2.71l.11,2.71.23,5.42c.13,3.62.37,7.23.41,10.85l.17,10.87.08,5.43c0,1.81-.12,3.62-.17,5.43l-.5,10.86c-.32-7.24-.65-14.48-.66-21.71l-.17-10.85c0-3.61-.28-7.23-.41-10.85l-.23-5.43-.12-2.71c0-.9,0-1.81,0-2.72l.13-10.87c.07-7.25.31-14.48.47-21.72l.3.55-9.57-5.82c-1.58-1-3.22-1.88-4.76-2.94l-4.66-3.1.22,0Q265.07,618.86,261.11,619Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M269.24,627.23a8,8,0,0,1-.66-1.06c0-.35,0-.71,0-1.07l0-2.15,0-2.14,0-1.08a9.82,9.82,0,0,1,.61-1.08,7.86,7.86,0,0,1,.64,1.06l0,1.07,0,2.15,0,2.15c0,.35,0,.71,0,1.07A8.42,8.42,0,0,1,269.24,627.23Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M282,638.4a17.44,17.44,0,0,1,.64,1.93v11.59c-.19.64-.37,1.29-.62,1.93-.25-.64-.44-1.28-.63-1.93V640.33C281.54,639.69,281.73,639,282,638.4Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M281.36,665.45a17.42,17.42,0,0,1,.68,1.91l0,1.93.11,3.87.11,3.86.05,1.93c-.17.65-.33,1.3-.57,1.95-.27-.64-.47-1.27-.68-1.91l-.05-1.94-.11-3.86-.11-3.86-.06-1.93A18,18,0,0,1,281.36,665.45Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M281.34,689.92a15.53,15.53,0,0,1,.7,1.64l0,1.66.13,3.33.13,3.33.06,1.66c-.17.56-.33,1.12-.56,1.69-.27-.55-.48-1.1-.69-1.64l-.06-1.67L281,696.6l-.13-3.33-.07-1.67A15.84,15.84,0,0,1,281.34,689.92Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M232.78,684.57a3.61,3.61,0,0,1,2.43-1.91,6.63,6.63,0,0,1,3.12.5,5.54,5.54,0,0,1,1.36.82,7,7,0,0,1,1.15,1.09,6.53,6.53,0,0,1,1.25,2.91,6.92,6.92,0,0,1-1.88,5.81,6.28,6.28,0,0,1-2.69,1.63,5.9,5.9,0,0,1-1.57,0,9.44,9.44,0,0,1-1.46-.36,7.62,7.62,0,0,0,2.66-.84,5,5,0,0,0,2.16-1.32,5.61,5.61,0,0,0,1.54-4.74,5.34,5.34,0,0,0-1-2.32,6,6,0,0,0-.93-.88,4.53,4.53,0,0,0-1.09-.66,5.15,5.15,0,0,0-2.5-.39A3.55,3.55,0,0,0,232.78,684.57Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M230.56,670.29c1.24,0,2.47,0,3.7,0l1.85,0a11.23,11.23,0,0,1,1.84.1l7.33.76L260,672.5a.62.62,0,0,1,.57.62l.14,11.18,0,2.8c0,.47,0,.93,0,1.4a13.13,13.13,0,0,1-.07,1.4c-.16,1.87-.29,3.73-.48,5.6-.24-1.86-.41-3.73-.61-5.59a10.85,10.85,0,0,1-.11-1.39c0-.47,0-.94,0-1.4l0-2.8-.14-11.19.57.62-14.68-1.32-7.33-.75a11.4,11.4,0,0,1-1.83-.28l-1.81-.36Q232.38,670.7,230.56,670.29Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M222.3,607.37c1-.44,2.08-.86,3.13-1.27l1.57-.62a9.93,9.93,0,0,1,1.59-.54l6.47-1.82L248,599.48a.64.64,0,0,1,.63.17l7.08,7.59,1.77,1.9a17.64,17.64,0,0,1,1.72,2c1.05,1.39,2.12,2.76,3.14,4.17-1.34-1.11-2.63-2.28-3.94-3.42a18.69,18.69,0,0,1-1.83-1.84l-1.77-1.9-7.09-7.59.63.17-12.94,3.65-6.46,1.83a10.24,10.24,0,0,1-1.64.37l-1.67.29C224.52,607,223.42,607.21,222.3,607.37Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M243.94,598.42c-1,.25-2,.42-3,.59l-3,0-5.94-.09-5.94-.08-3,0c-1-.21-2-.41-3-.67,1-.24,2-.41,3-.59l3,0,5.94.08,5.93.08,3,0C242,598,243,598.15,243.94,598.42Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M228.64,605.91a11.28,11.28,0,0,1,.76,1.4l.12,1.45.25,2.9.25,2.9.12,1.45a14.65,14.65,0,0,1-.5,1.5c-.29-.46-.51-.93-.75-1.39l-.12-1.45-.25-2.9-.25-2.9-.13-1.45A13.33,13.33,0,0,1,228.64,605.91Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M261,613.1a17,17,0,0,1,.52,1.88l-.11,1.86-.23,3.7-.22,3.7-.12,1.85a18.5,18.5,0,0,1-.73,1.81c-.22-.63-.36-1.26-.52-1.88l.12-1.86.22-3.7.23-3.7.1-1.85C260.48,614.3,260.7,613.7,261,613.1Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M298.92,697.93c1.84-.07,3.68-.07,5.52-.06l5.46.57,10.92,1.13h-.06l11,.08,5.49,0c1.82.22,3.65.39,5.48.66-1.83.24-3.66.39-5.49.59l-5.49,0-11-.08h-.06l-10.92-1.13-5.46-.56C302.51,698.75,300.71,698.38,298.92,697.93Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M342.62,690.08c-1.88,0-3.74-.16-5.6-.28l-5.49-.9-11-1.79-10.86-2.44-5.43-1.22c-1.77-.61-3.55-1.17-5.3-1.83,1.87.16,3.71.41,5.56.61l5.42,1.22,10.85,2.43,11,1.79,5.48.89C339,689,340.83,689.52,342.62,690.08Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M342.8,678.29c-1.88-.2-3.74-.5-5.61-.76l-5.46-1.37-10.92-2.73-11-2.56-5.48-1.28c-1.78-.64-3.57-1.22-5.34-1.89,1.88.18,3.75.45,5.62.67l5.49,1.28,11,2.56L332,674.94l5.46,1.37C339.26,677,341.05,677.58,342.8,678.29Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M-43.25,498.31l11.57,3.29,5.78,1.64c1.92.57,3.89,1,5.84,1.46l11.69,2.81c1.95.47,3.88,1,5.8,1.6l5.78,1.64L9,512.39l2.89.82c1,.28,1.95.47,2.92.71l11.71,2.72c3.91.88,7.8,1.89,11.68,2.89l11.66,3a.63.63,0,0,1,.47.61l-.22,17.46.34,17.46-.6,17.45.61,17.43V593l-.72,15.2c-.17,2.54-.07,5.08-.1,7.61v7.61c0,10.15,0,20.32.1,30.47,0,5.07.1,10.15.22,15.23l.17,7.61c.05,2.54,0,5.08.07,7.62,0,5.08.08,10.16.07,15.24l-.07,15.24-.46-15.23c-.16-5.08-.41-10.15-.6-15.23-.1-2.54-.24-5.07-.28-7.61l-.15-7.62c-.12-5.08-.18-10.16-.22-15.24-.14-10.16-.09-20.32-.1-30.48v-7.63c0-2.54-.06-5.08.11-7.62l.72-15.23V593L48.6,575.5,49.19,558l-.33-17.45.22-17.47.47.61-11.65-3c-3.88-1-7.76-2-11.68-2.89L14.5,515.13c-1-.24-2-.43-2.92-.7l-2.9-.83L2.89,512c-3.86-1.09-7.71-2.22-11.59-3.23l-11.59-3.22c-1.93-.55-3.88-1-5.78-1.65L-31.8,502Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M-43.18,550.7c6.23,1.07,12.44,2.21,18.66,3.32,1.56.25,3.13.48,4.68.77l4.66.87,9.32,1.71,18.57,3.81,18.6,3.64-.74.63-.45-13.33v0l.57-13.64.46.63-18.5-5.2L-6,529.26-15.34,527l-4.68-1.1c-1.56-.37-3.1-.81-4.65-1.22l-18.59-4.93,18.84,3.88c1.57.34,3.14.65,4.7,1l4.67,1.12,9.35,2.24L13,532.66l18.52,5.2a.62.62,0,0,1,.45.63l-.57,13.64v0l.45,13.33a.64.64,0,0,1-.6.65l-.15,0-18.6-3.63L-6.1,558.6l-9.32-1.71-4.66-.85c-1.56-.29-3.1-.63-4.64-1C-30.88,553.62-37,552.2-43.18,550.7Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M31.68,538.92c-.79.54-1.6,1-2.41,1.51s-1.74.66-2.61,1l-5.23,1.93.41-.55-.19,3c0,.5-.08,1-.08,1.5a8,8,0,0,1-.74,1.46,8.85,8.85,0,0,1-.54-1.54c.06-.5.07-1,.11-1.5l.19-3a.62.62,0,0,1,.41-.55l5.23-1.93c.88-.3,1.73-.69,2.63-.93S30.73,539,31.68,538.92Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M16.34,542.88c-2.55-.27-5.09-.64-7.63-1l-7.5-1.59c-5-.93-9.92-2.42-14.86-3.72s-9.84-2.74-14.82-3.86L-35.91,531c-2.43-.79-4.88-1.54-7.3-2.38,2.54.34,5.06.78,7.6,1.17l7.45,1.78c5,1.12,9.88,2.53,14.81,3.85s9.83,2.8,14.85,3.72L9,540.68C11.43,541.41,13.9,542.09,16.34,542.88Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M-43.29,609.2c6.27.56,12.53,1.2,18.8,1.8,1.56.23,3.11.44,4.66.71l4.65.82,9.29,1.61,18.67,2.53,18.76,1.89-.69.64-.53-13.75.38-13.77.53.64L12.46,589.4l-18.6-3.82-9.3-1.85-4.65-.91c-1.55-.31-3.09-.68-4.63-1l-18.55-4,18.75,3c1.56.28,3.12.52,4.67.83l4.65.93,9.31,1.86,18.58,3.81,18.73,2.91a.64.64,0,0,1,.53.64l-.38,13.72.53,13.71a.61.61,0,0,1-.6.65h-.09l-18.77-1.89-18.72-2.53-9.29-1.61L-20,613c-1.55-.26-3.09-.59-4.63-.9Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M31.22,591.3c-.75.68-1.51,1.35-2.29,2A15.31,15.31,0,0,1,26.44,595l-5.1,3.14.29-.62.4,2.63.2,1.32c.1.21,0,.46-.12.69s-.19.48-.3.73q-.27-.3-.51-.6c-.14-.21-.35-.4-.31-.63l-.2-1.32-.4-2.64a.62.62,0,0,1,.29-.62l5.11-3.14a14.88,14.88,0,0,1,2.62-1.46C29.33,592,30.27,591.66,31.22,591.3Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M16.41,597c-2.52-.23-5-.56-7.54-.85l-7.42-1.47c-4.93-1.09-9.95-1.66-14.95-2.41s-10-1.31-14.95-2.4l-7.43-1.46c-2.44-.7-4.89-1.34-7.32-2.08,2.53.24,5,.57,7.54.85l7.43,1.46c4.93,1.08,10,1.66,14.94,2.4s10,1.31,15,2.41L9.1,595C11.54,595.65,14,596.29,16.41,597Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M83.71,693.91l-26.79-3a.42.42,0,0,1-.37-.4h0L56,681.09c-.18-3.14-.31-6.28-.41-9.42v0l.81-19.12.28-19.13v0L55.5,614.28v0l.2-14.31c.09-4.77.06-9.54.07-14.31s0-9.55.12-14.32L56.22,557a.63.63,0,0,1,.64-.61h0l26.18,2.84a.62.62,0,0,1,.56.6l.42,16.78.05,2.1-.06,2.09L84,585l-.22,8.39-.12,4.19v12.57c0,2.79,0,5.58,0,8.37l.21,8.39.21,8.38c0,2.79,0,5.59-.06,8.39L83.93,652c0,2.8-.17,5.58-.26,8.38l-.33,8.37c-.1,2.79-.3,5.58-.13,8.41Zm0,0-1-16.78c-.26-2.75-.15-5.55-.14-8.35l.07-8.38,0-8.39.09-8.38c0-2.79.08-5.58.06-8.37l-.22-8.38-.21-8.38c-.08-2.79,0-5.59,0-8.39V597.52l.11-4.18L82.7,585l.11-4.19,0-2.09,0-2.1-.42-16.75.56.61-26.18-2.83.69-.61-.33,14.31c-.16,4.76-.1,9.54-.12,14.31s0,9.54-.07,14.31l-.2,14.32v0L58,633.34v0l-.28,19.15-.8,19.13v0q.21,4.71.3,9.43l.21,9.43-.37-.41C65.81,691.33,74.69,692.67,83.71,693.91Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M74.93,530.21c.3.93.52,1.88.74,2.82l.09,2.84.21,5.69.21,5.69.1,2.84c-.15,1-.3,1.91-.52,2.87-.29-.94-.5-1.88-.73-2.82l-.1-2.85-.21-5.68-.21-5.69-.11-2.85C74.56,532.12,74.71,531.16,74.93,530.21Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M49.46,565.68a5.82,5.82,0,0,1,1-.51c.3.05.6.08.9.12l1.82.22,1.82.23.91.11a8,8,0,0,1,.83.74,8.48,8.48,0,0,1-1,.51l-.91-.12L53,566.76l-1.82-.23-.91-.11A6.22,6.22,0,0,1,49.46,565.68Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M52.83,575.79a4.22,4.22,0,0,1,.56-.56l.48.07,1,.13,1,.13.48.06a5.47,5.47,0,0,1,.4.69,4.78,4.78,0,0,1-.56.55l-.49-.06-1-.13-1-.13c-.16,0-.32,0-.49-.06A4.85,4.85,0,0,1,52.83,575.79Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M49.42,671.06a6.14,6.14,0,0,1,.95-.59c.3,0,.6,0,.91,0l1.82.1,1.83.09.91,0a8.9,8.9,0,0,1,.88.67,6.83,6.83,0,0,1-.94.58l-.91,0L53,671.87l-1.82-.09c-.31,0-.61,0-.92,0A8.69,8.69,0,0,1,49.42,671.06Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M52.85,680.36a4.84,4.84,0,0,1,.58-.55c.15,0,.32.05.48.08l1,.15,1,.15.48.08a5.48,5.48,0,0,1,.38.69,5.6,5.6,0,0,1-.57.54l-.49-.07-1-.15-1-.15a4,4,0,0,0-.48-.07A5,5,0,0,1,52.85,680.36Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M3.37,539.59c.26.84.45,1.67.63,2.51v15c-.19.84-.38,1.67-.63,2.51-.25-.84-.43-1.67-.62-2.51v-15C2.93,541.26,3.11,540.43,3.37,539.59Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M3.38,594.88c.26.82.46,1.64.65,2.46v2.46l0,4.92,0,4.92,0,2.46c-.19.82-.36,1.64-.61,2.46-.25-.82-.45-1.63-.64-2.45l0-2.46,0-4.92,0-4.92,0-2.46C2.94,596.53,3.12,595.71,3.38,594.88Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M-39.67,668.59l13.38.39c4.46.12,8.92.32,13.36.72l26.68,2h.05l18.09,2.71-.72.63L30.92,661l0-14.07.53.61L-4.08,642-13,640.89l-4.46-.53c-1.48-.18-3-.43-4.44-.64l-17.81-2.37,17.93,1.32c1.49.14,3,.25,4.47.43l4.47.54,8.92,1.08,35.52,5.55a.61.61,0,0,1,.52.61l0,14.06L32.42,675a.62.62,0,0,1-.61.64H31.7l-18.09-2.7h.05L-13,671c-4.45-.25-8.88-.8-13.32-1.15Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M21.64,653.43c.23.81.38,1.62.54,2.43l-.1,2.42-.18,4.83-.18,4.82-.09,2.42c-.23.8-.44,1.59-.72,2.39-.22-.81-.38-1.63-.54-2.44l.1-2.41.18-4.83.18-4.83.08-2.41C21.13,655,21.35,654.22,21.64,653.43Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M16.48,651.84c-2.37.06-4.73,0-7.09,0l-7-.63c-4.7-.35-9.36-1-14-1.55s-9.33-1.21-14-1.53l-7-.6c-2.33-.41-4.66-.77-7-1.22,2.37-.05,4.73,0,7.09,0l7,.59c4.7.32,9.36,1,14,1.53s9.34,1.2,14,1.55l7,.62C11.83,651,14.16,651.37,16.48,651.84Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M1.09,651.13c.3.8.52,1.62.74,2.43l.1,2.46.22,4.92.21,4.92.11,2.46c-.16.83-.31,1.65-.52,2.48-.29-.8-.51-1.62-.73-2.43l-.11-2.46L.89,661l-.21-4.92-.11-2.46C.72,652.79.87,652,1.09,651.13Z" fill="gray"/>
            </g>
            <circle cx="234.7" cy="687.99" r="0.91" fill="#231815" stroke="gray" stroke-miterlimit="10" stroke-width="0.5" opacity="0.5"/>
            <circle cx="239" cy="687.56" r="0.91" fill="#231815" stroke="gray" stroke-miterlimit="10" stroke-width="0.5" opacity="0.5"/>
            <g opacity="0.5">
              <path d="M233.42,690.56l.66-.41a3.06,3.06,0,0,0,.43.13,5.06,5.06,0,0,0,.88.16,5.26,5.26,0,0,0,.89,0,3.27,3.27,0,0,0,.44,0l.56.55a3.39,3.39,0,0,1-.41.7,4.9,4.9,0,0,1-.54,0,6.42,6.42,0,0,1-1.08,0,7.62,7.62,0,0,1-1.06-.2l-.52-.15A2.47,2.47,0,0,1,233.42,690.56Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M600.18,554.91c-.23-1.69-.38-3.38-.57-5.07,0-.42-.1-.84-.11-1.26v-3.81l-.07-10.13a.62.62,0,0,1,.38-.58l13-5.66,6.59-2.62a9.81,9.81,0,0,1,1.68-.58l1.71-.49c1.14-.34,2.28-.66,3.43-1-1,.57-2.1,1.12-3.15,1.65l-1.58.82a9.4,9.4,0,0,1-1.62.72l-6.57,2.62-13,5.65.37-.58.07,10.14,0,2.53,0,1.27c0,.42-.07.84-.1,1.26C600.52,551.53,600.39,553.22,600.18,554.91Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M611.26,613.43c.31.58.55,1.16.8,1.75l.16,1.81.33,3.62.34,3.61.17,1.81c-.14.62-.26,1.24-.46,1.87-.3-.58-.54-1.17-.79-1.75l-.17-1.81-.33-3.62L611,617.1l-.17-1.81A18,18,0,0,1,611.26,613.43Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M612.25,659c.22.66.37,1.31.52,2l-.11,1.93-.21,3.86-.21,3.87-.11,1.93c-.22.63-.44,1.27-.73,1.9-.21-.66-.36-1.31-.52-2l.11-1.93.21-3.87.21-3.86.1-1.93A17.74,17.74,0,0,1,612.25,659Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M612.22,568.12a19.72,19.72,0,0,1,.65,2v2l0,4.05,0,4v2c-.19.67-.37,1.35-.61,2-.26-.68-.45-1.35-.64-2l0-2,0-4,0-4.05,0-2C611.79,569.47,612,568.8,612.22,568.12Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M623.08,533.7c-1.27.68-2.55,1.3-3.83,1.92a12.29,12.29,0,0,1-2,.75l-2,.7-8,2.77.42-.64.41,5.19-.86-.53,7.72-3.13,1.93-.79,1-.4a6.44,6.44,0,0,1,1-.3c1.35-.36,2.69-.75,4.06-1.07-1.21.72-2.44,1.38-3.66,2.07a8,8,0,0,1-.92.48l-1,.38-1.93.79L607.66,545a.62.62,0,0,1-.81-.34.67.67,0,0,1-.05-.19l-.41-5.2a.63.63,0,0,1,.42-.64l8-2.77,2-.69a13.24,13.24,0,0,1,2-.63C620.27,534.25,621.67,534,623.08,533.7Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M636.41,547.27c.26,4.65.42,9.3.63,14V645c-.22,4.65-.38,9.31-.63,14-.25-4.65-.42-9.31-.63-14V561.23C636,556.57,636.15,551.92,636.41,547.27Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <g>
                <path d="M170.28,576.81l-1.63,4.06c-.07.16.15.26.27.27l6.44.6c.42,0,1.69-.67.9-.75l-6.44-.59.28.27,1.63-4.06c.17-.42-1.26-.25-1.45.2Z" fill="gray"/>
                <path d="M174.15,579.81l2.71,3.55c.3.39,1.7-.1,1.35-.56l-2.71-3.55c-.3-.39-1.7.11-1.35.56Z" fill="gray"/>
                <path d="M170.64,587.14l.14-2.95-1.32.47,5,.44a3.2,3.2,0,0,1,.94.09c.14.07,0-.07,0,.3,0,.85.07,1.69.1,2.54,0,.21,1.46,0,1.45-.39l-.11-2.9a.15.15,0,0,0-.12-.08l-6.09-.54c-.27,0-1.31.07-1.33.46l-.14,2.95c0,.17,1.43,0,1.45-.39Z" fill="gray"/>
                <path d="M183,586.68l2.52,1a1.43,1.43,0,0,0,.93-.09c.05,0,.65-.31.44-.39l-2.52-.95a1.49,1.49,0,0,0-.93.09c-.06,0-.66.31-.44.39Z" fill="gray"/>
                <path d="M185.23,584.9l-.07,4.15.62-.51-3,.75c-.89.23-.65,1.1.24.88l3-.75c.2,0,.61-.24.62-.51l.06-4.15c0-.69-1.45-.44-1.46.14Z" fill="gray"/>
                <path d="M189.29,588.72l3.12-.17c.83,0,1.1-1.15.11-1.1l-3.11.17c-.84,0-1.11,1.15-.12,1.1Z" fill="gray"/>
                <path d="M189.23,584.44l-.94,6.16a.12.12,0,0,0,0,.15l3.44,1.8c.39.2,1.61-.39,1.36-.53l-2.39-1.24c-.23-.12-.88-.32-1-.54.1.17.07-.32.11-.51.32-1.83.56-3.68.84-5.52.07-.43-1.38-.17-1.44.23Z" fill="gray"/>
                <path d="M198.05,590.39l2.93,1c.55.19,1.7-.6.86-.89l-2.92-1c-.55-.19-1.71.6-.87.89Z" fill="gray"/>
                <path d="M199.6,588.41l-.24,5.51c0,.68,1.44.43,1.46-.15l.24-5.51c0-.68-1.44-.43-1.46.15Z" fill="gray"/>
                <path d="M198.48,594.5l3-.89c.13,0,.73-.19.67-.4s-.69-.05-.78,0l-3,.89c-.12,0-.72.19-.67.4s.69,0,.79,0Z" fill="gray"/>
                <path d="M202.44,592.35l6,1.45-.28-.28-.29,1.42c-.11.51,1.35.28,1.44-.19l.3-1.43c0-.18-.13-.24-.27-.28l-6-1.44c-.43-.11-1.7.56-.9.75Z" fill="gray"/>
                <path d="M205.57,590l.88,5c.1.6,1.56.24,1.45-.39l-.87-5c-.11-.6-1.56-.24-1.46.39Z" fill="gray"/>
                <path d="M203.23,590.39l-.09,6.42a.47.47,0,0,0,.33.46l6.47,1.85c.66.18,1.71-.77.83-1l-6.47-1.85.32.46.08-6.42c0-.79-1.46-.59-1.47.1Z" fill="gray"/>
              </g>
            </g>
            <g opacity="0.5">
              <path d="M23.1,563.71c-.15-1.17-.27-2.35-.37-3.52l-.07-.88a8.44,8.44,0,0,1,0-.87l.07-1.75.3-7,.51.65L10.07,548l.73-.64.08,3.11c0,.52,0,1,.06,1.55s0,1-.06,1.56c-.12,2.08-.22,4.15-.38,6.23-.27-2.07-.47-4.14-.7-6.2-.05-.52-.13-1-.14-1.55s0-1,0-1.56l-.08-3.1a.63.63,0,0,1,.61-.65h.12L23.8,549.1a.64.64,0,0,1,.52.65l-.3,7L24,558.49a8.44,8.44,0,0,1,0,.87l-.15.87Q23.48,562,23.1,563.71Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M10.62,546.78c-.24.38-.5.74-.76,1.08s-.49.74-.88.92l-2,1.49.25-.54.25,4,.12,2a4,4,0,0,0,0,.5,4.91,4.91,0,0,1-.15.51c-.11.35-.25.69-.39,1a10,10,0,0,1-.51-1,3.52,3.52,0,0,1-.22-.49,4.18,4.18,0,0,0,0-.5l-.12-2-.25-4a.64.64,0,0,1,.25-.54l2-1.5a3,3,0,0,1,1.13-.57C9.78,547.05,10.19,546.91,10.62,546.78Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M19.39,554.78a7.12,7.12,0,0,1-1,1.3,6.39,6.39,0,0,1-1.49,1.24l-.16.09a.39.39,0,0,1-.5-.12A11.64,11.64,0,0,0,15.4,556a5.28,5.28,0,0,0-1.16-1,.25.25,0,0,1-.07-.34l.07-.11a10.94,10.94,0,0,1,.88-1.33c.33-.42.64-.84,1-1.22a.68.68,0,0,1,.94-.06A12,12,0,0,1,19.39,554.78Zm-.13,0A21.12,21.12,0,0,1,16.18,553l.93-.05c-.35.39-.75.73-1.14,1.09a10.17,10.17,0,0,1-1.26,1v-.45a5.3,5.3,0,0,0,1,1.17c.38.33.86.57,1.28.87l-.66,0a3.72,3.72,0,0,1,1.22-.91C18,555.38,18.56,555.12,19.26,554.78Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M23.75,617.8c-.25-1.12-.47-2.25-.66-3.38l-.14-.84a6,6,0,0,1,0-.85l-.07-1.7-.28-6.79.5.59L10,602l.76-.59-.11,3.13c0,.52,0,1,0,1.56s-.1,1-.15,1.56c-.25,2.07-.47,4.15-.76,6.23-.14-2.09-.22-4.18-.32-6.27,0-.52-.07-1.05,0-1.57s0-1,.07-1.56l.11-3.12a.63.63,0,0,1,.65-.61l.11,0,13.07,2.83a.64.64,0,0,1,.5.59L24.1,611l.07,1.7a6,6,0,0,1,0,.85l-.07.85C24,615.52,23.9,616.66,23.75,617.8Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M10.18,600.29c-.25.4-.52.78-.8,1.16a3.67,3.67,0,0,1-.92,1l-2.12,1.67.24-.51.12,4.12.06,2.06a4.43,4.43,0,0,0,0,.52,5.25,5.25,0,0,1-.17.52c-.12.35-.26.69-.42,1a10.48,10.48,0,0,1-.48-1c-.07-.16-.15-.33-.21-.51a2.9,2.9,0,0,0,0-.51l0-2.06-.12-4.13a.63.63,0,0,1,.24-.51l2.11-1.66a3.31,3.31,0,0,1,1.19-.67C9.3,600.62,9.74,600.45,10.18,600.29Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M19,608.87a8.82,8.82,0,0,1-.76,1.88A8.48,8.48,0,0,1,17,612.62l-.11.12a.43.43,0,0,1-.6,0,.36.36,0,0,1-.1-.15,13.27,13.27,0,0,0-.81-1.89,10.85,10.85,0,0,0-1.25-1.63.29.29,0,0,1-.05-.32l.06-.13a13.76,13.76,0,0,1,.9-1.6,14.39,14.39,0,0,1,1-1.52l0,0a.64.64,0,0,1,.9-.11l.12.13A15.37,15.37,0,0,1,19,608.87Zm-.13,0a21.42,21.42,0,0,1-2.8-2.57l1.06,0a14.38,14.38,0,0,1-1.11,1.46,14.4,14.4,0,0,1-1.21,1.38v-.46a10.89,10.89,0,0,0,.87,1.86A12.29,12.29,0,0,0,17,612.1l-.81,0a6.1,6.1,0,0,1,1.09-1.61A19.8,19.8,0,0,1,18.9,608.87Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M-43.51,623.11c3.16.14,6.32.38,9.48.57l9.4,1.19,18.8,2.33L13,629.5,22.36,631c3.09.7,6.18,1.35,9.26,2.08-3.15-.23-6.3-.56-9.45-.84l-9.35-1.46L-6,628.44l-18.81-2.33-9.4-1.18C-37.3,624.33-40.41,623.77-43.51,623.11Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M-43,565.72c3.15.38,6.27.85,9.41,1.28l9.28,1.89h0l14.12,1.75c4.72.49,9.38,1.41,14.07,2.19s9.38,1.48,14,2.7c4.65,1,9.17,2.48,13.71,3.88-4.69-.79-9.39-1.44-14-2.66S8.3,574.9,3.64,574.06s-9.31-1.7-14-2.18l-14.1-1.75h-.05l-9.28-1.89C-36.87,567.4-39.93,566.61-43,565.72Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M77.3,575.56l-7.11,7.63a.32.32,0,0,1-.45,0l0,0h0l-6.52-7.27a.49.49,0,0,1,0-.66v0l3.11-3.57c1-1.19,2.1-2.36,3.18-3.51l0,0a.61.61,0,0,1,.86,0l0,0L72.22,570c.6.63,1.22,1.25,1.78,1.89C75.15,573.15,76.27,574.38,77.3,575.56Zm-.13,0-4-3.32c-.65-.53-1.24-1.08-1.86-1.61L69.52,569l.9,0c-1,1.18-2.11,2.35-3.19,3.49L64,575.91v-.68l6.24,7.52h-.47Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M76,596.6,70.17,603a.29.29,0,0,1-.41,0h0l-6.47-6a.5.5,0,0,1,0-.68h0l3.08-3.4c1-1.13,2.09-2.24,3.16-3.32l0,0a.6.6,0,0,1,.85,0l0,0,1.5,1.79c.49.6,1,1.19,1.45,1.79C74.31,594.32,75.21,595.49,76,596.6Zm-.12,0-3.36-3.16c-.54-.5-1-1-1.55-1.53l-1.51-1.52h.92c-1,1.13-2.09,2.23-3.18,3.31L64,596.94v-.7l6.21,6.31h-.43Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M77,617.63l-6.83,6.85a.31.31,0,0,1-.41,0h0L62.4,618a.49.49,0,0,1,0-.69l0,0,0,0,7.12-7,0,0a.61.61,0,0,1,.84,0l0,0C72.71,612.83,74.87,615.33,77,617.63Zm-.12,0c-2.52-2.3-5-4.4-7.36-6.46h.87L63.08,618v-.72L70.16,624h-.42Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M76.83,638.67l-6.66,6.76a.3.3,0,0,1-.43,0h0L63.22,639a.48.48,0,0,1,0-.65v0l3.11-3.72q1.55-1.86,3.17-3.65v0a.64.64,0,0,1,.89,0l0,0c.58.64,1.14,1.31,1.7,2s1.13,1.3,1.66,2C74.85,636.17,75.88,637.45,76.83,638.67Zm-.13,0-3.76-3.49c-.61-.55-1.17-1.12-1.75-1.68s-1.15-1.11-1.69-1.69h.93q-1.56,1.85-3.21,3.63L63.94,639l0-.66L70.18,645h-.44Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <polyline points="56.63 553.4 70.4 554.63 84.18 555.78" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
            </g>
            <g opacity="0.5">
              <polyline points="123.3 711.53 134.92 713.4 134.6 720.05" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
            </g>
            <g opacity="0.5">
              <polyline points="105.24 738.43 90.95 737.63 76.7 736.36" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
            </g>
            <g opacity="0.5">
              <polyline points="105.23 748.49 90.49 747.18 75.73 746" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
            </g>
            <g opacity="0.5">
              <polyline points="105.29 757.03 91 757.73 76.67 757.46" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
            </g>
            <g opacity="0.5">
              <polyline points="105.21 764.34 90.89 766.56 76.64 769.23" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
            </g>
            <g opacity="0.5">
              <polyline points="105.15 772.37 90.88 776.83 76.74 781.72" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
            </g>
            <g opacity="0.5">
              <polygon points="-43.4 727.65 -24.7 727.19 -5.91 727.47 12.85 725.91 32.02 725.62 31.24 742.57 31.5 759.08 31.7 775.59 31.83 792.1 31.84 808.78 13.06 815.05 -5.91 820.21 -24.9 825.31 -43.28 831.42 -43.76 814.38 -43.75 797.02 -43.72 779.66 -43.7 762.3 -43.38 744.93 -43.4 727.65" fill="#231815"/>
              <polygon points="-43.4 727.65 -24.7 727.19 -5.91 727.47 12.85 725.91 32.02 725.62 31.24 742.57 31.5 759.08 31.7 775.59 31.83 792.1 31.84 808.78 13.06 815.05 -5.91 820.21 -24.9 825.31 -43.28 831.42 -43.76 814.38 -43.75 797.02 -43.72 779.66 -43.7 762.3 -43.38 744.93 -43.4 727.65" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
            </g>
            <g opacity="0.5">
              <path d="M7.17,745.09c.12,3,.14,5.95.2,8.91l-.43,8.88-.3,17.78v0l1,17.78.08,8.91c-.18,3-.31,5.94-.54,8.91-.28-3-.47-5.93-.71-8.89l-.09-8.89-1-17.75v0l.3-17.8L6.11,754C6.46,751,6.77,748,7.17,745.09Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M1.59,747c-1.23.31-2.47.58-3.7.85l-1.85.4c-.62.09-1.25.12-1.87.2l-7.49.83h-.06l-15.06.32-7.53.1c-.63,0-1.25,0-1.88,0l-1.89-.18c-1.25-.11-2.51-.22-3.77-.37,1.26-.19,2.51-.33,3.76-.48l1.88-.22c.63,0,1.26,0,1.88,0l7.53-.1L-13.4,748h-.06L-6,747.2c.63-.06,1.24-.17,1.87-.22h1.9C-.94,747,.32,747,1.59,747Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M13.37,741.82c.58-.86,1.21-1.67,1.83-2.48l2.26-2,4.5-4,4.5-4,2.26-2c.87-.53,1.75-1.06,2.66-1.54-.58.86-1.21,1.66-1.83,2.47l-2.25,2-4.5,4-4.51,4-2.24,2C15.17,740.82,14.3,741.35,13.37,741.82Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <line x1="31.23" y1="691" x2="31.11" y2="710.04" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
            </g>
            <g opacity="0.5">
              <polyline points="39.66 727.44 55.09 726.79 54.72 749.6" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
            </g>
            <g opacity="0.5">
              <line x1="231.81" y1="598.24" x2="220.03" y2="603.56" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
            </g>
            <g opacity="0.5">
              <line x1="240.6" y1="609.41" x2="252.68" y2="612.2" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
            </g>
            <g opacity="0.5">
              <line x1="240.15" y1="613.17" x2="244.58" y2="614.48" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
            </g>
            <g opacity="0.5">
              <line x1="248.51" y1="615.86" x2="252.39" y2="617.35" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
            </g>
            <g opacity="0.5">
              <line x1="336.09" y1="682.49" x2="337.06" y2="687.99" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
            </g>
            <g opacity="0.5">
              <line x1="337.01" y1="695.01" x2="336.53" y2="699.58" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
            </g>
            <g opacity="0.5">
              <line x1="335.93" y1="670.97" x2="336.68" y2="676.92" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
            </g>
            <g opacity="0.5">
              <line x1="324.22" y1="668.47" x2="324.36" y2="673.17" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
            </g>
            <g opacity="0.5">
              <line x1="324.29" y1="680.99" x2="325.01" y2="686.52" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
            </g>
            <g opacity="0.5">
              <line x1="325.03" y1="694.51" x2="324.78" y2="699.58" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25"/>
            </g>
            <g opacity="0.5">
              <path d="M299.36,674c.22,1.73.36,3.47.5,5.21l-.14,5.19-.27,10.38.1,10.39.06,5.19c-.19,1.74-.34,3.47-.57,5.21-.28-1.73-.46-3.47-.68-5.2l-.06-5.2-.11-10.4.28-10.39.13-5.2C298.83,677.43,299.06,675.7,299.36,674Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M665,613.63a12,12,0,0,1-.18,2.12,4.65,4.65,0,0,1-.93,2.08,5.94,5.94,0,1,1,0-8.4,4.76,4.76,0,0,1,.92,2.07A11.17,11.17,0,0,1,665,613.63Zm0,0a3.45,3.45,0,0,0-.62-2,6,6,0,0,0-1.38-1.37,4.69,4.69,0,1,0-3.31,8,4.66,4.66,0,0,0,3.31-1.37,6.59,6.59,0,0,0,1.37-1.37A3.38,3.38,0,0,0,665,613.63Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M665,631.66a12,12,0,0,1-.18,2.12,4.65,4.65,0,0,1-.93,2.08,5.94,5.94,0,1,1,0-8.4,4.76,4.76,0,0,1,.92,2.07A11.17,11.17,0,0,1,665,631.66Zm0,0a3.45,3.45,0,0,0-.62-1.95,6,6,0,0,0-1.38-1.37,4.69,4.69,0,1,0-3.31,8A4.66,4.66,0,0,0,663,635a6.59,6.59,0,0,0,1.37-1.37A3.38,3.38,0,0,0,665,631.66Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M665,649.69a12,12,0,0,1-.18,2.12,4.65,4.65,0,0,1-.93,2.08,5.94,5.94,0,1,1,0-8.4,4.76,4.76,0,0,1,.92,2.07A11.17,11.17,0,0,1,665,649.69Zm0,0a3.45,3.45,0,0,0-.62-2,6,6,0,0,0-1.38-1.37,4.69,4.69,0,1,0-3.31,8A4.66,4.66,0,0,0,663,653a6.59,6.59,0,0,0,1.37-1.37A3.38,3.38,0,0,0,665,649.69Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M665,667.72a12,12,0,0,1-.18,2.12,4.65,4.65,0,0,1-.93,2.08,5.94,5.94,0,1,1,0-8.4,4.76,4.76,0,0,1,.92,2.07A11.17,11.17,0,0,1,665,667.72Zm0,0a3.45,3.45,0,0,0-.62-2A6,6,0,0,0,663,664.4a4.69,4.69,0,1,0-3.31,8A4.66,4.66,0,0,0,663,671a6.59,6.59,0,0,0,1.37-1.37A3.38,3.38,0,0,0,665,667.72Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M672.54,565.17c3.42-1.1,6.85-2.09,10.28-3.14l10.43-2.52,20.85-5.06,21-4.43,10.49-2.22c3.54-.53,7.08-1.11,10.63-1.6-3.45,1-6.91,1.88-10.36,2.83l-10.5,2.21-21,4.43-20.84,5.06-10.42,2.53C679.59,563.9,676.08,564.58,672.54,565.17Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M687,552.05a7.53,7.53,0,0,1,.69,1.08c0,.37.05.74.07,1.11l.13,2.24.14,2.23.06,1.12a10.88,10.88,0,0,1-.55,1.15,11,11,0,0,1-.7-1.08l-.06-1.11-.14-2.24-.13-2.23c0-.37,0-.75-.08-1.12A9.43,9.43,0,0,1,687,552.05Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M699,558.72a8.23,8.23,0,0,1,.63,1.09c0,.37,0,.73,0,1.09v5.45a9.29,9.29,0,0,1-.63,1.09,10.06,10.06,0,0,1-.63-1.08v-5.45c0-.37,0-.73,0-1.09A7.72,7.72,0,0,1,699,558.72Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M681.42,562.82a7.56,7.56,0,0,1,.6,1c0,.34,0,.69,0,1l-.06,2.06-.06,2.06,0,1a10.84,10.84,0,0,1-.66,1,9.25,9.25,0,0,1-.59-1l0-1,.06-2.06.06-2.06c0-.34,0-.68,0-1A8.38,8.38,0,0,1,681.42,562.82Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M710.49,547.27a9.61,9.61,0,0,1,.63,1.1c0,.37,0,.74,0,1.11l0,2.2,0,2.2V555a9.4,9.4,0,0,1-.63,1.1,10.29,10.29,0,0,1-.62-1.11v-1.1l0-2.2,0-2.21c0-.36,0-.73,0-1.1A8.83,8.83,0,0,1,710.49,547.27Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M722.51,553.3a11,11,0,0,1,.69,1.16l0,1.2.1,2.38.11,2.38,0,1.19a11.1,11.1,0,0,1-.58,1.21,10.51,10.51,0,0,1-.67-1.16l-.06-1.19-.1-2.38-.1-2.38-.06-1.19A9.63,9.63,0,0,1,722.51,553.3Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M732.07,551.15c-.24-1.71-.4-3.42-.59-5.13,0-.43-.1-.86-.13-1.29v-1.29l0-2.57.41-10.28.82.62-19.41,6.25.44-.6c0,.74,0,1.47,0,2.2s-.13,1.47-.19,2.2c-.13,1.47-.26,2.94-.43,4.4-.18-1.46-.32-2.93-.46-4.39-.07-.74-.16-1.47-.2-2.2s0-1.47,0-2.2a.62.62,0,0,1,.43-.6l19.4-6.25a.63.63,0,0,1,.79.41.76.76,0,0,1,0,.21l-.41,10.25,0,2.56,0,1.29c0,.42-.06.85-.1,1.28C732.38,547.73,732.27,549.44,732.07,551.15Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M743.84,548.65a10,10,0,0,1,.53,1.36l-.11,1.32-.2,2.64-.21,2.64-.1,1.32a11.79,11.79,0,0,1-.73,1.27c-.21-.46-.36-.91-.52-1.37l.1-1.32.21-2.64.2-2.63.1-1.32A10.9,10.9,0,0,1,743.84,548.65Z" fill="gray"/>
            </g>
            <g opacity="0.5">
              <path d="M575.81,601.36c.26,2.25.42,4.5.63,6.76v40.56c-.22,2.26-.38,4.51-.63,6.77-.25-2.26-.42-4.51-.63-6.77V608.12C575.39,605.86,575.55,603.61,575.81,601.36Z" fill="gray"/>
            </g>
            <path d="M477.65,592.34s-4,3.51-2.51,10" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" opacity="0.5"/>
            <path d="M482.15,590.34s6-2,8.52,3.5" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" opacity="0.5"/>
            <circle cx="477.4" cy="598.6" r="1.06" fill="#231815" stroke="gray" stroke-miterlimit="10" stroke-width="0.5" opacity="0.5"/>
            <circle cx="483.91" cy="596.6" r="1.06" fill="#231815" stroke="gray" stroke-miterlimit="10" stroke-width="0.5" opacity="0.5"/>
            <path d="M479.65,600.86s3.51.5,5.51-2" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" opacity="0.5"/>
            <path d="M476.14,604.36a4.52,4.52,0,0,0,4.51,2v2.51" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" opacity="0.5"/>
            <path d="M478.15,608.87a14.33,14.33,0,0,0-4,12" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" opacity="0.5"/>
            <line x1="481.65" y1="610.37" x2="485.16" y2="611.87" fill="none" opacity="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" stroke="gray"/>
            <line x1="490.17" y1="607.37" x2="486.66" y2="611.37" fill="none" opacity="0.5" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" stroke="gray"/>
            <path d="M492.67,605.36s6.51,2,7.51,7.52" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" opacity="0.5"/>
            <path d="M491.67,598.35a5.35,5.35,0,0,0,5.51.5" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" opacity="0.5"/>
            <path d="M491.67,595.35s2.5,1,4,0" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" opacity="0.5"/>
            <path d="M491.67,601.36s2.5,2.5,7.51,2.5" fill="none" stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" opacity="0.5"/>
          </g>
        </g>
        <g>
          <rect x="440.05" y="746.78" width="49.6" height="14.53" rx="7.27" fill="#e0e4e5" style={{ mixBlendMode: 'multiply' }}/>
          <rect x="247.9" y="814.56" width="51.7" height="10.44" rx="5.22" fill="#e0e4e5" style={{ mixBlendMode: 'multiply' }}/>
          <g>
            <path d="M299.54,738.67l-2.21-8.74,0,0h0l0,0a45.13,45.13,0,0,0-3-9.66c-2.33-5.33-5.5-6.59-9.43-8.34l.06.23-.26-.21.44-.89c.83-.56,1.67-2.2,1.5-2.67a.6.6,0,0,0-.06-.11l.09-.17a8.34,8.34,0,0,0,.89-1.65h0a7.34,7.34,0,0,0,.25-5.15c-1.26-3.8-4.74-9.18-14.24-6.65,0,0-6.65.64-5.38,9.5l.05-.07a3.57,3.57,0,0,0,.88,4.72l-.29,2.17a58,58,0,0,0-9,3.41,11.2,11.2,0,0,0-5.37,5.67,94.31,94.31,0,0,0-4.06,11.36l4.16,1.09v.21l-4.16-1.3-1.13,5.68a8,8,0,0,0,1.6,6.56,1.13,1.13,0,0,0-1.52.94l-1.63,14.2a4.61,4.61,0,0,0,3,4.85l7.47,2.74.19,0c-.3,3.31-.38,6.76.26,8.76,1.5,4.73-.05,10.49,1.3,15.13s2.84,6.93,2.22,10.26,1.15,2.67,1.26,4.33-1.09,2.29-.16,3.51a2.71,2.71,0,0,0,1.23.79H264l-1.53,2.69a3.4,3.4,0,0,0-.45,1.54,3.05,3.05,0,0,0,1.94,3.15,6.64,6.64,0,0,0,4.4.06c2.47-1.14,4-2,4.42-4.45v-2.94a2.53,2.53,0,0,0,1.54-2.76c-.24-3.33-1.56-3.7-.94-6s.4-5.62.48-7.87,2.73-15.16,2.73-15.16a38.17,38.17,0,0,1,2,9.37c.41,4.79-1.19,11.13-1,12.15.47,2.2,2.54,3.67,1.94,4.77s-1.15,2.05.22,3.29h1c-.16,1.41-.4,4.18.11,5.44a17.5,17.5,0,0,0,1.8,3.11,2.63,2.63,0,0,0,1.81,1.11,8.69,8.69,0,0,0,3.89-.3c2.41-.83,1.19-3.92,1.19-3.92l-1.65-5.7a1.79,1.79,0,0,0,1.05-2.53c-1-2.22-.12-3.19.19-5.19s1.54-3.91,1.51-7,2.39-18.07,1.48-24.7-.48-9.72-.48-9.72l-.06,0c.12-.54.25-1.15.39-1.82,0-.1,0-.2,0-.3l.06.21a4.86,4.86,0,0,0-.86-3.48l-.06-.09-.6-2.2-.6-2.3,1-1.28,1.86.15a6.43,6.43,0,0,0,5.24-2h0A6.42,6.42,0,0,0,299.54,738.67ZM269.6,703.41v0a1.45,1.45,0,0,0-.29,0A1,1,0,0,1,269.6,703.41Zm-.81,7.72h0v0Z" fill="#f2f2f2"/>
            <path d="M269.6,714s5.29,6.93,6.92,8.56l2-.41s3.66-5.3,4.07-6.11l2-4.07,6.1,2.85,3.67,6.11,2.85,8.55L290,733.94v4.48h-5.7l.41-3.26-.41-.82-6.92-.4-.41.81-1.22,2.85-2.45-.81-4.88,1.63-2.85-.41h-4.07l-.82-4.07-10.18-2.45,3.67-11,3.66-4.89,5.7-2.85,6.11-2Z" fill="#ccc"/>
            <path d="M259.8,714.38l3.69-1.62s2.45,17.1,2,25.25l-3.26-.41S260.59,715.58,259.8,714.38Z" fill="#f2f2f2"/>
            <polygon points="289.96 746.56 289.96 750.22 290.77 751.85 292 753.48 291.59 757.55 277.34 764.88 260.64 761.22 258.61 746.56 276.52 744.93 289.96 746.56" fill="#ccc"/>
            <g>
              <path d="M270.35,697.78c1.38-1.73,4.29-2.85,6.49-3,6.07-.46,10.87,4.79,9.55,10.25,0,0-.78,2.15-1.28,3.68s-.45,6.88-4.78,8.53c0,0-5.19,2.12-8.47-3.88" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M280.68,708.82l-.67,2s.95,2.87-1.59,2.26" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M277.24,714.58a17.84,17.84,0,0,0,1.89.17" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <circle cx="276.38" cy="710.61" r="0.82" fill="#231815"/>
              <circle cx="282.3" cy="711.11" r="0.82" fill="#231815"/>
              <path d="M270.56,704s-1.3-1.37-2.29,0-1,4.15,2.08,5.58" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <line x1="269.51" y1="706.49" x2="270.71" y2="707.31" fill="#f5f1df" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M269.51,709.11a19.47,19.47,0,0,1,0,4.69" fill="#f5f1df" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M270.56,715a46,46,0,0,0,5.92,7.52" fill="#f5f1df" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M278.72,722.24s3.42-4.41,4.19-6.92" fill="#f5f1df" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M268.84,711a57.77,57.77,0,0,0-9,3.42,11.2,11.2,0,0,0-5.37,5.67,94.31,94.31,0,0,0-4.06,11.36l10.24,2.69" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M250.37,731.41l-1.13,5.68a8,8,0,0,0,2.33,7.36h0a8,8,0,0,0,6.11,2.16l12-.93,6.9-.74s.84-.08.72-1.54a1.81,1.81,0,0,0-.2-2l3.36-1,.85-1.59-5.6,1-1.65-1" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M257.55,739.14a12.21,12.21,0,0,1,4.53-1.37v-2" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <line x1="268.33" y1="738.23" x2="268.07" y2="740.4" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M284.87,711.93c3.93,1.75,7.1,3,9.43,8.34a45.13,45.13,0,0,1,3,9.66l-6,3.37" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <line x1="277.3" y1="723.46" x2="277.3" y2="732.08" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M268.86,714.75,267.68,718l4.73,6.89s.79-3.53,2.41-3.61" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M284,715l1,1.83-2.68,7.48a2.63,2.63,0,0,0-2-1.82" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M291.57,757.79c-2.58,3.15-8,4.76-16.19,4.94a61.29,61.29,0,0,1-14.93-2.07,6.53,6.53,0,0,1-4.91-7.21,7.25,7.25,0,0,1,.89-2.72,5.92,5.92,0,0,0,.5-4.28" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M258.65,749.84a22.12,22.12,0,0,0,11.77,6" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M290,747.73s-.81,2.84.86,4.2,1.27,3.9,1.27,3.9" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <line x1="276.3" y1="747.6" x2="276.3" y2="755.83" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M297.33,729.93l2.21,8.74a6.42,6.42,0,0,1-1.5,5.91h0a6.43,6.43,0,0,1-5.24,2l-10.8-.87s-3.34.38-5.46-.82" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M285.57,707.31s.85.54,1,1.08a3.63,3.63,0,0,1-1.87,2.84" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M259.58,757.69s-2.48,12.78-1,17.5-.05,10.49,1.3,15.13,2.84,6.93,2.22,10.26,1.15,2.67,1.26,4.33-1.09,2.29-.16,3.51,4,1.39,5.88,1.37,5.44,0,5.2-3.29-1.56-3.7-.94-6,.4-5.62.48-7.87,2.73-15.16,2.73-15.16a38.17,38.17,0,0,1,2,9.37c.41,4.79-1.19,11.13-1,12.15.47,2.2,2.54,3.67,1.94,4.77s-1.15,2.05.22,3.29h7.46s2.73-.57,1.78-2.79-.12-3.19.19-5.19,1.54-3.91,1.51-7,2.39-18.07,1.48-24.7-.48-9.72-.48-9.72-5.21,4.84-16.29,5C275.38,762.73,263.35,762.44,259.58,757.69Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M264,809.21l-1.53,2.69a3.4,3.4,0,0,0-.45,1.54h0a3.05,3.05,0,0,0,1.94,3.15,6.64,6.64,0,0,0,4.4.06c2.47-1.14,4-2,4.42-4.45v-3Z" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M287.79,806.37l1.78,6.14s1.22,3.09-1.19,3.92a8.69,8.69,0,0,1-3.89.3,2.63,2.63,0,0,1-1.81-1.11,17.5,17.5,0,0,1-1.8-3.11c-.7-1.73,0-6.3,0-6.3Z" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M281.27,768.35a8.23,8.23,0,0,1-.78,3,5.41,5.41,0,0,1-2.17,2.46l.15-.14c-.19.26-.4.56-.58.85s-.36.6-.51.92a11.33,11.33,0,0,0-.8,2,5.14,5.14,0,0,1,.14-2.27,6.64,6.64,0,0,1,.4-1.08,6.17,6.17,0,0,1,.58-1,.25.25,0,0,1,.12-.11l0,0a6.56,6.56,0,0,0,1.9-2C280.3,770.15,280.76,769.26,281.27,768.35Z" fill="#f5f1df"/>
              <line x1="281.49" y1="729.37" x2="286.61" y2="729.37" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M290,725a14.62,14.62,0,0,0-.85,5.54c.2,2.82.91,3.6.85,5.51s0,2.38,0,2.38a55.91,55.91,0,0,0-5.83,0l.75-2.42a1.27,1.27,0,0,0-1.12-1.66l-5.61-.46a1.28,1.28,0,0,0-1.31.84l-1.73,4.75" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M280.43,738.81l-1.57-1.37.21-.21a1.28,1.28,0,0,1,1.34-.29l3.72,1.45v2.47" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M276.4,699.4a25.71,25.71,0,0,1,1.09,5.59,1.9,1.9,0,0,0,1.5,1.78c2,.42,5.36.87,6.87-.28l.12,1.13.55.65a8,8,0,0,0,1.26-7c-1.26-3.8-4.74-9.18-14.24-6.65,0,0-6.65.64-5.38,9.5,0,0,1.26-1.9,2.85.32l.75,2.76a.29.29,0,0,0,.53,0l.62-1.23S277.35,705.42,276.4,699.4Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M259.94,714.6s2.39,16.9,2.14,23.17l6.25.46,4.29-1.41a1.79,1.79,0,0,1,1.05,0l2,.63" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M263.48,712.77S266,726.94,265.64,738" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M250.89,743.66l14.75,6.07-1.3,12.78a4.63,4.63,0,0,1-6.18,3.87l-7.47-2.74a4.61,4.61,0,0,1-3-4.85l1.63-14.2A1.14,1.14,0,0,1,250.89,743.66Z" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <line x1="265.64" y1="745.99" x2="265.64" y2="749.73" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
              <line x1="250.44" y1="752.27" x2="262.15" y2="756.7" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <polygon points="289.39 735.53 289.55 738.01 284.99 738.05 289.39 735.53" fill="#ccc"/>
          </g>
          <path d="M267.93,897.61H161.75a11.57,11.57,0,0,0-11.57,11.57c0,.23,0,.45,0,.68H118.87a11.57,11.57,0,0,0,0,23.14H225.05a11.57,11.57,0,0,0,11.57-11.57c0-.23,0-.46,0-.68h31.34a11.57,11.57,0,1,0,0-23.14Z" fill="#e0e4e5" style={{ mixBlendMode: 'multiply' }}/>
          <rect x="304.1" y="903.41" width="73.92" height="14.93" rx="7.46" fill="#e0e4e5" style={{ mixBlendMode: 'multiply' }}/>
          <g>
            <path d="M263.32,683c0-1.94-2.58-1.94-2.58-1.94l-2.15,2.16a.36.36,0,0,1-.59-.37L259,680a1.49,1.49,0,0,0-2.65-1.3l-2.05,3.82a.36.36,0,0,1-.67-.14l-.13-2.23a1.49,1.49,0,0,0-1.48-1.67h-.33a1.5,1.5,0,0,0-1.46,1.17l-1.68,7.85-.13.39-.09-.47.11-.16a2.29,2.29,0,0,0-.08-.48,1.14,1.14,0,0,0-.68-1,2.43,2.43,0,0,0-1.26-.33,8.46,8.46,0,0,0-1.22,0l.1,1.71-.08,1.22.6,3.05.42,2.91-4.78,13.84-7.84,1.64-8.27,2.41a42.22,42.22,0,0,0-5.18,1.51l.19-3.18a12.24,12.24,0,0,0,.51-4.36c-.08-1.19-.07-2.4,0-3.62l.64-10.51,0-.08,3.08-1.08S223,676.37,210.05,679c-21.54-3.45-20.68,11.41-20.68,11.41l.85.09c-.21.74-.43,1.63-.64,2.72-.86,4.52,3.59,9.61,3.59,9.61v0a4.39,4.39,0,0,0,2.35,2.33l-2.44,8.78L175,718.39l-1.93,49-.65,43.83.47-.17,19.51,38.69h3.62a92.79,92.79,0,0,1-.05,19.34c-1,10.41.11,28,.71,35.64h0v12.22h22.55a3.29,3.29,0,0,0,1.76-6.08l-9.72-6.14h-.08c-.22-10.27-.75-38.59.08-44.12a78,78,0,0,0,.73-10.87c3.07-.07,6.78-2.66,6.62-5.74-.34-6.46-.59-17.07.73-24.8a143.64,143.64,0,0,0,1.59-25.12h0L221,794c2-1.61,1.26-5,1.09-7.15-.14-1.89-.08-3.79-.15-5.68-.34-9.17-1.42-7.13.27-12.9s-.34-25.46-.34-25.46l-3.68-6.36.44-1.75-.44,1.75,32.22-14.82c3.87-7.74,10.31-26.43,10.31-26.43l4.52-10.31A1.9,1.9,0,0,0,263.32,683Zm-70.47,18.66a4.24,4.24,0,0,0,.3,1.11A3.68,3.68,0,0,1,192.85,701.61Zm.61-2.87a.28.28,0,0,0,0-.07l0,0Zm.22-.31a4.19,4.19,0,0,1,1.64-1.39v0A4.45,4.45,0,0,0,193.68,698.43Z" fill="#fff"/>
            <path d="M175,718.39l18.05-4.51.64,1.94,7.09,6.44,3.22-.64,5.8-5.8s6.45,0,8.38-1.94L233,710.66l5.16,18.69-18.69,7.09,1.93,21.27s3.22,7.08,0,12.88c0,0,.65,9.67.65,12.89s1.29,8.38-1.29,10.32H173.1Z" fill="#fff"/>
            <g>
              <path d="M209,695.11a7.4,7.4,0,0,1,2.59-.21" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.29"/>
              <path d="M216.73,694.68a4.33,4.33,0,0,1,1.72.65" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.29"/>
              <path d="M220.61,689.94c1.19,5.24-.14,10.83.21,16.16.22,3.23-.86,9.27-7.32,9.7a18,18,0,0,1-6-.86" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.29"/>
              <path d="M215.87,697.27s-.22,2.35-.22,2.37a5.48,5.48,0,0,1,.76.61,1.78,1.78,0,0,1,.49,1.3,1.67,1.67,0,0,1-2.32,1.52" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.29"/>
              <circle cx="209.85" cy="699.31" r="1.49" fill="#231815"/>
              <circle cx="218.9" cy="699.53" r="1.49" fill="#231815"/>
              <path d="M198.45,696.79a4.49,4.49,0,0,0-1.22-.17,4.42,4.42,0,1,0,0,8.84s.11,4.74,3.77,6.24" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.29"/>
              <line x1="196.05" y1="700.93" x2="198.63" y2="701.36" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.29"/>
              <path d="M206.6,690.81a9.31,9.31,0,0,1-3.15,5.77,3.65,3.65,0,0,0-1.37,2.82v.07a1.33,1.33,0,0,1-1.45,1.32h0a1.35,1.35,0,0,1-1-.7l-1.83-3.44a4.37,4.37,0,0,0-4.26,2,4.84,4.84,0,0,0-.33,4.12s-4.45-5.09-3.59-9.61,1.94-5.82,1.94-5.82L207,688.87Z" fill="#ccc" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.29"/>
              <path d="M194.37,704.86s.6,6.63-1.12,8.78" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.29"/>
              <line x1="200.07" y1="721.42" x2="193.53" y2="715.56" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
              <polyline points="203.81 724.48 204.82 729.23 213.31 720.74" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
              <polyline points="200.07 723.8 198.04 728.21 194.64 725.15" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
              <path d="M219.76,730.24l1.67,27.47a34.55,34.55,0,0,1,0,12.88c-1,5.94.19,1.4.53,10.57.07,1.89,0,3.79.15,5.68.17,2.16.89,5.54-1.09,7.15-1.56,1.27-7.5.43-9.32.43l-7.89,0" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
              <path d="M215.69,781.84a59.73,59.73,0,0,1-12.9,2.71" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
              <line x1="215.69" y1="786.93" x2="207.2" y2="786.25" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
              <line x1="202.45" y1="727.19" x2="202.45" y2="740.43" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
              <path d="M218.21,714.53s2.32-1.63,11-3.2,3.81-.77,3.81-.77l3.23,10.06" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
              <path d="M220.94,794.05a143.64,143.64,0,0,1-1.59,25.12c-1.32,7.73-1.07,18.34-.73,24.8.17,3.13-3.61,5.75-6.74,5.75H184.05L172.92,811l17.65-16.81Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
              <path d="M212,846a89,89,0,0,1-.68,14.6c-1,6.79,0,47.86,0,47.86H197s-2.38-25.8-1-39.38-.34-22.06-.34-22.06Z" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
              <path d="M211.27,904.7H196.68v12.22h22.55a3.28,3.28,0,0,0,2.53-1.18h0a3.29,3.29,0,0,0-.77-4.9Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
              <line x1="207.2" y1="862.96" x2="203.81" y2="859.9" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
              <line x1="210.9" y1="887.39" x2="195.9" y2="887.39" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
              <path d="M246.19,694.24l-.89-6.15-.15-2.74s3.74-.33,3.1,1.86l.24,1.33-.18,6Z" fill="#fff"/>
              <path d="M248.39,687.22h0c-.15-2.53-3-1.73-3-1.73l-.18,2.79.33,6.94-4.3,12.83L233,710.56" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
              <path d="M214.79,684.34s-6.52-1.08-8.43,7.65l-17-1.62s-.86-14.86,20.68-11.41Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.29"/>
              <path d="M214.79,684.34s6.4,3,6.33,7.65l3.37-1.18S223,676.37,210.05,679" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.29"/>
              <path d="M216.73,683.7s-5.17,3.87-5.39,6.67c0,0-.86-4.09.65-6.67Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.29"/>
              <path d="M210.7,706.53s1.29,1.08,4.31.65" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.29"/>
              <line x1="204.68" y1="720.97" x2="210.15" y2="715.56" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
              <line x1="220.79" y1="735.79" x2="246.57" y2="724.84" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
              <path d="M248.5,687.46l1.68-7.85a1.5,1.5,0,0,1,1.46-1.17H252a1.49,1.49,0,0,1,1.48,1.67l.13,2.23a.36.36,0,0,0,.67.14l2.05-3.81a1.47,1.47,0,0,1,1.6-.62h0A1.49,1.49,0,0,1,259,680L258,682.8a.36.36,0,0,0,.59.37l2.15-2.16s2.58,0,2.58,1.94a1.9,1.9,0,0,1,1.94,1.93l-3.87,8.38-2.58,2.58s-3.87,16.75-7.73,24.49" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
              <line x1="263.32" y1="682.95" x2="262.68" y2="684.24" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
              <line x1="258.81" y1="695.84" x2="256.23" y2="695.84" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            </g>
          </g>
          <g>
            <g>
              <path d="M184.56,765.19c-.74-4.17-1.56-8.13-1.56-8.13Z" fill="#f2f2f2"/>
              <path d="M197.94,795.41l-4.42-1.69,3.74-1c1.36-1,0-2.72,0-2.72h-8.83a18.43,18.43,0,0,1,2-2.71c1.7-2,0-2.72,0-2.72l-1.84.92a26.17,26.17,0,0,0-.76-4.77,7.28,7.28,0,0,0-1-3.5l-.73-3.62-1.61-8.39c.15.84.3,1.68.43,2.5l-3.18-13.52-8.48-5.43,1.36-3.73c9.16,0,9.33-9,9.33-9,1.22-.45,3.73-.67,2.38-3-.72-1.25-1.31-1.79-1-3.39a24,24,0,0,0,.26-5.33,2.29,2.29,0,0,0,1.44,0l2.37-.79c-2-.81-2.69-3.89-3.15-5.72a10.12,10.12,0,0,0-5.06-6.31c-.21-.12-2.57-1.56-4.15-1.29-2.81-1.34-12.33-5-20.9,2.46-10.18,8.82-1.36,19-1.36,19a4.84,4.84,0,0,0,1.58,2.06l-.56,2.28-3.9-4.17-20.71,14.6,0,.22-.13-.05-1,6.45v3s-3.05,1.36-3.73,3.39a8.28,8.28,0,0,0-.52,3.75l.18-.3-.1.26a8,8,0,0,0-4.62,6.08c-.84,5.58.71,11.12-.08,16.68-.73,5.21-2.81,10.69-1.31,16,1.44,5,7,5.41,10.18,5.2l0-.67,1.17.73.76,1.13a4.48,4.48,0,0,0,1.86,3.28,65.19,65.19,0,0,0-1.09,19.18c1,11.2,2.67,11.88,3,25.8s2.68,29.19.33,36-2,7.13-1.67,13.24h1.72v8.61a4.3,4.3,0,0,0,4.3,4.29h25a2.36,2.36,0,0,0,2.26-1.71h19.38a2.7,2.7,0,0,0,1.16-5.14l-12.71-6.06h2.8c3-6.79,1-8.48,1-18.33S182,859.22,184,849.72c1.81-8.56-.44-32.79-.9-37.54,1.67-4,1.08-6.35.18-8.28h10.56a1.57,1.57,0,0,0,.68-2.38c1.7-1.7,0-2.71,0-2.71l3.06-1A1.66,1.66,0,0,0,197.94,795.41Zm-42.71-56.94-.18.77-.13,0C155,739,155.14,738.73,155.23,738.47Zm27.41,64.11.05-.13.08.38C182.73,802.75,182.69,802.66,182.64,802.58Z" fill="#fff"/>
              <path d="M119.19,774.37v9.84A27.74,27.74,0,0,0,119.19,774.37Z" fill="#f2f2f2"/>
              <polygon points="120.55 784.89 118.17 784.89 117.83 788.62 119.53 788.62 120.55 784.89" fill="#f2f2f2"/>
            </g>
            <path d="M144.14,745.69s2.72,8.82,2,12.22l4.41,6.45,1.7,2.37v15.61H131.58l-.34-12.55.68-11.54,1.53,1.18,2-1h1l3.06-1,2.71-1.36v-1.36L144,753l-1-4.76-1-1.69Z" fill="#fff"/>
            <path d="M151.44,749.59s1,12.22-1,14.6l2,2.71-.34,21.72,28.17,3.06L182,790l4.42-3.05,2.37-1.36-1-6.45-1.69-4.07-3.06-18L181.65,754l-4.76-3-1.69,2.37-4.76,20L159.58,756Z" fill="#fff"/>
            <path d="M134.81,795.41s1.35,6.45,11.88,7.81h32.92l3.39.68s2,2.71.68,7.13H133.45l-1.7-2.72.68-6.11S134.81,796.77,134.81,795.41Z" fill="#fff"/>
            <path d="M176.55,897.92l-1,7.8,12.71,6.06a2.69,2.69,0,0,1,1.54,2.44h0a2.7,2.7,0,0,1-2.7,2.7h-19a9,9,0,0,1-6.85-3.14l-7.82-9.08v-6.11Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <circle cx="182.38" cy="730.98" r="1.24" fill="#231815"/>
            <path d="M183.14,738.28a3.59,3.59,0,0,1-3.19-.91" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <path d="M171.12,729.57s-2.71-2.72-5.43-.34-2.37,8.82,3.74,8.48" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <line x1="169.77" y1="733.3" x2="166.37" y2="732.28" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <path d="M174.86,725.15l-1,5.09-.16,0a1.46,1.46,0,0,1-2.47,0l-.88-1.36a3.79,3.79,0,0,0-2.26-.75l.34-4.07,6.79-.34Z" fill="#221714" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <path d="M185.38,721.42a28.86,28.86,0,0,1,0,8.15c-.29,1.6.3,2.14,1,3.39,1.35,2.38-1.16,2.6-2.38,3,0,0-.68,9.51-9.16,8.83l-1.36,3.73-4.75,3.74.31,1.32a2.55,2.55,0,0,0,4.28,1.21l3.21-3.21" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <path d="M156.19,735a21,21,0,0,1-3.39,8.14l14.93,9.17-.47,1.08a5.16,5.16,0,0,1-7.84,2.1l-8.32-6.24" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <line x1="168.07" y1="756.04" x2="169.43" y2="768.26" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <polyline points="175.34 752.85 170.44 773.35 159.24 755.36" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <line x1="151.3" y1="743.15" x2="141.93" y2="746.54" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <line x1="173.98" y1="748.88" x2="181.65" y2="754.34" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <path d="M183,757.06s2.38,11.54,2.72,16,2.71,5.09,2,8.83" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <path d="M183.24,803.77c.95,2,1.61,4.37-.2,8.61H136.88s-5.27.34-4.94-5.77,3.5-8.14,1.85-14.93" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <path d="M180.4,763.85s-1.65,6.45-.33,12.22a90.62,90.62,0,0,1,2,13.23" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <path d="M183,811.36s3,28.85,1,38.36-4.67,27.83-4.67,37.67,2,11.54-1,18.33H159.57l-2.07-94.35Z" fill="#221714" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <path d="M134,811a63.29,63.29,0,0,0-1.22,19.71c1,11.2,2.67,11.88,3,25.8s2.68,29.19.33,36-2,7.13-1.67,13.24h25.13s3.69-2.37,2.35-8.48-3.69-26.48-2.35-34.28S165,811,165,811Z" fill="#221714" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <path d="M152.12,774.14v8.2H131.41s-1-17.53.34-22.91" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <polyline points="152.12 782.51 152.12 788.62 180.29 791.34 179.61 796.09" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <path d="M179.61,803.22l-34-.3a12.18,12.18,0,0,1-11.85-11.11l-.68-9.3" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <path d="M180.29,791.34l6.11-4.75,4.07-2s1.7.68,0,2.72a18.43,18.43,0,0,0-2,2.71h8.83s1.36,1.7,0,2.72l-3.74,1,4.42,1.69a1.66,1.66,0,0,1-.34,2.38l-3.06,1s1.7,1,0,2.71a1.57,1.57,0,0,1-.68,2.38H183" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <path d="M177.91,710.56s-11.54-6.79-21.72,2-1.36,19-1.36,19h0a4.8,4.8,0,0,0,5,3l4.34-.49s-.84-5.55,3.91-5.89l7.13-5.43Z" fill="#221714" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <path d="M153.47,904.7l13.36,9.64a2.35,2.35,0,0,1,.94,2.38h0a2.37,2.37,0,0,1-2.32,1.9h-25a4.3,4.3,0,0,1-4.3-4.29V904.7Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <polygon points="182.66 722.79 181.99 725.83 173.5 726.51 173.84 722.78 182.66 722.79" fill="#221714" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <path d="M149,744s5.42,7.91,1.62,20.36" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <path d="M144.14,745.83s2.71,6,2.11,12" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <path d="M131.27,761.92l-3.56.65a8,8,0,0,0-6.45,6.65c-.84,5.58.71,11.12-.08,16.68-.73,5.21-2.81,10.69-1.31,16,1.44,5,7,5.41,10.18,5.2" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <line x1="130.05" y1="784.89" x2="122.59" y2="784.89" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <path d="M127,766.9a4.53,4.53,0,0,0-2.38,4.41c.34,3.06,1.36,6.79.68,10.53" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <path d="M119.19,774.37a27.74,27.74,0,0,1,0,9.84" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <polyline points="120.55 784.89 118.17 784.89 117.83 788.62 119.53 788.62" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <path d="M130.05,756s-3.05,1.36-3.73,3.39a8.28,8.28,0,0,0-.52,3.75" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <path d="M131.07,758.42s-2-.34-3,4.07" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <path d="M173.84,724.81s.1-12,2.37-14.25c1.36-1.36,4.78.73,5,.87a10.12,10.12,0,0,1,5.06,6.31c.46,1.83,1.14,4.91,3.15,5.72l-2.37.79a2.27,2.27,0,0,1-2.64-1l-.87-1.41C181.23,723.29,177.82,725.41,173.84,724.81Z" fill="#221714" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <line x1="152.12" y1="766.9" x2="145.33" y2="756.38" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <path d="M168.07,824.6,164,837.5s2,13.58,1,23.76-2.37,17-1,26.13a59.27,59.27,0,0,1-.58,18.33" fill="#231815" stroke="#f2f2f2" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <line x1="151.82" y1="731.89" x2="132.77" y2="744.84" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <path d="M140.92,750.61l1.35,5.09a3.76,3.76,0,0,1-3.05,1.7s-2.38,2-3.74,1a3.17,3.17,0,0,1-3.39.34l-2-5.77,1-6.45" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            <path d="M140.58,744.84l2.37,3.39,1,4.76a1.81,1.81,0,0,1-1.7,1.35" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
          </g>
          <g>
            <path d="M367.53,781.19a29.11,29.11,0,0,0-.3-9.19,58.55,58.55,0,0,0-4.49-16.49l-.22.18a18.38,18.38,0,0,0-2.67-3.82l0-.08,0-.12a4.05,4.05,0,0,0-.74-2l-.63-2.82v-3.41l.15-1.2a21.67,21.67,0,0,0-.15-6.53v-1.26s-1-2.91-2.27-6.3l-2.1-6.21a11.13,11.13,0,0,0-1.64-3.13,5.69,5.69,0,0,0-.36-.65,9.9,9.9,0,0,0-4.37-3.11,11.2,11.2,0,0,0-2.51-.75L338.11,713l0,.19-3,.34-10.27,9.21.53,4.08-2.29,15.37a6.56,6.56,0,0,1,.52-.49l-.62,1.15.1-.66a8,8,0,0,0-2.32,4.82h0a5.93,5.93,0,0,0,.88,3.36l-16.43-2.65,3.35-12.88,4.69-10.48a1.15,1.15,0,0,0,0-.89h0a1.16,1.16,0,0,0-2.14,0l-1.25,1.93a.74.74,0,0,1-1.15.23.75.75,0,0,1-.22-.8l2.13-6a1.77,1.77,0,0,0-.67-2.06c-.47-.3-1-.35-1.54.43l-1.46,3-.22-3.32a1.15,1.15,0,0,0-2.25-.21l-.7,2.7-1.27-2.82a1.21,1.21,0,0,0-2-.35,1,1,0,0,0-.2.92l.77,2.23s-2.21-1.93-2.87.61l.23,9.54-5,18.91-.65,3.93a6.31,6.31,0,0,0,0,2.3v.49a2.73,2.73,0,0,0,1.65,2.51l5.9,1.89,6.32,2.19-.1,1.11,18.4,5.71.46,4.4.39,6.14-.77,4.08-.21.7.07.13-.06.93h0v.28l-.57,9.1.2,0-1,12.56,1.28,50a5.41,5.41,0,0,0,4.4,5.17l-.77,0,1.82,38.32h-.35l-8.07,3a2.3,2.3,0,0,0,.8,4.45h17.9v-7.44s1.35-27.74.75-38.26h5.93l1.82,38.26-7.84,6.86a2,2,0,0,0,1.29,3.42h6.1a1.9,1.9,0,0,0,.85-.2l8.15-3.93a3.06,3.06,0,0,0,1.72-2.75v-3.4s1.39-28.51.73-38.69a5.39,5.39,0,0,0,3.31-5.18l-1.91-49.66v-8.51l-.21,0-.46-3.41,0-.07a8.05,8.05,0,0,0,7-8v-5.52l-.28,0Zm-41.56-3Z" fill="#fff"/>
            <g>
              <path d="M324.83,722.74l10.27-9.21,3.19-.35L347.5,715s3.19,1.06,4.61,3.19,6.37,16.29,6.37,16.29v12.4l.71,3.19.71,1.78-10.27,13.81-20.55-2.48-8.33-8.68,1.07-3.9L320.75,747l2.84-5.31,1.77-14.88Z" fill="#ccc"/>
              <path d="M336.16,717.43s2.84,2.48,5.67,8.5c1,2.06,3.08,1.63,5.32,4.25,0,0,.71,12.76-.71,15.59,0,0-5.67,5.67-8.86,6.73h-1.42l-1.41-34.36Z" fill="#f2f2f2"/>
              <polygon points="349.1 772.34 349.1 778.01 338.47 779.08 338.47 773.41 349.1 772.34" fill="#f6e105"/>
              <line x1="325.36" y1="749.14" x2="320.98" y2="753.65" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M336.15,717.28c-2.42,1.33-6,3.88-6.89,7.77-1.42,6-5.67,16.65,3.18,20.19l1.07,4.25,2.83,3.19" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M333,727.12l.59,3.87s-2,.86-.7,3.09" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <circle cx="339.22" cy="731.25" r="1.33" fill="#231815"/>
              <circle cx="330.03" cy="730.96" r="1.33" fill="#231815"/>
              <path d="M338.68,736.12l-6.53.15S334,739.46,338.68,736.12Z" fill="#221714" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M328,782.44V780l2.12-1.06,4.61,4.25H355.3V780l12.4-.71a60,60,0,0,0-5-23.73" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <polyline points="328.02 779.96 325.89 780.32 324.83 783.86 326.24 786.34" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M324.83,784.92v5.32l2.12,2.48,6.38.35,25.74,1.86a8,8,0,0,0,6.27-2.34h0a8,8,0,0,0,2.36-5.69v-5.52" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <polyline points="330.14 778.9 330.14 765.79 350.69 762.96 352.46 765.79 352.46 783.15" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <line x1="334.75" y1="768.98" x2="349.63" y2="766.85" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <line x1="334.04" y1="772.52" x2="334.04" y2="778.9" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <line x1="358.84" y1="754.45" x2="353.17" y2="760.48" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <polyline points="324.47 794.13 323.41 806.89 361.32 806.89 361.32 798.38" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M323.41,806.89l1.28,50a5.41,5.41,0,0,0,5.4,5.27h27.74a5.41,5.41,0,0,0,5.4-5.61l-1.91-49.66Z" fill="#221714" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M347.1,862.1l1.82,38.32h10.27s1.42-29,.71-39Z" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M324.83,763.66l1,5.06a21.58,21.58,0,0,1,.36,4.81l-.26,6.79" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M348.92,900.42l-7.84,6.86a2,2,0,0,0-.66,1.47h0a2,2,0,0,0,1.95,2h6.1a1.9,1.9,0,0,0,.85-.2l8.15-3.93a3.06,3.06,0,0,0,1.72-2.75v-3.4Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M328.32,862.1l1.82,38.32h10.28s1.41-29,.7-39Z" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M329.79,900.42l-8.07,3a2.29,2.29,0,0,0-1.5,2.15h0a2.3,2.3,0,0,0,2.3,2.3h17.9v-7.44Z" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M344.49,744.89a11.58,11.58,0,0,1-6.38.7" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <polygon points="338.29 779.25 348.92 778.19 348.92 772.52 338.29 773.58 338.29 779.25" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <line x1="348.92" y1="775.44" x2="338.29" y2="776.42" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M335.63,716.9a14.4,14.4,0,0,1,4.47,5.56,16.87,16.87,0,0,0,8.87,8.41l.48.2" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M351.58,742.76a44.72,44.72,0,0,0-9.22,6.73c-3.89,3.9-8.5,3.9-12.4,6.73" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M338.11,713l7.12,1.28a11.52,11.52,0,0,1,8.88,7.64l3.52,10.4a22,22,0,0,1,1,9.9,26,26,0,0,0-.32,5.5c.35,2.48,3.59,3.54-.51,7.26l-5.16,6.2-3.19,4.61" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <line x1="334.92" y1="713.71" x2="325" y2="722.92" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M325.36,726.82l-1.77,14.88s-5,4.12-1.78,9" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <polygon points="329.15 743.06 329.26 748.43 333.01 754.2 336.34 752.68 333.51 749.49 332.44 745.24 329.15 743.06" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M353.35,748.43a24.34,24.34,0,0,0-6.45,4.79c-2.91,3.32-3.49,6.32-8.08,8" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M347,730s1.13,12.4-.35,15.94" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M324.65,747a62.22,62.22,0,0,1,3.9,6.38" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <polyline points="318.36 750.37 307.87 748.4 306.56 762.83 325.57 768.73" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M304.92,748.81l3.63-14,4.69-10.49a1.15,1.15,0,0,0,0-.89h0a1.16,1.16,0,0,0-2.14,0l-1.25,1.93a.74.74,0,0,1-1.15.23h0a.75.75,0,0,1-.22-.8l2.13-6a1.77,1.77,0,0,0-.67-2.06c-.47-.3-1-.35-1.54.43l-1.46,3-.22-3.32a1.14,1.14,0,0,0-.86-1h0a1.13,1.13,0,0,0-1.39.81l-.7,2.7-1.27-2.82a1.21,1.21,0,0,0-2-.35,1,1,0,0,0-.2.92l.77,2.23s-2.21-1.93-2.87.61l.23,9.54-5,18.91" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M292.79,752.34v2.79a2.73,2.73,0,0,0,1.65,2.51l12.12,3.88" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
            </g>
          </g>
          <PulseCircle animationDelay="-0.5s" onClick={() => handleOnClick(1)} cx="208.16" cy="698.25" r="25.41" fill="#f6e105" style={{ mixBlendMode: 'multiply' }}/>
          <rect x="368.53" y="864.07" width="232.46" height="19.33" rx="8.48" fill="#e0e4e5" style={{ mixBlendMode: 'multiply' }}/>
          <g>
            <g>
              <path d="M473.67,778.45c-1-7.9-5.44-22.46-7.29-28.34l1.95-1.2.28-.12-4.19-17.63-4.57-9.81-17.11-6.55h0l-.89-2.89-1.71.79-.15-2.06a4.63,4.63,0,0,0,.42.7l-.59-6.2a2.35,2.35,0,0,0-.19.39l0-.51.28-.25a5,5,0,0,0,5.45-4.85l.15-.13-.05-.31c.6-.41,3.61-2.73,4-8.79.41-6.74-4.7-9.84-10.28-10.79S426,675.33,425.79,673l-1.72,1.42a4.68,4.68,0,0,0-1.62,4.64,7,7,0,0,0,.5,1.38c-1,1.22-2.31,3.63-2.69,8.2-.63,7.6-6.44,13.24,2.1,18.14,0,0-.12-.24,1.55.2l-1.31,7.84,2.4-.22h-2.69l-.94,1.62-10.53,3.17-1.48,3-.06-.38-10.85,21,2,.78-2.72,7.62-.64,3.21-.12,0s-5.07,10.12-7.24,18.8l-5.46,5.47a6,6,0,0,0-1.77,4.26V785l5.95,2.71a1.69,1.69,0,0,0,2.37-1.26l.36-2.17,2.17-1.44,1.09,3.25a1.13,1.13,0,0,0,1.44.72,1.15,1.15,0,0,0,.73-1.44l-.37-2.53,1.45-5.07s9.4-13,11.57-18.8l-10.52-3.71,10.1,4,4.57-10.45h.19c.72,7.24-3.61,20.24-2.17,24.57l-.72,39.78s-8.68,31.1-8,42c0,0-3.61,5.06-2.17,10.85l-8.28,4.73a3.66,3.66,0,0,0,1.82,6.84H416A5.58,5.58,0,0,0,421.6,872v-6s3.61-5.79,2.17-10.13c-1.26-3.76,5.64-17.84,8-26.24,4.68,7.38,15.33,24.47,15.89,28.41.73,5.06,5.06,5.06,5.06,5.06l-4.17,5.22a4.44,4.44,0,0,0,5.75,6.57l18.24-11a3.32,3.32,0,0,0,1.29-4.29l-3.45-7.14.43-.26c-.73-5.78-5.79-5.06-6.51-9.4S457,828.35,457,828.35l-8.35-25.62c2.6-3.91,4.52-7.79,4.74-11.27.72-11.57.72-17.35.72-17.35s1.74-8.59.72-14.47c-2.2-12.8,1.15-5.34,1.07-8.23l2.61,11.11.17.58-.23-.57s.83,3.54,2.27,7.71h0a44.63,44.63,0,0,0,4.38,9.55l-.14,3.72-.65,7.75a1.39,1.39,0,0,0,.76,1.34,1.34,1.34,0,0,0,1.23,0l.17-.08a1.4,1.4,0,0,0,.75-1.06l.63-5.06,2.17,1.45v3.4a2.14,2.14,0,0,0,2.37,2.12l2.41-.27a2.13,2.13,0,0,0,1.89-1.93l.56-6.22Zm-8.55.67.07-.66,0,.76Z" fill="#fff"/>
              <path d="M410.84,719.39l9.8-3.92-.65,3.27,5.88,2.61,2.61-1.3,1.31-3.27h2l1.31,2.61,2.61,1.31,7.84-3.27-.65-3.26,17,7.18,5.23,11.11,3.26,16.34s-9.8,3.92-14.37,2.61l.65,5.88.65,7.19-1.3,9.8H411.49l-.32-4.9,2.61-15v-5.88l-14.38-5.23v-2Z" fill="#fff"/>
              <line x1="429.59" y1="716.63" x2="431.9" y2="716.63" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <line x1="443.49" y1="714.07" x2="455.32" y2="719.11" fill="none" opacity="0.14" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.59" stroke="#231815"/>
              <g opacity="0.14">
                <path d="M412.63,781.53c.59,0,1.84-.88.69-.88-.58,0-1.83.88-.69.88Z" fill="#231815"/>
              </g>
              <g opacity="0.14">
                <path d="M405.43,859c-.22-.41-.1-.94-.62-1.12a1.36,1.36,0,0,0-1.55.7.33.33,0,0,0,0,.4c.73,1,1.51,1.91,2.21,2.92.51.72,1.27,1.22,2.14.67a.56.56,0,0,0,.31-.55c-.06-.37-.5-.61-.55-1-.1-.67-1.81-.27-1.71.46.05.37.5.73.55,1l.31-.54h0l1.06-.2c.13,0-.09-.05-.12-.09s-.27-.31-.39-.47l-.72-1c-.5-.65-1-1.28-1.5-1.93l0,.41h-.07l-1.05.2c-.2-.05-.16-.24-.21-.06s.06.24.08.31a2.45,2.45,0,0,0,.23.56c.3.56,2,0,1.58-.71Z" fill="#231815"/>
              </g>
              <g opacity="0.14">
                <path d="M452.09,779.87c-1.71,1-.48-.19-.76,1.06a10.34,10.34,0,0,0,.18,2.07,58,58,0,0,1,0,6c-.17,4.14-.89,8.71-3.38,12.1-.47.64,1.25.53,1.59.06,3.32-4.51,3.6-10.64,3.55-16.05,0-1.31.63-7.48-2.27-5.82-1,.57.5.88,1.07.54Z" fill="#231815"/>
              </g>
              <g opacity="0.14">
                <path d="M450.56,792.45c.57,0,1.84-.86.69-.86-.57,0-1.83.86-.69.86Z" fill="#231815"/>
              </g>
              <g opacity="0.14">
                <path d="M414.66,773.45c4.36,3,9.92,3.48,15.06,3.77,7.41.43,14.84.26,22.25.23.66,0,1.82-1,.67-1-6.53,0-13,.1-19.57-.12-5.73-.19-12.16-.39-17.05-3.74-.47-.32-2,.37-1.36.84Z" fill="#231815"/>
              </g>
              <path d="M424.21,679.32s-3.3,1.57-3.95,9.32-6.44,13.24,2.1,18.14c0,0,4.73,2.19,9.12,1.32" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <circle cx="421.14" cy="693.24" r="1.21" fill="#231815" stroke="#231815" stroke-miterlimit="10" stroke-width="0.35"/>
              <circle cx="429.81" cy="695.41" r="1.21" fill="#231815" stroke="#231815" stroke-miterlimit="10" stroke-width="0.35"/>
              <path d="M428.89,690.84a5.66,5.66,0,0,1,3.26,2" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M438.35,696.68s2.82-2.8,5.91.56-1,8.75-5.24,7.34" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <line x1="423.72" y1="707.37" x2="423.08" y2="711.4" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M422,698.11l7.55,1.92S424.8,702.68,422,698.11Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M422.45,679.06c.45,1.9,1.88,4.15,5.87,5.31,0,0,7.5.36,8,4.49s-.1,6,3.6,6.92c0,0,4.54-1.33,5.4,3.78,0,0,3.69-2.13,4.1-8.87s-4.7-9.84-10.28-10.79S426,675.33,425.79,673l-1.72,1.42A4.68,4.68,0,0,0,422.45,679.06Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M425.58,675.87s-2.5,3.66,2,6.17" fill="#231815" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.02"/>
              <path d="M442.46,685.76s2.61,5.9.52,10.6" fill="#231815" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.02"/>
              <line x1="438.8" y1="700.35" x2="442.52" y2="699.79" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M425,714.6l4.59,2-.53,2a3.31,3.31,0,0,1-4.67,2.1L420,718.55l2.27-3.95Z" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M431.9,716.51l10-4.6,1.64,5.32-6.6,3a3.32,3.32,0,0,1-4.58-2.17Z" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M423.46,691.47s.38,1.91,0,2.42-1.11,2.51,1.06,2.45" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M439,705.12a6.63,6.63,0,0,0,.6,6.55c.34.47,2-.1,1.58-.67a6.15,6.15,0,0,1-.58-5.84c.39-.71-1.32-.54-1.6,0Z" fill="#231815"/>
              <line x1="422.32" y1="714.8" x2="410.75" y2="719.14" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M415.09,728.54s-2.17,13-1.45,20.25S410,769,411.47,773.38c0,0,34,4.34,42.67.73,0,0,2.17-8.68.72-14.47s-.72-17.36-.72-17.36" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <polyline points="409.3 722.03 398.45 743.01 413.64 748.79" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <polyline points="400.81 743.9 397.73 751.69 400.62 753.13" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M397,754.58s-5.07,10.12-7.24,18.8l-5.46,5.47a6,6,0,0,0-1.77,4.26V785l5.95,2.71a1.69,1.69,0,0,0,2.37-1.26l.36-2.17,2.17-1.45,1.09,3.26a1.13,1.13,0,0,0,1.44.72h0a1.15,1.15,0,0,0,.73-1.44l-.37-2.54,1.45-5.06s9.4-13,11.57-18.8" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M416.53,757.47a37.91,37.91,0,0,0,17.36,5.79" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <line x1="442.46" y1="714.07" x2="458.48" y2="720.59" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <line x1="461.37" y1="724.2" x2="464.99" y2="732.16" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M467.16,742.28l1.45,6.51s-7.52,3.45-14.61,2.81" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M466.31,749.69s5.91,17.18,7.36,28.76l3.61,6.5-.56,6.22a2.13,2.13,0,0,1-1.89,1.93l-2.41.27a2.14,2.14,0,0,1-2.37-2.12v-3.4l-2.17-1.45-.63,5.06a1.4,1.4,0,0,1-.75,1.06l-.17.08a1.34,1.34,0,0,1-1.23,0h0a1.39,1.39,0,0,1-.76-1.34l.65-7.75" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <line x1="456.32" y1="751.86" x2="457.76" y2="758.2" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M458.48,762.53s2.89,12.3,7.23,18.09" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <line x1="473.67" y1="778.45" x2="470.78" y2="779.17" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M411.47,773.38l-.72,39.78s-8.68,31.1-8,42c0,0-3.61,5.06-2.17,10.85h21s3.61-5.79,2.17-10.13,8-22.42,8.67-29.65,20.25-23.14,21-34.72.72-17.35.72-17.35Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M448.48,802.13,457,828.35s6.51,10.12,7.24,14.46,5.78,3.62,6.51,9.4l-18.09,10.85s-4.33,0-5.06-5.06-18.08-31.82-18.08-31.82Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M400.62,866l-8.28,4.73a3.65,3.65,0,0,0-1.84,3.18h0a3.66,3.66,0,0,0,3.66,3.66H416A5.58,5.58,0,0,0,421.6,872v-6Z" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M452.69,863.06l-4.17,5.22a4.44,4.44,0,0,0-.65,4.41h0a4.43,4.43,0,0,0,6.4,2.16l18.24-11a3.32,3.32,0,0,0,1.29-4.29l-3.56-7.36Z" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <line x1="415.09" y1="806.65" x2="425.21" y2="787.12" fill="#231815" stroke="#e0e4e5" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.52"/>
              <polyline points="445.89 805.91 436.54 817.3 432.42 828.67" fill="#231815" stroke="#e0e4e5" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.52"/>
              <line x1="451.25" y1="729.99" x2="458.48" y2="722.76" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
            </g>
            <g>
              <g>
                <path d="M503.67,779c.06-1,.1-2.08.13-3.11.13-6.42-1.41-12.65-.77-19.1.46-4.69,1.26-9.4,1.26-14.12a9,9,0,0,0-1.37-4.78l.05-.15-.14-.25c.43-.55,2.4-3.13,2.49-5l0,0v0a2,2,0,0,0-.23-1.06c-.92-1.58-2.86-4.7-3.61-5.89v0l-.06-.12.06.1L500,710.87l-1.39-4.38s-5.39-15.09-22.43-7.76L474,700.58l-3.53,7.3-2.05,10.83.61,1.83v.53l-.18-.48,2.14,5.34-2,3.92.11.13-.68,1.63.84,3.55-1.5,7.13,2.67,2.89-5.56-5-4.47-4.46-1.1-3.63a2.56,2.56,0,0,0-2.42-1.8,25,25,0,0,1,.08,2.59c-.13,1.09-1.16,1.21-1.78.25l-1-1.53-1.47-2.32a1,1,0,0,0-1.71,1l.85,1.48-1.22.55a1.94,1.94,0,0,0-1.61,2.6,2,2,0,0,0-.13,2.49,1.58,1.58,0,0,0,.13,2.26l9.27,7.45s7.61,10.18,10.72,12.72l.54,30.12h0l-.6,49h5.61c1.28,5.79,2.38,22,2.38,22l-4.32,2.51a5,5,0,0,0-2.14,6.12l.27.68h24.89a3.41,3.41,0,0,0,3.42-3.41,5.08,5.08,0,0,0-.59-2.35l-1.9-3.55V839h5.07s-1.28-28.34,0-35.86c.3-1.72.59-3.81.84-5.88h6.73a5.89,5.89,0,0,0,5.89-5.89V779ZM471.9,745l0,.21c-.47,0-.93,0-1.41-.05Zm13.84,117.74-.95-1.78V839h1.38c1.28,5.79,2.63,22,2.63,22Z" fill="#fff"/>
                <g>
                  <path d="M498.61,706.49s-5.39-15.09-22.43-7.76L474,700.58l-3.53,7.3-2.05,10.83,2.4,7.28-2.36,5.62.84,3.55-1.5,7.13,2.67,2.89,5.51-.59,7.38-5.74s6.64-6.61,11.7-7c0,0,6.91,2.49,7.64,5.62l2.67-5.06-3.91-7.07L500,710.87Z" fill="#ccc"/>
                  <path d="M484.79,839v22H477s-1.1-16.19-2.38-22Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <path d="M484.79,861l1.9,3.55a5,5,0,0,1,.59,2.35h0a3.41,3.41,0,0,1-3.41,3.41H470.79l-.27-.68a5,5,0,0,1,2.14-6.12L477,861Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <path d="M479,730.53l-1-5.32c-11.87-5.63-5.61-14.78-3-20.25a7.48,7.48,0,0,1,5.31-4.24s1.1,9.42,6.14,15.11l1,1.4-1,8.56Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <path d="M476.32,707.08s1.51,1.06.15,3.09-1.64,2.88-.15,3.64" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <path d="M479.88,716.68a6.69,6.69,0,0,1-5.19-.85" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <circle cx="473.48" cy="709.89" r="1.21" fill="#231815"/>
                  <path d="M474.69,788h28.05a101,101,0,0,0,1.06-12.14c.13-6.42-1.41-12.65-.77-19.1.46-4.69,1.26-9.4,1.26-14.12,0-7.65-9.26-10.8-9.26-10.8s-8.39,2.23-11.7,7-7.86,6.75-12.89,6.33" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <path d="M469.61,788.1,469,839h32.69s-1.28-28.34,0-35.86a123.82,123.82,0,0,0,1.51-13.45c.06-2.23-28.49-1.71-28.49-1.71" fill="#ccc" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <polyline points="491.61 742.92 476.04 748.04 476.04 751.66" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <path d="M495.65,735.63a18.3,18.3,0,0,0-5.1,5.15" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <path d="M473.48,749.94c.09-.13-1.71,2.91-1.71,2.91" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <path d="M473.42,747.83l-8.54-7.62s-5.69,3-6.55,7c0,0,8.72,11.67,11.31,13.13" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <path d="M496.61,756.92c-4.91,4.75-23.55,5.49-23.55,5.49" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <path d="M464.88,740.21l-4.47-4.46-1.1-3.63a2.56,2.56,0,0,0-2.42-1.8,25,25,0,0,1,.08,2.59c-.13,1.09-1.16,1.21-1.78.25l-1-1.53-1.47-2.32a1,1,0,0,0-1.71,1l.85,1.48-1.22.55a1.94,1.94,0,0,0-1.61,2.6,2,2,0,0,0-.13,2.49,1.58,1.58,0,0,0,.13,2.26l9.27,7.45" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <line x1="491.61" y1="759.67" x2="491.61" y2="778.95" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <path d="M515.13,779H486.45v12.42a5.89,5.89,0,0,0,5.89,5.89h16.9a5.89,5.89,0,0,0,5.89-5.89Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <line x1="511.18" y1="778.95" x2="503.18" y2="756.92" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <line x1="512.2" y1="781.95" x2="502.74" y2="787.98" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <line x1="499.66" y1="787.42" x2="489.49" y2="782.67" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <path d="M496.61,839v22H488.8s-1.35-16.19-2.63-22Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <path d="M496.61,861l1.9,3.55a5.08,5.08,0,0,1,.59,2.35h0a3.41,3.41,0,0,1-3.42,3.41H482.61l-.27-.68a5,5,0,0,1,2.14-6.12L488.8,861Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <path d="M479.57,710.68a4.12,4.12,0,0,1-.29-1.32l1.41,1.13,0,0a1.19,1.19,0,0,1,.81,1.12,1.21,1.21,0,1,1-2.41,0A1.18,1.18,0,0,1,479.57,710.68Z" fill="#231815"/>
                  <path d="M474,724.61c0,2.57-.43,5.4,1.18,7.61.37.52,2.41-.17,2-.79-1.51-2.09-1.07-5-1-7.39,0-.55-2.09-.19-2.11.57Z" fill="#231815"/>
                  <path d="M475.78,726.59a47.69,47.69,0,0,0,.86,4.72c.17.67,2.05.18,2.1-.42.13-1.76.32-4.62-1-6a1.54,1.54,0,0,0-2,.55,18.89,18.89,0,0,0-.26,5.31c0,.63,2.1.23,2.1-.56v-.86c0-.62-2.1-.24-2.1.56v.86l2.1-.56a17.64,17.64,0,0,1,.27-5l-2,.55c1.14,1.19,1,3.92.85,5.44l2.1-.42c-.4-1.55-.61-3.13-.86-4.71-.11-.68-2.22-.2-2.1.56Z" fill="#231815"/>
                  <path d="M489.49,718c-7.29-3.67-9.22-17.32-9.22-17.32" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <line x1="487.4" y1="716.61" x2="486.42" y2="725.31" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <path d="M491.61,722.22s-8,5.69-11.73,7.65" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <path d="M498.61,706.49s-5.51-14.76-22.43-7.76" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <path d="M500,710.87l1.19,14.06s2.76,4.39,3.91,6.37-2.36,6.25-2.36,6.25" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <path d="M488,701.31s2,8.54,6.61,12.54" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <path d="M489.49,728.17s-9.44,4.66-13.45,9.38" fill="#f5f1df" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <path d="M468.82,720.59,470.1,724a6.81,6.81,0,0,1,0,4.75l-.55,1.44a6.7,6.7,0,0,0-.22,4l.21.85" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                  <line x1="473.88" y1="701.06" x2="470.26" y2="707.57" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
                </g>
              </g>
              <path d="M469.65,766.44s-4.22,11.72-.15,18.6" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.36"/>
            </g>
            <g>
              <path d="M597.42,786.05l-9.68-1.82,0,.13-3.53-1.61c-9.56-5.36-15.72-7.71-18.73-8.68l-1.81-1.4.55-1.09-10.13-6.51,0,.16-13.84-6.62,0-.05L537,752.72l1.65-.1s3.45-5,2.72-8.36a12.5,12.5,0,0,0-6.33-8.11s-9-8.65-19.3,3c0,0,.15.36.44.92a10.5,10.5,0,0,0-1.52,4.86c-.14,1.54-1,3.09-2,2.71s-2,2.39,1.16,3a10.44,10.44,0,0,0,.07,2.21c.05.62.1,1.14.12,1.24.92,4.7,5.69,7.09,10.19,6.81v0l1.64,3-6.46,3.06-.72.35h.24l-1.56,1.15-.9.9L510,774.47l.45.84-.28.28.66,1.31-8.06,6.25-8.32,6.14-8.92,2.56a1.65,1.65,0,0,0,.45,3.23h5.58l.27-.25-.18.25-1.56,1a.91.91,0,0,0,.78,1.64l3.58-1.19s5.79-.73,6.51-3.62l15.55-6.15-.24-.45.44.39,2,2h0c-.08,4.91-1.46,9.76-2.16,12.57-.52,2.09.46,3,1,3.41L513,825.21a2.15,2.15,0,0,1-.5.85L510,828.68a2,2,0,0,0,1.33,3.46l3.85.27h.08L506,860.89l-10.22,3a2.89,2.89,0,0,0,.82,5.67h20.24v-6.51s10.77-21,14.43-28.2l-8.83-1.61,13.93,1.61,2.15-6.19.15-.21,7.1,8.57,2.2-1.29,21.39,19.14-4.4,7.28a3,3,0,0,0,4.45,4l9.86-7.51a4.56,4.56,0,0,0,.81-6.46L578,849.5l.27-.2a114,114,0,0,0-17.81-20.81l2.72-1.59-7.95-10.12-4-10.64a10.16,10.16,0,0,0,.3-1.3h.41c3.62-2.17,2.89-5.78,2.17-10.12-.67-4-3.33-13.75-3.43-14.56l4.48,2.89,2.57,1.54.65-1.3,3.48,1c4.52,1.44,12.46,3.88,17.07,4.77h0c.57.11,1.1.2,1.56.25l-.63-.17,2.09.19c1.15,1.12,5.78,2.69,7.76,3.32a1.26,1.26,0,1,0,.76-2.4l-1-.32h0a5.72,5.72,0,0,0,2.61-1.33h5.1a1.27,1.27,0,0,0,.24-2.52Z" fill="#fff"/>
              <polygon points="510.16 774.94 518.66 767.75 521.92 765.79 527.15 771.02 530.42 767.1 533.69 771.02 543.49 760.56 553.29 764.48 564.4 771.67 557.21 784.74 551.66 781.14 552.96 788.99 554.92 800.09 551.66 804.67 517.02 804.67 516.37 802.05 518.98 788.33 516.37 786.37 510.16 774.94" fill="#ccc"/>
              <path d="M558.47,826.83s9.64,7.49,19.77,22.47L570,855.41l-23-20.57Z" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M515.42,832,506,860.89l10.84,2.17s10.77-21,14.43-28.2Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M519.21,737.32c-3,1.44-4.33,4.35-4.6,7.57-.14,1.54-1,3.09-2,2.71s-2,2.39,1.16,3A22.84,22.84,0,0,0,514,754c1,5,6.3,7.38,11,6.72" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M527,747.74s2-3.76,5.26-1.61,1.91,6.32-1.33,7.58" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <line x1="528.66" y1="750.24" x2="530.39" y2="749.15" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <circle cx="517.04" cy="747.27" r="1.27" fill="#231815"/>
              <path d="M515.69,739.11a15.35,15.35,0,0,0,3.79,5.31l.71-2.56s2.94,4.66,7.51,4.93c0,0,2.11-2.6,4.56-.66a4.48,4.48,0,0,1,1.59,4.7l4.75,1.79s3.45-5,2.72-8.36a12.5,12.5,0,0,0-6.33-8.11S525.94,727.5,515.69,739.11Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M531.58,731.53a17,17,0,0,1,3.41,4.62,7.83,7.83,0,0,0,.08-3.93" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M537.88,754.22s.22,3.1,2.39,4.45" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <line x1="523.68" y1="760.93" x2="525.71" y2="764.3" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M513.89,752.8a4.86,4.86,0,0,0,3.42-1.32" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <line x1="542.54" y1="760" x2="551.94" y2="763.62" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <polyline points="554.11 765.07 564.24 771.58 557.73 784.59 550.5 780.25" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M552.66,784.59s.73,5.79,1.45,10.13,1.45,7.95-2.17,10.12H518s-2.17-.72-1.45-3.61,2.17-8,2.17-13" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <polyline points="518.67 767.24 525.54 763.98 530.61 766.87 526.99 771.21 523.37 767.6" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M518.67,775.91a21.94,21.94,0,0,1,0,7.24" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <polyline points="539.28 760.37 530.61 766.87 533.5 771.21 540.73 763.26" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <line x1="530.61" y1="771.94" x2="530.61" y2="782.78" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M522.65,796.53s11.57,3.61,16.63,3.61" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M517.59,804.48,513,825.21a2,2,0,0,1-.49.85L510,828.68a2,2,0,0,0-.51.92h0a2,2,0,0,0,1.84,2.54l3.85.27,21.2,2.45,5.79-16.64s8.68-7.23,9.4-13.74Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <polygon points="550.88 805.21 555.2 816.78 563.15 826.9 545.79 837.03 536.14 825.38 538.46 812.19 550.88 805.21" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.52"/>
              <g>
                <path d="M538.54,828.67l3.64-10.45s6.51-2.89,8-6.51" fill="#231815"/>
                <path d="M538.54,828.67a49,49,0,0,1,1.25-5.42c.5-1.78,1.07-3.53,1.67-5.28h0a.81.81,0,0,1,.41-.44c.75-.33,1.52-.74,2.27-1.14s1.48-.83,2.2-1.28a12.67,12.67,0,0,0,3.79-3.4,6.17,6.17,0,0,1-1.2,2.4,12.45,12.45,0,0,1-1.9,1.94,17.71,17.71,0,0,1-2.18,1.57,22.38,22.38,0,0,1-2.37,1.29l.41-.44c-.6,1.74-1.25,3.47-2,5.17A49.11,49.11,0,0,1,538.54,828.67Z" fill="#f2f2f2"/>
              </g>
              <path d="M506,860.89l-10.22,3a2.9,2.9,0,0,0-2.08,2.78h0a2.89,2.89,0,0,0,2.9,2.89h20.24v-6.51Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
              <path d="M569.67,854.39,565,862.16a3.05,3.05,0,0,0,.59,3.86h0a3,3,0,0,0,3.86.14l9.86-7.51a4.56,4.56,0,0,0,.81-6.46l-2.3-2.91Z" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.54"/>
              <line x1="545.07" y1="771.94" x2="547.24" y2="777.72" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.54"/>
              <path d="M563.3,773.46s7.08,1.37,22.27,10" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.54"/>
              <path d="M587.74,784.23l9.68,1.82a1.26,1.26,0,0,1,.82,2h0a1.25,1.25,0,0,1-1.06.57h-5.1a5.54,5.54,0,0,1-3.62,1.45" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.54"/>
              <path d="M585.57,787.12l3.91,2.79,1,.32a1.26,1.26,0,0,1,.67,1.9h0a1.28,1.28,0,0,1-1.43.5c-2-.64-6.65-2.21-7.78-3.34" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.54"/>
              <path d="M558.45,783.15s16.27,5.42,22.06,6.14" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.54"/>
              <path d="M511.08,777l-16.64,12.29-8.92,2.56a1.64,1.64,0,0,0-1.2,1.58h0a1.65,1.65,0,0,0,1.65,1.65h5.58" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.54"/>
              <path d="M493.72,792.19l-2.08,2.89-1.56,1a.91.91,0,0,0-.33,1.18h0a.91.91,0,0,0,1.11.46l3.58-1.19s5.79-.73,6.51-3.62l15.55-6.15L510,774.47l7.24-5.79" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.42"/>
            </g>
          </g>
          <PulseCircle onClick={() => handleOnClick(0)} cx="528.83" cy="748.66" r="22.62" fill="#f6e105" style={{ mixBlendMode: 'multiply' }}/>
          <rect x="544" y="1038" width="247" height="33" rx="16.5" fill="#e0e4e5" style={{ mixBlendMode: 'multiply' }}/>
          <g clip-path="url(#a)">
            <g>
              <path d="M582.53,769.92s.6,7.71,4.75,8.89c0,0-.6,10.08-2.38,11.86h-2.37l-8.89,6.52a33.69,33.69,0,0,0-23.12,23.71l-4.15,19L541,872.47l9.48,1.78L565.34,879l-3,47.43,79.43-13-4.74-14.23V892l11.86,11.85,33.19,14.82,1.78-17.19-8.3-4.15L675,892l7.7-13,42.69-23.71-36.17-26.67s-13,6.52-20.15,19.56c0,0-10.08,16-12.45,30.23l-1.18,1.19-5.34-16-1.78-7.11,3-1.78-2.37-9.49s-4.75-27.26-8.3-32.6-10.08-14.23-21.94-18.37L601.5,775.85Z" fill="#fff"/>
              <path d="M706,775.94s22.27-7,29.1,9,6.79,19.41,6.79,19.41l-6.1,14.24s2.12,6.78,6.1,7.28c0,0,8-.85,20.73,4.78l-6.85,18.15-52.45,2.42-15.34-21.7s13.44-3.48,15.43-3.61c0,0,2.14.11,1.82-1.87l-1.29-2.18s-10.31-4.82-10.4-10.7,1.65-13,1.41-15.78-.82-6.6-.82-6.6Z" fill="#fff"/>
              <path d="M699.91,826.24s4.74,13.63,21.34,14.22c0,0,17.78-1.78,20.74-14.82,0,0,21.34,0,30.83,12.45L790.6,860l-20.15,17.79s1.78,14.82-.6,22.52l.6,6.52s3,5.93,1.78,9.49l-42.09,14.23-47.43-7.12s-1.18-3,0-11.26,1.78-10.67,1.19-16.6-1.78-16.6-1.78-16.6l3-7.11s0-6.52-7.12-10.67a23.21,23.21,0,0,0-14.22-3S676.19,827.42,699.91,826.24Z" fill="#ccc"/>
              <path d="M638.92,911.84s.3,25.9-1.72,41-.88,22.8,2.23,36.94-4,29.13-.13,34,6,5,1.9,12.23H559.38s-3.36-3.61,0-10.35,3.08-23.67,3.22-29.87-.93-54.36,2.37-71.72l25.65-13.87,26.08-3.4Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <line x1="704.88" y1="822.05" x2="705.96" y2="828.86" fill="#f3f1ee"/>
              <path d="M772.08,916.13s3.62,18.64,0,33.25-1.65,30.36.8,47.34-3.63,24-1,29-2,8.43-6.37,10.35H687.89s-8.36.43-5.93-10.35,4-40.28,2-58.47-5.6-30.11-1-43.75Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M648.14,857l10,29.08s17.81,10.54,22.19,14.39l16.21,6.32-.89,15.49-16.22-4.17s-23.3-11.73-28.22-13.32-14.33-12.49-14.33-12.49" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M659.44,867.53s-4,13.25-5.9,22.38l11.53-1.26-.94-18.79Z" fill="#fff"/>
              <path d="M581.18,768.7c-2.81-10.85,3.34-24.39,13.91-27.44a32.65,32.65,0,0,1,13.81-1,23.21,23.21,0,0,1,16.4,10.67s3,4.79,3,13.14c0,6.63,3.8,13.68.65,20.08s-10.32,10.44-17.26,8.73" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M617.51,765.33s3.44,4,1,7.51" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M613.44,767.56c.44,1.67-1,1.58-2.69,2s-2.89,1.24-3.33-.43.89-2.09,2.55-2.53S613,765.9,613.44,767.56Z" fill="#231815"/>
              <path d="M626.44,767.8c-.4,1.49-1.5.77-3,.37s-2.8-.33-2.4-1.81,1.62-1.23,3.11-.83S626.83,766.32,626.44,767.8Z" fill="#231815"/>
              <line x1="604.47" y1="838.17" x2="605.35" y2="872.41" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M582.45,790.27A142.47,142.47,0,0,1,601.25,829" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M582.45,790.27l-8.08,6.15s-.1,16.94-.91,23.89l14.9-7.86,4.33,2.19" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <line x1="616.7" y1="794.65" x2="606.03" y2="831.2" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <polyline points="618.73 794.03 622.52 796.17 624.14 810.72 618.25 809.17 622.94 817.64 609.34 831.35" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <line x1="560.98" y1="812.69" x2="569.87" y2="828.86" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M573.46,839.23s-1,22.51.91,27.13,0,7.8,0,7.8a115.66,115.66,0,0,1-33.11-1.5c2.09-8.49,4-36.46,9.64-53.72,4.14-12.56,12-17.52,23.48-22.52" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M633.62,825.19s1.21,18.23.18,26.2a72,72,0,0,0-.7,12.78,176.12,176.12,0,0,1,18.08-9.25s-5.49-29.29-9.66-40.34-19-18.41-19-18.41" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M565,924.09l36.28-.09,5.43-15.74,7.49,12.64,27.35-6.27s-4.55-13.48-4.67-22.3-3.75-28.16-3.75-28.16" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M601.25,768a29.77,29.77,0,0,0,4.77.42" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M601.93,771.64a6.37,6.37,0,0,1,3.34-1.81" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M735.75,818.58s7.88-1.28,13.3-12-6.17-13.51-7.2-24.35c-.7-7.3-6.51-13.92-14.11-13.86-3.73,0-7.31,1.25-11,1.5s-7.72.4-11.55.89c-7.52,1-10,3.18-13.51,9.21s1.57,11.43,1.57,11.43,8.45-2.25,11.06-6.78,12.94-5.57,15.68-.47c0,0-.35,12.07,9.44,13.8,0,0,4.85-6.35,9.14-1.43,0,0,4.33,5.48-1.93,10.06l-.86.68S733.7,810.36,735.75,818.58Z" fill="#231815"/>
              <path d="M709.6,823.47c-6.07-1-12.67-4.3-15.36-10.13-3.38-7.35,2.6-14.94,0-22.36,0,0,6.08-.38,8.39-4.39s6.32-9.7,18.47-5.09" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M730.08,797c2.27-3.35,8.12-2.53,9.61,1.16,1.63,4-2.71,10.77-7.34,9.07" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <line x1="731.23" y1="802.5" x2="735.21" y2="800.1" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M734.75,807.5s-1.46,15.73,7.1,18.36h0a19.39,19.39,0,0,1-12.1,12.6l-2.9,1a19.3,19.3,0,0,1-11.38.44l-1.68-.44a19.38,19.38,0,0,1-12.13-9.65l-.44-.85" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M732.38,808s-2.68,5-4.61,6.21" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M701.55,794.89s-2.78,3.57.21,6.09" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M700.05,805.75l9.12-1.41S704.61,810.21,700.05,805.75Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <circle cx="707.66" cy="794.7" r="2.22" fill="#231815"/>
              <circle cx="697.35" cy="796.48" r="2.13" fill="#231815"/>
              <path d="M722,784.13s-1.73,10,6,12.37" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M728.86,784.13s-1.37,6.06,4.9,10.76" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M745,798.87s-1.43,12.28-9.93,15.38" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M698.48,779.78c-.19,2.52,2.83,3.34,4.8,3.29" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M695.51,783.61a5,5,0,0,0,5,2.62" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M711.75,793.31a7.73,7.73,0,0,0,4.31-.86" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M711.75,795a9,9,0,0,1,3.88.33" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M708.91,801a7.69,7.69,0,0,1,4.09,3.36" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M748.6,826.27c24.89,3.9,29.25,20.39,41.84,34.67l-20.06,16.63s1,16.06-.46,20.87,2.79,10.07,2.16,17.69c0,0-16.29,2.47-28.33,7.55a34,34,0,0,1-22.47,1.42c-11.47-3.22-29.15-4-38.27-1.64,0,0-1.86-2.92.22-11.38s-.09-25.52-1.27-33" fill="#ccc" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M767.16,855.45s4.38,21.93-15.32,26.76" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M689.44,845s1.6,11.74,1.26,17.25" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <line x1="685.37" y1="872.41" x2="689.44" y2="865.89" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M696.4,900.05s5.53,11.29,30.79,9.78" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M770.38,877.57c9,11.55,24,17.43,37,17.53" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M705.88,789.51a6,6,0,0,1,4.25.81" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M665.07,858.24S680.41,857,684,868.83" fill="#f9fbf8" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M705.37,825.76a54.76,54.76,0,0,0-15.93,3.1C678.57,833,666,850.09,660.62,864.17c0,0-6.74,18.54-7.08,25.74l14.23-.48" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M684,875l-8.89,17.34.81,6.09a2.34,2.34,0,0,1,0,.79l-1.06,4.55a2.23,2.23,0,0,1-2.17,1.72h0a2.22,2.22,0,0,1-2.2-2.51l.53-4.06a1.31,1.31,0,0,0-1.3-1.48h0a1.31,1.31,0,0,0-1.31,1.18l-.78,7.34a1.64,1.64,0,0,1-1.64,1.47h-.46a1.64,1.64,0,0,1-1.63-1.83l.52-4.48a.89.89,0,0,0-.89-1h-.08a.9.9,0,0,0-.89.78l-.77,6.63A1.65,1.65,0,0,1,660.1,909h-.55a1.64,1.64,0,0,1-1.63-1.83l.62-5.68a.9.9,0,0,0-.79-1h0a.89.89,0,0,0-1,.81l-.5,5.45a1.65,1.65,0,0,1-1.59,1.5h0a1.65,1.65,0,0,1-1.69-1.8l.51-5.8a.89.89,0,0,0-1-1l-.14,0a.88.88,0,0,0-.77.73L651,905.3c-.11.63-1,1.08-1.66,1.08h0a1.29,1.29,0,0,1-1.29-1.43l.5-6.21a12.76,12.76,0,0,1,5-8.83l4.83-2.39" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M582.45,836.23h10.24v9.23a10.16,10.16,0,0,1-5.84,9.19h0a10.18,10.18,0,0,1-7.7.37l-5.69-2" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M699,1027a16.28,16.28,0,0,0-9.26,9l-1.8,4.55a6.58,6.58,0,0,0-.45,2.39v.81c0,3.89,3.43,7,7.67,7h21.74c4.21,0,7.63-3.1,7.68-7h0a6.51,6.51,0,0,0-.46-2.46L722,1036a16.24,16.24,0,0,0-9.15-9h0a18.43,18.43,0,0,0-13.66,0Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M573.12,1027a16.25,16.25,0,0,0-9.26,9l-1.8,4.55a6.58,6.58,0,0,0-.45,2.39v.81c0,3.89,3.43,7,7.67,7H591c4.2,0,7.62-3.1,7.67-7h0a6.5,6.5,0,0,0-.45-2.46l-2.12-5.42a16.23,16.23,0,0,0-9.16-9h0a18.4,18.4,0,0,0-13.65,0Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M618.2,1027a16.25,16.25,0,0,0-9.26,9l-1.8,4.55a6.58,6.58,0,0,0-.45,2.39v.81c0,3.89,3.43,7,7.67,7H636.1c4.2,0,7.62-3.1,7.67-7h0a6.5,6.5,0,0,0-.45-2.46L641.2,1036a16.24,16.24,0,0,0-9.15-9h0a18.43,18.43,0,0,0-13.66,0Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M742.53,1027a16.27,16.27,0,0,0-9.27,9l-1.79,4.55A6.59,6.59,0,0,0,731,1043v.81c0,3.89,3.44,7,7.67,7h21.75c4.2,0,7.62-3.1,7.67-7h0a6.5,6.5,0,0,0-.45-2.46l-2.13-5.42a16.19,16.19,0,0,0-9.15-9h0a18.4,18.4,0,0,0-13.65,0Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M620.79,779.63a12.49,12.49,0,0,1-10.36-1.37l10.58-.78Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M730.09,943.62s6.11,25.24-.58,92.41" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M737.29,940.76s-1.49,6.74-6.08,10.62" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M567,875.54v47.92l5,13.33a3.07,3.07,0,0,1-.63,3.18h0a3.09,3.09,0,0,1-4.73-.22l-2.38-3.13-.59,8.53s2.45,2.72-1.1,4.23c0,0-.82,2.55-4,1.54,0,0,.14,2.42-2.87,1.87l-1.27-1a17.45,17.45,0,0,1-6.65-13,40.09,40.09,0,0,1,.39-8.34c3.3-18.77-1-37.54-1-56.44" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M559.41,940.21v3.58a5.35,5.35,0,0,0,1.39,3.61l1.8,2" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M588.5,764.44s-7-.77-6,7.15,6,7.62,9.4,7.44c0,0-.63,4.76,2.29,6.85" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M583.21,751.88s3.41,5.09,7.95,4.54,8,6.61,3,8.91a12.9,12.9,0,0,1-10,0s-4.76,1.53-1,10.61c0,0-13.5-1.34-6.94-18.31,0,0,3.09-7.23,7.91-8.73Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <line x1="586.67" y1="771.59" x2="591.16" y2="772.03" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M587.39,782.29a42.8,42.8,0,0,1-1.85,8" fill="#f9fbf8" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M614.81,801.3s-5.81,7.31-20.67,1.46" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <line x1="558.63" y1="950.92" x2="555.27" y2="946.02" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M606,765.76a4.39,4.39,0,0,1,1.45-4.68,8.53,8.53,0,0,1,4.25-2c3.49-.37,2.86,3,.62,3.46s-6.32,3.19-6.32,3.19Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M629.37,765.33a4.36,4.36,0,0,0-1.45-4.68,8.46,8.46,0,0,0-4.24-2c-3.5-.37-2.86,3-.63,3.46s6.32,3.19,6.32,3.19Z" fill="#fff" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
              <path d="M614.06,932.45l-7.37,12.34s.85,69.51-1.34,91.24" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.78"/>
            </g>
          </g>
          <g clip-path="url(#b)">
            <g>
              <path d="M-99.14,944.52a23.81,23.81,0,0,0,22.67,22.15l12.19.55.83,6.66,1,1.38,1.1,57.21s-1.12,61.1,1.11,92.21,14.44,50,18.88,72.22-2.22,27.78,0,37.77-3.33,11.11,1.12,21.11h9.62l-3,11.11L-44,1284.48a14.11,14.11,0,0,0-.27,13.87,14.1,14.1,0,0,0,12.43,7.43h7.62a14.11,14.11,0,0,0,12.4-7.36L1.4,1274.27a23.06,23.06,0,0,0,2.81-11v-8.34a11.93,11.93,0,0,0,6.47-6.18h0a12,12,0,0,0,1-6.14l-4.06-36.53a91,91,0,0,1,0-20.54l6.76-58.72,3.17,30.1s-3.33,65.55-3.33,80,2.22,22.22-1.12,32.22A26.43,26.43,0,0,0,12,1279.84a10.12,10.12,0,0,0,4.45,7.43v2.24a33.18,33.18,0,0,0,5.13,17.72,27.85,27.85,0,0,0,23.56,13h19.4a11.58,11.58,0,0,0,10-17.38l-8.31-14.36a10.42,10.42,0,0,0,3.08-1.77,10.23,10.23,0,0,0,3.45-10.42c-1.08-4-3.49-4.14-1.89-10.51,2.22-8.89,4.44-20,4.44-43.33S93.09,1133.57,96.42,1108c2.21-16.92,2.47-41.63,2.39-56.39h0l7.13,9.48,1.57,15.67-4.43,7.92v19.23h2.8a9.65,9.65,0,0,0-7.25,9.32v86.21a9.67,9.67,0,0,0,9.67,9.67h15.11a9.66,9.66,0,0,0,9.66-9.67v-86.21a9.64,9.64,0,0,0-7.26-9.33h.12v-19.06l.21.25.71-2.12-.44,2.81,4.44-8.89,1.6-23.92,1.28-8.33-.55-1.31c4.22-8.71-1.25-12.07-4.55-20.88s5.56-13.33,3.33-35.55-15.55-133.33-15.55-133.33l-2.91-26.34L57.9,791.86l-12.54,9.78-1.33.89-6,2.12c.28-.08.57-.13.85-.22,16-4.71,23.15-20.8,20.61-36.57C58.3,760.62,58,753.23,57,745.94a.14.14,0,0,1,0,.06s4.68-16.6-4.33-29.18c-7.9-11-23.82-19.36-38.26-18.43a44.47,44.47,0,0,0-17.72,4.74A28.68,28.68,0,0,0-11,708.89c-.84.91-6.06,7.36-5,8.57l-4.91,5a27.28,27.28,0,0,0-7.72,21.26,27.23,27.23,0,0,0,4.16,12.42l3.11,4.92a13,13,0,0,1,1.46-.67l-.34.68c-4.63,2.65-4.36,9-2.52,13.81,2.14,5.56,6.35,10,11.87,10.56l.54,1.35,2.53,7.59-68.26,37.92-7.19,12.51C-87,855.66-102.09,902.61-99.14,944.52Zm207.78,148.16a1.8,1.8,0,0,0,.22.06c4.29.53,9.61-.08,10.89-5.83v16.66H108.64Z" fill="#fff"/>
              <path d="M77,805.21,63.65,825.28,77,840.88,30.21,901.06,7.92,907.75l-42.35-71.33,11.15-8.92-11.15-20.06-37.38,19.73-8.89,13s-16.14,52-16.14,87.66V950.1l13,12.26,24.89,3.35v64.64l78,29,82.47-24.51,4.46,6.68h29l2.23-8.91L130.52,1017,135,994.68,117.15,852l-6.69-26.74Z" fill="#fff"/>
              <path d="M50.27,898.83-3.22,910v6.69l-8.92,4.45-6.69,15.61s-2.22,17.83,4.46,29L21.3,959l2.23-6.69-2.23-6.69L57,939l-6.69-37.9Z" fill="#fff"/>
              <g>
                <path d="M-27.37,1247.9l-4.45,16.66-10.39,17.59a14.11,14.11,0,0,0-.27,13.87h0A14.11,14.11,0,0,0-30,1303.45h7.63A14.11,14.11,0,0,0-10,1296.09l13.18-24.15a23.06,23.06,0,0,0,2.81-11v-20.8Z" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <path d="M67.06,1284.56l9.24,16a11.59,11.59,0,0,1,.52,10.59h0a11.59,11.59,0,0,1-10.55,6.79H46.87a27.85,27.85,0,0,1-23.56-13h0a33.18,33.18,0,0,1-5.13-17.72v-12.62Z" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <path d="M21.14,705.47s26-.76,34.4,23.4c4.1,11.79,3.71,24.46,5.68,36.66,2.54,15.77-1.81,32.35-17.55,37.36-11.54,3.67-30.12-1.32-37.38-11.42" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <path d="M45.1,752.12l-.09,9.8a7.11,7.11,0,0,1,3.67,5.93c.12,3.18-1.43,6.44-6.07,5.66" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <circle cx="30.26" cy="766.42" r="3.65" fill="#231815"/>
                <circle cx="53.81" cy="761.78" r="3.65" fill="#231815"/>
                <path d="M4.2,736.56h0a29.25,29.25,0,0,1,28.13-3.18c6.31,2.62,12.76,5.89,15.65,9.19a10.73,10.73,0,0,0-2.24-8.6s11.38,2.56,13,9.7c0,0,4.68-16.6-4.33-29.18-7.9-11-23.82-19.36-38.25-18.43A44.42,44.42,0,0,0-1.52,700.8a28.73,28.73,0,0,0-7.73,5.76c-.83.91-6.06,7.36-5,8.58Z" fill="#221714" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <path d="M-14.26,715.14l-4.91,5a27.24,27.24,0,0,0-7.71,21.26h0a27.18,27.18,0,0,0,4.15,12.43l3.12,4.91s6-3.59,9.67.62l6.15,9.3h0a5.47,5.47,0,0,0,5.15-6l-.69-7.82c3.13-10,4.08-24,4.08-24" fill="#221714" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <path d="M33,783.58s6.43,2.86,12.15-1.43" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.86"/>
                <polyline points="47.25 801.46 47.06 812.38 23.73 831.27 39.83 850.62" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <line x1="57.98" y1="792.16" x2="108.17" y2="824.6" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <path d="M57.06,801.27,24.84,897.93" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <polyline points="-5.15 811.27 18.18 830.15 0.9 856.39" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <path d="M31.13,840.15H12s-2.75,3.34-1.64,6.67-3.33,8.89,0,10,17.78,0,17.78,0" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <path d="M14.85,847.93s7.77,0,12.22,2.22" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <line x1="25.64" y1="856.99" x2="31.51" y2="875.71" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <line x1="15.96" y1="901.26" x2="-16.26" y2="805.71" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <line x1="12.99" y1="857.71" x2="7.07" y2="874.6" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <line x1="77.85" y1="805.33" x2="63.73" y2="825.71" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <polyline points="58.18 824.6 75.95 839.04 39.29 889.04" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <polyline points="-16.26 824.6 -35.15 834.6 -0.71 891.26" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <path d="M108,839.36s-16,18-19.31,35.75" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <path d="M118.17,851.26s13.33,111.11,15.56,133.33-6.67,26.66-3.34,35.55,8.89,12.22,4.45,21.11H108.17" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <polyline points="91.58 922.3 100.39 1036.81 23.73 1055.69 24.25 1014.53" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <polyline points="5.96 1055.69 -59.59 1030.14 -60.7 972.37" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <polyline points="134.84 1041.25 132.61 1074.58 128.17 1083.47" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <path d="M100.39,1036.81s1.12,43.32-2.22,68.88-21.11,91.1-21.11,114.43-2.22,34.45-4.44,43.33c-1.59,6.38.81,6.47,1.89,10.51a10.23,10.23,0,0,1-3.45,10.42h0a10.4,10.4,0,0,1-6.64,2.41H24.06a10.32,10.32,0,0,1-10.33-9.28,26.44,26.44,0,0,1,1.12-10.72c3.33-10,1.11-17.78,1.11-32.22s3.33-80,3.33-80l-6.67-63.32,11.11-35.56Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <path d="M-59.59,1030.14s-1.11,61.11,1.11,92.21,14.44,50,18.89,72.22-2.23,27.78,0,37.78-3.34,11.11,1.11,21.1H1.57a11.89,11.89,0,0,0,10.86-7h0a12,12,0,0,0,1-6.14l-4.06-36.52a91.7,91.7,0,0,1,0-20.55l7.71-67,6.66-60.55H9.29Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <path d="M-7.06,932.68,21.32,922a4.24,4.24,0,0,1,5.43,2.43h0a4.22,4.22,0,0,1-2,5.29L14,935.08s7.59,1.69,6.15,8.2a7,7,0,0,1,1.57,8.31s3.85,3.13-.6,7.83L-.3,963.77" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <path d="M-.92,930.36l-2.46-11.12a8.34,8.34,0,0,1,6.17-9.92l37.75-9.25a8.36,8.36,0,0,1,10.23,6.74l3.91,23.48a8.36,8.36,0,0,1-6.24,9.49l-26.31,6.48" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <rect x="100.39" y="1101.24" width="34.44" height="105.55" rx="9.67" transform="translate(235.23 2308.04) rotate(-180)" stroke-width="2.33" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
                <polygon points="127.69 1101.59 127.69 1081.72 122.33 1074.41 109.28 1074.41 104.84 1082.36 104.84 1101.59 110.39 1101.59 110.39 1083.47 112.62 1080.13 120.39 1080.13 121.5 1084.58 121.5 1101.24 127.69 1101.59" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
                <path d="M107.06,1052.36l2.22,22.22-1.11,10h0a3.66,3.66,0,0,0,2.44,5.83c4.29.53,9.62-.08,10.89-5.83a84.78,84.78,0,0,0,2.23-16.67l5.55-10" fill="#f2f2f2" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
              </g>
              <path d="M30.21,751.72a7.91,7.91,0,0,0-6.68,4.46" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.33"/>
            </g>
          </g>
          <PulseCircle animationDelay="-1s" onClick={() => handleOnClick(2)} cx="599.83" cy="769.02" r="41.98" fill="#f6e105" style={{ mixBlendMode: 'multiply' }}/>
          <g clip-path="url(#c)">
            <g>
              <g>
                <path d="M877.77,1034.81a33.3,33.3,0,0,0,9.76-30.71l-4.73-23.7-.84.26v0l.85-.22c-1.58-5.64-3.08-11.55-4.76-17.48v-.07h0c-4.37-15.49-9.92-31.1-21.06-42.41-13.65-13.87-33-20.19-51.26-25.37l.52,1.47-.9-.83-3.11-4.16h0c10-4.94,11.06-14.78,8.08-20.37a9,9,0,0,1,1.54,3.75c-.06-.29,4.84-6.08,5.23-7,5.35-12.89-5.85-26.95-17-32.14a1.61,1.61,0,0,0-2.21.55A47.47,47.47,0,0,0,780.2,828c-16.82-3.68-32.73,3.82-40.54,16.31h0s-17.76,7.32-9.21,28.24v0l.19.41c.22.51.44,1,.69,1.57l0,0,1.43,3.08a4.88,4.88,0,0,0-.85,1.12c-1.12,2.11,2.39,11.43,6.11,12.8l-.22-3.08,1,10.69c-16.4,7.27-29.64,12.57-39.38,34.79a187.38,187.38,0,0,0-12.32,38.81l-.7.93v2.06l-8.82,35a26.74,26.74,0,0,0,6.26,24.67h0a26.77,26.77,0,0,0,21.85,8.55l5.5-.44,6.92,5.47-.28,1c-.17-.88-.32-1.42-.32-1.42l-4.95,18.82c.18-.19.37-.39.56-.57L708.87,1082l1.32,7.93.79.53-.15,0a38.36,38.36,0,0,0,9.32,8,24.74,24.74,0,0,1-3.46-2.42s-3.81,19.13-7.62,46.8,5.63,63.79,5.52,76.61,5,20.8,6.3,29.14,4.75,12.41.8,21.68c-2.57,6,1.34,9.13,4.39,10.57l-6.9,23.78s-5.07,12.87,5,16.36c5.86,2,12.07,1.8,16.23,1.25a11,11,0,0,0,7.57-4.63,74,74,0,0,0,7.5-13c2.12-5.28,1.15-16.83.47-22.71h.26c5.74-5.18,3.44-9.14.93-13.72s.62-13.75,2.59-22.92,13.69-72.63,13.69-72.63,2,50.87,2.29,60.25-.61,23.34,2,32.84-2.91,11.06-3.91,24.94c-.88,12.13,8.11,15.82,15.55,16.61v10.23c1.83,10.36,9.75,13.83,22.13,18.59,6.57,2.52,15.26,1.37,22-.27a12.72,12.72,0,0,0,7.49-19.56l-7.7-11.21H821.7c3.56-.82,6.31-1.9,7.4-3.32,3.89-5.07-1.11-7.71-.64-14.63s7.87-4.19,5.27-18.09,3.66-23.42,9.27-42.8,7-51.9,13.32-71.62c5.48-17.25,3.7-53.46,3.17-62.24v-6.21a26.72,26.72,0,0,0,1.72-13.82,30.28,30.28,0,0,0-3.72-11.37c-4.13-6.68-2.78-14.72-2.24-17.12A33.29,33.29,0,0,0,877.77,1034.81Z" fill="#fff"/>
                <path d="M708.63,1082.33l3.94-15C707.18,1073.21,708.63,1082.33,708.63,1082.33Z" fill="#f2f2f2"/>
              </g>
              <path d="M746.53,914.86s17.17,29.07,23.78,31.71c0,0,27.75-27.75,29.07-35.68L802,893.72l21.14,7.93s50.2,5.28,59.45,79.27l-49.54,12.55,1.32,11.89-26.43,4-18.49-6.61-11.89,2.64-5.29-13.21H743.22l-5.28,5.28,4,13.22s-21.14-2.65-23.78-1.32V989.51l-31.71-15.86S697,938,706.23,922.13c0,0,14.53-19.82,33-22.47C739.26,899.66,744.55,908.91,746.53,914.86Z" fill="#fff"/>
              <path d="M717.46,1044.34l1.32,13.21-7.93,11.89s-10.56,13.21,5.29,26.43l63.42,23.78,74-19.82,7.92-17.18-1.32-17.17-5.28-10.57v-10.57L773,1036.41,752.47,1041h-5.28l-29.07,1.33Z" fill="#fff"/>
              <g>
                <path d="M805.3,844.23C800.59,836.3,789.17,830,780.2,828c-24.82-5.44-47.69,13.48-45.41,36.83,0,0,2,9.32,3.17,16s-2.12,28.69,14.82,38c0,0,20.25,11.74,37.25-11.14" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.56"/>
                <path d="M756.2,883.91l1.65,8.5s-5.59,11.33,5.25,10.26" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.56"/>
                <path d="M767.1,909.67a73.66,73.66,0,0,1-7.9-.35" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.56"/>
                <circle cx="772.95" cy="893.78" r="3.43" fill="#231815"/>
                <circle cx="748.19" cy="892.44" r="3.43" fill="#231815"/>
                <path d="M778.27,883.83c-1.64-.37-12.92-6.54-11.11-.26a3.62,3.62,0,0,0,1.52,1.89,11.06,11.06,0,0,0,6.78,1.55c.81-.07,2.24-.15,2.8-.86C778.56,885.77,779.2,884,778.27,883.83Z" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.56"/>
                <path d="M739.83,882.44c1.68-.06,13.9-4.06,11,1.79a3.66,3.66,0,0,1-1.84,1.58,11.09,11.09,0,0,1-7,.27c-.78-.22-2.18-.57-2.6-1.36C739.19,884.29,738.88,882.48,739.83,882.44Z" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.56"/>
                <path d="M798.51,912s-12,19.25-24.7,31.38" fill="#f5f1df" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.56"/>
                <path d="M764.45,942.13S750.4,926,747.19,915.52" fill="#f5f1df" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.56"/>
                <path d="M882.8,980.4l4.73,23.7a33.3,33.3,0,0,1-9.76,30.71h0a33.32,33.32,0,0,1-25.48,9L802.38,1040l-28.81-3.08s-3.5-.34-3-6.44a7.59,7.59,0,0,1,.85-8.29l-14-4.25-3.53-6.62,23.38,4,6.88-4" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.56"/>
                <path d="M738.81,899.12c-16.4,7.27-29.64,12.57-39.38,34.79-6.21,14.16-9.46,25.28-12.64,40.32l25.22,14" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.56"/>
                <line x1="770.38" y1="947.21" x2="770.38" y2="983.19" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.56"/>
                <path d="M742.47,912.09l-4.3,7.64,11.19,31.2a10.93,10.93,0,0,1,8.37-7.58" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.56"/>
                <path d="M710.83,1090.5c10.76,13.17,33.4,19.86,67.59,20.62,21.68.48,46.81-4.74,62.31-8.63a27.24,27.24,0,0,0,20.48-30.08,30.28,30.28,0,0,0-3.72-11.37c-4.91-7.94-2-14.72-2-14.72" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.56"/>
                <path d="M717.52,1048.53s3.37,11.84-3.6,17.54-5.29,16.26-5.29,16.26" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.56"/>
                <line x1="774.57" y1="1047.98" x2="774.57" y2="1082.33" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.56"/>
                <path d="M686.79,974.23l-9.2,36.47a26.77,26.77,0,0,0,6.26,24.68h0a26.77,26.77,0,0,0,21.85,8.55l45.09-3.64s13.94,1.6,22.78-3.4" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.56"/>
                <path d="M739.66,844.32s-18.9,7.78-8.33,30.24" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.56"/>
                <path d="M736.84,874.85s-3.79,1.75-4.91,3.86,2.39,11.43,6.11,12.8" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.56"/>
                <path d="M859.37,1090.5s3.22,44.46-3,64.18-7.72,52.24-13.32,71.62-11.87,28.9-9.27,42.8-4.8,11.16-5.27,18.09,4.53,9.56.64,14.63-29,5.8-36.7,5.72-19.65-2.91-18.64-16.79,6.52-15.43,3.91-24.94-1.69-23.47-2-32.84-2.29-60.25-2.29-60.25-11.71,63.45-13.69,72.63-5.09,18.33-2.59,22.92,4.81,8.54-.93,13.72H729.12s-11.38-2.37-7.43-11.64.49-13.34-.8-21.68-6.41-16.33-6.3-29.14-9.33-48.94-5.52-76.61,7.62-46.8,7.62-46.8,15.48,14.18,61.73,15C778.42,1111.12,843.63,1110.34,859.37,1090.5Z" fill="#231815" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.56"/>
                <path d="M726.62,1279.05l-7.44,25.65s-5.07,12.87,5,16.36c5.86,2,12.07,1.8,16.23,1.25a11,11,0,0,0,7.57-4.63,74,74,0,0,0,7.5-13c2.92-7.25,0-26.32,0-26.32Z" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.56"/>
                <path d="M753.83,1134.58c.87,2,1.76,3.89,2.71,5.77a62.13,62.13,0,0,0,3.05,5.49,37.48,37.48,0,0,0,3.61,5,20.61,20.61,0,0,0,4.52,3.81l.06,0a1.18,1.18,0,0,1,.33.3,28.41,28.41,0,0,1,2.42,4.12,33.23,33.23,0,0,1,1.76,4.39,26.81,26.81,0,0,1,1,4.6,20.2,20.2,0,0,1,.08,4.66,39.91,39.91,0,0,0-1.14-4.43,35.94,35.94,0,0,0-1.65-4.18c-.6-1.37-1.32-2.68-2.05-4s-1.59-2.54-2.42-3.68l.4.34a18.57,18.57,0,0,1-5.22-4.36,29.66,29.66,0,0,1-3.62-5.52,39.64,39.64,0,0,1-2.44-6A35.19,35.19,0,0,1,753.83,1134.58Z" fill="#f5f1df"/>
                <line x1="752.89" y1="971.88" x2="731.53" y2="971.88" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.56"/>
                <path d="M717.54,953.6s4.4,11.35,3.57,23.14-3.82,15-3.57,23a86.72,86.72,0,0,1,0,9.9s16.19-.85,24.37-.12l-3.16-10.09a5.36,5.36,0,0,1,4.68-6.93l23.42-1.91a5.34,5.34,0,0,1,5.46,3.5l7.25,19.82" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.56"/>
                <path d="M757.34,1011.29l6.54-5.7-.89-.9a5.22,5.22,0,0,0-5.59-1.18l-15.49,6v10.35" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.56"/>
              </g>
            </g>
          </g>
        </g>
        <g>
          <rect x="0.82" y="1094.33" width="772.71" height="263.67" fill="#b3b3b3"/>
          <path d="M150.36,1131.59H654.08a0,0,0,0,1,0,0v122.49a30.41,30.41,0,0,1-30.41,30.41H119.95a0,0,0,0,1,0,0V1162A30.41,30.41,0,0,1,150.36,1131.59Z" fill="#e6e6e6"/>
          <path d="M687.24,1234.65a7.92,7.92,0,1,1,7.92-7.91" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.24"/>
          <circle cx="720.08" cy="1226.74" r="7.92" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.24"/>
          <polygon points="724.93 1149.94 703.66 1150.23 682.39 1149.89 679.88 1147.25 679.64 1147.25 682.39 1144.13 703.66 1144.04 724.93 1143.72 727.59 1147.25 727.81 1147.25 724.93 1149.94" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.24"/>
          <polygon points="724.93 1166.92 703.66 1166.82 682.39 1166.72 679.96 1163.92 679.25 1163.92 682.39 1160.93 703.66 1160.88 724.93 1160.8 727.59 1163.92 728.35 1163.92 724.93 1166.92" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.24"/>
          <polygon points="724.93 1183.19 703.66 1183.59 682.39 1183.57 679.04 1180.59 679.01 1180.59 682.39 1177.66 703.66 1177.51 724.93 1177.31 728.37 1180.59 727.43 1180.59 724.93 1183.19" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.24"/>
          <polygon points="724.93 1199.71 703.66 1199.73 682.39 1199.93 679.49 1197.25 678.99 1197.25 682.39 1194.25 703.66 1194.58 724.93 1194.36 727.42 1197.25 727.41 1197.25 724.93 1199.71" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.24"/>
          <path d="M50.92,1234.65a7.92,7.92,0,1,1,7.91-7.91" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.24"/>
          <circle cx="83.76" cy="1226.74" r="7.92" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.24"/>
          <polygon points="88.61 1149.94 67.34 1150.23 46.06 1149.89 43.55 1147.25 43.31 1147.25 46.06 1144.13 67.34 1144.04 88.61 1143.72 91.27 1147.25 91.49 1147.25 88.61 1149.94" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.24"/>
          <polygon points="88.61 1166.92 67.34 1166.82 46.06 1166.72 43.63 1163.92 42.93 1163.92 46.06 1160.93 67.34 1160.88 88.61 1160.8 91.26 1163.92 92.03 1163.92 88.61 1166.92" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.24"/>
          <polygon points="88.61 1183.19 67.34 1183.59 46.06 1183.57 42.72 1180.59 42.69 1180.59 46.06 1177.66 67.34 1177.51 88.61 1177.31 92.04 1180.59 91.11 1180.59 88.61 1183.19" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.24"/>
          <polygon points="88.61 1199.71 67.34 1199.73 46.06 1199.93 43.17 1197.25 42.66 1197.25 46.06 1194.25 67.34 1194.58 88.61 1194.36 91.09 1197.25 91.08 1197.25 88.61 1199.71" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.24"/>
          <polygon points="362.85 1052.17 388 1095.21 316.72 1095.21 320.68 1044.33 362.85 1052.17" fill="#b3b3b3"/>
          <path d="M362.76,1052H-39.59v-48.19H273.33A107.08,107.08,0,0,1,362.76,1052Z" fill="#b3b3b3"/>
          <path d="M-39.59,1017.22H269.24a65.88,65.88,0,0,1,28.64,6.54h0a65.93,65.93,0,0,1,37.3,59.39v12.07H-39.59Z" fill="#e6e6e6"/>
          <line x1="330.46" y1="1020.17" x2="344.99" y2="1032.36" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.13"/>
          <path d="M-44,1017.31H268a71.19,71.19,0,0,1,44.95,15.77c7.82,6.35,14.72,14.66,16.76,25" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.13"/>
          <path d="M332,1066.36s3.63,19,1.3,28.81H-16" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.13"/>
          <line x1="168.79" y1="1078" x2="-0.61" y2="1078" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.13"/>
          <path d="M-10.33,1038H289.21c3.2,0,5.79,3.56,5.79,8v24.1c0,4.39-2.59,8-5.79,8H185.83" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.13"/>
          <polyline points="362.5 1052.5 388 1095 760 1095" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.13"/>
          <path d="M-42.44,1003.82H269.62a128.07,128.07,0,0,1,35.3,4.9,71.13,71.13,0,0,1,13.86,5.22" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.13"/>
          <polyline points="181.53 1284.53 160.83 1284.97 140.13 1284.38 119.06 1285.27 119.59 1266.49 119.33 1248.12 119.23 1229.75 119.2 1211.38 119.61 1193 119.85 1174.62 119.84 1156.24 125.67 1139.13 141.88 1131.36 160.55 1131.48 179.23 1131.17 197.9 1131.68 216.57 1131.3 235.25 1131 253.93 1131.39 272.6 1130.82 291.27 1130.85 309.95 1130.9 328.63 1130.77 347.3 1131.1 365.98 1131.32 384.66 1130.76 403.33 1131.69 422 1130.71 440.68 1130.92 459.36 1130.99 478.03 1130.84 496.71 1131.3 515.38 1131.66 534.06 1131.78 552.74 1131.3 571.42 1130.91 590.1 1131.85 608.79 1131.87 627.47 1130.93" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.35"/>
          <polyline points="653.94 1147.8 653.97 1165.79 653.5 1183.78 653.65 1201.78 653.87 1219.77 654.02 1237.77 653.04 1255.77 646.39 1275.81 627.38 1284.78 608.48 1284.68 589.59 1284.29 570.69 1285.02 551.79 1284.86 532.89 1284.92 513.99 1285.01 495.09 1284.52 476.19 1285.32 457.3 1284.56 438.4 1284.86 419.5 1284.5 400.6 1284.37 381.71 1284.62 362.81 1285 343.91 1285.1 325 1285.44 306.11 1284.52 287.2 1284.75 268.3 1284.63 249.39 1285.32 230.48 1285.4" fill="none" stroke="#231815" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.35"/>
          <text transform="translate(424.53 1165.47) rotate(0.04) scale(0.98 1)" font-size="15.6" font-family="'LantingHei TC', 'Microsoft Jhenghei', sans-serif" letter-spacing="0.05em">老人人口上升183萬(+53%)</text>
          <text transform="translate(421.52 1214.54) rotate(0.04) scale(0.98 1)" font-size="15.6" font-family="'LantingHei TC', 'Microsoft Jhenghei', sans-serif" letter-spacing="0.05em">勞動人口減少161萬(-10%)</text>
          <text transform="translate(421.8 1259.86) rotate(0.04) scale(0.98 1)" font-size="15.6" font-family="'LantingHei TC', 'Microsoft Jhenghei', sans-serif" letter-spacing="0.05em">幼年人口減少27萬(-9%)</text>
          {/* <polyline points="281.5 1253.86 207.75 1253.86 207.75 1237.96 281.5 1237.96" /> */}
          <GrowUp x="207.75" y="1237.96" width="73.75" height="15.9" fill="#999" stroke="#000" stroke-miterlimit="10" stroke-width="1.65" />
          <GrowUp x="207.52" y="1179.65" width="173.1" height="58.31" fill="#999" stroke="#000" stroke-miterlimit="10" stroke-width="1.68" />
          <GrowUp x="207.74" y="1152.09" width="56.81" height="27.56" fill="#999" stroke="#000" stroke-miterlimit="10" stroke-width="1.68"/>
          <GrowUp x="264.78" y="1152.09" delay={1000} width="28.57" height="27.56" fill="#f6e105" stroke="#000" stroke-miterlimit="10" stroke-width="1.91"/>
          <line x1="183.31" y1="1179.56" x2="626.07" y2="1179.56" fill="none" stroke="gray" stroke-miterlimit="10" stroke-width="1.44"/>
          <line x1="183.31" y1="1238.11" x2="626.07" y2="1238.11" fill="none" stroke="gray" stroke-miterlimit="10" stroke-width="1.44"/>
          <text transform="translate(150.53 1238.77) scale(0.98 1)" font-size="11.76" font-family="'LantingHei TC', 'Microsoft Jhenghei', sans-serif" letter-spacing="0.05em">15歲</text>
          <text transform="translate(150.53 1179.19) scale(0.98 1)" font-size="11.76" font-family="'LantingHei TC', 'Microsoft Jhenghei', sans-serif" letter-spacing="0.05em">65歲</text>
          <g>
            <GrowUp x="380.62" y="1179.56" delay={1000} width="10.7" height="58.54" fill="#fff" stroke="#000" strokeMiterlimit="10" strokeWidth="1.44" strokeDasharray="2.6 2.6"/>
          </g>
          <GrowUp x="281.5" y="1238.7" delay={1000} width="11.52" height="14.93" fill="#fff" stroke="#000" stroke-miterlimit="10" stroke-width="1.5" stroke-dasharray="3.01"/>
          <text transform="translate(35.92 1064) rotate(0.04) scale(0.98 1)" font-size="19.36" font-family="'LantingHei TC', 'Microsoft Jhenghei', sans-serif" letter-spacing="0.05em">十年後台灣社會的人口結構</text>
        </g>
      </g>
    </SVG>
  );
};

export default Background;
