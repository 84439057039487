import React, { PureComponent } from 'react'
import { compose } from 'redux';

// import completdashboard from './completedashboard.svg';

import Background from './Background';
import BackgroundMobile from './BackgroundMobile';
import CompleteDashboard from './CompleteDashboard';
import Card, { ages } from './Card';

import Layout from '../Layout';
import Container from '../../components/Container'
import Box from '../../components/Box'
import Button from '../../components/Button'
// import BackgroundImage from '../../components/BackgroundImage'
import VerticalCenter from '../../components/VerticalCenter'
import Link from '../../components/Link'
import Text from '../../components/Text'
import Title from '../../components/Title'
import { mobileOrDesktop } from '../../components/ThemeProvider/theme';

import withResponsive from '../../hoc/withResponsive'

import withReducer from '../QuestionPage/hoc'

import storeAnswers from '../../services/googleSheets/storeAnswers';
import resultHoc from '../Result/hoc'

class Destination extends PureComponent {
  componentDidMount() {
    const {
      answers,
      startTimes,
      requestAnswerStorage,
      answerStorageLoading,
      answerStorageResponse,
    } = this.props;
    if (!answerStorageLoading && !answerStorageResponse) {
      const now = new Date();
      const { size } = startTimes;
      const timeData = startTimes.reduceRight((all, thisPageStart, qId) => {
        const answer = answers[qId];
        const nextPageStart = qId === size - 1 ? now : startTimes.get(qId + 1);
        all[qId] = {
          answerTime: answer ? answer[2] - thisPageStart : '',
          nextTime: answer ? nextPageStart - answer[2] : nextPageStart - thisPageStart,
        };
        return all;
      }, []);
      requestAnswerStorage(answers.map((a, i) => [
        a && a[0],
        a && a[1],
        timeData[i].answerTime,
        timeData[i].nextTime
      ]));
    }
  }

  state = {
    age: 1
  }

  closeModal = () => {
    this.setState({ isOpen: false })
  }

  openModal = (age) => {
    this.setState({ isOpen: true, age })
    if (window.ga) {
      window.ga('send', 'event', '人群變化', ages[age].title);
    }
  }

  render() {
    const { isMobile } = this.props;
    const { isOpen , age} = this.state;
    return (
      <Layout>
        <Box position="relative" height="100%" overflow="hidden">
          <Box position="absolute" left="0" right="0" top="50%" transform="translateY(-50%)">
            {isMobile ? <BackgroundMobile handleOnClick={this.openModal} /> : <Background handleOnClick={this.openModal} />}
          </Box>
          <Card isOpen={isOpen} onRequestClose={this.closeModal} age={age} isMobile={isMobile} />
          {!isMobile && (
            <Box position="absolute" bottom="0" width="100%">
              <CompleteDashboard />
            </Box>
          )}
          <Box position="absolute" top="0" left="0" right="0" bottom="0" pointerEvents="none">
            <VerticalCenter>
              <Container textAlign="center">
                <Box>
                  <Box transform="rotate(-3deg)" my="2em">
                    <Title>
                      你已抵達目的地
                    </Title>
                    <Title>
                    「超高齡臺灣」
                    </Title>
                  </Box>
                  <Text fontSize="1.25em" fontWeight="bold" my="2em">找找看<br/>十年來，路上的人群如何變化</Text>
                </Box>
                <Box height={mobileOrDesktop('45vh', '50vh', '50vh')} />
              </Container>
            </VerticalCenter>
          </Box>
          <Box position="absolute" top={mobileOrDesktop('42%', '50%', '40%')} left="50%" transform="translateX(-50%)">
            <Button is={Link} borderRadius="3em" px={mobileOrDesktop('2em', '3em', '1em')} to="/result">點我看結果</Button>
          </Box>
        </Box>
      </Layout>
    );
  }
}

export default compose(withReducer, withResponsive, resultHoc, storeAnswers)(Destination)
