import React from 'react';

import old from './old.svg'
import young from './young.svg'
import teenager from './teenager.svg'

import Box from '../../../components/Box'
import Container from '../../../components/Container'
import Modal from '../../../components/Modal'
import Text from '../../../components/Text'
import BackgroundImage from '../../../components/BackgroundImage'

export const ages = [
  {
    img: teenager,
    title: '幼年',
    top: '10%',
    left: '75%',
    text: '- 2022年起總人口數負成長，2028年新生兒僅15.7萬\n- 相較2018年，國小新生入學人數可能減少1/3'
  },
  {
    img: young,
    title: '青壯年',
    top: '10%',
    left: '5%',
    text: '- 青壯年扶養負擔上升37%\n- 18歲人口將近減半'
  },
  {
    img: old,
    title: '老人人口',
    top: '10%',
    left: '80%',
    text: '- 每5個人中，就有1個65歲以上的老人; 每2.5個人中，就有1個75歲以上的老人'
  },
]

const Card = ({ isOpen, age, onRequestClose, isMobile }) => {
  const a = ages[age]
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      customStyles={isMobile ? {} : {maxWidth: '18em', top: `${a.top}`, left: `${a.left}`, transform: 'unset',}}
    >
      <Container px="1em">
        <Box width="5em" mx="auto">
          <BackgroundImage src={a.img} ratio={99 / 86} />
        </Box>
        <Box align="center">
          <Box
            border="1.5px solid"
            bg="yellow"
            borderRadius="2em"
            display="inline-block"
            px="2em"
            py="0.5em"
            my="1em"
          >
            {a.title}
          </Box>
        </Box>
        <Text whiteSpace="pre-wrap" lineHeight="1.5">
          {a.text}
        </Text>
      </Container>
    </Modal>
  );
};

export default Card;
