import React, { PureComponent } from 'react';
import TWEEN from '@tweenjs/tween.js';

class GrowUp extends PureComponent {
  state = { width: 0 }

  componentDidMount() {
    const { duration, delay, width } = this.props
    const tween = new TWEEN.Tween({ width: 0 });
    tween
      .to({ width: parseFloat(width) }, duration)
      .delay(delay)
      .onUpdate((state) => {
        this.setState(state)
      });

    this.animate()
    tween.start()
  }

  animate = () => {
    requestAnimationFrame(this.animate);
    TWEEN.update();
  }

  render() {
    const { duration, delay, width, ...props } = this.props
    return (
      <rect width={this.state.width} {...props} />
    );
  }
}

GrowUp.defaultProps = {
  duration: 1000,
  delay: 0,
}

export default GrowUp;
